import React from 'react';
import PropTypes from 'prop-types';
import { gql, compose, graphql } from 'react-apollo';
import { queries, mutations } from '../../graphql';
import { Certificate } from '../../components';
import { message } from 'antd';
import { Loading } from 'modules/common/components';

const CapacityBuildingContainer = (props, { __ }) => {
  const { companyByUserQuery } = props;

  if (companyByUserQuery.loading) {
    return <Loading />;
  }

  const save = doc => {
    const { certificateInfoEdit, history } = props;

    certificateInfoEdit({ variables: { certificateInfo: doc } })
      .then(() => {
        companyByUserQuery.refetch();
        message.success(__('Succesfully saved'));
        history.push('/');
      })
      .catch(error => {
        message.error(error.message);
      });
  };

  const updatedProps = {
    ...props,
    save,
    data: {
      ...companyByUserQuery.companyByUser.certificateInfo,
    },
  };

  return <Certificate {...updatedProps} />;
};

CapacityBuildingContainer.propTypes = {
  companyByUserQuery: PropTypes.object,
};

CapacityBuildingContainer.contextTypes = {
  __: PropTypes.func,
};

export default compose(
  graphql(gql(queries.companyByUser), {
    name: 'companyByUserQuery',
  }),

  // mutations
  graphql(gql(mutations.certificateInfo), {
    name: 'certificateInfoEdit',
  })
)(CapacityBuildingContainer);
