import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose, gql, graphql } from 'react-apollo';
import PropTypes from 'prop-types';
import { Common } from '../../components';
import { mutations } from '../../graphql';
import { message } from 'antd';

const ManageSuppliersDowContainer = props => {
  const { configsSaveManageSuppliersDowMutation } = props;

  const mainAction = doc => {
    configsSaveManageSuppliersDowMutation({ variables: { doc } }).then(() => {
      message.success('Saved Successfully');
    });
  };

  const updatedProps = {
    ...props,
    mainAction,
  };

  return <Common {...updatedProps} name="manageSuppliers" />;
};

ManageSuppliersDowContainer.propTypes = {
  configsSaveManageSuppliersDowMutation: PropTypes.func.isRequired,
};

export default compose(
  graphql(gql(mutations.configsSaveManageSuppliersDow), {
    name: 'configsSaveManageSuppliersDowMutation',
  })
)(withRouter(ManageSuppliersDowContainer));
