const validationRequestParams = `
  $awardedCwNumber: String
  $awardedCwNumberFile: JSON
  $awardedPoNumber: String
  $awardedPoFile: JSON
  $distributorType: [String]
  $distributorFile: JSON
  $_id: String
`;

const validationRequestFields = `
  awardedCwNumber: $awardedCwNumber
  awardedCwNumberFile: $awardedCwNumberFile
  awardedPoNumber: $awardedPoNumber
  awardedPoFile: $awardedPoFile
  distributorType: $distributorType
  distributorFile: $distributorFile
  _id: $_id
`;

const roleRelatedValidationParams = `
  $validationId: String
  $adminReason: String
  $adminReasonFile: JSON
  $userRoleAvailableProductCodes: [String]
  $userRoleAwardedRfqCodes: [String]
  $action: String

  $managerReason: String
  $managerReasonFile: JSON
  $validationResponseId: String
  $adminName: String
`;

const roleRelatedValidationFields = `
  validationId: $validationId
  adminReason: $adminReason
  adminReasonFile: $adminReasonFile
  userRoleAvailableProductCodes: $userRoleAvailableProductCodes
  userRoleAwardedRfqCodes: $userRoleAwardedRfqCodes
  action: $action

  managerReason: $managerReason
  managerReasonFile: $managerReasonFile
  validationResponseId: $validationResponseId
  adminName: $adminName
`;

const setValidationPermission = `
  mutation setValidationPermission($companyId: String){
    setValidationPermission(companyId: $companyId)
  }
`;

const supplierValidationRequestCreated = `
  mutation supplierValidationRequestCreated(${validationRequestParams}){
    supplierValidationRequestCreated(${validationRequestFields}) {
      _id
    }
  }
`;

const createRoleValidationResponse = ` 
  mutation createRoleValidationResponse(${roleRelatedValidationParams}){
    createRoleValidationResponse(${roleRelatedValidationFields}){
      _id
    }
  }
`;

const createManagerValidationResponse = ` 
  mutation createManagerValidationResponse(${roleRelatedValidationParams}){
    createManagerValidationResponse(${roleRelatedValidationFields}){
      _id
    }
  }
`;

const fixValidationResponse = ` 
  mutation fixValidationResponse(
    $validationId: String 
    $userRoleAvailableProductCodes: [String]
    $userRoleAwardedRfqCodes: [String]
    $fixedReasonFile: JSON 
    $fixedReason: String
  ){
    fixValidationResponse(
    validationId: $validationId
    userRoleAvailableProductCodes: $userRoleAvailableProductCodes
    userRoleAwardedRfqCodes: $userRoleAwardedRfqCodes
    fixedReason: $fixedReason
    fixedReasonFile: $fixedReasonFile
    
  ){
      _id
    }
  }
`;

const adminValidateCodes = `
  mutation adminValidateCodes(${roleRelatedValidationParams}){
    adminValidateCodes(${roleRelatedValidationFields}){
      _id
    }
  }
`;

const declineValidationRequest = `
  mutation declineValidationRequest(${roleRelatedValidationParams}){
    declineValidationRequest(${roleRelatedValidationFields}){
      _id
    }
  }
`;

export default {
  setValidationPermission,
  supplierValidationRequestCreated,
  createManagerValidationResponse,
  createRoleValidationResponse,
  adminValidateCodes,
  declineValidationRequest,
  fixValidationResponse,
};
