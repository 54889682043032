export const createTreeData = data => {
  let treeData = [];
  for (const d of data) {
    let children = [];

    for (const action of d.actions) {
      children = [...children, { title: action.description, value: action.name }];
    }

    treeData = [...treeData, { title: d.description, value: d.name, children }];
  }

  return treeData;
};
