import React from 'react';
import { withRouter } from 'react-router';
import { Checkbox, Form, Input, Button, Card, Popconfirm } from 'antd';
import {
  Uploader,
  ProductsTree,
  ExtendedField as Field,
  BaseForm,
} from 'modules/common/components';
import { getFlatProductsTree } from 'modules/common/utils';
import { withCurrentUser } from 'modules/auth/containers';

const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;
const { TextArea } = Input;

class ValidationRoleForm extends BaseForm {
  constructor(props, context) {
    super(props, context);

    const { locale = 'en' } = context;
    this.flatProductsInfo = getFlatProductsTree(locale);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.state = { files: [], adminReason: '', managerReason: '' };
  }

  handleSubmit = (e, action) => {
    e.preventDefault();

    const { form, match, currentUser } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }

      values.validationId = match.params.id;
      values.action = action;
      values.adminName = currentUser.username;

      return this.props.responseSend(values);
    });
  };

  handleFixed = (e, action) => {
    e.preventDefault();
    let fixedValues = {};
    const { form, match } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }

      fixedValues = {
        validationId: match.params.id,
        fixedReason: values.fixedReason,
        fixedReasonFile: values.fixedReasonFile,
        userRoleAvailableProductCodes: values.userRoleAvailableProductCodes,
        awardedRfqs: values.awardedRfqs,
        action: values.action,
      };

      return this.props.responseFixed(fixedValues);
    });
  };

  onFileUpload(files) {
    this.setState({ files: files.filter(file => file.url) });
  }

  generateOptions(flatProductsInfo, productsInfo) {
    return productsInfo.map(p => ({
      value: p,
      label: flatProductsInfo[p] || '',
    }));
  }

  awardedContactCWNumberInput = () => {
    const { awardedCwNumber, awardedCwNumberFile } = this.props.data.supplierInfo;

    return (
      <div>
        {awardedCwNumber && (
          <Field
            label="Awarded contract CW number:"
            initialValue={awardedCwNumber}
            name="awardedCwNumber"
            control={
              <Input placeholder="CW Number fill" style={{ marginRight: '25px' }} disabled />
            }
          />
        )}
        {awardedCwNumberFile.length !== 0 && (
          <Field
            label="Awarded contract CW number File:"
            initialValue={awardedCwNumberFile}
            name="awardedCwNumberFile"
            control={<Uploader defaultFileList={awardedCwNumberFile} disabled />}
          />
        )}
      </div>
    );
  };

  awardedPoandLa = () => {
    const { awardedPoNumber, awardedPoFile } = this.props.data.supplierInfo;

    return (
      <div>
        {awardedPoNumber && (
          <Field
            label="Awarded PO/LA Number:"
            initialValue={awardedPoNumber}
            name="awardedPoNumber"
            control={<Input disabled />}
          />
        )}
        {awardedPoFile.length !== 0 && (
          <Field
            label="Awarded PO/LA Number:"
            initialValue={awardedPoFile}
            name="awardedPoFile"
            control={<Uploader defaultFileList={awardedPoFile} disabled={true} />}
          />
        )}
      </div>
    );
  };

  distributorType = () => {
    const { distributorType, distributorFile } = this.props.data.supplierInfo;

    return (
      <div>
        {distributorType.length > 0 && (
          <Field
            viewMode={true}
            label="Distributor type:"
            initialValue={distributorType || []}
            name="awardedRfqProductCodes"
            control={<ProductsTree disabled={true} />}
          />
        )}
        {distributorFile.length > 0 && (
          <Field
            label="Distributor file:"
            initialValue={distributorFile}
            name="distributorType"
            control={<Uploader defaultFileList={distributorFile} disabled={true} />}
          />
        )}
      </div>
    );
  };

  supplierValidationInformations = () => {
    const {
      data: { supplierInfo, roleRelatedInfo = {} },
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { awardedRfqs, availableProducts, awardedRfqProductCodes, businessType } = supplierInfo;
    const { userRoleAvailableProductCodes = [] } = roleRelatedInfo || {};
    let placeholder = '';

    if (awardedRfqs.length === 0) {
      placeholder = 'Awarded RFQs';
    }
    return (
      <>
        <div style={{ display: 'flex' }}>
          <FormItem label="Check Available Product Codes:" style={{ marginRight: '25px' }}>
            {getFieldDecorator('userRoleAvailableProductCodes', {
              initialValue: userRoleAvailableProductCodes
                ? userRoleAvailableProductCodes
                : availableProducts,
            })(
              <CheckboxGroup
                options={this.generateOptions(this.flatProductsInfo, availableProducts)}
                className="horizontal capitalize"
              />
            )}
          </FormItem>

          <FormItem label="Check Awarded RFQ Product Codes:">
            {getFieldDecorator('userRoleAwardedRfqCodes', {
              initialValue: awardedRfqProductCodes,
            })(
              <CheckboxGroup
                options={this.generateOptions(this.flatProductsInfo, awardedRfqProductCodes)}
                className="horizontal capitalize"
                disabled
              />
            )}
          </FormItem>
        </div>

        <Field
          label="Awarded RFQ:"
          initialValue={awardedRfqs}
          name="awardedRfqs"
          optional={true}
          control={<ProductsTree searchPlaceholder={placeholder} disabled={true} />}
        />

        <FormItem label="Business type:">
          <Input disabled={true} defaultValue={businessType} />
        </FormItem>
      </>
    );
  };

  supplierInformation = () => {
    return (
      <div>
        {this.supplierValidationInformations()}
        {this.awardedContactCWNumberInput()}
        {this.awardedPoandLa()}
        {this.distributorType()}
      </div>
    );
  };

  validationResponseReason = () => {
    const { getFieldDecorator } = this.props.form;
    const {
      data: {
        roleRelatedInfo,
        supplierInfo: { validationRequestedType = '' },
      },
      currentUser,
    } = this.props;
    const { adminReasonFile, adminReason, managerReason, managerReasonFile = [] } =
      roleRelatedInfo || {};
    if (validationRequestedType === 'validated') {
      this.isDisable = true;
    }

    return (
      <div>
        <FormItem label={`Admin Reason - (${currentUser && currentUser.username})`}>
          {getFieldDecorator('adminReason', {
            initialValue: adminReason,
          })(<TextArea style={{ height: 50 }} maxLength={500} disabled={this.isDisable} />)}
        </FormItem>
        {getFieldDecorator('adminReasonFile', {})(
          <Uploader
            defaultFileList={adminReasonFile}
            onChange={this.onFileUpload}
            disabled={this.isDisable}
          />
        )}

        <div style={{ marginTop: '20px' }}>
          {managerReason && (
            <Field
              label="Manager Reason:"
              initialValue={managerReason}
              name="managerReason"
              control={<TextArea style={{ height: 50 }} maxLength={500} disabled />}
            />
          )}
          {managerReasonFile.length > 0 && (
            <Field
              label="Manager Reason File:"
              initialValue={managerReasonFile}
              name="adminReason"
              control={<Uploader defaultFileList={adminReasonFile} disabled />}
            />
          )}
        </div>
      </div>
    );
  };

  validationFixedReason = () => {
    const { getFieldDecorator } = this.props.form;
    const {
      data: {
        roleRelatedInfo,
        supplierInfo: { validationRequestedType = '' },
      },
    } = this.props;
    const { fixedReason, fixedReasonFile = [] } = roleRelatedInfo || {};
    if (validationRequestedType === 'validated') {
      return (
        <div>
          <FormItem label={'Fixed Reason'}>
            {getFieldDecorator('fixedReason', {
              initialValue: fixedReason,
            })(<TextArea style={{ height: 50 }} maxLength={500} />)}
          </FormItem>
          {getFieldDecorator('fixedReasonFile', {})(
            <Uploader defaultFileList={fixedReasonFile} onChange={this.onFileUpload} />
          )}
        </div>
      );
    }
  };

  declineValidationRequest = () => {
    const { form, match } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }

      values.validationId = match.params.id;

      return this.props.decline(values);
    });
  };

  renderOperations = () => {
    const {
      data: {
        supplierInfo: { validationRequestedType = '' },
      },
    } = this.props;

    if (validationRequestedType === 'validated') {
      return (
        <FormItem style={{ marginTop: '15px' }}>
          <Button
            type="primary"
            style={{ marginRight: '15px', width: '80px', alignItems: 'center' }}
            onClick={e => this.handleFixed(e)}
          >
            Enable
          </Button>
        </FormItem>
      );
    }

    const submitButton = validationRequestedType === 'open' ? 'Submit' : 'Edit';

    return (
      <FormItem style={{ marginTop: '15px' }}>
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginRight: '15px', width: '80px', alignItems: 'center' }}
          onClick={this.declineValidationRequest}
        >
          {'Decline'}
        </Button>

        <Button
          type="primary"
          style={{ marginRight: '15px', width: '80px', alignItems: 'center' }}
          onClick={e => this.handleSubmit(e)}
        >
          {submitButton}
        </Button>

        {validationRequestedType === 'approved' && (
          <Popconfirm title={'Are you sure ?'} onConfirm={e => this.handleSubmit(e, 'validate')}>
            <Button
              type="primary"
              style={{ marginRight: '15px', width: '80px', alignItems: 'center' }}
            >
              {'Validate'}
            </Button>
          </Popconfirm>
        )}
      </FormItem>
    );
  };

  render() {
    return (
      <Form style={{ width: '700px' }}>
        <Card title={this.props.data.companyInfo.basicInfo.enName || ''}>
          {this.supplierInformation()}
          {this.validationResponseReason()}
          {this.validationFixedReason()}
          {this.renderOperations()}
        </Card>
      </Form>
    );
  }
}

export default withCurrentUser(Form.create()(withRouter(ValidationRoleForm)));
