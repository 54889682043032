import { gql, graphql } from 'react-apollo';
import { queries, mutations } from '../graphql';
import { queries as userQuery } from 'modules/auth/graphql';

const generateVariables = ({ type, queryParams, rfqTypes }) => {
  const page = queryParams[`${type}page`] || 1;
  const perPage = queryParams[`${type}perPage`] || 15;
  const search = queryParams[`${type}search`] || '';
  const status = queryParams[`${type}status`] || '';
  const month = queryParams[`${type}month`] || null;

  return {
    page,
    perPage,
    search,
    status,
    type: type,
    month,
    rfqTypes: rfqTypes,
  };
};

const generator = (listName, totalCountName) => [
  graphql(gql(queries[listName]), {
    name: 'tendersTableQuery',
    options: props => {
      return {
        variables: generateVariables(props),
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
      };
    },
  }),

  graphql(gql(queries[totalCountName]), {
    name: 'tendersCountQuery',
    options: props => {
      return {
        variables: generateVariables(props),
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
      };
    },
  }),

  graphql(gql(userQuery.currentUser), {
    name: 'currentUserQuery',
  }),

  graphql(gql(queries.eoiUnsubscribedSuppliersCount), {
    name: 'unsubscribedSupplierCountQuery',
  }),

  graphql(gql(mutations.userEmailSubEoi), {
    name: 'userEmailSubEoiMutation',
    options: () => ({
      refetchQueries: ['currentUser'],
    }),
  }),
];

export default generator;
