import React from 'react';
import moment from 'moment';
import { dateFormat } from 'modules/common/constants';
import { Sidebar, Common } from 'modules/companies/components';
import { Search } from 'modules/common/components';
import { withRouter } from 'react-router';
import { Card, Row, Col, Button, Icon, Popconfirm } from 'antd';

class ValidationSuppliersList extends Common {
  sendSupplierToValidationAccess = companyId => {
    const { setValidationAccessOnSupplier } = this.props;

    setValidationAccessOnSupplier(companyId);
  };

  renderValidationColumns = () => {
    const {
      currentUser: { role },
    } = this.props;

    const validationColumn = [
      {
        title: 'Validation sent date',
        render: record => {
          const {
            validationRequestedSuppliers: { sentValidationRequestDate },
          } = record;

          if (sentValidationRequestDate)
            return moment(sentValidationRequestDate).format(dateFormat);
        },
      },
      {
        title: 'Approved date',
        render: record => {
          const {
            validationRequestedSuppliers: { approvedDate },
          } = record;

          if (approvedDate) return moment(approvedDate).format(dateFormat);
        },
      },
      {
        title: 'Validated date',
        render: record => {
          const {
            validationRequestedSuppliers: { validatedDate },
          } = record;

          if (validatedDate) return moment(validatedDate).format(dateFormat);
        },
      },
      {
        title: 'Declined date',
        render: record => {
          const {
            validationRequestedSuppliers: { adminDeclinedDate, managerDeclinedDate },
          } = record;

          if (adminDeclinedDate) return moment(adminDeclinedDate).format(dateFormat);
          if (managerDeclinedDate) return moment(managerDeclinedDate).format(dateFormat);
        },
      },
      {
        title: 'Status of Validation',
        render: record => {
          const {
            validationRequestedSuppliers: { validationRequestedType, _id },
          } = record;

          return <a href={`/validation/${_id}`}>{validationRequestedType}</a>;
        },
      },
      {
        title: 'Request sent date',
        render: record => {
          const {
            validationRequestedSuppliers: { buyerSetValidationDate },
          } = record;

          if (buyerSetValidationDate) return moment(buyerSetValidationDate).format(dateFormat);
        },
      },
    ];

    if (role === 'admin') {
      validationColumn.push({
        title: 'Send Validation',
        render: record => {
          const {
            validationRequestedSuppliers: { isValidationCreated },
          } = record;

          const disabled = isValidationCreated ? true : false;
          return (
            <Popconfirm
              title={'Are you sure?'}
              onConfirm={() => this.sendSupplierToValidationAccess(record._id)}
            >
              <Button type="primary" size="small" disabled={disabled}>
                Send
              </Button>
            </Popconfirm>
          );
        },
      });
    }

    return validationColumn;
  };

  render() {
    const { totalCount, generateExcel } = this.props;
    const { selectedCompanies } = this.state;

    const columns = this.getWrappedColumns([{}], this.renderValidationColumns(), false, true);

    return (
      <Row gutter={16}>
        <Sidebar
          suppliersCount={totalCount}
          checkedCount={selectedCompanies ? selectedCompanies.length : 0}
        />

        <Col span={19}>
          <Card title="Validation related Suppliers">
            <div className="table-operations">
              <Search />
              {this.renderSearchButtons()}

              <Button onClick={() => generateExcel(selectedCompanies)}>
                Export excel
                <Icon type="file-excel" />
              </Button>
            </div>

            {this.renderTable({
              rowSelection: {
                selectedCompanies,
                onChange: this.onSelectedCompaniesChange,
              },
              columns,
            })}
          </Card>
        </Col>
      </Row>
    );
  }
}

export default withRouter(ValidationSuppliersList);
