import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Icon, Table, Input } from 'antd';
import { rfqProductsColumns as rpc } from '../../constants';
import { generateTemplateUrl, xlsxHandler } from 'modules/common/utils';
import { controlValueParser, collectProducts } from '../utils';

const { Column } = Table;

class RfqTable extends Component {
  constructor(props, context) {
    super(props, context);

    const { requestedProducts } = props;

    const products = [];
    const perProductStates = {};

    requestedProducts.forEach(product => {
      products.push(product);

      perProductStates[`product__${product.productId}`] = product;
    });

    this.state = {
      products,
      ...perProductStates,
    };

    if (this.state.products.length === 0) {
      this.state.products = [{ productId: Math.random().toString() }];
    }

    this.onProductInputChange = this.onProductInputChange.bind(this);
    this.addProductRow = this.addProductRow.bind(this);
    this.handleFile = this.handleFile.bind(this);
  }

  addProductRow() {
    const { products } = this.state;

    products.push({ productId: Math.random().toString() });

    this.setState({ products });
  }

  handleFile(e) {
    xlsxHandler({
      e,
      parse: false,
      success: data => {
        // removing all prev products
        Object.keys(this.state).forEach(key => {
          if (key.startsWith('product__')) {
            delete this.state[key];
          }
        });

        const products = [];
        const perProductStates = {};

        data.forEach(row => {
          const productId = Math.random();

          if (row.length === 7) {
            const [
              purchaseRequestNumber,
              code,
              shortText,
              quantity,
              uom,
              manufacturer,
              manufacturerPartNumber,
            ] = row;

            const extendedProduct = {
              productId,
              code,
              purchaseRequestNumber,
              shortText,
              quantity,
              uom,
              manufacturer,
              manufacturerPartNumber,
            };

            products.push(extendedProduct);
            perProductStates[`product__${productId}`] = extendedProduct;
          } else {
            //freight
            const [
              srnNo,
              grossWeight,
              freightDesc,
              transportMode,
              transportType,
              packageQty,
              cbm,
              placeOfLoading,
              placeOfDelivery,
            ] = row;

            const freightExtendedProduct = {
              productId,
              srnNo,
              grossWeight,
              freightDesc,
              transportMode,
              transportType,
              packageQty,
              cbm,
              placeOfLoading,
              placeOfDelivery,
            };

            products.push(freightExtendedProduct);
            perProductStates[`product__${productId}`] = freightExtendedProduct;
          }
        });

        this.setState({ products, ...perProductStates }, this.onChange);
      },
    });
  }

  onChange() {
    return this.props.onChange(collectProducts(this.state));
  }

  onProductInputChange(e, name, recordId, dataType) {
    const stateKey = `product__${recordId}`;
    const product = this.state[stateKey] || { productId: recordId };

    product[name] = controlValueParser({ e, dataType });

    this.setState({ [stateKey]: product }, () => this.onChange());
  }

  renderCell(options) {
    const { name, title, type, dataType, width = 140 } = options;

    const render = (text, record) => {
      const product = this.state[`product__${record.productId}`] || {};
      const value = product[name];

      const inputProps = {
        value,
        type: type,
        onChange: e => this.onProductInputChange(e, name, record.productId, dataType),
      };

      if (dataType === 'eightDigit') {
        inputProps.placeholder = 'Must be less than 8 digits';
        inputProps.maxLength = '8';
      }

      return <Input {...inputProps} />;
    };

    return <Column title={title} key={name} dataIndex={name} render={render} width={width} />;
  }

  renderTable() {
    const { __ } = this.context;
    const { rfq } = this.props;
    const { products } = this.state;
    const isFreight = rfq === 'freight';

    return (
      <Table
        className="form-table"
        dataSource={products}
        pagination={false}
        size="middle"
        scroll={{
          x: 1000,
          y: '65vh',
        }}
      >
        <Column title="#" key="index" width={30} render={(text, record, index) => index + 1} />

        {!isFreight &&
          this.renderCell({
            name: 'purchaseRequestNumber',
            title: __(rpc.purchaseRequestNumber),
            type: 'number',
            dataType: 'eightDigit',
          })}
        {!isFreight &&
          this.renderCell({
            name: 'code',
            title: __(rpc.code),
            dataType: 'eightDigit',
          })}
        {!isFreight &&
          this.renderCell({
            name: 'shortText',
            title: __(rpc.shortText),
          })}
        {!isFreight &&
          this.renderCell({
            name: 'quantity',
            title: __(rpc.quantity),
            type: 'number',
          })}
        {!isFreight &&
          this.renderCell({
            name: 'uom',
            title: __(rpc.uom),
          })}
        {!isFreight &&
          this.renderCell({
            name: 'manufacturer',
            title: __(rpc.manufacturer),
          })}
        {!isFreight &&
          this.renderCell({
            name: 'manufacturerPartNumber',
            title: __(rpc.manufacturerPart),
            type: 'string',
          })}

        {isFreight &&
          this.renderCell({
            name: 'srnNo',
            title: __(rpc.srnNo),
            type: 'string',
          })}
        {isFreight &&
          this.renderCell({
            name: 'grossWeight',
            title: __(rpc.grossWeight),
            type: 'number',
          })}
        {isFreight &&
          this.renderCell({
            name: 'freightDesc',
            type: 'string',
            title: __(rpc.freightDesc),
          })}
        {isFreight &&
          this.renderCell({
            name: 'transportMode',
            title: __(rpc.transportMode),
            type: 'string',
          })}
        {isFreight &&
          this.renderCell({
            name: 'transportType',
            title: __(rpc.transportType),
            type: 'string',
          })}
        {isFreight &&
          this.renderCell({
            name: 'packageQty',
            title: __(rpc.packageQty),
            type: 'number',
          })}
        {isFreight &&
          this.renderCell({
            name: 'cbm',
            type: 'number',
            title: __(rpc.cbm),
          })}
        {isFreight &&
          this.renderCell({
            name: 'placeOfLoading',
            type: 'string',
            title: __(rpc.placeOfLoading),
          })}
        {isFreight &&
          this.renderCell({
            name: 'placeOfDelivery',
            title: __(rpc.placeOfDelivery),
            type: 'string',
          })}
      </Table>
    );
  }

  render() {
    const { __ } = this.context;
    const { rfq } = this.props;
    let requestUrl = generateTemplateUrl('rfq_requested_products');

    if (rfq === 'freight') {
      requestUrl = generateTemplateUrl('freight_rfq_requested_products');
    }

    return (
      <Card title="Form" className="margin">
        <div>
          <div className="table-operations margin">
            <Button onClick={() => window.open(requestUrl)}>
              {__('Download template')}
              <Icon type="download" />
            </Button>

            <div className="upload-btn-wrapper">
              <Button>
                {__('Import excel file')} <Icon type="file-excel" />
              </Button>
              <input
                type="file"
                className="ant-btn"
                onChange={this.handleFile}
                value={this.state.file}
              />
            </div>
          </div>
          {this.renderTable()}
        </div>

        <Button className="dashed-button-big" size="large" onClick={this.addProductRow}>
          <Icon type="plus" /> Add row
        </Button>
      </Card>
    );
  }
}

RfqTable.propTypes = {
  requestedProducts: PropTypes.array,
  onChange: PropTypes.func,
};

RfqTable.contextTypes = {
  __: PropTypes.func,
};

export default RfqTable;
