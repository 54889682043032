import React from 'react';
import { Input, Select } from 'antd';
import { countryData } from '../constants';

const addressCard = (renderField, renderOptions, showExtendedField = false, disabled = false) => {
  const countryOptions = renderOptions(countryData);
  const control = <Input disabled={disabled} />;

  return (
    <div>
      {renderField({
        label: 'Address line',
        name: 'address',
        control,
        showExtendedField,
      })}

      {renderField({
        label: 'Address line 2 / Soum',
        name: 'address2',
        optional: true,
        control,
        showExtendedField,
      })}

      {renderField({
        label: 'Address line 3',
        name: 'address3',
        optional: true,
        control,
        showExtendedField,
      })}

      {renderField({
        label: 'Postcode or zipcode',
        name: 'zipCode',
        optional: true,
        dataType: 'number',
        control: <Input type="number" disabled={disabled} />,
        showExtendedField,
      })}

      {renderField({
        label: 'Town/City/Aimag',
        name: 'townOrCity',
        control,
        showExtendedField,
      })}

      {renderField({
        label: 'State/Province',
        name: 'province',
        control,
        showExtendedField,
      })}

      {renderField({
        label: 'Country',
        name: 'country',
        control: (
          <Select
            disabled={disabled}
            showSearch
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {countryOptions}
          </Select>
        ),
        showExtendedField,
      })}
    </div>
  );
};

export default addressCard;
