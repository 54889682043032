export const yearData = [
  { value: '2022', text: '2022' },
  { value: '2021', text: '2021' },
  { value: '2020', text: '2020' },
];

export const booleanData = [{ text: 'Yes', value: 'true' }, { text: 'No', value: 'false' }];

export const currencyData = [
  { value: 'AED', text: 'United Arab Emirates Dirham' },
  { value: 'AFN', text: 'Afghan Afghani' },
  { value: 'ALL', text: 'Albanian Lek' },
  { value: 'AMD', text: 'Armenian Dram' },
  { value: 'ANG', text: 'Netherlands Antillean Guilder' },
  { value: 'AOA', text: 'Angolan Kwanza' },
  { value: 'ARS', text: 'Argentine Peso' },
  { value: 'AUD', text: 'Australian Dollar' },
  { value: 'AWG', text: 'Aruban Florin' },
  { value: 'AZN', text: 'Azerbaijani Manat' },
  { value: 'BAM', text: 'Bosnia-Herzegovina Convertible Mark' },
  { value: 'BBD', text: 'Barbadian Dollar' },
  { value: 'BDT', text: 'Bangladeshi Taka' },
  { value: 'BGN', text: 'Bulgarian Lev' },
  { value: 'BHD', text: 'Bahraini Dinar' },
  { value: 'BIF', text: 'Burundian Franc' },
  { value: 'BMD', text: 'Bermudan Dollar' },
  { value: 'BND', text: 'Brunei Dollar' },
  { value: 'BOB', text: 'Bolivian Boliviano' },
  { value: 'BRL', text: 'Brazilian Real' },
  { value: 'BSD', text: 'Bahamian Dollar' },
  { value: 'BTC', text: 'Bitcoin' },
  { value: 'BTN', text: 'Bhutanese Ngultrum' },
  { value: 'BWP', text: 'Botswanan Pula' },
  { value: 'BYN', text: 'Belarusian Ruble' },
  { value: 'BZD', text: 'Belize Dollar' },
  { value: 'CAD', text: 'Canadian Dollar' },
  { value: 'CDF', text: 'Congolese Franc' },
  { value: 'CHF', text: 'Swiss Franc' },
  { value: 'CLF', text: 'Chilean Unit of Account (UF)' },
  { value: 'CLP', text: 'Chilean Peso' },
  { value: 'CNH', text: 'Chinese Yuan (Offshore)' },
  { value: 'CNY', text: 'Chinese Yuan' },
  { value: 'COP', text: 'Colombian Peso' },
  { value: 'CRC', text: 'Costa Rican Colón' },
  { value: 'CUC', text: 'Cuban Convertible Peso' },
  { value: 'CUP', text: 'Cuban Peso' },
  { value: 'CVE', text: 'Cape Verdean Escudo' },
  { value: 'CZK', text: 'Czech Republic Koruna' },
  { value: 'DJF', text: 'Djiboutian Franc' },
  { value: 'DKK', text: 'Danish Krone' },
  { value: 'DOP', text: 'Dominican Peso' },
  { value: 'DZD', text: 'Algerian Dinar' },
  { value: 'EGP', text: 'Egyptian Pound' },
  { value: 'ERN', text: 'Eritrean Nakfa' },
  { value: 'ETB', text: 'Ethiopian Birr' },
  { value: 'EUR', text: 'Euro' },
  { value: 'FJD', text: 'Fijian Dollar' },
  { value: 'FKP', text: 'Falkland Islands Pound' },
  { value: 'GBP', text: 'British Pound Sterling' },
  { value: 'GEL', text: 'Georgian Lari' },
  { value: 'GGP', text: 'Guernsey Pound' },
  { value: 'GHS', text: 'Ghanaian Cedi' },
  { value: 'GIP', text: 'Gibraltar Pound' },
  { value: 'GMD', text: 'Gambian Dalasi' },
  { value: 'GNF', text: 'Guinean Franc' },
  { value: 'GTQ', text: 'Guatemalan Quetzal' },
  { value: 'GYD', text: 'Guyanaese Dollar' },
  { value: 'HKD', text: 'Hong Kong Dollar' },
  { value: 'HNL', text: 'Honduran Lempira' },
  { value: 'HRK', text: 'Croatian Kuna' },
  { value: 'HTG', text: 'Haitian Gourde' },
  { value: 'HUF', text: 'Hungarian Forint' },
  { value: 'IDR', text: 'Indonesian Rupiah' },
  { value: 'ILS', text: 'Israeli New Sheqel' },
  { value: 'IMP', text: 'Manx pound' },
  { value: 'INR', text: 'Indian Rupee' },
  { value: 'IQD', text: 'Iraqi Dinar' },
  { value: 'IRR', text: 'Iranian Rial' },
  { value: 'ISK', text: 'Icelandic Króna' },
  { value: 'JEP', text: 'Jersey Pound' },
  { value: 'JMD', text: 'Jamaican Dollar' },
  { value: 'JOD', text: 'Jordanian Dinar' },
  { value: 'JPY', text: 'Japanese Yen' },
  { value: 'KES', text: 'Kenyan Shilling' },
  { value: 'KGS', text: 'Kyrgystani Som' },
  { value: 'KHR', text: 'Cambodian Riel' },
  { value: 'KMF', text: 'Comorian Franc' },
  { value: 'KPW', text: 'North Korean Won' },
  { value: 'KRW', text: 'South Korean Won' },
  { value: 'KWD', text: 'Kuwaiti Dinar' },
  { value: 'KYD', text: 'Cayman Islands Dollar' },
  { value: 'KZT', text: 'Kazakhstani Tenge' },
  { value: 'LAK', text: 'Laotian Kip' },
  { value: 'LBP', text: 'Lebanese Pound' },
  { value: 'LKR', text: 'Sri Lankan Rupee' },
  { value: 'LRD', text: 'Liberian Dollar' },
  { value: 'LSL', text: 'Lesotho Loti' },
  { value: 'LYD', text: 'Libyan Dinar' },
  { value: 'MAD', text: 'Moroccan Dirham' },
  { value: 'MDL', text: 'Moldovan Leu' },
  { value: 'MGA', text: 'Malagasy Ariary' },
  { value: 'MKD', text: 'Macedonian Denar' },
  { value: 'MMK', text: 'Myanma Kyat' },
  { value: 'MNT', text: 'Mongolian Tugrik' },
  { value: 'MOP', text: 'Macanese Pataca' },
  { value: 'MRO', text: 'Mauritanian Ouguiya (pre-2018)' },
  { value: 'MRU', text: 'Mauritanian Ouguiya' },
  { value: 'MUR', text: 'Mauritian Rupee' },
  { value: 'MVR', text: 'Maldivian Rufiyaa' },
  { value: 'MWK', text: 'Malawian Kwacha' },
  { value: 'MXN', text: 'Mexican Peso' },
  { value: 'MYR', text: 'Malaysian Ringgit' },
  { value: 'MZN', text: 'Mozambican Metical' },
  { value: 'NAD', text: 'Namibian Dollar' },
  { value: 'NGN', text: 'Nigerian Naira' },
  { value: 'NIO', text: 'Nicaraguan Córdoba' },
  { value: 'NOK', text: 'Norwegian Krone' },
  { value: 'NPR', text: 'Nepalese Rupee' },
  { value: 'NZD', text: 'New Zealand Dollar' },
  { value: 'OMR', text: 'Omani Rial' },
  { value: 'PAB', text: 'Panamanian Balboa' },
  { value: 'PEN', text: 'Peruvian Nuevo Sol' },
  { value: 'PGK', text: 'Papua New Guinean Kina' },
  { value: 'PHP', text: 'Philippine Peso' },
  { value: 'PKR', text: 'Pakistani Rupee' },
  { value: 'PLN', text: 'Polish Zloty' },
  { value: 'PYG', text: 'Paraguayan Guarani' },
  { value: 'QAR', text: 'Qatari Rial' },
  { value: 'RON', text: 'Romanian Leu' },
  { value: 'RSD', text: 'Serbian Dinar' },
  { value: 'RUB', text: 'Russian Ruble' },
  { value: 'RWF', text: 'Rwandan Franc' },
  { value: 'SAR', text: 'Saudi Riyal' },
  { value: 'SBD', text: 'Solomon Islands Dollar' },
  { value: 'SCR', text: 'Seychellois Rupee' },
  { value: 'SDG', text: 'Sudanese Pound' },
  { value: 'SEK', text: 'Swedish Krona' },
  { value: 'SGD', text: 'Singapore Dollar' },
  { value: 'SHP', text: 'Saint Helena Pound' },
  { value: 'SLL', text: 'Sierra Leonean Leone' },
  { value: 'SOS', text: 'Somali Shilling' },
  { value: 'SRD', text: 'Surinamese Dollar' },
  { value: 'SSP', text: 'South Sudanese Pound' },
  { value: 'STD', text: 'São Tomé and Príncipe Dobra (pre-2018)' },
  { value: 'STN', text: 'São Tomé and Príncipe Dobra' },
  { value: 'SVC', text: 'Salvadoran Colón' },
  { value: 'SYP', text: 'Syrian Pound' },
  { value: 'SZL', text: 'Swazi Lilangeni' },
  { value: 'THB', text: 'Thai Baht' },
  { value: 'TJS', text: 'Tajikistani Somoni' },
  { value: 'TMT', text: 'Turkmenistani Manat' },
  { value: 'TND', text: 'Tunisian Dinar' },
  { value: 'TOP', text: "Tongan Pa'anga" },
  { value: 'TRY', text: 'Turkish Lira' },
  { value: 'TTD', text: 'Trinidad and Tobago Dollar' },
  { value: 'TWD', text: 'New Taiwan Dollar' },
  { value: 'TZS', text: 'Tanzanian Shilling' },
  { value: 'UAH', text: 'Ukrainian Hryvnia' },
  { value: 'UGX', text: 'Ugandan Shilling' },
  { value: 'USD', text: 'United States Dollar' },
  { value: 'UYU', text: 'Uruguayan Peso' },
  { value: 'UZS', text: 'Uzbekistan Som' },
  { value: 'VEF', text: 'Venezuelan Bolívar Fuerte' },
  { value: 'VND', text: 'Vietnamese Dong' },
  { value: 'VUV', text: 'Vanuatu Vatu' },
  { value: 'WST', text: 'Samoan Tala' },
  { value: 'XAF', text: 'CFA Franc BEAC' },
  { value: 'XAG', text: 'Silver Ounce' },
  { value: 'XAU', text: 'Gold Ounce' },
  { value: 'XCD', text: 'East Caribbean Dollar' },
  { value: 'XDR', text: 'Special Drawing Rights' },
  { value: 'XOF', text: 'CFA Franc BCEAO' },
  { value: 'XPD', text: 'Palladium Ounce' },
  { value: 'XPF', text: 'CFP Franc' },
  { value: 'XPT', text: 'Platinum Ounce' },
  { value: 'YER', text: 'Yemeni Rial' },
  { value: 'ZAR', text: 'South African Rand' },
  { value: 'ZMW', text: 'Zambian Kwacha' },
  { value: 'ZWL', text: 'Zimbabwean Dollar' },
];

export const actionStatusData = [
  { value: 'In Progress', text: 'In Progress' },
  { value: 'Completed', text: 'Completed' },
];

//texts
export const labels = {
  hasConvictedLabourLawsDescription:
    'If Yes, what steps have you taken to ensure this does not happen again',

  hasConvictedForHumanRightsDescription:
    'If Yes, what steps have you taken to ensure this does not happen again',

  organizationChartFile: 'Please upload copy of your organisation chart',

  documentLabel: 'Please upload supporting document',

  doesMeetMinimumStandarts:
    'Does your company meet minimum standards of fair employment practice required by Mongolian labor laws and regulations',

  doesHaveJobDescription: 'Does the Company have a job description procedure in place?',

  doesConcludeValidContracts:
    'Does the company conclude valid contracts with all employees. (include skilled/unskilled, temporary and permanent workers, etc)',

  employeeTurnoverRate:
    'Please provide the employee turnover rate within your company in the last 12 months',

  doesHaveLiabilityInsurance:
    'Does the organisation have Liability insurance which meets Oyu Tolgoi’s minimum requirements and valid worker compensation insurance or enrolment in an applicable occupational injury/illness insurance programme?',

  doesHaveCodeEthics: 'Does your company have a documented code of ethics/conduct?',

  doesHaveResponsiblityPolicy:
    'Does your company have a documented Corporate Social Responsibility policy?',

  hasConvictedLabourLaws:
    'Has your company ever been convicted for a breach of any labour laws in the countries you operate ?',

  hasConvictedForHumanRights:
    'Has your company ever been convicted for a breach of any human rights in the countries you operate within the last five years?',

  hasConvictedForBusinessIntegrity:
    'Has your company ever been convicted for a breach of any business integrity in the countries you operate within the last five years?',

  proveHasNotConvicted: 'If Yes, what steps have you taken to ensure this does not happen again',

  hasLeadersConvicted:
    'Has your company or any of its directors been investigated or convicted of any other legal infringement not described above within the last five years?',

  inv1: `Investigation 1`,

  doesEmployeePoliticallyExposed:
    'Does your company employ any politically exposed person? If yes, provide list of PEP name',

  pepName: `PEP Name`,

  //envLabels
  doesHavePlan:
    'Does the organisation have environmental management plans or procedures (including air quality, greenhouse gases emissions, water and contamination prevention, noise and vibration, Waste Management)?',

  hasEnvironmentalRegulatorInvestigated:
    'Has any environmental regulator inspected / investigated your company within the last 5 years?',

  dateOfInvestigation: 'Date of Inspection / Investigation',

  reasonForInvestigation: 'Reasons for investigation/inspection',

  actionStatus: `Action status`,

  investigationDocumentation: 'Upload Inspection / Investigation Documentation',

  hasConvictedForEnvironmentalLaws:
    'Has your company ever been convicted for a breach of any Environmental laws in the countries you operate?',

  additionalInformation: `Additional Information`,

  //healthLabels
  doesHaveHealthSafety:
    'Does the organisation have a Health Safety & Environment management system?',

  areHSEResourcesClearlyIdentified:
    'Are HSE resources, roles, responsibilities and authority levels clearly identified and defined within your Organisation?',

  doesHaveDocumentedProcessToEnsure:
    'Does your company have a documented  process to ensure all staff receive health and safety training and induction?',

  areEmployeesUnderYourControl:
    'Are all employees under your control required to utilise appropriate Personal Protective Equipment (PPE) at all times?',

  doesHaveDocumentForRiskAssesment:
    'Does the company have a documented process or guidelines for risk assessment (including CRM)?',

  doesHaveDocumentForIncidentInvestigation:
    'Does the company have a documented process for incident investigation?',

  doesHaveDocumentedFitness: 'Does your company have a documented Fitness for Work (FFW) policy?',

  isWillingToComply: 'Is your company willing to comply with Oyu Tolgoi/RT HSE management system?',

  hasIndustrialAccident: 'Has there been any industrial accident?',

  tmha:
    'Provide total man hours accrued for the previous five calendar years for all onsite personnel on Contractor managed projects',

  ltifr:
    'Provide Lost Time Injury Frequency Rate (LTIFR) as defined for the previous five calendar years for all onsite personnel on Contractor managed projects. Additionally, provide as an attachment, the guidance note (or similar) for how the Tenderer defines a Lost Time Injury.',

  injuryExplanation:
    'Provide a summary explaining the  fatality or injury event(s) that contributed to the above:',

  seniorManagement: `Provide details of how senior management demonstrates its commitment to the company's own HSE management system? `,

  isWillingToCommit:
    'Is your company willing to commit itself, its employees and all Sub-contractors, to implementing and being held to KPIs relating to critical risk management (CRM)?',

  isPerparedToCompile:
    'Is your company prepared to compile weekly and monthly safety statistics for the work performed on Site?',

  hasWorkedOnWorldBank:
    'Has your company previously worked on World Bank or International Finance Corporation projects? If so provide details.',

  hasWorkedOnLargeProjects:
    'Has your company previously worked on large scale mining construction projects? If so provide details.',

  doesHaveLicense:
    'Does the organisation have valid industry certifications and/or licenses if required by the type of services provided?',

  //financial labels
  canProvideAccountsInfo: `Can you provide accounts for the last 3 financial years?`,

  currency: `Currency`,

  annualTurnover: 'Sales revenue',

  preTaxProfit: `Net income before tax`,

  totalAssets: `Total of assets`,

  totalCurrentAssets: `Total of current assets`,

  totalShareholderEquity: `Total shareholders equity`,

  reasonToCannotNotProvide: `If not, explain the reasons`,

  isUpToDateSSP: `Is your company up to date with Social Security payments?`,

  isUpToDateCTP: `Is your company up to date with Corporation Tax payments?`,

  recordsInfo: `Please provide financial records for your last 3 years`,

  isSubContractor:
    'Does your company, parent company or any sub-contractor is registered in any of the following countries to which international trade sanctions apply',
};

export const descriptions = {
  isSubContractor:
    'Abkhazia, Andorra, Belarus, Burundi, Central African Republic, Cook Island, Cuba, Cuba, Democratic Republic of Congo, Iran, Iraq, Lebanon, Libya, Liechtenstein, Monaco, Nagamo Karabaksh, Niue and North Korea, Northern Cyprus, North Korea, Sahrawi Arab Democratic Republic, Somalia, Somaliland, South Ossetia, Sudan, Syria, Taiwan, Transnistria, Transnistria, Ukraine/Russia, Venezuela, Yemen and Zimbabwe.',

  employeeTurnoverRate:
    'Employee Turnover is the act of replacing an employee with a new employee. You calculate the turnover rate by dividing the number of employees who left by the total number of employees at the beginning of the period.',

  doesMeetMinimumStandarts:
    'Fair employment practice includes disabled ethnic groups, anti-discrimination etc.',

  doesConcludeValidContracts:
    'Include skilled/unskilled manufacturing employees, temporary employees, underage workers etc.',

  hasConvictedForHumanRights:
    'The five years is based on the date of your declaration and submission of your questionnaire compared to the date of conviction. You may remove any breaches older than five years.',

  hasConvictedForBusinessIntegrity:
    'The five years is based on the date of your declaration and submission of your questionnaire compared to the date of conviction. You may remove any breaches older than five years.',

  hasLeadersConvicted:
    'The five years is based on the date of your declaration and submission of your questionnaire compared to the date of conviction. You may remove any breaches older than five years.',

  doesEmployeePoliticallyExposed:
    'PEP - Individuals who are or have been entrusted with prominent public functions either domestically or by a foreign country, for example Heads of state or Heads of government, senior politicians, senior government, judicial or military officials, senior executives of state owned corporations, important political party officials',

  additionalInformation: `Please use this space to provide additional information regarding
    your Corporate Social Responsibility`,

  areHSEResourcesClearlyIdentified: 'Staff includes all employees and sub-contractors.',

  doesHaveDocumentedFitness:
    'The Fitness for Work (FFW) policy should incorporate alcohol, fatigue and general fitness for work.',

  doesHaveCodeEthics:
    'Prevention of Bribery & Corruption, Conflict of interest, Fair Labor Standards, Business Ethics, Human Rights, Equality and Diversity, Dealing with government intermediaries, Charitable contributions, Travels and hospitality, Gifts and entertainment, Money laundering',
  doesHaveDocumentForRiskAssesment:
    'CRM- Critical Risk Management: http://ot.mn/худалдан-авалт-2/files-pps/',
};
