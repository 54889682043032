import React from 'react';
import PropTypes from 'prop-types';
import { gql, compose, graphql } from 'react-apollo';
import { mutations, queries } from '../graphql';
import { Edit } from '../components';
import { Loading } from 'modules/common/components';
import { message } from 'antd';

const EditContainer = (props, { __ }) => {
  const { companyDetailQuery, match, saveMutation, updateDueDiligenceMutation } = props;
  const _id = match.params.id;

  if (companyDetailQuery.error) {
    return null;
  }

  if (companyDetailQuery.loading) {
    return <Loading />;
  }

  const save = (name, doc) => {
    const mutation = props[`${name}Edit`];

    if (mutation) {
      mutation({ variables: { supplierId: _id, [name]: doc } })
        .then(() => {
          message.success(__('Successfully saved'));
        })
        .catch(error => {
          message.error(error.message);
        });
    }
  };

  const send = callback => {
    saveMutation({ variables: { supplierId: _id } })
      .then(({ data: { dueDiligencesSave } }) => {
        const data = dueDiligencesSave;

        callback && callback(data);
      })
      .catch(error => {
        message.error(error.message);
      });
  };

  const update = doc => {
    const { history } = props;

    updateDueDiligenceMutation({
      variables: { supplierId: _id, ...doc },
    })
      .then(() => {
        message.success('Success');
        history.push('/due-diligence');
      })

      .catch(error => {
        message.error(error.message);
      });
  };

  const updatedProps = {
    ...props,
    viewMode: true,
    showExtendedField: true,
    save,
    send,
    update,
    company: companyDetailQuery.companyDetail,
  };

  return <Edit {...updatedProps} />;
};

EditContainer.propTypes = {
  companyDetailQuery: PropTypes.object,
  saveMutation: PropTypes.func,
  updateDueDiligenceMutation: PropTypes.func,
};

EditContainer.contextTypes = {
  __: PropTypes.func,
};

export default compose(
  graphql(gql(queries.companyDetail), {
    name: 'companyDetailQuery',
    options: ({ match }) => {
      return {
        variables: {
          _id: match.params.id,
        },
        notifyOnNetworkStatusChange: true,
      };
    },
  }),
  graphql(gql(mutations.basicInfo), {
    name: 'basicInfoEdit',
  }),
  graphql(gql(mutations.shareholderInfo), {
    name: 'shareholderInfoEdit',
  }),
  graphql(gql(mutations.managementTeamInfo), {
    name: 'managementTeamInfoEdit',
  }),
  graphql(gql(mutations.groupInfo), {
    name: 'groupInfoEdit',
  }),
  graphql(gql(mutations.saveDueDiligence), {
    name: 'saveMutation',
  }),
  graphql(gql(mutations.updateDueDiligence), {
    name: 'updateDueDiligenceMutation',
  })
)(EditContainer);
