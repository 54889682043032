import { colors } from 'modules/common/constants';

/*
 * names: { firstName, fullName }
 * color
 * supplierIds
 */
const companyColors = [];

const replaceData = (obj, supplier) => {
  const _id = supplier._id;
  const doc = obj;

  if (!obj.supplierIds.includes(_id)) {
    doc.supplierIds.push(_id);
  }

  companyColors.pop(obj);
  companyColors.push(doc);
};

const findAndCreate = supplier => {
  const obj = companyColors.find(obj => obj.supplierIds.includes(supplier._id));

  if (!obj) {
    const info = supplier.shareholderInfo || {};
    const teamInfo = supplier.managementTeamInfo || {};
    let color;

    if (!info && !teamInfo) return null;

    const persons =
      (info.shareholders && info.shareholders.filter(p => p.type === 'individual')) || [];

    const names = {
      firstName: [],
      fullName: [],
    };

    persons.forEach(p => {
      const obj = companyColors.find(obj => obj.names.firstName.includes(p.firstName));

      if (obj) {
        color = obj.color;
        replaceData(obj, supplier);
      }

      p.firstName && names.firstName.push(p.firstName);
    });

    Object.keys(teamInfo).forEach(key => {
      const name = teamInfo[key] && teamInfo[key].name;

      const obj = companyColors.find(obj => obj.names.fullName.includes(name));

      if (obj) {
        color = obj.color;
        replaceData(obj, supplier);
      }

      name && names.fullName.push(name);
    });

    if (!color) {
      const length = companyColors.length;

      companyColors.push({
        names,
        supplierIds: [supplier._id],
        color: length < 9 ? colors[length] : '#' + ((Math.random() * 0xffffff) << 0).toString(16),
      });
    }

    return color;
  }

  if (obj.supplierIds.length > 1) return obj.color;

  return null;
};

export const getColor = supplier => {
  const color = findAndCreate(supplier);

  return color;
};
