import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { handleButtons } from '../utils';

export default class Actions extends React.Component {
  renderNotInterested() {
    const { response, __, onNotInterested } = this.props;

    if (response && response.isNotInterested) {
      return null;
    }

    return (
      <Button
        style={{ marginRight: '16px' }}
        type="danger"
        htmlType="button"
        onClick={onNotInterested}
      >
        {__('Not interested')}
      </Button>
    );
  }

  render() {
    const { tender, __, onSaveDraft, onSubmit, handleButtonShow } = this.props;
    const statuses = ['open'];
    let title = 'Save & submit';
    let type =
      tender.type === 'eoi' ? statuses.push('closed') && (title = 'Submit') && statuses : statuses;

    if (!type.includes(tender.status)) {
      return null;
    }

    if (handleButtons(handleButtonShow, tender.type))
      return (
        <>
          <div className="margin">
            {this.renderNotInterested()}
            <Button style={{ marginRight: '16px' }} htmlType="button" onClick={onSaveDraft}>
              {__('Save as draft')}
            </Button>
            <Button type="primary" htmlType="button" onClick={onSubmit}>
              {__(title)}
            </Button>
          </div>
        </>
      );

    return null;
  }
}

Actions.propTypes = {
  tender: PropTypes.object,
  onSaveDraft: PropTypes.func,
  onSubmit: PropTypes.func,
  onNotInterested: PropTypes.func,
  response: PropTypes.object,
  __: PropTypes.func,
};
