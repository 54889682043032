import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { compose, gql, graphql } from 'react-apollo';
import { alert } from 'modules/common/utils';
import { mutations, queries } from '../../graphql';
import { UserForm as UserFormComponent } from '../../components';
import { Loading } from '../../../common/components';
import { createTreeData } from '../../utils';

const propTypes = {
  user: PropTypes.object,
  usersAddMutation: PropTypes.func,
  usersEditMutation: PropTypes.func,
  modulePermissionsQuery: PropTypes.object,
  onSuccess: PropTypes.func.isRequired,
};

class UserFormContainer extends React.Component {
  render() {
    const {
      user,
      usersAddMutation,
      usersEditMutation,
      onSuccess,
      permissionModulesQuery,
      currentUserQuery,
      userPermissionsQuery,
    } = this.props;

    if (
      usersEditMutation.loading ||
      usersAddMutation.loading ||
      permissionModulesQuery.loading ||
      currentUserQuery.loading ||
      userPermissionsQuery.loading
    ) {
      return <Loading />;
    }

    const { permissionModules } = permissionModulesQuery;
    const treeData = createTreeData(permissionModules);

    const mainAction = doc => {
      const mutation = user ? usersEditMutation : usersAddMutation;
      const messageText = user ? 'User succesfully edited.' : 'User succesfully added.';

      mutation({ variables: doc })
        .then(() => {
          onSuccess();
          userPermissionsQuery.refetch();
          window.location.reload();
          message.success(messageText);
        })
        .catch(error => {
          alert.error(error);
        });
    };

    const updatedProps = {
      ...this.props,
      mainAction,
      permissionModules: treeData,
      userPermissions: userPermissionsQuery.userPermissions,
      currentUserQuery,
    };
    return <UserFormComponent {...updatedProps} />;
  }
}

UserFormContainer.propTypes = propTypes;

export default compose(
  graphql(gql(mutations.usersAdd), {
    name: 'usersAddMutation',
  }),
  graphql(gql(mutations.usersEdit), {
    name: 'usersEditMutation',
  }),
  graphql(gql(queries.modulePermissions), {
    name: 'modulePermissionsQuery',
  }),
  graphql(gql(queries.permissionModules), {
    name: 'permissionModulesQuery',
  }),
  graphql(gql(queries.userPermissions), {
    name: 'userPermissionsQuery',
    options: ({ user }) => {
      return {
        variables: {
          userId: user ? user._id : null,
        },
        fetchPolicy: 'network-only',
      };
    },
  }),
  graphql(gql(queries.currentUser), {
    name: 'currentUserQuery',
  })
)(UserFormContainer);
