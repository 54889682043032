const userFields = `
  _id
  username
  email
  isSupplier
  companyId
  firstName
  lastName
  jobTitle
  phone
  role

  temporarySecureInformation
  
  unsubscribeEoi {
    userEmail,
    contactEmail,
    adminEmail
  }

  userPermission

  showPopUpValidation
  supplierValidationMenu
  checkValidationRequirement
  validationRequestedType
`;

const currentUser = `
  query currentUser {
    currentUser {
      ${userFields}
    }
  }
`;

const systemConfig = `
  query config {
    config {
      logo
      name
      phone
      email
      address

      rfqTemplates
      trfqTemplates
      eoiTemplates
      freightTemplates
      successFeedbackTemplates
      capacityBuildingTemplates
      blockTemplates
      prequalificationTemplates
      desktopAuditTemplates
      dueDiligenceTemplates
      manageSuppliersTemplates
      validationTemplates

      prequalificationDow
      specificPrequalificationDow

      dueDiligenceDow
      specificDueDiligenceDow

      manageSuppliersDow
      specificManageSuppliersDow

      auditDow
      specificAuditDow

      improvementPlanDow
      specificImprovementPlanDow

      validationDow
      specificValidationDow
    }
  }
`;

const simpleUsers = `
  query users($search: String) {
    users(page: 1, perPage: 10, search: $search) {
      _id
      firstName
      lastName
      email
      isSupplier
    }
  }
`;

const userDetail = `
  query userDetail($_id: String) {
    userDetail(_id: $_id) {
      ${userFields}
    }
  }
`;

export default {
  currentUser,
  userDetail,
  systemConfig,
  simpleUsers,
};
