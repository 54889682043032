const statusTabs = {
  businessInfo: 'Business integrity & human resource',
  environmentalInfo: 'Environmental management',
  financialInfo: 'Financial information',
  healthInfo: 'Health & safety mangement system',
  approved: 'Approved',
  expired: 'Expired',
  failed: 'Failed',
  outstanding: 'outstanding',
};

const auditTabs = {
  businessInfo: 'Business info',
  coreHseqInfo: 'Core HSEQ info',
  hrInfo: 'Human resource info',
};

export { statusTabs, auditTabs };
