import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Card, Row, Col, Tabs } from 'antd';
import Templates from './Templates';

const propTypes = {
  onEmailContentChange: PropTypes.func,
  configsSaveTemplate: PropTypes.func,
};

const TabPane = Tabs.TabPane;

class ManageTemplates extends React.Component {
  constructor(props) {
    super(props);

    this.state = { currentTab: 'rfq' };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  handleTabChange(e) {
    this.setState({ currentTab: e });
  }

  renderTabPane({ tab, key, kindOptions }) {
    return (
      <TabPane tab={tab} key={key}>
        <Templates name={key} kindOptions={kindOptions} />
      </TabPane>
    );
  }

  rfqKindOptions() {
    return [
      { value: 'buyer__publish', text: 'To buyer when publish' },
      {
        value: 'supplier__publish',
        text: 'To supplier when publish',
      },
      { value: 'buyer__close', text: 'To buyer when close' },
      { value: 'supplier__close', text: 'To supplier when close' },
      { value: 'buyer__cancel', text: 'To buyer when cancel' },
      {
        value: 'supplier__cancel',
        text: 'To supplier when cancel',
      },
      { value: 'buyer__manuallyclosed', text: 'To buyer when close manually' },
      { value: 'supplier__manuallyclosed', text: 'To supplier when close manually' },
      { value: 'buyer__award', text: 'To buyer when award' },
      { value: 'supplier__award', text: 'To supplier when award' },
      { value: 'supplier__reminder', text: 'Remind supplier' },
      { value: 'supplier__regretLetter', text: 'Regret letter' },
      { value: 'supplier__reopen', text: 'Reopen' },
      { value: 'supplier__edit', text: 'Edit' },
      { value: 'supplier__message_notification', text: 'Supplier message notification' },
      { value: 'buyer__message_notification', text: 'Buyer message notification' },
    ];
  }

  render() {
    return (
      <Row gutter={16}>
        <Col span={24}>
          <Card title="ManageTemplates">
            <Tabs defaultActiveKey="rfq" onChange={this.handleTabChange}>
              {this.renderTabPane({
                tab: 'RFQ',
                key: 'rfq',
                kindOptions: this.rfqKindOptions(),
              })}
              {this.renderTabPane({
                tab: 'Travel RFQ',
                key: 'trfq',
                kindOptions: this.rfqKindOptions(),
              })}
              {this.renderTabPane({
                tab: 'EOI',
                key: 'eoi',
                kindOptions: [
                  { value: 'buyer__publish', text: 'To buyer when publish' },
                  {
                    value: 'supplier__publish',
                    text: 'To supplier when publish',
                  },
                  { value: 'buyer__close', text: 'To buyer when close' },
                  { value: 'supplier__close', text: 'To supplier when close' },
                  { value: 'buyer__manuallyclosed', text: 'To buyer when close manually' },
                  {
                    value: 'supplier__manuallyclosed',
                    text: 'To supplier when close manually',
                  },
                  { value: 'buyer__cancel', text: 'To buyer when cancel' },
                  {
                    value: 'supplier__cancel',
                    text: 'To supplier when cancel',
                  },
                  { value: 'supplier__reminder', text: 'Remind supplier' },
                  { value: 'supplier__regretLetter', text: 'Regret letter' },
                  { value: 'supplier__reopen', text: 'Reopen' },
                  { value: 'supplier__edit', text: 'Edit' },
                  {
                    value: 'supplier__message_notification',
                    text: 'Supplier message notification',
                  },
                  { value: 'buyer__message_notification', text: 'Buyer message notification' },
                ],
              })}
              {this.renderTabPane({
                tab: 'Freight RFQ',
                key: 'freight',
                kindOptions: this.rfqKindOptions(),
              })}
              {this.renderTabPane({
                tab: 'Success feedback',
                key: 'successFeedback',
                kindOptions: [
                  { value: 'buyer__new', text: 'Buyer' },
                  { value: 'supplier__new', text: 'Supplier' },
                ],
              })}
              {this.renderTabPane({
                tab: 'Capacity building',
                key: 'capacityBuilding',
                kindOptions: [
                  {
                    value: 'supplier__submit',
                    text: 'To supplier when submit',
                  },
                  { value: 'buyer__submit', text: 'To buyer when submit' },
                  { value: 'supplier__enable', text: 'To supplier when enable' },
                ],
              })}
              {this.renderTabPane({
                tab: 'Block',
                key: 'block',
                kindOptions: [
                  { value: 'buyer__block', text: 'Block' },
                  { value: 'buyer__reminder', text: 'Reminder' },
                  { value: 'buyer__reminder__inactiveuser__removed', text: 'Removed user' },
                ],
              })}
              {this.renderTabPane({
                tab: 'Prequalification',
                key: 'prequalification',
                kindOptions: [
                  { value: 'supplier__qualified', text: 'Approved' },
                  { value: 'supplier__failed', text: 'Failed' },
                  {
                    value: 'supplier__submit',
                    text: 'To supplier when submit',
                  },
                  { value: 'buyer__submit', text: 'To buyer When submit' },
                ],
              })}
              {this.renderTabPane({
                tab: 'Desktop audit',
                key: 'desktopAudit',
                kindOptions: [
                  {
                    value: 'supplier__invitation',
                    text: 'To supplier when receive invitation',
                  },
                  {
                    value: 'buyer__invitation',
                    text: 'To buyer when receive invitation',
                  },
                  {
                    value: 'supplier__approved',
                    text: 'To supplier when Qualified',
                  },
                  {
                    value: 'buyer__approved',
                    text: 'To auditor when Qualified',
                  },
                  {
                    value: 'supplier__failed',
                    text: 'To supplier when Not-qualified with Improvement plan',
                  },
                  {
                    value: 'buyer__failed',
                    text: 'To auditor when Not-qualified with Improvement plan',
                  },
                  {
                    value: 'supplier__enable',
                    text: 'To supplier when resubmission request enabled by OT',
                  },
                  {
                    value: 'supplier__send_resubmission_request',
                    text: 'To auditor when supplier send resubmission request',
                  },
                  { value: 'buyer__cancel', text: 'To auditor when invitation canceled' },
                  {
                    value: 'supplier__cancel',
                    text: 'To supplier when cancel',
                  },
                  {
                    value: 'supplier__due_closeDate',
                    text: 'To supplier when the due close date',
                  },
                  {
                    value: 'supplier__due_reassessmentDate',
                    text: 'To supplier when due re-assessment date',
                  },
                  {
                    value: 'supplier__close',
                    text: 'To supplier when due close date',
                  },
                  {
                    value: 'buyer__close',
                    text: 'To auditor when due close date',
                  },
                ],
              })}
              {this.renderTabPane({
                tab: 'Due Diligence',
                key: 'dueDiligence',
                kindOptions: [
                  {
                    value: 'supplier__verified',
                    text: 'To supplier when DD verified',
                  },
                  {
                    value: 'buyer__verified',
                    text: 'To auditor when DD verified',
                  },
                  {
                    value: 'supplier__verification__failed',
                    text: 'To supplier when DD verification failed',
                  },
                  {
                    value: 'buyer__verification__failed',
                    text: 'To auditor when DD verification failed',
                  },
                  {
                    value: 'supplier__reminderDate',
                    text: 'To supplier when Reminder date',
                  },
                  {
                    value: 'buyer__reminderDate',
                    text: 'To auditor when Reminder date',
                  },
                  {
                    value: 'supplier__closeDate',
                    text: 'To supplier when Close date',
                  },
                  {
                    value: 'buyer__closeDate',
                    text: 'To auditor when Close date',
                  },
                  {
                    value: 'supplier__cancel',
                    text: 'To supplier when Cancel',
                  },
                  {
                    value: 'buyer__cancel',
                    text: 'To auditor when Cancel',
                  },
                  {
                    value: 'supplier__enable',
                    text: 'To supplier when Enable',
                  },
                  {
                    value: 'buyer__enable',
                    text: 'To auditor when Enable',
                  },
                ],
              })}
              {this.renderTabPane({
                tab: 'Manage Suppliers',
                key: 'manageSuppliers',
                kindOptions: [
                  {
                    value: 'buyer__reminderDate',
                    text: 'To admin when de-activate reminder',
                  },
                  {
                    value: 'supplier__reminderDate',
                    text: 'To supplier when de-activate reminder',
                  },
                  {
                    value: 'supplier__deActive',
                    text: 'To supplier when de-activate',
                  },
                  {
                    value: 'buyer__deActive',
                    text: 'To admin when de-activate',
                  },
                  {
                    value: 'buyer__reActive',
                    text: 'To admin when reactivate',
                  },
                  {
                    value: 'supplier__reActive',
                    text: 'To supplier when reactivate',
                  },
                  {
                    value: 'buyer__reActive__Supplier',
                    text: 'Admin reactivate',
                  },
                  {
                    value: 'supplier__final__reminderDate',
                    text: 'To supplier when de-activated reminder',
                  },
                ],
              })}
              {this.renderTabPane({
                tab: 'Validation',
                key: 'validation',
                kindOptions: [
                  {
                    value: 'to__supplier__validation__access',
                    text: 'To supplier when Validation Request receive email set validation access',
                  },
                  {
                    value: 'to__buyer__supplierSend__request',
                    text: 'To Buyer when Supplier send Validation Request',
                  },
                  {
                    value: 'to__manager__adminSend__request',
                    text: 'To Manager when admin send approval request',
                  },
                  {
                    value: 'to__admin__manager__approved',
                    text: 'To Admin when Manager approved product codes of supplier',
                  },
                  {
                    value: 'to__admin__manager__declined',
                    text: 'To Admin when manager declined supplier product codes',
                  },
                  {
                    value: 'to__supplier__admin__validated',
                    text: 'To Supplier when product codes validated',
                  },
                  {
                    value: 'to__manager__admin__validated',
                    text: 'To Manager when Supplier product codes validated.',
                  },
                ],
              })}
              {this.renderTabPane({
                tab: 'Expire password',
                key: 'resetPassword',
                kindOptions: [
                  {
                    value: 'to__allUser__resetPassword',
                    text: 'To allUser when expired date',
                  },
                  {
                    value: 'resetPassword__sendNotification__to__allUser',
                    text: 'To allUser send notification before 7 days',
                  },
                ],
              })}
            </Tabs>
          </Card>
        </Col>
      </Row>
    );
  }
}

ManageTemplates.propTypes = propTypes;

export default withRouter(ManageTemplates);
