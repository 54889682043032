import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Form, Card, Tabs, message, Alert, Button } from 'antd';
import { Uploader, BaseForm } from 'modules/common/components';
import Actions from './Actions';
import RfqTable from './RfqTable';
import MainInfo from './MainInfo';
import { TenderMessagesSingle } from 'modules/tender_messages/containers/';
import { alert } from 'modules/common/utils';
import { disclaimerRelatedTender, showRFQTable, showMainInfo } from '../utils';

const { TabPane } = Tabs;

class SubmitTender extends BaseForm {
  constructor(props, context) {
    super(props, context);

    const response = props.response || {};

    this.state = {
      rfqTableShow: response.disclaimerIsAgree || response.isSent ? true : false,
      isDisclaimerAgree: response.disclaimerIsAgree || null,
      respondedProducts: response.respondedProducts || [],
      respondedFiles: response.respondedFiles || [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onServiceFileUpload = this.onServiceFileUpload.bind(this);
    this.onChangeProducts = this.onChangeProducts.bind(this);
  }

  onChangeProducts(respondedProducts) {
    this.setState({ respondedProducts });
  }

  getRespondedProducts() {
    const { requestedProducts } = this.props.data;
    const { rfqType } = this.props.data;

    const products = [];
    const currencies = [];
    const allShippingTerms = [];

    for (const [index, product] of this.state.respondedProducts.entries()) {
      const requestedProduct = requestedProducts[index];

      if (!requestedProduct) {
        continue;
      }

      if (
        !product.unitPrice &&
        !product.currency &&
        !product.leadTime &&
        !product.shippingTerms &&
        !product.alternative &&
        !product.suggestedManufacturer &&
        !product.suggestedManufacturerPartNumber &&
        !product.comment
      ) {
        if (rfqType === 'freight') {
          if (
            !product.freightCost ||
            !product.currency ||
            !product.leadTime ||
            !product.detailedQuote
          ) {
            throw new Error('Please input atleast one row');
          }
        }
        products.push({ productId: product.productId });
        continue;
      }

      const totalPrice = requestedProduct.quantity * product.unitPrice;

      delete product.__typename;
      delete product.purchaseRequestNumber;
      delete product.shortText;
      delete product.quantity;
      delete product.uom;
      delete product.manufacturer;
      delete product.manufacturerPartNumber;

      if (rfqType === 'freight') {
        if (
          !product.freightCost ||
          !product.currency ||
          !product.leadTime ||
          !product.detailedQuote
        ) {
          throw new Error('Please fill the row');
        }
      }

      if ((!product.alternative || product.alternative === '-') && !product.detailedQuote) {
        throw new Error('Please choose a value in "alternative" field');
      }

      if (
        product.alternative === 'Yes' &&
        (!product.suggestedManufacturer || !product.suggestedManufacturerPartNumber)
      ) {
        throw new Error(
          'Please choose a value in "Suggested Manufacturer if yes",	"Suggest manufacturer part number" field'
        );
      }

      if (product.alternative && (!product.unitPrice || product.unitPrice === '0')) {
        throw new Error('Please fill a value in "unit price" field');
      }

      if (product.unitPrice && !product.currency) {
        throw new Error('Please fill a value in "currency" field');
      }

      if (product.unitPrice && !product.shippingTerms) {
        throw new Error('Please fill a value in "shipping terms" field');
      }

      if (product.currency && !currencies.includes(product.currency)) {
        currencies.push(product.currency);
      }

      if (product.shippingTerms && !allShippingTerms.includes(product.shippingTerms)) {
        allShippingTerms.push(product.shippingTerms);
      }

      products.push({
        ...product,
        totalPrice,
      });
    }

    if (currencies.length > 1) {
      throw new Error('Please choose only 1 currency');
    }

    if (allShippingTerms.length > 1) {
      throw new Error('Please choose only 1 shipping term');
    }

    return products;
  }

  handleSubmit(e) {
    e.preventDefault();

    const { data, save } = this.props;
    const { type, rfqType } = data;
    const { respondedFiles, isDisclaimerAgree } = this.state;
    const { __ } = this.context;

    try {
      const respondedProducts = this.getRespondedProducts();

      if (
        type === 'rfq' &&
        (rfqType === 'goods' || rfqType === 'freight') &&
        respondedProducts.length === 0
      ) {
        return message.error(__('Please fill products table'));
      }

      save(
        {
          respondedProducts,
          respondedFiles,
          isNotInterested: false,
          disclaimerIsAgree: isDisclaimerAgree,
        },
        true
      );
    } catch (e) {
      message.error(e.message);
    }
  }

  onServiceFileUpload(files) {
    this.setState({ respondedFiles: files });
  }

  isComplete(product) {
    return product.leadTime && product.shippingTerms && product.unitPrice;
  }

  disclaimerAction = bool => {
    const { data } = this.props;
    const { rfqTableShow } = this.state;

    if (data.status === 'closed') {
      alert.error('Tender is closed');

      this.setState({ rfqTableShow: !rfqTableShow });
    }

    if (bool) {
      this.setState({ rfqTableShow: bool, isDisclaimerAgree: bool });
    } else {
      this.props.save({ disclaimerIsAgree: bool }, false);
    }
  };

  renderDisclaimer = (component, type, rfqType) => {
    const { __ } = this.context;
    const { rfqTableShow } = this.state;

    return (
      <div>
        {disclaimerRelatedTender(type) && !rfqTableShow && (
          <Alert
            description={
              <div>
                <div>
                  <p>
                    {__(
                      'At Oyu Tolgoi, one of our company values is Integrity. This means Oyu Tolgoi has a requirement that any business we do is done with Integrity. Our company policies “The way We Work”, “Competition Standard” and “Supplier Code of Conduct” describe how we conduct our business and the requirements from Suppliers. These are in addition to the “Competition law of Mongolia”. Copies can be obtained from the following links'
                    )}
                    :
                  </p>

                  <p>
                    <a href="#ot" src="https://www.ot.mn/the-way-we-work-en/">
                      https://www.ot.mn/the-way-we-work-en/
                    </a>{' '}
                    <br />
                    <a
                      href="#riotinto"
                      src="https://www.riotinto.com/-/media/Content/Documents/Sustainability/Corporate-policies/RT-Competition-standard.pdf"
                    >
                      https://www.riotinto.com/-/media/Content/Documents/Sustainability/Corporate-policies/RT-Competition-standard.pdf
                    </a>
                  </p>

                  <div>
                    {__('At a minimum, this means')}:
                    <ol>
                      <li>
                        {__(
                          'Any suggestion or actual facilitation payments, bribes, personal favors or payment in-kind are prohibited'
                        )}
                        .
                      </li>
                      <li>
                        {__(
                          'Conflicts of Interest should be declared before doing business with Oyu Tolgoi'
                        )}
                        .
                      </li>
                      <li>{__('Anti-competitive behavior (bid rigging) is prohibited')}.</li>
                    </ol>
                  </div>

                  <p>
                    {__(
                      'Failure to comply with the company’s policies may result in disqualification of your company from tenders and/or termination of business arrangements with Oyu Tolgoi'
                    )}
                    .
                  </p>
                </div>

                <div>
                  <p>
                    {__(
                      'Lead time quoted will be taken seriously and treated as final.  Quotes will be rejected with different formats than this template.  Please dont delete the lines you cannot quote just leave with a value of 0 and dont  change the sequence of lines.  Quoted price must include all expenses including packaging and delivery to OT warehouse.'
                    )}
                  </p>
                </div>

                <div
                  style={{
                    width: '100%',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    style={{ marginRight: '10px', width: '82px' }}
                    type="primary"
                    onClick={() => this.disclaimerAction(true)}
                  >
                    {__('Agree')}
                  </Button>
                  <Button type="primary" onClick={() => this.disclaimerAction(false)}>
                    {__('Disagree')}
                  </Button>
                </div>
              </div>
            }
            message={__('Disclaimer')}
            type="info"
          />
        )}
        {showRFQTable(type, rfqType, rfqTableShow) && component ? component : null}
      </div>
    );
  };

  renderForm = () => {
    const { data, generateTemplate, response } = this.props;
    const { type, rfqType, requestedProducts } = data;
    let title = '';

    let form = (
      <RfqTable
        rfqType={rfqType}
        requestedProducts={requestedProducts}
        respondedProducts={response ? response.respondedProducts : []}
        generateTemplate={generateTemplate}
        onChange={this.onChangeProducts}
        disclaimerAction={this.renderDisclaimer()}
        rfqTableShow={this.state.rfqTableShow}
      />
    );

    if (type === 'trfq' || (type === 'rfq' && rfqType === 'service')) {
      const response = this.props.response || {};
      const serviceFiles = response.respondedFiles || [];
      title = 'Schedule of service/ financial proposal';

      const uploader = (
        <div style={{ marginTop: '25px' }}>
          <Uploader multiple onChange={this.onServiceFileUpload} defaultFileList={serviceFiles} />
        </div>
      );

      form = this.renderDisclaimer(uploader, type, rfqType);
    }

    return { form: form, title: title };
  };

  render() {
    const { data, response, queryParams } = this.props;
    const { __ } = this.context;
    const renderForm = this.renderForm();
    const title = renderForm && renderForm.title ? renderForm.title : 'Form';
    const { rfqTableShow, isDisclaimerAgree } = this.state;
    const { type } = data || {};

    return (
      <Tabs defaultActiveKey={`${queryParams.tab || '1'}`}>
        <TabPane tab="Main" key="1">
          <Form layout="inline">
            {showMainInfo(type, rfqTableShow) && <MainInfo {...data} />}
            <Card title={title} className="margin">
              {renderForm.form}
              <br />

              <Actions
                __={__}
                tender={data}
                response={response}
                onNotInterested={() =>
                  this.save({ isNotInterested: true, disclaimerIsAgree: isDisclaimerAgree })
                }
                onSaveDraft={() => {
                  try {
                    this.save({
                      respondedProducts: this.getRespondedProducts(),
                      respondedFiles: this.state.respondedFiles,
                      isNotInterested: false,
                      disclaimerIsAgree: isDisclaimerAgree,
                    });
                  } catch (e) {
                    message.error(e.message);
                  }
                }}
                onSubmit={this.handleSubmit}
                handleButtonShow={rfqTableShow}
              />
            </Card>
          </Form>
        </TabPane>
        <TabPane tab="Message" key="2">
          <TenderMessagesSingle tenderDetail={data} isSupplier queryParams={queryParams} />
        </TabPane>
      </Tabs>
    );
  }
}

SubmitTender.propTypes = {
  data: PropTypes.object,
};

SubmitTender.contextTypes = {
  __: PropTypes.func,
};

const form = Form.create()(SubmitTender);

export default withRouter(form);
