import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Table, Card, Row, Col, Icon, Input, Divider, Button, Form } from 'antd';
import { Paginator } from 'modules/common/components';
import { UserForm } from '../../containers';

const propTypes = {
  form: PropTypes.object.isRequired,
  users: PropTypes.array,
  history: PropTypes.object,
  user: PropTypes.object,
  addUser: PropTypes.func,
  totalCount: PropTypes.number,
  toggleState: PropTypes.func,
  resetPassword: PropTypes.func,
  refetchUsers: PropTypes.func,
  numbering: PropTypes.number.isRequired,
};

class UserList extends React.Component {
  constructor(props) {
    super(props);

    const { history } = props;

    const query = queryString.parse(history.location.search);
    const searchQuery = query.search;

    this.state = {
      search: searchQuery || '',
      users: this.props.users,
      currentUser: null,
      showPopup: false,
      showResetPopup: false,
      title: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.checkPassword = this.checkPassword.bind(this);
    this.checkConfirm = this.checkConfirm.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onClose = this.onClose.bind(this);
    this.emitEmpty = this.emitEmpty.bind(this);

    this.columns = [
      {
        title: 'Num',
        key: '_id',
        render: (value, row, index) => <span>{this.props.numbering + index}</span>,
      },
      {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
      },
      {
        title: 'Email address',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Phone number',
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <a href="#edit" onClick={this.editUser.bind(this, record)}>
              Edit
            </a>
            <Divider type="vertical" />
            {this.renderStateChanger(record)}
          </span>
        ),
      },
    ];
  }

  renderStateChanger(record) {
    let stateChanger = (
      <a href="#deactive" onClick={() => this.props.toggleState(record._id)}>
        Deactivate
      </a>
    );

    if (window.location.href.includes('isActive=false')) {
      stateChanger = (
        <a href="#active" onClick={() => this.props.toggleState(record._id)}>
          Activate
        </a>
      );
    }

    return stateChanger;
  }

  handleSearch(value) {
    const { history } = this.props;

    let query = queryString.parse(history.location.search);

    query.search = value;

    history.push({
      search: queryString.stringify(query),
    });
  }

  handleChange(e) {
    this.setState({ search: e.target.value });
  }

  checkPassword(rule, value, callback) {
    const form = this.props.form;

    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  }

  editUser(user) {
    this.setState({ showPopup: true, currentUser: user, title: 'Edit User' });
  }

  addUser(user) {
    this.setState({
      showPopup: true,
      currentUser: user,
      title: 'Add User',
    });
  }

  checkConfirm(rule, value, callback) {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  }

  onSuccess() {
    this.setState({ showPopup: false, title: '' });
    this.props.refetchUsers();
  }

  onClose() {
    this.setState({ showPopup: false });
  }

  emitEmpty() {
    this.searchInput.focus();
    this.handleSearch('');
    this.setState({ search: '' });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ users: nextProps.users });
  }

  renderStateFilter() {
    const state = window.location.href.includes('isActive=false') ? 'inactive' : 'active';

    if (state === 'active') {
      return (
        <Button>
          <a href="?isActive=false">Inactive users</a>
        </Button>
      );
    }

    return (
      <Button>
        <a href="?isActive=true">Active users</a>
      </Button>
    );
  }

  render() {
    const { users, search } = this.state;
    const { totalCount, excelGenerate } = this.props;

    const suffix = search ? <Icon type="close-circle" onClick={this.emitEmpty} /> : null;

    return (
      <Row gutter={16}>
        <Col span={24}>
          <Card title="Manage Users">
            <div className="table-operations">
              <Input
                value={search}
                prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                style={{ width: 200, float: 'left' }}
                placeholder="Search by username"
                onPressEnter={e => this.handleSearch(e.target.value)}
                onChange={this.handleChange}
                suffix={suffix}
                ref={node => (this.searchInput = node)}
                className="users-search"
              />
              <Button onClick={() => excelGenerate('manageUsers')}>
                Export to excel
                <Icon type="file-excel" />
              </Button>

              {this.renderStateFilter()}

              <Button type="primary" onClick={this.addUser.bind(this, null)}>
                Add User
              </Button>

              {this.state.showPopup ? (
                <UserForm
                  user={this.state.currentUser}
                  onSuccess={this.onSuccess}
                  onClose={this.onClose}
                  title={this.state.title}
                />
              ) : null}
            </div>

            <Table
              columns={this.columns}
              rowKey={record => record._id}
              dataSource={users}
              pagination={false}
            />

            <Paginator total={totalCount} />
          </Card>
        </Col>
      </Row>
    );
  }
}

UserList.propTypes = propTypes;

export default Form.create()(withRouter(UserList));
