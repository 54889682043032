import React from 'react';
import { gql, graphql, compose } from 'react-apollo';
import { mutations, queries } from '../graphql';
import { ValidationRequest } from '../components';
import { Loading } from 'modules/common/components';
import { withRouter } from 'react-router';
import { alert } from '../../common/utils';

class ValidationRequestContainer extends React.Component {
  render() {
    const { validationInfoByUserQuery, supplierValidationRequestCreatedMutation } = this.props;

    if (validationInfoByUserQuery.error) {
      return null;
    }

    if (validationInfoByUserQuery.loading) {
      return <Loading />;
    }

    const validationCreate = doc => {
      supplierValidationRequestCreatedMutation({ variables: doc })
        .then(() => {
          alert.success('Successfully Requested');
          setTimeout(() => {
            window.location.replace('/rfq-and-eoi');
          }, 2500);
        })
        .catch(error => {
          alert.error(error.message);
        });
    };

    const extendedProps = {
      ...this.props,
      validationInformations: validationInfoByUserQuery.validationInfoByUser,
      validationCreate,
    };

    return <ValidationRequest {...extendedProps} />;
  }
}

export default compose(
  graphql(gql(queries.validationInfoByUser), {
    name: 'validationInfoByUserQuery',
    options: () => {
      return {
        fetchPolicy: 'network-only',
      };
    },
  }),
  graphql(gql(mutations.supplierValidationRequestCreated), {
    name: 'supplierValidationRequestCreatedMutation',
  })
)(withRouter(ValidationRequestContainer));
