import React from 'react';
import PropTypes from 'prop-types';
import { gql, graphql, compose } from 'react-apollo';
import { exportFile, Loading } from 'modules/common/components';
import { alert } from 'modules/common/utils';
import { BuyerTenders } from '../components';
import { queries, mutations } from '../graphql';
import { message } from 'antd';
import listCommonQueriesGenerator from './listCommonQueriesGenerator';

class TendersContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = { tenders: [] };

    this.exportTenders = this.exportTenders.bind(this);
    this.cancelTender = this.cancelTender.bind(this);
    this.closeTender = this.closeTender.bind(this);
    this.userEmailSubEoi = this.userEmailSubEoi.bind(this);
  }

  componentDidMount() {
    const { tendersTableQuery, location = {} } = this.props;

    if (location.search === '?refetch') {
      tendersTableQuery.refetch();
    }
  }

  getDateInterval(date) {
    return {
      startDate: date[0],
      endDate: date[1],
    };
  }

  exportTenders(range) {
    const { queryParams, type, supplierId } = this.props;

    this.setState({ exportLoading: true });

    exportFile({
      query: queries.exportTenders,
      name: 'exportTenders',
      variables: {
        search: queryParams ? queryParams.search : '',
        status: queryParams ? queryParams.status : '',
        type: type,
        publishDate: range ? this.getDateInterval(range) : null,
        supplierId: supplierId,
        ignoreSubmitted: queryParams ? queryParams.ignoreSubmitted : '',
      },
      onFinish: () => this.setState({ exportLoading: false }),
      onError: () => {
        this.setState({ exportLoading: false });
      },
    });
  }

  cancelTender(_id, cancelReasonDesc, reason) {
    const { tendersCancel, tendersTableQuery } = this.props;

    tendersCancel({
      variables: { _id, cancelReason: reason, cancelReasonDesc },
    })
      .then(() => {
        tendersTableQuery.refetch();
        alert.success('Canceled a tender');
      })
      .catch(error => {
        alert.error(error.message);
      });
  }

  closeTender(_id) {
    const { tendersCloseManually, tendersTableQuery } = this.props;
    tendersCloseManually({
      variables: { _id },
    })
      .then(() => {
        alert.success('Closed tender');
        tendersTableQuery.refetch();
      })
      .catch(error => {
        alert.error(error.message);
      });
  }

  userEmailSubEoi = key => {
    const { userEmailSubEoiMutation } = this.props;

    userEmailSubEoiMutation({ variables: { isUserEmail: key } })
      .then(e => {
        message.success('Success');
      })
      .catch(error => {
        message.error(error.message);
      });
  };

  render() {
    const {
      tendersTableQuery,
      tendersCountQuery,
      currentUserQuery,
      unsubscribedSupplierCountQuery,
    } = this.props;

    if (tendersTableQuery.error || tendersCountQuery.error) {
      return null;
    }

    if (tendersTableQuery.loading || tendersCountQuery.loading || currentUserQuery.loading) {
      return <Loading />;
    }

    const { exportLoading } = this.state;
    const tenders = tendersTableQuery.tenders || [];
    const totalCount = tendersCountQuery.tendersBuyerTotalCount || 0;
    const unsubscribedUsersTotalCount =
      unsubscribedSupplierCountQuery.eoiUnsubscribedSuppliersCount || null;

    const updatedProps = {
      ...this.props,
      exportLoading,
      totalCount,
      cancelTender: this.cancelTender,
      data: tenders,
      exportTenders: this.exportTenders,
      closeTender: this.closeTender,
      currentUser: currentUserQuery.currentUser,
      userEmailSubEoi: this.userEmailSubEoi,
      unsubscribedUsersTotalCount,
    };

    return <BuyerTenders {...updatedProps} />;
  }
}

TendersContainer.propTypes = {
  type: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
  tendersTableQuery: PropTypes.object,
  tendersResponsesAdd: PropTypes.func,
  tendersCancel: PropTypes.func,
  queryParams: PropTypes.object,
  supplierId: PropTypes.string,
  tendersCountQuery: PropTypes.object,
};

export default compose(
  ...listCommonQueriesGenerator('tendersBuyer', 'totalBuyerTenders'),

  graphql(gql(mutations.tendersResponsesAdd), {
    name: 'tendersResponsesAdd',
  }),

  graphql(gql(mutations.tendersCancel), {
    name: 'tendersCancel',
  }),

  graphql(gql(mutations.tendersCloseManually), {
    name: 'tendersCloseManually',
  })
)(TendersContainer);
