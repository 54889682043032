const User = `
  _id,
  username,
  email,
  role,
  isSupplier,
  companyId,
  jobTitle
  phone
  permissions
  accessOfficer
  attachments
`;

export const users = `
  query users($page: Int, $perPage: Int, $search: String, $isActive: String) {
    users(page: $page, perPage: $perPage, search: $search, isActive: $isActive) {
      ${User}
    }
  }
`;

export const currentUser = `
  query currentUser {
    currentUser {
      email
    }
  }
`;

export const userDetail = `
  query userDetail($_id: String) {
    userDetail(_id: $_id) {
      ${User}
    }
  }
`;

export const usersTotalCount = `
  query usersTotalCount($search: String, $isActive: String) {
    usersTotalCount(search: $search, isActive: $isActive)
  }
`;

export const modulePermissions = `
  query modulePermissions {
    modulePermissions {
      name
    }
  }
`;

export const mailDeliveries = `
  query mailDeliveries($page: Int, $perPage: Int, $search: String, $old: Boolean) {
    mailDeliveries(page: $page, perPage: $perPage, search: $search, old: $old) {
      _id
      createdDate
      from
      to
      subject
      html
      status
    }
  }
`;
export const permissionModules = `
  query permissionModules {
    permissionModules {
      name
      description
      actions {
        name
        description
      }
    }
  }
`;

export const userPermissions = `
  query userPermissions($userId: String) {
    userPermissions(userId: $userId) 
  }
`;

export const mailDeliveriesTotalCount = `
  query mailDeliveriesTotalCount($search: String, $old: Boolean) {
    mailDeliveriesTotalCount(search: $search, old: $old)
  }
`;

const manageUsers = `
  query manageUsers {
    manageUsers 
  }
`;

export default {
  users,
  userDetail,
  usersTotalCount,
  modulePermissions,
  mailDeliveries,
  mailDeliveriesTotalCount,
  permissionModules,
  userPermissions,
  manageUsers,
  currentUser,
};
