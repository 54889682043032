import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import Field from './Field';

const TextArea = Input.TextArea;

export default class ExtendedField extends Field {
  render() {
    const {
      layout,
      isVisible = true,
      hasFeedback = true,
      validateStatus,
      help,
      name,
      control,
      viewMode = false,
      showExtendedField = false,
      optional,
      dataType,
      ddInitialValue,
    } = this.props;

    const { form } = this.context;
    const { getFieldDecorator } = form;

    let initialValue = this.cleanInitialValue();

    const renderStaticData = (className = '') => {
      if (dataType === 'boolean') {
        initialValue = !this.props.initialValue ? 'No' : 'Yes';
      }

      return (
        <Form.Item
          {...layout}
          label={this.label}
          colon={false}
          extra={this.description}
          style={isVisible ? {} : { display: 'none' }}
          hasFeedback={hasFeedback}
          validateStatus={validateStatus}
          help={help}
          className={`disabled-inputs ${className}${!optional ? ' dd-required' : ''}`}
        >
          {typeof initialValue !== 'object' ? (
            <div className="dd-input">{initialValue}&nbsp;</div>
          ) : (
            getFieldDecorator(name, this.args)(control)
          )}
        </Form.Item>
      );
    };

    if (viewMode && showExtendedField) {
      return (
        <>
          {renderStaticData('dd-row')}
          {isVisible && (
            <Form.Item>
              {getFieldDecorator(name, { initialValue: ddInitialValue })(
                <TextArea placeholder="Change reason/comment" />
              )}
            </Form.Item>
          )}
        </>
      );
    }

    if (viewMode) {
      return renderStaticData();
    }

    return (
      <Form.Item
        {...layout}
        label={this.label}
        colon={false}
        extra={this.description}
        style={isVisible ? {} : { display: 'none' }}
        hasFeedback={hasFeedback}
        validateStatus={validateStatus}
        help={help}
      >
        {getFieldDecorator(name, this.args)(control)}
      </Form.Item>
    );
  }
}

ExtendedField.propTypes = {
  label: PropTypes.any,
  labelIgnoreIndex: PropTypes.bool,
  labelIndex: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.node,
  optional: PropTypes.bool,
  validation: PropTypes.string,
  isVisible: PropTypes.bool,
  hasFeedback: PropTypes.bool,
  initialValue: PropTypes.any,
  layout: PropTypes.object,
  rules: PropTypes.array,
  getFieldValue: PropTypes.func,
  dataType: PropTypes.string,
  validateStatus: PropTypes.string,
  help: PropTypes.string,
  validator: PropTypes.func,
  canBeCryllic: PropTypes.bool,
  viewMode: PropTypes.bool,
};

ExtendedField.contextTypes = {
  form: PropTypes.object,
  __: PropTypes.func,
  currentUser: PropTypes.object,
  locale: PropTypes.string,
};
