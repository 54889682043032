import React from 'react';
import { Route } from 'react-router-dom';
import queryString from 'query-string';
import { List, Edit } from './containers';

const generateQueryParams = props => {
  return queryString.parse(props.location.search);
};

export default [
  <Route
    key="/due-diligence"
    exact
    path="/due-diligence"
    component={props => {
      return <List queryParams={generateQueryParams(props)} />;
    }}
  />,
  <Route key="/due-diligence-edit" exact path="/due-diligence/:id" component={Edit} />,
];
