import { queries } from 'modules/companies/graphql';
import { commonParams, commonValues } from 'modules/qualification/graphql/queries';

const companyDetail = `
  query companyDetail($_id: String!) {
    companyDetail(_id: $_id) {
      ${queries.registrationFields}
      lastDueDiligence
    }
  }
`;

const companiesGenerateDueDiligenceList = `
  query companiesGenerateDueDiligenceList(${commonParams}) {
    companiesGenerateDueDiligenceList(${commonValues})
  }
`;

export default {
  companyDetail,
  companiesGenerateDueDiligenceList,
};
