import React from 'react';
import { Link } from 'react-router-dom';
import { LocaleProvider, Layout, BackTop, Modal, Button } from 'antd';
import { PropTypes } from 'prop-types';
import { IntlProvider, addLocaleData, injectIntl } from 'react-intl';
import mn from 'react-intl/locale-data/mn';
import en from 'react-intl/locale-data/en';
import { T } from 'modules/common/components';
import enUS from 'rc-pagination/lib/locale/en_US';
import * as messages from 'modules/translations';
import Sidenav from './Sidenav';
import Header from './Header';
import ActivateModal from './activateStatusModal';

addLocaleData([...mn, ...en]);
const { Content, Footer } = Layout;

const visitorPaths = [
  '/sign-in',
  '/register',
  '/expression-of-interest',
  '/confirm-registration',
  '/forgot-password',
  '/reset-password',
  '/',
];

const mergedMessages = {
  ...messages.Registration.CompanyInfo,
  ...messages.Registration.Contact,
  ...messages.Registration.Shareholder,
  ...messages.Registration.ManagementTeam,
  ...messages.Registration.Group,
  ...messages.Registration.Products,
  ...messages.Prequalification.BusinessIntegrity,
  ...messages.Prequalification.Enviroment,
  ...messages.Prequalification.Health,
  ...messages.Prequalification.FinancialInfo,
  ...messages.CapacityBuilding,
  ...messages.Common,
  ...messages.Options,
  ...messages.Feedback,
  ...messages.Qualification,
  ...messages.SupplierQualification,
  ...messages.RfqAndEoi.Tenders,
  ...messages.Auth.Profile,
  ...messages.Auth.Common,
  ...messages.Auth.SignIn,
};

// antd custom component text
const mn_Mn = {
  locale: 'mn-MN',
  Pagination: {
    jump_to: 'Үсрэх',
    items_per_page: '/ хуудас',
  },
  Table: {
    filterConfirm: 'Тийм',
    filterReset: 'Цуцлах',
    emptyText: 'Мэдээлэл алга',
  },
};

const withSidebar = { marginLeft: 230 };
const withSidebarCollapsed = { marginLeft: 80 };

class InjectInstance extends React.Component {
  getChildContext() {
    const { intl, currentUser } = this.props;
    const { formatMessage } = intl;
    const isSupplier = (currentUser && currentUser.isSupplier) || false;

    return {
      __: msg =>
        !currentUser || isSupplier ? formatMessage({ id: msg, defaultMessage: msg }) : msg,
    };
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

InjectInstance.propTypes = {
  intl: PropTypes.object,
  children: PropTypes.object,
  currentUser: PropTypes.object,
};

InjectInstance.childContextTypes = {
  __: PropTypes.func,
};

const InjectedComponent = injectIntl(InjectInstance);

class MainLayout extends React.Component {
  constructor(props) {
    super(props);

    const locale = localStorage.getItem('locale');
    const messages = locale === 'mn' ? mergedMessages : {};
    const { currentUser } = props;

    this.state = {
      collapsed: localStorage.getItem('collapsed') === 'true' ? true : false,
      locale,
      messages,
      validationModalVisible: currentUser ? true : false,
    };

    this.onCollapse = this.onCollapse.bind(this);
    this.toggleLang = this.toggleLang.bind(this);
  }

  componentDidMount() {
    const { history, currentUser } = this.props;
    const path = history.location.pathname;

    if (!currentUser && !visitorPaths.includes(path)) {
      history.push('/sign-in?required');
    }
  }

  componentDidUpdate() {
    const { location, logsWriteMutation } = this.props;

    const path = location.pathname;

    if (path !== '/' && path !== '/sign-in') {
      logsWriteMutation &&
        logsWriteMutation({
          variables: { apiCall: path },
        });
    }
  }

  toggleLang() {
    const currentLocale = this.state.locale;

    const updatedLocale = currentLocale === 'en' ? 'mn' : 'en';

    localStorage.setItem('locale', updatedLocale);

    window.location.reload();
  }

  onCollapse(collapsed) {
    localStorage.setItem('collapsed', collapsed);
    this.setState({ collapsed });
  }

  getChildContext() {
    return {
      toggleLang: this.toggleLang,
      currentUser: this.props.currentUser,
      systemConfig: this.props.systemConfig || {},
      locale: this.state.locale,
    };
  }

  setModalVisible(validationModalVisible) {
    localStorage.setItem('validationModalVisible', true);
    this.setState({ validationModalVisible });
  }

  validationPopup = () => {
    const { currentUser } = this.props;

    const isValidationModalVisible = localStorage.getItem('validationModalVisible');
    let title = 'Баталгаажуулах хүсэлт илгээсэн мэдээлэл';
    let bodyText =
      'Таньд баяр хүргье. Та Компанийн Баталгаажуулалтын эхний шалгуурыг давсан байна. Та баталгаажуулалтын формыг бөглөж, хүсэлтээ илгээн, дараагийн шалгуурт хамрагдана уу.';
    let approvalText = '';
    let nextUrl = '/request-validation';
    let buttonText = 'Баталгаажуулах';

    if (currentUser) {
      const { isSupplier, showPopUpValidation, validationRequestedType } = currentUser;
      if (isSupplier && showPopUpValidation && validationRequestedType) {
        if (validationRequestedType.validationRequestedType === 'validated') {
          title = 'Мэдэгдэл';
          bodyText =
            'Таньд баяр хүргье. Та Компанийн Баталгаажуулалтын шалгууруудыг давсан байна. ';
          buttonText = 'Харах';
          nextUrl = '/statusvalidation';
        }
      }
    }

    if (currentUser && !currentUser.isSupplier) {
      if (!currentUser.showPopUpValidation) return null;

      bodyText =
        currentUser.showPopUpValidation.requestCount &&
        `Өдрийн мэнд хүргье. Одоогоор ${
          currentUser.showPopUpValidation.requestCount
        } нийлүүлэгч компани баталгаажуулалтын хүсэлтээ илгээсэн байна.`;
      nextUrl = '/validation?productsInfoStatus=requested';

      approvalText =
        currentUser.showPopUpValidation.approvedCount &&
        `${
          currentUser.showPopUpValidation.approvedCount
        } нийлүүлэгч компанийг Менежер баталгаажуулсан байна.`;
      buttonText = 'Жагсаалт харах';
      if (currentUser.showPopUpValidation.approvedCount)
        nextUrl = '/validation?productsInfoStatus=approved';
    }

    const onClick = () => {
      this.setState({ validationModalVisible: false });
      localStorage.setItem('validationModalVisible', true);
    };

    if (!isValidationModalVisible && currentUser && currentUser.showPopUpValidation)
      return (
        <Modal
          className="validationPopup"
          title={title}
          centered
          visible={this.state.validationModalVisible}
          onOk={() => this.setModalVisible(false)}
          onCancel={() => this.setModalVisible(false)}
          footer={[
            <Button key="submit" type="primary" onClick={onClick}>
              <Link to={nextUrl}>{buttonText}</Link>
            </Button>,
          ]}
        >
          <h3>
            {bodyText}
            <br />
            {approvalText}
          </h3>
        </Modal>
      );
  };

  render() {
    const { currentUser, location, companyDeActiveStatus } = this.props;
    const { collapsed, locale, messages } = this.state;
    const antdLocale = locale === 'en' ? enUS : mn_Mn;

    const navProps = {
      collapsed: collapsed ? true : false,
      onCollapse: this.onCollapse,
      pathname: location.pathname,
    };

    let layoutStyle = {};
    if (currentUser) {
      collapsed ? (layoutStyle = withSidebarCollapsed) : (layoutStyle = withSidebar);
    }

    return (
      <LocaleProvider locale={antdLocale}>
        <IntlProvider locale={locale || 'en'} messages={messages}>
          <Layout className={`main-wrapper ${locale}`}>
            {currentUser && <Sidenav {...navProps} />}
            {this.validationPopup()}
            <Layout className="main" style={layoutStyle}>
              {currentUser && <Header location={location} />}
              {currentUser && currentUser.isSupplier && companyDeActiveStatus && (
                <ActivateModal {...this.props} />
              )}
              <Content>
                <InjectedComponent {...this.props} />
                <BackTop />
              </Content>
              {currentUser && (
                <Footer>
                  <T id="Oyu Tolgoi ©2018 All Rights Reserved">
                    Oyu Tolgoi 2018 All Rights Reserved
                  </T>
                </Footer>
              )}
            </Layout>
          </Layout>
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

MainLayout.propTypes = {
  currentUser: PropTypes.object,
  systemConfig: PropTypes.object,
  children: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  logsWriteMutation: PropTypes.func,
};

MainLayout.childContextTypes = {
  toggleLang: PropTypes.func,
  currentUser: PropTypes.object,
  systemConfig: PropTypes.object,
  locale: PropTypes.string,
};

export default MainLayout;
