export default {
  auth: 'VcMkugayZiE',
  dashboard: 'HvRuKbXu8xc',
  audit: 'bb61CwHNZGs',
  registration: 'l4V9B7T4Zzw',
  prequalification: '5HRuUT83N8U',
  successFeedback: 'Q39qC5cAj9g',
  rfq: '-xy7kpRt2Ig',
  trfq: '-xy7kpRt2Ig',
  eoi: 'ZL4WUhaQxAU',
};
