import React from 'react';
import PropTypes from 'prop-types';
import { Rfq, Eoi } from '../components';
import { gql, graphql, compose } from 'react-apollo';
import { queries, mutations } from '../graphql';
import { message, notification, Icon, Button } from 'antd';
import { colors } from 'modules/common/constants';
import { exportFile } from 'modules/common/components';
import { alert } from 'modules/common/utils';
import { mutations as logMutations } from 'modules/logs/graphql/index';
import { Loading } from '../../common/components';

const notifyIfWantToSend = {
  message: 'Succesfully awarded',
  description: 'Do you want to send regret letters to remaining suppliers now?',
  icon: <Icon type="mail" style={{ color: colors[0] }} />,
  duration: 0,
  key: 'awardedNotification',
};

class TenderContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = { exportLoading: false };

    this.downloadReport = this.downloadReport.bind(this);
    this.award = this.award.bind(this);
    this.sendRegretLetter = this.sendRegretLetter.bind(this);
    this.tenderResponseEdit = this.tenderResponseEdit.bind(this);
    this.addNewSupplier = this.addNewSupplier.bind(this);
    this.tendersRemoveResponse = this.tendersRemoveResponse.bind(this);
  }

  tendersRemoveResponse(doc) {
    const { tendersRemoveResponse, tenderDetailQuery } = this.props;

    tendersRemoveResponse({ variables: { tenderId: tenderDetailQuery.tenderDetail._id, ...doc } })
      .then(() => {
        tenderDetailQuery.refetch();
        message.success('Successfully removed supplier.');
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  tenderResponseEdit(variables) {
    const { tendersResponseEdit, tenderResponsesTableQuery, tenderDetailQuery } = this.props;

    tendersResponseEdit({
      variables: { tenderId: tenderDetailQuery.tenderDetail._id, ...variables },
    })
      .then(() => {
        alert.success('Updated Tender Response');
        tenderResponsesTableQuery.refetch();
      })
      .catch(error => {
        alert.error(error.message);
      });
  }

  addNewSupplier(tenderId, supplierId) {
    const { tendersToNewSupplierAdd, tenderResponsesTableQuery, tenderDetailQuery } = this.props;

    tendersToNewSupplierAdd({
      variables: {
        tenderId: tenderId,
        supplierIds: supplierId.map(e => e._id),
      },
    })
      .then(() => {
        alert.success('Added New Supplier');
        tenderResponsesTableQuery.refetch();
        tenderDetailQuery.refetch();
      })
      .catch(error => {
        alert.error(error.message);
      });
  }

  award(variables) {
    const { tendersAward, tenderDetailQuery } = this.props;
    const { tenderResponsesTableQuery } = this.props;
    const tenderResponses = tenderResponsesTableQuery.tenderResponses || [];

    tendersAward({
      variables: {
        _id: tenderDetailQuery.tenderDetail._id,
        ...variables,
      },
    })
      .then(() => {
        if (tenderResponses.length === variables.supplierIds.length) {
          alert.success('Successfully Awarded!');
          tenderDetailQuery.refetch();
        } else {
          notification.open({
            ...notifyIfWantToSend,
            btn: (
              <Button
                type="primary"
                onClick={() => {
                  this.showRegretLetter();
                  notification.close('awardedNotification');
                }}
              >
                Send
              </Button>
            ),
          });
        }
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  showRegretLetter() {
    this.setState({ regretLetterModalVisible: true });
  }

  sendRegretLetter(content) {
    const { sendRegretLetter, tenderDetailQuery } = this.props;
    const { tenderDetail } = tenderDetailQuery;

    sendRegretLetter({
      variables: {
        _id: tenderDetail._id,
        subject: `Regret notice for ${tenderDetail.name}`,
        content: content,
      },
    })
      .then(() => {
        message.success('Succesfully sent regret letters!');
        tenderDetailQuery.refetch();
        this.setState({ regretLetterModalVisible: false });
      })
      .catch(error => {
        alert.error(error.message);
      });
  }

  /**
   * Prepares file to be downloaded
   * @param {Object[]} companies Companies
   * @param {string} name Query name part
   * @param {string} desc Tender log description
   */
  downloadReport(name, variables, desc) {
    const { tenderDetailQuery, writeTenderLog } = this.props;
    const loadingReportName = `${name}Loading`;

    let loading = {};

    loading[loadingReportName] = true;

    this.setState(loading);

    exportFile({
      query: queries[name],
      name,
      variables: {
        tenderId: tenderDetailQuery.tenderDetail._id,
        ...variables,
      },
      onError: () => {},
      onFinish: () => {
        loading[loadingReportName] = false;
        tenderDetailQuery.refetch();
        this.setState(loading);
      },
      onDownload: () => {
        writeTenderLog({
          variables: {
            tenderId: tenderDetailQuery.tenderDetail._id,
            action: 'download',
            description: desc || 'File has been downloaded',
          },
        });
      },
    });
  }

  modifySuppliersQuery(suppliers) {
    return suppliers.map(supplier => ({
      supplier,
    }));
  }

  render() {
    const {
      tenderDetailQuery,
      tenderResponsesTableQuery,
      tenderResponsesTotalCountQuery,
      notRespondedSuppliersQuery,
      invitedSuppliersQuery,
      location,
      writeTenderLog,
    } = this.props;

    const { systemConfig } = this.context;

    const Component =
      location.pathname.includes('rfq') || location.pathname.includes('freight') ? Rfq : Eoi;

    if (tenderDetailQuery.error || tenderResponsesTableQuery.error) {
      return null;
    }

    if (tenderDetailQuery.loading || tenderResponsesTableQuery.loading) {
      return <Loading />;
    }

    const tenderDetail = tenderDetailQuery.tenderDetail || {};
    const tenderResponses = tenderResponsesTableQuery.tenderResponses || [];

    let totalCount = tenderResponsesTotalCountQuery.tenderResponsesTotalCount || 0;
    let notRespondedSuppliers = [];
    let invitedSuppliers = [];

    if (notRespondedSuppliersQuery && !notRespondedSuppliersQuery.loading) {
      notRespondedSuppliers = notRespondedSuppliersQuery.tenderResponseNotRespondedSuppliers.list;
      totalCount = notRespondedSuppliersQuery.tenderResponseNotRespondedSuppliers.totalCount;
    }

    if (invitedSuppliersQuery && !invitedSuppliersQuery.loading) {
      invitedSuppliers = invitedSuppliersQuery.tenderResponseInvitedSuppliers.list;
      totalCount = invitedSuppliersQuery.tenderResponseInvitedSuppliers.totalCount;
    }

    const {
      rfqBidSummaryReportLoading,
      regretLetterModalVisible,
      rfqBidSummaryPdfLoading,
    } = this.state;

    const exportSuppliersTenderResponses = companies => {
      this.setState({ exportLoading: true });
      exportFile({
        query: queries.exportTenderResponseSuppliers,
        variables: {
          _ids: companies,
          tenderId: this.props.match.params.id,
        },
        onError: () => this.setState({ exportLoading: false }),
        onFinish: () => this.setState({ exportLoading: false }),
      });
    };

    const updatedProps = {
      ...this.props,
      rfqBidSummaryReportLoading,
      rfqBidSummaryPdfLoading,
      regretLetterModalVisible,
      tenderDetail,
      emailTemplate: systemConfig.regretLetterTemplate,
      award: this.award,
      downloadReport: this.downloadReport,
      sendRegretLetter: this.sendRegretLetter,
      data: tenderResponses,
      notRespondedSuppliers: this.modifySuppliersQuery(notRespondedSuppliers),
      invitedSuppliers: this.modifySuppliersQuery(invitedSuppliers),
      totalCount,
      writeTenderLog,
      exportSuppliersTenderResponses,
      exportLoading: this.state.exportLoading,
      tenderResponseEdit: this.tenderResponseEdit,
      addNewSupplier: this.addNewSupplier,
      tendersRemoveResponse: this.tendersRemoveResponse,
    };

    return <Component {...updatedProps} />;
  }
}

TenderContainer.propTypes = {
  tenderDetailQuery: PropTypes.object,
  tenderResponsesTableQuery: PropTypes.object,
  tenderResponsesTotalCountQuery: PropTypes.object,
  tendersAward: PropTypes.func,
  sendRegretLetter: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  notRespondedSuppliersQuery: PropTypes.object,
  invitedSuppliersQuery: PropTypes.object,
};

TenderContainer.contextTypes = {
  systemConfig: PropTypes.object,
};

const generatePageParams = queryParams => ({
  page: queryParams.page || 1,
  perPage: queryParams.perPage || 15,
});

const generateResponsesParams = ({ match, queryParams }) => ({
  tenderId: match.params.id,
  supplierSearch: queryParams.search,
  sort: {
    name: queryParams.sorter,
    productCode: queryParams.productCode,
  },
  betweenSearch: {
    name: queryParams.between,
    productCode: queryParams.productCode,
    minValue: queryParams.from,
    maxValue: queryParams.to,
  },
  isNotInterested: queryParams.filter === 'isNotInterested',
});

export default compose(
  graphql(gql(queries.tenderDetail), {
    name: 'tenderDetailQuery',
    options: ({ match }) => {
      return {
        variables: { _id: match.params.id },
      };
    },
  }),

  graphql(gql(queries.tenderResponseNotRespondedSuppliers), {
    name: 'notRespondedSuppliersQuery',
    options: ({ match, queryParams }) => {
      return {
        variables: {
          tenderId: match.params.id,
          ...generatePageParams(queryParams),
        },
        skip: queryParams.filter !== 'isNotResponded',
      };
    },
  }),

  graphql(gql(queries.tenderResponseInvitedSuppliers), {
    name: 'invitedSuppliersQuery',
    options: ({ match, queryParams }) => {
      return {
        variables: {
          tenderId: match.params.id,
          ...generatePageParams(queryParams),
        },
        skip: queryParams.filter !== 'isInvited',
      };
    },
  }),

  graphql(gql(queries.tenderResponses), {
    name: 'tenderResponsesTableQuery',
    options: ({ match, queryParams }) => {
      return {
        variables: {
          ...generatePageParams(queryParams),
          ...generateResponsesParams({ match, queryParams }),
        },
        notifyOnNetworkStatusChange: true,
      };
    },
  }),

  graphql(gql(queries.tenderResponsesTotalCount), {
    name: 'tenderResponsesTotalCountQuery',
    options: ({ match, queryParams }) => {
      return {
        variables: generateResponsesParams({ match, queryParams }),
        notifyOnNetworkStatusChange: true,
      };
    },
  }),

  graphql(gql(mutations.tendersAward), {
    name: 'tendersAward',
  }),
  graphql(gql(mutations.tendersResponseEdit), {
    name: 'tendersResponseEdit',
  }),

  graphql(gql(mutations.sendRegretLetter), {
    name: 'sendRegretLetter',
  }),
  graphql(gql(logMutations.logsWriteTenderLog), {
    name: 'writeTenderLog',
    options: () => ({
      refetchQueries: ['logsTender'],
    }),
  }),
  graphql(gql(mutations.tendersToNewSupplierAdd), {
    name: 'tendersToNewSupplierAdd',
    options: () => ({
      refetchQueries: ['tenderResponses'],
    }),
  }),
  graphql(gql(mutations.tendersRemoveResponse), {
    name: 'tendersRemoveResponse',
    options: () => ({
      refetchQueries: ['tenderResponses'],
    }),
  })
)(TenderContainer);
