const labels = {
  totalEmploymentOt: 'Total national employment',
  totalEmploymentUmnugovi: 'Total Umnugovi employment',
  employmentChangesAfter: 'Changes in employment number after working with OT',
  numberOfEmployeeWorkToScopeNational: 'National',
  numberOfEmployeeWorkToScopeUmnugovi: 'Umnugovi',
  procurementTotalSpend: 'Total spend/procurement',
  procurementNationalSpend: 'National spend',
  procurementUmnugoviSpend: 'Umnugovi spend',
  corporateSocial: '3. Corporate Social Responsibility – Contribution to community',
  otherStories: '4. Other success stories to share',
};

const titles = {
  '1': '1. Please provide your employment details',
  '1a': 'Please provide 2017 employment details:',
  '1b': 'Of which, how many employee work related to the scope you provide to OT',
  '2': '2. Please provide 2017 procurement spend details',
};

export { labels, titles };
