import React from 'react';
import { generator } from 'modules/companies/containers';
import { exportFile } from 'modules/common/components';
import { alert } from 'modules/common/utils';
import { gql, graphql, compose } from 'react-apollo';
import { ValidationSuppliersList } from '../components';
import { mutations, queries } from '../graphql';
import { withCurrentUser } from 'modules/auth/containers';

class ValidationSuppliersListContainer extends React.Component {
  render() {
    const { companiesQuery, setValidationPermissionOnSupplierMutation } = this.props;

    const generateExcel = ids => {
      if (ids.length === 0) {
        return alert.error('Select the company!');
      }

      exportFile({
        query: queries.companiesValidatedProductsInfoExport,
        variables: { ids },
        onError: () => {},
      });
    };

    const setValidationAccessOnSupplier = companyId => {
      setValidationPermissionOnSupplierMutation({ variables: { companyId } })
        .then(() => {
          alert.success('Successfully send Validation Access.');
          companiesQuery.refetch();
        })
        .catch(error => {
          alert.error(error.message);
        });
    };

    const extendedProps = {
      ...this.props,
      generateExcel,
      setValidationAccessOnSupplier,
    };

    return <ValidationSuppliersList {...extendedProps} />;
  }
}

const WithData = compose(
  graphql(gql(mutations.setValidationPermission), {
    name: 'setValidationPermissionOnSupplierMutation',
  })
)(withCurrentUser(ValidationSuppliersListContainer));

export default generator(WithData, 'validation', () => ({
  source: 'Validation',
}));
