/* eslint-disable react/display-name */
import React from 'react';
import { RegistrationForms } from 'modules/companies/components';
import ModalForm from './Modal';

class DueDiligenceEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = { data: null };
    this.hideModal = this.hideModal.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.setSupplierId = this.setSupplierId.bind(this);
    this.sendRecommendation = this.sendRecommendation.bind(this);
  }

  handleSave(values) {
    const { data } = this.state;

    if (data) {
      this.props.update(values);
    }

    this.hideModal();
  }

  hideModal() {
    this.setState({ data: null });
  }

  sendRecommendation() {
    this.props.send(this.setSupplierId);
  }

  setSupplierId(data) {
    this.setState({ data });
  }

  render() {
    return (
      <>
        <RegistrationForms {...this.props} send={this.sendRecommendation} />;
        <ModalForm data={this.state.data} hide={this.hideModal} onSave={this.handleSave} />
      </>
    );
  }
}

export default DueDiligenceEdit;
