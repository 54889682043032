const shareholderInfo = `
  mutation dueDiligencesSaveShareholderInfo(
    $supplierId: String!, $shareholderInfo: DueDiligenceShareholderInfoInput
  ) {
    dueDiligencesSaveShareholderInfo(
      supplierId: $supplierId, shareholderInfo: $shareholderInfo
    ) {
      _id
    }
  }
`;

const basicInfo = `
  mutation dueDiligencesSaveBasicInfo(
    $supplierId: String!, $basicInfo: DueDiligenceBasicInfoInput
  ) {
    dueDiligencesSaveBasicInfo(
      supplierId: $supplierId, basicInfo: $basicInfo
    ) {
      _id
    }
  }
`;

const managementTeamInfo = `
  mutation dueDiligencesSaveManagementTeamInfo(
    $supplierId: String!, $managementTeamInfo: DueDiligenceManagementTeamInfoInput
  ) {
    dueDiligencesSaveManagementTeamInfo(
      supplierId: $supplierId, managementTeamInfo: $managementTeamInfo
    ) {
      _id
    }
  }
`;

const groupInfo = `
  mutation dueDiligencesSaveGroupInfo(
    $supplierId: String!, $groupInfo: DueDiligenceGroupInfoInput
  ) {
    dueDiligencesSaveGroupInfo(
      supplierId: $supplierId, groupInfo: $groupInfo
    ) {
      _id
    }
  }
`;

const saveDueDiligence = `
  mutation dueDiligencesSave($supplierId: String!) {
    dueDiligencesSave(supplierId: $supplierId)
    {
      _id,
      dueDiligenceStatusDisplay,
      isDueDiligenceValidated
    }
  }
`;

const cancelDueDiligence = `
  mutation dueDiligencesCancel($supplierId: String!) {
    dueDiligencesCancel(supplierId: $supplierId)
    {
      _id,
      dueDiligenceStatusDisplay,
      isDueDiligenceValidated
    }
  }
`;

const enableState = `
  mutation dueDiligencesEnableState($supplierId: String!) {
    dueDiligencesEnableState(supplierId: $supplierId) {
      _id
    }
  }
`;

const removeRisk = `
  mutation dueDiligencesRemoveRisk($supplierId: String!) {
    dueDiligencesRemoveRisk(supplierId: $supplierId) {
      _id
    }
  }
`;

const updateDueDiligence = `
  mutation dueDiligencesUpdate(
    $supplierId: String!,
    $files: JSON,
    $risk: String,
    $date: Date,
    $closeDate: Date,
    $reminderDay: Int
  ) {
    dueDiligencesUpdate(
      supplierId: $supplierId,
      date: $date, 
      closeDate: $closeDate,
      reminderDay: $reminderDay,
      files: $files,
      risk: $risk
    ) {
      _id
    }
  }
`;

const saveRelatedCompany = `
  mutation saveRelatedCompany(
    $supplierIds: [String],
    $comment: String,
    $oldSupplierIds: [String]
  ) {
    saveRelatedCompany(
      supplierIds: $supplierIds,
      comment: $comment,
      oldSupplierIds: $oldSupplierIds
    )
  }
`;

export default {
  shareholderInfo,
  basicInfo,
  managementTeamInfo,
  groupInfo,
  saveDueDiligence,
  enableState,
  updateDueDiligence,
  cancelDueDiligence,
  removeRisk,
  saveRelatedCompany,
};
