import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { Card, Col, Checkbox, Select } from 'antd';
import { regionOptions } from '../../constants';
import { ProductsTree } from 'modules/common/components';
import router from 'modules/common/router';
import { riskData } from 'modules/dueDiligence/components/constants';

const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;

const propTypes = {
  history: PropTypes.object,
  suppliersCount: PropTypes.number,
  checkedCount: PropTypes.number,
  stats: PropTypes.node,
  isDueDiligence: PropTypes.bool,
};

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    const { history } = props;

    const query = queryString.parse(history.location.search);

    const { region, productCodes } = query;

    const regionQuery = region && region.split(',');
    const productCodesQuery = productCodes && productCodes.split(',');

    this.state = {
      productCodes: productCodesQuery || [],
      region: regionQuery || [],
      difotRange: query.difotRange || '',
      prequalifiedStatus: query.prequalifiedStatus || '',
      qualifiedStatus: query.qualifiedStatus || '',
      productsInfoStatus: query.productsInfoStatus || '',
      includeBlocked: query.includeBlocked || '',
      dueDiligenceStatus: query.dueDiligenceStatus || '',
      dueDiligenceRisk: query.dueDiligenceRisk || '',
      sapRegistration: query.sapRegistration || '',
      suppliersScale: query.suppliersScale || '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.filter = this.filter.bind(this);
  }

  handleChange(filter) {
    this.setState({ ...filter }, () => {
      this.filter(filter);
    });
  }

  handleSelect(value, name) {
    this.setState({ [name]: value }, () => {
      this.filter({ [name]: value });
    });
  }

  filter(filter) {
    const { history } = this.props;

    const {
      region,
      productCodes,
      difotRange,
      prequalifiedStatus,
      qualifiedStatus,
      productsInfoStatus,
      includeBlocked,
      sapRegistration,
      suppliersScale,
    } = this.state;

    let regionString = '';
    let productCodesString = '';

    region.forEach(i => {
      regionString += i + ',';
    });

    productCodes.forEach(i => {
      productCodesString += i + ',';
    });

    const filterValues = {
      ...filter,
      region: regionString.replace(/.$/, ''),
      productCodes: productCodesString.replace(/.$/, ''),
      difotRange,
      prequalifiedStatus,
      qualifiedStatus,
      productsInfoStatus,
      includeBlocked,
      sapRegistration,
      suppliersScale,
    };

    filterValues.difotRange = difotRange;

    router.setParams(history, filterValues);
  }

  renderActivationStatus() {
    const { renderActivationStatus } = this.props;

    if (renderActivationStatus) {
      return renderActivationStatus();
    }

    return null;
  }
  renderValidationSelect() {
    return (
      <tr key={'validation'}>
        <td>
          <label>{'Validation'}</label>
        </td>
        <td>
          <Select
            onChange={e => this.handleSelect(e, 'productsInfoStatus')}
            size="small"
            value={this.state['productsInfoStatus']}
          >
            <Option value="">All</Option>
            <Option value="yes">Validated</Option>
            <Option value="requested">Requests</Option>
            <Option value="approved">Approved</Option>
            <Option value="declined">Declined</Option>
          </Select>
        </td>
      </tr>
    );
  }

  renderSAPRegistration() {
    return (
      <tr key={'Sapregistration'}>
        <td>
          <label>{'SAP Registration'}</label>
        </td>
        <td>
          <Select
            onChange={e => this.handleSelect(e, 'sapRegistration')}
            size="small"
            value={this.state['sapRegistration']}
          >
            <Option value="">All</Option>
            <Option value="vendorNumber">Vendor number</Option>
          </Select>
        </td>
      </tr>
    );
  }

  renderSuppliersScale() {
    return (
      <tr key={'SuppliersScale'}>
        <td>
          <label>{'Suppliers Scale'}</label>
        </td>
        <td>
          <Select
            onChange={e => this.handleSelect(e, 'suppliersScale')}
            size="small"
            value={this.state['suppliersScale']}
          >
            <Option value="">All</Option>
            <Option value="sme">SME</Option>
            <Option value="medium">Medium size</Option>
            <Option value="group">Group or Corporation</Option>
          </Select>
        </td>
      </tr>
    );
  }

  renderDifotSelect() {
    return (
      <tr key="difotRange">
        <td>
          <label>By DIFOT score</label>
        </td>
        <td>
          <Select
            onChange={e => this.handleSelect(e, 'difotRange')}
            size="small"
            value={this.state.difotRange}
          >
            <Option value="">All</Option>
            <Option value="0-25">0% - 25%</Option>
            <Option value="26-50">26% - 50%</Option>
            <Option value="51-74">51% - 74%</Option>
            <Option value="75-100">75% - 100%</Option>
          </Select>
        </td>
      </tr>
    );
  }

  renderBlockSelect() {
    return (
      <tr key="block">
        <td>
          <label>Blocked suppliers</label>
        </td>
        <td>
          <Select
            onChange={e => this.handleSelect(e, 'includeBlocked')}
            size="small"
            value={this.state.includeBlocked}
          >
            <Option value="true">include</Option>
            <Option value="">Not include</Option>
          </Select>
        </td>
      </tr>
    );
  }

  renderSelect({ name, label, included, notIncluded }) {
    return (
      <tr key={name}>
        <td>
          <label>{label}</label>
        </td>
        <td>
          <Select onChange={e => this.handleSelect(e, name)} size="small" value={this.state[name]}>
            <Option value="">All</Option>
            <Option value="yes">{included}</Option>
            <Option value="no">{notIncluded}</Option>
            <Option value="undefined">In process</Option>
          </Select>
        </td>
      </tr>
    );
  }

  renderDueDiligenceRiskSelect() {
    return (
      <tr key="dueDiligenceRisk">
        <td>
          <label>Due Diligence Risk</label>
        </td>
        <td>
          <Select
            onChange={e => this.handleSelect(e, 'dueDiligenceRisk')}
            size="small"
            value={this.state.dueDiligenceRisk}
          >
            <Option value="">All</Option>
            {riskData.map((option, i) => (
              <Option key={i} value={option.value}>
                {option.text}
              </Option>
            ))}
          </Select>
        </td>
      </tr>
    );
  }

  renderDueDiligenceSelect() {
    if (!this.props.isDueDiligence) {
      return this.renderSelect({
        name: 'dueDiligenceStatus',
        label: 'Verification',
        included: 'Verifed',
        notIncluded: 'Verification failed',
      });
    }

    return (
      <tr key="dueDiligenceStatus">
        <td>
          <label>Verification failed</label>
        </td>
        <td>
          <Select
            onChange={e => this.handleSelect(e, 'dueDiligenceStatus')}
            size="small"
            value={this.state.dueDiligenceStatus}
          >
            <Option value="exclude">Exclude</Option>
            <Option value="">Include</Option>
          </Select>
        </td>
      </tr>
    );
  }

  render() {
    const { productCodes, region } = this.state;
    const { suppliersCount, checkedCount, stats, match } = this.props;

    return (
      <Col span={5}>
        <Card title="Suppliers">
          <div className="suppliers-count">
            {suppliersCount}
            {checkedCount !== undefined && (
              <span>
                <small>/</small>
                {checkedCount}
              </span>
            )}
          </div>
          {stats}
        </Card>

        <Card title="Select product & service code" className="margin">
          <ProductsTree
            value={productCodes}
            onChange={value =>
              this.handleChange({
                productCodes: value,
              })
            }
            style={{ width: '100%' }}
          />
        </Card>

        <Card title="Select suppliers by tier type" className="margin">
          <CheckboxGroup
            options={regionOptions}
            value={region}
            className="horizontal"
            onChange={value => this.handleChange({ region: value })}
          />
        </Card>

        <Card title="Select suppliers by qualification status" className="margin">
          <table className="suppliers-filter">
            <tbody>
              {this.renderDueDiligenceSelect()}
              {this.renderSelect({
                name: 'prequalifiedStatus',
                label: 'Pre-qualified',
                included: 'Pre-qualified',
                notIncluded: 'Not qualified',
              })}
              {this.renderSelect({
                name: 'qualifiedStatus',
                label: 'Qualified',
                included: 'Qualified',
                notIncluded: 'Not qualified',
              })}
              {this.renderValidationSelect()}
              {this.renderDifotSelect()}
              {this.renderDueDiligenceRiskSelect()}
              {this.renderBlockSelect()}
              {this.renderActivationStatus()}
              {match.path === '/companies' && this.renderSAPRegistration()}
              {match.path === '/companies' && this.renderSuppliersScale()}
            </tbody>
          </table>
        </Card>
      </Col>
    );
  }
}

Sidebar.propTypes = propTypes;

export default withRouter(Sidebar);
