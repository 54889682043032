import React from 'react';
import moment from 'moment';
import { dateFormat } from 'modules/common/constants';
import { Card, Table } from 'antd';
import { Paginator } from 'modules/common/components';

class ValidationStatus extends React.Component {
  columns = () => {
    return [
      {
        title: 'Validated Codes',
        width: 100,
        dataIndex: 'validatedCodes',
      },
      {
        title: 'Expired Period',
        render: ({ expiryDate }) => {
          return expiryDate === '-' ? expiryDate : moment(expiryDate).format(dateFormat);
        },
        width: 100,
      },
    ];
  };

  render() {
    const { validatedCodes } = this.props;

    return (
      <Card style={{ marginBottom: '16px' }} title={'Validation Status'}>
        <div className="table-operations" />

        <Table
          columns={this.columns()}
          rowKey={record => record.validatedCodes}
          pagination={false}
          dataSource={validatedCodes}
          scroll={{ x: 1500 }}
          className="tenders-table"
        />
        <Paginator total={0} />
      </Card>
    );
  }
}

export default ValidationStatus;
