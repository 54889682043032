import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Form, Input, Card, Select } from 'antd';
import { BaseForm, ExtendedField as Field, Uploader } from 'modules/common/components';
import { uploadDisclaimer } from 'modules/common/constants';
import { shareholderType } from '../constants';

class ShareHolders extends BaseForm {
  constructor(props) {
    super(props);

    const shareholderInfo = this.props.data || {};
    const shareholders = shareholderInfo.shareholders || [];

    let percentages = {};
    const types = {};
    const items = [0, 1, 2, 3, 4];

    items.forEach(i => {
      types[`isCompany${i}`] = false;
    });

    shareholders.forEach((shareholder, i) => {
      if (shareholder) {
        percentages[`percentage${i}`] = shareholder.percentage;
        types[`isCompany${i}`] = shareholder.type === 'company';
      }
    });

    this.state = { ...percentages, ...types };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleShareChange = this.handleShareChange.bind(this);
    this.renderShareholder = this.renderShareholder.bind(this);
    this.validateShare = this.validateShare.bind(this);
  }

  handleIsTypeChange(k, value) {
    this.setState({ [`isCompany${k}`]: value === 'company' });
  }

  handleSubmit(e) {
    e.preventDefault();
    const showExtendedField = this.props.showExtendedField;
    const items = [0, 1, 2, 3, 4];

    const shareholders = [];

    const collectValues = (doc = {}, key, value) => {
      if (key && value) doc[key] = value;

      return doc;
    };

    items.forEach(i => {
      const type = this.getFieldValue(`type${i}`);
      const firstName = this.getFieldValue(`firstName${i}`);
      const lastName = this.getFieldValue(`lastName${i}`);
      const jobTitle = this.getFieldValue(`jobTitle${i}`);
      const companyName = this.getFieldValue(`companyName${i}`);
      const percentage = this.getFieldValue(`percentage${i}`);

      const doc = {};

      if (showExtendedField) {
        collectValues(doc, 'type', type);
        collectValues(doc, 'firstName', firstName);
        collectValues(doc, 'lastName', lastName);
        collectValues(doc, 'jobTitle', jobTitle);
        collectValues(doc, 'companyName', companyName);
        collectValues(doc, 'percentage', percentage);

        if (Object.keys(doc).length > 0) shareholders.push(doc);
      } else if (type === 'company' && percentage && companyName) {
        shareholders.push({ type, companyName, percentage });
      } else if (type === 'individual' && percentage && firstName && lastName && jobTitle) {
        shareholders.push({ type, firstName, lastName, jobTitle, percentage });
      }
    });

    this.save({ shareholders });
  }

  handleShareChange(e, name) {
    const value = e.target.value;

    this.setState({ [name]: value ? parseInt(value, 10) : 0 });
  }

  validateShare(value, callback, name) {
    let totalShare = 0;

    Object.keys(this.state).forEach(key => {
      if (key !== name && typeof this.state[key] === 'number') {
        totalShare += this.state[key];
      }
    });

    if (totalShare + parseInt(value, 10) > 100)
      callback('Total share percentage should be lower than 100%');

    callback();
  }

  renderShareholder(k, optional) {
    const shareholderInfo = this.props.data || {};
    const shareholders = shareholderInfo.shareholders || [];
    const { __ } = this.context;
    const shareholder = shareholders[k] || {};
    const viewMode = this.props.viewMode;
    const isCompany = this.state[`isCompany${k}`];
    const disabled = this.props.disabled;
    const showExtendedField = this.props.showExtendedField;

    const fieldProps = {
      showExtendedField,
      viewMode,
    };

    let ddShareholder = {};

    if (showExtendedField) {
      const ddShareholderInfo = this.props.ddData || {};
      const ddShareholders = ddShareholderInfo.shareholders || [];
      ddShareholder = ddShareholders[k] || {};
    }

    return (
      <Card title={__('Shareholder') + ` ${k + 1}`}>
        <Field
          {...fieldProps}
          viewMode={viewMode}
          label="Type"
          name={`type${k}`}
          control={
            <Select onChange={this.handleIsTypeChange.bind(this, k)} disabled={disabled}>
              {this.renderOptions(shareholderType)}
            </Select>
          }
          initialValue={isCompany ? 'company' : 'individual'}
          ddInitialValue={ddShareholder.type}
        />

        <Field
          {...fieldProps}
          label="First name"
          name={`firstName${k}`}
          control={<Input disabled={disabled} />}
          initialValue={shareholder.firstName}
          ddInitialValue={ddShareholder.firstName}
          isVisible={!isCompany}
          optional={optional}
        />

        <Field
          {...fieldProps}
          label="Last name"
          name={`lastName${k}`}
          control={<Input disabled={disabled} />}
          initialValue={shareholder.lastName}
          ddInitialValue={ddShareholder.lastName}
          isVisible={!isCompany}
          optional={optional}
        />

        <Field
          {...fieldProps}
          label="Job title"
          name={`jobTitle${k}`}
          control={<Input disabled={disabled} />}
          initialValue={shareholder.jobTitle}
          ddInitialValue={ddShareholder.jobTitle}
          isVisible={!isCompany}
          optional={optional}
        />

        <Field
          {...fieldProps}
          label="Company name"
          name={`companyName${k}`}
          control={<Input disabled={disabled} />}
          initialValue={shareholder.companyName}
          ddInitialValue={ddShareholder.companyName}
          isVisible={isCompany}
          optional={optional}
        />

        <Field
          {...fieldProps}
          label="Share percentage %"
          name={`percentage${k}`}
          initialValue={shareholder.percentage}
          control={
            <Input
              type="number"
              onChange={e => this.handleShareChange(e, `percentage${k}`)}
              disabled={disabled}
            />
          }
          optional={optional}
          validator={(rules, value, callback) =>
            this.validateShare(value, callback, `percentage${k}`)
          }
          ddInitialValue={ddShareholder.percentage}
        />
      </Card>
    );
  }

  render() {
    return (
      <Form>
        <Card>
          {this.renderField({
            label: '20. Please provide key shareholders information',
            name: 'attachments',
            description: uploadDisclaimer,
            optional: true,
            dataType: 'file-multiple',
            control: <Uploader multiple={true} />,
          })}
        </Card>

        {this.renderShareholder(0, true)}
        {this.renderShareholder(1, true)}
        {this.renderShareholder(2, true)}
        {this.renderShareholder(3, true)}
        {this.renderShareholder(4, true)}

        {this.renderGoBack()}
        {this.renderSubmit()}
      </Form>
    );
  }
}

ShareHolders.contextTypes = {
  __: PropTypes.func,
};

const ShareHoldersForm = Form.create()(ShareHolders);

export default withRouter(ShareHoldersForm);
