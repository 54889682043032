import React from 'react';
import { Tooltip } from 'antd';

export const labels = {
  rfq: 'Request For Quotation',
  trfq: 'Travel request for quotation',
  eoi: 'Expression Of Interest',
  freight: 'Freight RFQ',
};
export const rfqProductsColumns = {
  code: 'OT Material number',
  purchaseRequestNumber: 'PR Number',
  shortText: 'Material Description',
  quantity: 'Quantity',
  uom: 'UOM',
  manufacturer: 'Manufacturer',
  manufacturerPart: 'Manufacturer part number',
  unitPrice: 'Unit Price in MNT (excluding VAT)',
  currency:
    'Currency (If you are Mongolian supplier please provide your quotations only in MNT, if you are international supplier please provide your quotations only in USD)',
  leadTime: 'Lead time in Days',
  shippingTerms: 'Shipping terms',
  alternative: 'Alternative (YES/NO)',
  suggestedManufacturer: 'Suggested Manufacturer if yes',
  suggestedManufacturerPart: 'Suggest manufacturer part number',
  totalPrice: 'Total price',
  comment: 'Comment',
  picture: 'Picture (if required)',

  srnNo: 'SRN Number',
  grossWeight: 'Total Gross Weight',
  freightDesc: 'FREIGHT DESCRIPTION',
  packageQty: 'PACKAGE QUANTITY',
  cbm: 'Total CBM',
  placeOfLoading: 'PLACE OF LOADING',
  placeOfDelivery: 'PLACE OF DELIVERY',
  transportMode: 'TRANSPORT MODE',
  transportType: 'TRANSPORT TYPE',
  detailedQuote: 'Attached detailed quotation /Yes or /No',
  freightCost: 'Total Freight Rate',
  multipleQuote: 'Multiple Options',
};

export const eoiProductsColumns = {
  document: 'Document',
  isSubmitted: 'Submitted',
  documentFileName: 'Document file name',
  notes: 'Notes',
};

export const eoiRequestColumns = [{ title: 'Required document', dataIndex: 'document', key: '1' }];

export const eoiResponseColumns = [
  { title: 'Document file name', dataIndex: 'name', key: '3' },
  { title: 'Submitted', dataIndex: 'isSubmitted', key: '2' },
  { title: 'Picture', dataIndex: 'file', key: '5' },
  { title: 'Notes', dataIndex: 'notes', key: '4' },
];

export const eoiColumns = [...eoiRequestColumns, ...eoiResponseColumns];

export const rfqRequestColumns = [
  {
    title: rfqProductsColumns.purchaseRequestNumber,
    dataIndex: 'purchaseRequestNumber',
    key: '1',
  },
  {
    title: rfqProductsColumns.code,
    width: 150,
    dataIndex: 'code',
    key: '2',
  },
  {
    title: rfqProductsColumns.shortText,
    dataIndex: 'shortText',
    key: '3',
    width: 150,
    onCell: () => {
      return {
        style: {
          maxWidth: 150,
        },
      };
    },
    render: shortText => {
      return (
        <Tooltip placement="topLeft" title={shortText}>
          <span>{shortText}</span>
        </Tooltip>
      );
    },
  },
  { title: rfqProductsColumns.quantity, dataIndex: 'quantity', key: '4' },
  { title: rfqProductsColumns.uom, dataIndex: 'uom', key: '5' },
  { title: rfqProductsColumns.manufacturer, dataIndex: 'manufacturer', key: '6' },
  {
    title: rfqProductsColumns.manufacturerPart,
    dataIndex: 'manufacturerPartNumber',
    key: '7',
  },
];

export const rfqResponseColumns = [
  { title: rfqProductsColumns.unitPrice, dataIndex: 'unitPrice', key: '10' },
  { title: rfqProductsColumns.totalPrice, dataIndex: 'totalPrice', key: '11' },
  { title: rfqProductsColumns.currency, dataIndex: 'currency', key: '12' },
  { title: rfqProductsColumns.leadTime, dataIndex: 'leadTime', key: '13' },
  { title: rfqProductsColumns.shippingTerms, dataIndex: 'shippingTerms', key: '14' },
  {
    title: rfqProductsColumns.suggestedManufacturer,
    dataIndex: 'suggestedManufacturer',
    key: '8',
  },
  {
    title: rfqProductsColumns.purchaseRequestNumber,
    dataIndex: 'suggestedManufacturerPartNumber',
    key: '9',
  },
  { title: rfqProductsColumns.comment, dataIndex: 'comment', key: '15' },
  { title: rfqProductsColumns.picture, dataIndex: 'file', key: '16' },
];

export const rfqColumns = [...rfqRequestColumns, ...rfqResponseColumns];

export const initialDocuments = [
  'Scope specific experience',
  'Customer reference /atleast 2/',
  'Special licences if applicable (copy)',
];

export const initialPerDocuments = {
  document__0: 'Scope specific experience',
  document__1: 'Customer reference /atleast 2/',
  document__2: 'Special licences if applicable (copy)',
};
