import React from 'react';
import PropTypes from 'prop-types';
import { gql, compose, graphql } from 'react-apollo';
import { MainLayout } from '../components';
import { withSystemConfig, withCurrentUser } from 'modules/auth/containers';
import { withRouter } from 'react-router-dom';
import { logout } from '../utils';
import { mutations } from '../graphql';
import {
  mutations as companyMutations,
  queries as companyQueries,
} from 'modules/companies/graphql';

import { alert } from 'modules/common/utils';
import { Loading } from 'modules/common/components';

class MainLayoutContainer extends React.Component {
  componentDidMount() {
    if (this.props.currentUser) {
      const idleLogout = () => {
        let time;

        const resetTimer = () => {
          clearTimeout(time);
          time = setTimeout(logout, 20 * 60000); // 20 minutes
        };

        window.onload = resetTimer;
        window.onmousemove = resetTimer;
        window.onmousedown = resetTimer; // catches touchscreen presses
        window.onclick = resetTimer; // catches touchpad clicks
        window.onscroll = resetTimer; // catches scrolling with arrow keys
        window.onkeypress = resetTimer;
      };

      idleLogout();
    }
  }

  render() {
    const pathname = window.location.pathname;
    const { currentUser, supplierSendReActivateMutation } = this.props;
    let updatedProps = { ...this.props };
    const { companyByUserQuery } = this.props;
    if (currentUser && companyByUserQuery && companyByUserQuery.loading) {
      return <Loading />;
    }
    if (currentUser && currentUser.isSupplier) {
      const checkUrl = url => {
        if (pathname === url || pathname === `${url}/`) {
          throw new Error('Permission denied');
        }
      };

      const supplierSendReactivate = () => {
        supplierSendReActivateMutation()
          .then(() => {
            alert.success('Successfully request sent');
            window.location.href = '/';
          })
          .catch(error => {
            alert.error(error);
          });
      };
      updatedProps = {
        ...this.props,
        supplierSendReactivate,
        companyDeActiveStatus:
          (companyByUserQuery &&
            companyByUserQuery.companyByUser &&
            companyByUserQuery.companyByUser.deActiveStatus) ||
          '',
      };

      try {
        checkUrl('/dashboard');
        checkUrl('/companies');
        checkUrl('/prequalification-status');
        checkUrl('/capacity-building-status');
        checkUrl('/audit');
        checkUrl('/audit/responses');
        checkUrl('/audit/responses-physical');
        checkUrl('/audit/reports');
        checkUrl('/validation');
        checkUrl('/difot');
        checkUrl('/due-diligence');
        checkUrl('/feedback');
        checkUrl('/feedback/responses');
        checkUrl('/blocking');
        checkUrl('/rfq');
        checkUrl('/trfq');
        checkUrl('/eoi');
        checkUrl('/report');
        checkUrl('/logs');
        checkUrl('/settings/templates');
        checkUrl('/settings/manage-expiry-dates');
        checkUrl('/user-list');
        checkUrl('/mail-deliveries');
        checkUrl('/delegation');
        checkUrl('/manage-suppliers');
      } catch (e) {
        if (e.message === 'Permission denied') {
          return null;
        }
      }
    }

    return <MainLayout {...updatedProps} />;
  }
}

MainLayoutContainer.propTypes = {
  currentUser: PropTypes.object,
  logsWriteMutation: PropTypes.func,
};

const WithLogMutation = compose(
  graphql(gql(mutations.logsWrite), {
    name: 'logsWriteMutation',
    skip: props => !props.currentUser,
  })
)(withRouter(MainLayoutContainer));

const QueryMutations = compose(
  graphql(gql(mutations.logsWrite), {
    name: 'logsWriteMutation',
    skip: props => !props.currentUser,
  }),
  graphql(gql(companyMutations.supplierSendReactivate), {
    name: 'supplierSendReActivateMutation',
  }),
  graphql(gql(companyQueries.companyByUser), {
    name: 'companyByUserQuery',
    options: ({ currentUser }) => {
      return {
        skip: currentUser ? !currentUser.isSupplier : true,
      };
    },
  })
)(withRouter(MainLayoutContainer));

const BuyerLayout = withSystemConfig(WithLogMutation);

const withUser = props => {
  const { currentUser } = props;

  if (currentUser && !currentUser.isSupplier) {
    return <BuyerLayout {...props} />;
  }

  return <QueryMutations {...props} />;
};

export default withCurrentUser(withUser);
