import React from 'react';
import { withRouter } from 'react-router';
import CompanyInfoForm from './forms/CompanyInfo';
import ContactForm from './forms/Contact';
import ManagementForm from './forms/ManagementTeam';
import ShareholderForm from './forms/Shareholder';
import ProductsForm from './forms/Products';
import GroupForm from './forms/Group';
import { Tabs, Button, Modal, Form, Select, Input, Popconfirm } from 'antd';
import { HelpModal } from 'modules/common/components';
import { Panes } from 'modules/common/components';
import PropTypes from 'prop-types';

const Option = Select.Option;
const { TextArea } = Input;
class RegistrationForms extends Panes {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      reason: '',
      isField: false,
      selectedCompanyId: null,
      currentTabKey: '1',
      search: props.location.search || '',
    };

    this.renderDeActive = this.renderDeActive.bind(this);
    this.renderDeactivateModal = this.renderDeactivateModal.bind(this);
  }

  renderDeActive() {
    const {
      company: { _id, deActiveStatus },
      isPrequalified,
    } = this.props;
    const { deActive = null } = deActiveStatus || {};
    const { search } = this.state;

    const onClick = () => {
      this.setState({ selectedCompanyId: _id });
    };

    const onCancel = () => {
      this.props.history.push(
        '/manage-suppliers?activationStatus=&prequalifiedStatus=undefined&qualifiedStatus=undefined'
      );
    };

    if (search === '?manageSuppliers') {
      if (deActive === null && !isPrequalified) {
        return (
          <div style={{ marginLeft: 250 }}>
            {this.renderDeactivateModal(_id)}
            <Button type="primary" htmlType="submit" onClick={onClick} style={{ marginRight: 15 }}>
              {'De-activate'}
            </Button>
            <Button type="primary" htmlType="submit" onClick={onCancel}>
              {'Cancel'}
            </Button>
          </div>
        );
      }
    }

    return null;
  }

  renderDeactivateModal(_id) {
    const { selectedCompanyId, text, reason } = this.state;

    if (_id !== selectedCompanyId) {
      return null;
    }

    const onCancelText = reasons => {
      this.setState({ text: reasons.target.value });
    };

    const renderCommentField = () => {
      this.setState({ isField: !this.state.isField });
    };

    const toggleCancelForm = () => {
      this.setState({ selectedCompanyId: null });
    };

    const onChooseReason = reasons => {
      this.setState({ reason: reasons.join(', ') });
    };

    const onConfirm = () => {
      this.props.deActive(_id, text, reason);
    };

    return (
      <Modal
        title="Deactivate reasons"
        visible={true}
        width="50%"
        onCancel={toggleCancelForm}
        footer={[
          <Button key="comment" value="Comment" onClick={renderCommentField}>
            Comment
          </Button>,
          <Button key="cancel" onClick={toggleCancelForm}>
            Cancel
          </Button>,
          <Button key="confirm" type="primary">
            <Popconfirm
              key="popconfirm"
              title={`De-activating supplier. Are you sure?`}
              onConfirm={onConfirm}
            >
              <a href="#Confirm" key="submit" type="primary">
                Confirm
              </a>
            </Popconfirm>
          </Button>,
        ]}
      >
        <Form.Item label="Reasons">
          <Select
            onChange={onChooseReason}
            placeholder="Please select reasons ..."
            style={{ width: '100%' }}
            mode="multiple"
          >
            <Option value="Not-responsive">Not-responsive</Option>
            <Option value="Pre-qualification not submitted 2 years">
              Pre-qualification not submitted 2 years
            </Option>
          </Select>
          {this.state.isField ? (
            <TextArea
              name="cancelTextReason"
              onChange={onCancelText}
              style={{ height: 100 }}
              maxLength={500}
            />
          ) : null}
        </Form.Item>
      </Modal>
    );
  }

  render() {
    const { currentTabKey } = this.state;
    const {
      send,
      exportForm,
      viewMode = false,
      showExtendedField = false,
      validatedProductCodes,
    } = this.props;
    const company = this.props.company || {};
    const basicInfo = company.basicInfo || {};
    const soleTrader = basicInfo.corporateStructure === 'Sole Trader';
    const disabled = !company.isDueDiligenceEditable;

    const { isSentRegistrationInfo, lastDueDiligence = {} } = company;

    return (
      <div>
        <HelpModal videoId="registration" />

        {viewMode && <h2 className="registration-title">{basicInfo.enName}</h2>}

        <Tabs
          activeKey={currentTabKey}
          onTabClick={this.moveToTab}
          tabPosition="left"
          className="supplier-forms"
        >
          {this.renderPane({
            key: 1,
            title: 'Company information',
            name: 'basicInfo',
            Component: CompanyInfoForm,
            data: {
              disabled,
              isSentRegistrationInfo,
              exportForm,
              localStorageKey: `companyInfo_${company._id}`,
              showExtendedField,
            },
            recommendation: lastDueDiligence.basicInfo,
          })}
          {this.renderPane({
            key: 2,
            title: 'Contact details',
            name: 'contactInfo',
            Component: ContactForm,
            data: {
              basicInfo,
              localStorageKey: `contactInfo_${company._id}`,
            },
          })}

          {!soleTrader &&
            this.renderPane({
              key: 3,
              title: 'Management Team',
              name: 'managementTeamInfo',
              Component: ManagementForm,
              data: {
                disabled,
                localStorageKey: `managementTeamInfo_${company._id}`,
                showExtendedField,
              },
              recommendation: lastDueDiligence.managementTeamInfo,
            })}

          {!soleTrader &&
            this.renderPane({
              key: 4,
              title: 'Company shareholder information',
              name: 'shareholderInfo',
              Component: ShareholderForm,
              data: {
                disabled,
                localStorageKey: `shareholderInfo_${company._id}`,
                showExtendedField,
              },
              recommendation: lastDueDiligence.shareholderInfo,
            })}

          {!soleTrader &&
            this.renderPane({
              key: 5,
              title: 'Group information',
              name: 'groupInfo',
              Component: GroupForm,
              data: {
                disabled,
                localStorageKey: `groupInfo_${company._id}`,
                showExtendedField,
              },
              recommendation: lastDueDiligence.groupInfo,
            })}

          {this.renderPane({
            key: soleTrader ? 3 : 6,
            title: 'Products & services',
            name: 'productsInfo',
            Component: ProductsForm,
            data: {
              send,
              showExtendedField,
              validatedProductsInfo: company.validatedProductsInfo,
              deActivateFunc: this.renderDeActive(),
              validatedProductCodes,
            },
          })}
        </Tabs>
      </div>
    );
  }
}

RegistrationForms.propTypes = {
  deActive: PropTypes.func,
};

export default withRouter(RegistrationForms);
