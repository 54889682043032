import React from 'react';
import { Modal } from 'antd';
import { logout } from '../utils';
import { alert } from '../../common/utils';
class ActivateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deActivatedModal: true,
    };

    this.renderModal = this.renderModal.bind(this);
  }

  renderModal(title, body, action, footer, onCancel, closable) {
    title = <div>{title}</div>;
    return (
      <Modal
        title={title}
        visible={this.state.deActivatedModal}
        footer={null}
        onCancel={onCancel}
        closable={closable}
      >
        <p style={{ fontSize: '16px ' }}>
          Accounts that are not completed the registration and/or qualification questionnaires over
          2 years are deactivated. <br />
          {body}
        </p>
        {action}
        <h2>
          <br />
          {footer}
          <br /> Email : <a href="#oyu-supplierdatabase.mn">OTOyusupport@ot.mn</a>
          <br />
          Phone : 11331880 ext:8888, 6000
          <br />
          <br />
          Sincerely <br />
          OT Procurement
        </h2>
      </Modal>
    );
  }

  render() {
    const { companyDeActiveStatus = {} } = this.props;
    const { status, supplierSentReActivate } = companyDeActiveStatus;

    const onActive = () => {
      this.props.supplierSendReactivate();
    };

    const onCancel = bool => {
      const { history } = this.props;

      if (bool) {
        return alert.error('Click the Reactivate my account button');
      }

      this.setState({ deActivatedModal: false });

      history.push('/registration');
    };

    let action = (
      <h2>
        Login here: <a href="#otsupplierdatabase.mn">OT - Suppliers database</a>
      </h2>
    );

    if (['deactivated', 'reactivated'].includes(status)) {
      return this.renderModal(
        'Your account is scheduled to be deactivated in 7days.',
        'However, you can keep the account by logging in and completing your registration and qualification stages.',
        action,
        'Let us know if you have any questions or need help the keeping account.',
        () => onCancel(false),
        true
      );
    }

    if (status === 'notPrequalified' && !supplierSentReActivate) {
      action = (
        <a
          href="#reactiving"
          onClick={onActive}
          style={{ fontSize: 18, textDecoration: 'underline' }}
        >
          Reactivate my account
        </a>
      );

      return this.renderModal(
        'Your account has been deactivated',
        'To reactivate your account please send us your request and update your profile information completely.',
        action,
        'Let us know if you have any questions or need help the activating account.',
        () => onCancel(true),
        true
      );
    }

    if (supplierSentReActivate) {
      action = (
        <a href="/" style={{ fontSize: 18 }}>
          Your ReActivate Request has been sent
        </a>
      );

      return this.renderModal(
        'Your account has been deactivated',
        'To reactivate your account please send us your request and update your profile information completely.',
        action,
        'Let us know if you have any questions or need help the activating account.',
        () => setTimeout(logout(), 3000),
        true
      );
    }

    if (status === 'completed') {
      return null;
    }

    return null;
  }
}

export default ActivateModal;
