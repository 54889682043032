import React from 'react';
import { gql, graphql, compose } from 'react-apollo';
import { queries } from '../graphql';
import { ValidationStatus } from '../components';
import { Loading } from 'modules/common/components';

class ValidationStatusContainer extends React.Component {
  render() {
    const { validationStatusByUserQuery } = this.props;

    if (validationStatusByUserQuery.error) {
      return null;
    }

    if (validationStatusByUserQuery.loading) {
      return <Loading />;
    }

    const extendedProps = {
      ...this.props,
      validatedCodes: validationStatusByUserQuery.validatedCodesByUser || [],
    };

    return <ValidationStatus {...extendedProps} />;
  }
}

export default compose(
  graphql(gql(queries.validatedProductCodes), {
    name: 'validationStatusByUserQuery',
    options: ({ match }) => {
      return {
        variables: { _id: match.params.id },
      };
    },
  })
)(ValidationStatusContainer);
