import React from 'react';
import { Route } from 'react-router-dom';
import queryString from 'query-string';
import { List, ValidationRoleForm, ValidationRequest, ValidationStatus } from './containers';

const generateQueryParams = props => {
  return queryString.parse(props.location.search);
};

export default [
  <Route
    key="/validation"
    path="/validation"
    exact
    component={props => {
      return <List {...props} queryParams={generateQueryParams(props)} />;
    }}
  />,

  <Route
    key={'/request-validation'}
    exact
    path={'/request-validation'}
    component={props => {
      return <ValidationRequest {...props} queryParams={generateQueryParams(props)} />;
    }}
  />,

  <Route
    key={'/statusvalidation'}
    exact
    path={'/statusvalidation'}
    component={props => {
      return <ValidationStatus {...props} queryParams={generateQueryParams(props)} />;
    }}
  />,

  <Route
    key="/validation"
    path="/validation/:id"
    exact
    component={props => {
      return <ValidationRoleForm {...props} queryParams={generateQueryParams(props)} />;
    }}
  />,
];
