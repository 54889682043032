import 'babel-polyfill';
import React from 'react';
import * as Sentry from '@sentry/react';
import { render } from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import store from './store';
import apolloClient from './apolloClient';

import Routes from './routes';
import { LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import './css/main.min.css';

Sentry.init({
  dsn: 'https://a8699888d63d4ac090a3cd5d47400d86@o507158.ingest.sentry.io/4505109459304448',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

render(
  <ApolloProvider store={store} client={apolloClient}>
    <LocaleProvider locale={enUS}>
      <Routes />
    </LocaleProvider>
  </ApolloProvider>,
  document.getElementById('root')
);
