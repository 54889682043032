import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose, gql, graphql } from 'react-apollo';
import PropTypes from 'prop-types';
import { Common } from '../../components';
import { mutations } from '../../graphql';
import { message } from 'antd';

const DueDiligenceContainer = props => {
  const { configsSaveDueDiligenceDowMutation } = props;

  const mainAction = doc => {
    configsSaveDueDiligenceDowMutation({ variables: { doc } }).then(() => {
      message.success('Saved Successfully');
    });
  };

  const updatedProps = {
    ...props,
    mainAction,
  };

  return <Common {...updatedProps} name="dueDiligence" />;
};

DueDiligenceContainer.propTypes = {
  configsSaveDueDiligenceDowMutation: PropTypes.func.isRequired,
};

export default compose(
  graphql(gql(mutations.configsSaveDueDiligenceDow), {
    name: 'configsSaveDueDiligenceDowMutation',
  })
)(withRouter(DueDiligenceContainer));
