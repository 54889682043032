import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import moment from 'moment';
import { Table, Card, Icon, DatePicker, Button, Dropdown, Menu } from 'antd';
import { Paginator, Search, HelpModal } from 'modules/common/components';
import { dateTimeFormat, statusIcons } from 'modules/common/constants';
import router from 'modules/common/router';
import { labels } from '../../../constants';

const MonthPicker = DatePicker.MonthPicker;

class Tenders extends React.Component {
  constructor(props) {
    super(props);

    const { history, type } = props;

    this.statusParam = `${type}status`;

    const query = queryString.parse(history.location.search);
    const status = query[this.statusParam] || '';

    this.state = {
      statuses: status.split(','),
    };

    this.renderOperation = this.renderOperation.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  handleMonthChange(value) {
    const { type } = this.props;

    router.setParams(this.props.history, { [`${type}month`]: value });
  }

  handleTableChange(pagination, filters) {
    const statuses = filters.status;

    if (statuses && statuses.length > 0) {
      router.setParams(this.props.history, {
        [this.statusParam]: statuses.join(','),
      });
    } else {
      router.removeParams(this.props.history, this.statusParam);
    }

    this.setState({ statuses });
  }

  renderIcon(name, style) {
    const s = statusIcons[name];
    return <Icon type={s.type} style={{ color: s.color, ...style }} />;
  }

  commonColumns() {
    const { __ } = this.context;

    return [
      {
        title: __('Tender Number'),
        dataIndex: 'number',
        fixed: 'left',
        width: 75,
      },
      {
        title: __('Tender Description'),
        width: 100,
        render: (text, record) => <p style={{ maxWidth: '300px' }}>{record.name}</p>,
      },
      {
        title: __('Publish Date'),
        render: (text, record) => this.renderDate(record.publishDate),
        width: 150,
      },
      {
        title: __('Close Date'),
        render: (text, record) => this.renderDate(record.closeDate),
        width: 150,
      },
    ];
  }

  renderDate(date) {
    return moment(date).format(dateTimeFormat);
  }

  renderFileDownload(url) {
    const { __ } = this.context;

    return (
      <a href={url} target="__blank">
        {__('View')}
      </a>
    );
  }

  renderEmailDropDown({ emails, map, isSupplier }) {
    const { type, unsubscribedUsersTotalCount } = this.props;
    const { __ } = this.context;

    const onClick = e => {
      if (isSupplier) {
        return this.props.userEmailSubEoi(e.key);
      }
      return this.props.userEmailSubEoi(null);
    };

    const menu = (
      <Menu onClick={onClick}>
        {emails.map((email, i) => (
          <Menu.Item disabled={!map[email]} key={i}>
            {email}
          </Menu.Item>
        ))}
      </Menu>
    );

    const withCounter = (
      <div style={{ top: 0, display: 'flex' }}>
        <div style={{ color: 'white' }}>
          {!isSupplier ? (
            <div
              style={{
                marginRight: '3px',
                flexDirection: 'row',
              }}
            >
              {!unsubscribedUsersTotalCount ? null : unsubscribedUsersTotalCount}
            </div>
          ) : null}
        </div>

        <span>{__(`EOI ${isSupplier ? 'Subscribe' : 'Unsubscribed'}`)}</span>
      </div>
    );

    if (type === 'eoi') {
      return (
        <Dropdown overlay={menu} placement="bottomCenter">
          <Button
            style={{
              marginLeft: '10px',
              height: '18px',
              backgroundColor: 'darkorange',
              color: '#ffff',
              borderRadius: '20px',
              border: '1px',
              verticalAlign: 'text-bottom',
            }}
          >
            {withCounter}
          </Button>
        </Dropdown>
      );
    }
  }

  renderTitle(label) {
    const { currentUser, contactInfo } = this.props;
    const { unsubscribeEoi, isSupplier, email } = currentUser || {};
    const { userEmail, contactEmail, adminEmail } = unsubscribeEoi || {};
    const emails = [];
    const emailsMap = {};

    if (contactInfo) {
      if ((email || '').toLowerCase() === (contactInfo.email || '').toLowerCase()) {
        emails.push(email);
        emailsMap[email] = userEmail || false;
      } else {
        emails.push(email, contactInfo.email);
        emailsMap[email] = userEmail || false;
        emailsMap[contactInfo.email] = contactEmail || false;
      }
    } else {
      emails.push(email);
      emailsMap[email] = adminEmail || false;
    }

    return (
      <>
        {label} {isSupplier ? <HelpModal videoId={this.props.type} /> : null}
        {this.renderEmailDropDown({
          emails: emails,
          map: emailsMap,
          isSupplier: isSupplier,
        })}
      </>
    );
  }

  renderTenders(args) {
    const { type, data, loading, history, totalCount, rfqTypes = [] } = this.props;

    const { __ } = this.context;

    const { columns, operation } = args;

    const { location } = history;

    const highlightedId = location.state && location.state.newTenderId;

    let label = labels[type] ? __(labels[type]) : labels[type];

    if (rfqTypes.includes('freight')) {
      label = labels['freight'];
    }

    return (
      <Card style={{ marginBottom: '16px' }} title={this.renderTitle(label)}>
        <div className="table-operations">
          <Search placeholder={__('Tender Name')} paramPrefix={type} />

          <MonthPicker
            style={{ float: 'left', width: '200px', marginLeft: '20px' }}
            placeholder={__('Select year and month')}
            onChange={this.handleMonthChange}
            allowClear
          />

          {operation || <div style={{ height: '32px' }} />}
        </div>

        <Table
          columns={columns}
          rowKey={record => record._id}
          rowClassName={record => {
            if (record._id === highlightedId) return 'highlight';
          }}
          pagination={false}
          dataSource={data}
          loading={loading}
          scroll={{ x: 1500 }}
          onChange={this.handleTableChange}
          className="tenders-table"
        />

        <Paginator total={totalCount} paramPrefix={type} />
      </Card>
    );
  }
}

Tenders.propTypes = {
  type: PropTypes.string,
  isSupplier: PropTypes.bool,
  data: PropTypes.array,
  pagination: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  history: PropTypes.object,
  totalCount: PropTypes.number,
  rfqTypes: PropTypes.string,
  userEmailSubEoi: PropTypes.func,
  contactEmailSubEoi: PropTypes.func,
  currentUser: PropTypes.object,
  contactInfo: PropTypes.object,
};

Tenders.contextTypes = {
  __: PropTypes.func,
};

export default Tenders;
