import React from 'react';
import PropTypes from 'prop-types';
import { compose, gql, graphql } from 'react-apollo';
import { List } from '../components';
import { generator } from 'modules/companies/containers';
import { mutations, queries } from '../graphql';
import { message } from 'antd';
import { exportFile } from 'modules/common/components';

class ListContainer extends React.Component {
  render() {
    const {
      enableMutation,
      updateMutation,
      removeRiskMutation,
      companiesQuery,
      cancelMutation,
      saveRelatedMutation,
    } = this.props;

    const enable = supplierId => {
      enableMutation({ variables: { supplierId } })
        .then(() => {
          message.success('Success');
          companiesQuery.refetch();
        })
        .catch(error => {
          message.error(error.message);
        });
    };

    const addRisk = doc => {
      updateMutation({
        variables: doc,
      })
        .then(() => {
          message.success('Success');
          companiesQuery.refetch();
        })

        .catch(error => {
          message.error(error.message);
        });
    };

    const removeRisk = supplierId => {
      removeRiskMutation({
        variables: { supplierId },
      })
        .then(() => {
          message.success('Success');
          companiesQuery.refetch();
        })

        .catch(error => {
          message.error(error.message);
        });
    };

    const cancel = supplierId => {
      cancelMutation({ variables: { supplierId } })
        .then(() => {
          message.success('Success');
          companiesQuery.refetch();
        })
        .catch(error => {
          message.error(error.message);
        });
    };

    const updateCloseDate = doc => {
      updateMutation({
        variables: doc,
      })
        .then(() => {
          enable(doc.supplierId);
        })

        .catch(error => {
          message.error(error.message);
        });
    };

    const exportExcel = () => {
      exportFile({
        query: queries.companiesGenerateDueDiligenceList,
        variables: companiesQuery ? companiesQuery.variables : null,
        onError: () => {},
      });
    };

    const saveRelatedCompany = variables => {
      saveRelatedMutation({ variables })
        .then(() => {
          message.success('Success');
          companiesQuery.refetch();
        })
        .catch(error => {
          message.error(error.message);
        });
    };

    const extendedProps = {
      ...this.props,
      exportExcel,
      addRisk,
      removeRisk,
      cancel,
      updateCloseDate,
      saveRelatedCompany,
    };

    return <List {...extendedProps} />;
  }
}

ListContainer.propTypes = {
  companiesQuery: PropTypes.object,
  updateMutation: PropTypes.func,
  enableMutation: PropTypes.func,
  cancelMutation: PropTypes.func,
  removeRiskMutation: PropTypes.func,
  saveRelatedMutation: PropTypes.func,
};

const WithData = compose(
  graphql(gql(mutations.enableState), {
    name: 'enableMutation',
  }),
  graphql(gql(mutations.removeRisk), {
    name: 'removeRiskMutation',
  }),
  graphql(gql(mutations.updateDueDiligence), {
    name: 'updateMutation',
  }),
  graphql(gql(mutations.cancelDueDiligence), {
    name: 'cancelMutation',
  }),
  graphql(gql(mutations.saveRelatedCompany), {
    name: 'saveRelatedMutation',
  })
)(ListContainer);

export default generator(WithData, 'dueDiligence', () => ({
  source: 'DueDiligence',
}));
