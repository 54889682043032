import React from 'react';
import PropTypes from 'prop-types';
import { compose, graphql, gql } from 'react-apollo';
import { message } from 'antd';
import { withCurrentUser } from 'modules/auth/containers';
import { Delegation } from '../components';
import { queries, mutations } from '../graphql';
import client from 'apolloClient';

class DelegationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.delegate = this.delegate.bind(this);
    this.searchUser = this.searchUser.bind(this);
  }

  delegate(variables) {
    this.props
      .usersDelegate({ variables })
      .then(() => {
        message.success('Delegated successfully!');
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  searchUser(searchValue, callback) {
    client
      .query({
        query: gql(queries.simpleUsers),
        name: 'usersQuery',

        variables: { search: searchValue },
      })
      .then(response => {
        callback && callback(response.data.users);
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  render() {
    const { currentUser } = this.props;

    if (currentUser.isSupplier) {
      return null;
    }

    const updatedProps = {
      ...this.props,
      delegate: this.delegate,
      searchUser: this.searchUser,
    };

    return <Delegation {...updatedProps} />;
  }
}

DelegationContainer.propTypes = {
  usersDelegate: PropTypes.func,
  history: PropTypes.object,
};

export default compose(
  graphql(gql(mutations.usersDelegate), {
    name: 'usersDelegate',
  })
)(withCurrentUser(DelegationContainer));
