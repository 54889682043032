import { alert } from 'modules/common/utils';

export const controlValueParser = ({ e, dataType }) => {
  let value;

  if (e.target) {
    if (e.target.value) {
      //input
      value = e.target.value;

      if (dataType === 'float') value = parseFloat(value);

      if (dataType === 'eightDigit' && value.length > 8) value = value.substring(0, 8);
    }
  } else {
    //file
    value = e;
  }

  return value;
};

export const collectProducts = state => {
  const products = [];

  // collect products table values
  Object.keys(state).forEach(key => {
    if (key.startsWith('product__')) {
      products.push({ ...state[key] });
    }
  });

  return products;
};

export const getExcelData = param => {
  const { rfqType, data } = param;
  let docs = [];
  let doc = {};

  for (const row of data) {
    if (rfqType === 'goods') {
      doc = {
        unitPrice: row[7],
        currency: row[8] || '',
        leadTime: row[9],
        shippingTerms: row[10] || '',
        alternative: row[11] || '',
        suggestedManufacturer: row[12] || '',
        suggestedManufacturerPartNumber: row[13] || '',
        comment: row[14] || '',
      };
    }

    if (rfqType === 'freight') {
      doc = {
        freightCost: row[7],
        currency: row[8] || '',
        leadTime: row[9] || '',
        detailedQuote: row[10] || '',
        multipleQuote: row[13] || '',
      };
    }

    if (rfqType === 'service') {
      return null;
    }

    docs.push(doc);
  }

  return docs;
};

export const setRequestedProducts = param => {
  const { docs, respondedProducts, rp } = param;
  const perProductStates = {};

  for (const [index, doc] of docs.entries()) {
    const requestedProduct = rp[index];

    if (!requestedProduct) {
      continue;
    }

    const product = {
      productId: requestedProduct.productId,
      ...respondedProducts[index],
    };

    respondedProducts[index] = { ...product, ...doc };
    perProductStates[`product__${product.productId}`] = respondedProducts[index];
  }

  return { respondedProducts, perProductStates };
};

export const showMainInfo = (type, tableShow) => {
  if ((type !== 'eoi' && tableShow) || type === 'trfq') {
    return true;
  }

  return false;
};

export const showRFQTable = (type, rfqType, tableShow) => {
  if ((type === 'rfq' && rfqType === 'service' && tableShow) || (type === 'trfq' && !tableShow)) {
    return true;
  }
};

export const disclaimerRelatedTender = type => {
  if (type === 'trfq' || type === 'eoi') {
    return false;
  }

  return true;
};

export const handleButtons = (rfqTableShow, type) => {
  if (!['eoi', 'trfq'].includes(type) && !rfqTableShow) {
    return false;
  } else {
    return true;
  }
};

export const responseText = (type, doc, shouldSend, __) => {
  let draft = 'Successfully saved to draft';
  let success = 'Successfully saved tender response';

  if (type === 'eoi' || type === 'trfq') {
    if (doc.isNotInterested && !shouldSend) return alert.success(success, __);
  }

  if (type === 'rfq') {
    if (!doc.disclaimerIsAgree && !shouldSend) return alert.error('Disagreed', __);
    if (doc.isNotInterested && doc.disclaimerIsAgree) return alert.success(success, __);
  }

  if (!doc.isNotInterested && !shouldSend) return alert.success(draft, __);
};
