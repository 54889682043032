export const riskData = [
  { value: 'high', text: 'High' },
  { value: 'medium', text: 'Medium' },
  { value: 'low', text: 'Low' },
  { value: 'veryHigh', text: 'Very high' },
  { value: 'unrated', text: 'Unrated' },
];

export const riskValue = {
  high: 'High',
  medium: 'Medium',
  low: 'Low',
  veryHigh: 'Very high',
  unrated: 'Unrated',
};
