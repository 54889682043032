/* eslint-disable react/display-name */

import React from 'react';
import { withRouter } from 'react-router';
import { Card, Row, Col, Button, Icon, Modal, DatePicker, message, List } from 'antd';
import { Common } from 'modules/companies/components';
import { Sidebar } from 'modules/companies/components';
import { Search } from 'modules/common/components';
import { EditorCK } from 'modules/common/components';
import moment from 'moment';
import { dateFormat, dateTimeFormat } from 'modules/common/constants';
import { labels } from './constants';
import PropTypes from 'prop-types';

class Feedback extends Common {
  constructor(props, context) {
    super(props);

    this.state = {
      ...this.state,
      feedbackModalVisible: false,
      feedbackContent: context.systemConfig.successFeedbackTemplate || '',
      feedbackCloseDate: moment(),
      viewModalVisible: false,
      viewModalData: {},
    };

    this.addFeedback = this.addFeedback.bind(this);
    this.handleFeedbackContentChange = this.handleFeedbackContentChange.bind(this);
    this.toggleFeedbackModal = this.toggleFeedbackModal.bind(this);
    this.handleCloseDateChange = this.handleCloseDateChange.bind(this);
    this.toggleViewModal = this.toggleViewModal.bind(this);
    this.viewFeedbackInfo = this.viewFeedbackInfo.bind(this);
  }

  addFeedback() {
    const { addFeedback } = this.props;
    const { selectedCompanies, feedbackCloseDate, feedbackContent } = this.state;

    addFeedback({ selectedCompanies, feedbackCloseDate, feedbackContent });

    this.setState({ feedbackModalVisible: false });
  }

  toggleFeedbackModal(value) {
    if (value && this.state.selectedCompanies.length < 1)
      message.error('Please select atleast one supplier');
    else this.setState({ feedbackModalVisible: value });
  }

  handleFeedbackContentChange(e) {
    this.setState({ feedbackContent: e.editor.getData() });
  }

  handleCloseDateChange(value) {
    this.setState({ feedbackCloseDate: value });
  }

  viewFeedbackInfo(feedback) {
    this.setState({
      viewModalData: feedback.supplierResponse,
      viewModalVisible: true,
    });
  }

  toggleViewModal(value) {
    this.setState({ viewModalVisible: value });
  }

  render() {
    const { totalCount } = this.props;
    const {
      selectedCompanies,
      feedbackModalVisible,
      feedbackContent,
      feedbackCloseDate,
      viewModalVisible,
      viewModalData,
    } = this.state;

    const columns = this.getWrappedColumns([
      {
        title: 'Last feedback date',
        render: record =>
          record.lastFeedback ? moment(record.lastFeedback.closeDate).format(dateFormat) : '-',
      },
      {
        title: 'Last feedback information',
        render: record => {
          const lfd = record.lastFeedback;
          if (lfd && lfd.supplierResponse) {
            return <Button onClick={() => this.viewFeedbackInfo(record.lastFeedback)}>View</Button>;
          } else return '-';
        },
      },
    ]);

    let responseItemList = [];

    Object.keys(viewModalData).forEach(key => {
      if (!['_id', '__typename'].includes(key)) {
        responseItemList.push({
          title: key,
          description: viewModalData[key],
        });
      }
    });

    return (
      <Row gutter={16}>
        <Sidebar
          suppliersCount={totalCount}
          checkedCount={selectedCompanies ? selectedCompanies.length : 0}
        />

        <Col span={19}>
          <Card title="Suppliers">
            <div className="table-operations">
              <Search />
              {this.renderSearchButtons()}

              <Button onClick={() => this.toggleFeedbackModal(true)}>
                Send success feedback request
                <Icon type="mail" />
              </Button>
            </div>

            {this.renderTable({
              rowSelection: {
                selectedCompanies,
                onChange: this.onSelectedCompaniesChange,
              },
              columns,
            })}
          </Card>

          <Modal
            title="Send success feedback request"
            visible={feedbackModalVisible}
            onCancel={() => this.toggleFeedbackModal(false)}
            maskClosable={false}
            onOk={this.addFeedback}
            okText="Send"
            width="50%"
          >
            <label>Request Deadline: </label>
            <DatePicker
              defaultValue={feedbackCloseDate}
              showTime={{ format: 'HH:mm' }}
              format={dateTimeFormat}
              placeholder="Choose date"
              onChange={this.handleCloseDateChange}
            />
            <p />
            <EditorCK content={feedbackContent} onChange={this.handleFeedbackContentChange} />
          </Modal>

          <Modal
            title="Last feedback"
            visible={viewModalVisible}
            onCancel={() => this.toggleViewModal(false)}
            footer={null}
            bodyStyle={{ height: '70vh', overflow: 'scroll' }}
          >
            <List
              itemLayout="horizontal"
              dataSource={responseItemList}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta title={labels[item.title]} description={item.description} />
                </List.Item>
              )}
            />
          </Modal>
        </Col>
      </Row>
    );
  }
}

Feedback.contextTypes = {
  systemConfig: PropTypes.object,
};

export default withRouter(Feedback);
