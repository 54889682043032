import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Input } from 'antd';
import SupplierSearcher from 'modules/companies/containers/Searcher';

const TextArea = Input.TextArea;
const FormItem = Form.Item;

class RelatedModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      supplierIds: [],
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.onChangeSuppliers = this.onChangeSuppliers.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { suppliers } = this.props;

    if (prevProps.suppliers !== suppliers) {
      this.onChangeSuppliers(suppliers);
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const { form, save, comment, suppliers } = this.props;
    const { supplierIds } = this.state;

    if (supplierIds.length > 1) {
      form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          const doc = values;
          doc.supplierIds = supplierIds;

          if (comment) {
            doc.oldSupplierIds = suppliers.map(s => s._id);
          }

          save(doc);

          this.hideModal();
        }
      });
    }
  }

  showModal() {
    this.setState({ modalVisible: true });
  }

  hideModal() {
    this.setState({ modalVisible: false, supplierIds: [] });
  }

  onChangeSuppliers(suppliers) {
    this.setState({ supplierIds: suppliers.map(s => s._id) });
  }

  render() {
    const { modalVisible } = this.state;
    const { comment, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <>
        {comment ? (
          <span key={2} className="small-label pointer" onClick={this.showModal}>
            &nbsp; | &nbsp; Edit
          </span>
        ) : (
          <Button key={0} onClick={this.showModal}>
            Company link
          </Button>
        )}
        <Modal
          key={1}
          title="Related companies"
          visible={modalVisible}
          onCancel={this.hideModal}
          onOk={this.handleSubmit}
          width="50%"
          okText="Save"
        >
          <Form onSubmit={this.handleSubmit}>
            <FormItem label="Companies">
              <SupplierSearcher
                title="Add an Company"
                onSelect={this.onChangeSuppliers}
                initialChosenSuppliers={this.props.suppliers}
              />
            </FormItem>

            <FormItem label="Comment">
              {getFieldDecorator('comment', {
                rules: [{ required: true, message: 'This field is required!' }],
                initialValue: comment,
              })(<TextArea />)}
            </FormItem>
          </Form>
        </Modal>
      </>
    );
  }
}

RelatedModal.propTypes = {
  suppliers: PropTypes.array,
  form: PropTypes.object,
  save: PropTypes.func,
  comment: PropTypes.string,
};

export default Form.create()(RelatedModal);
