export const supplierSideMenu = [
  {
    url: '/rfq-and-eoi',
    title: 'Tenders & EOI',
    icon: 'desktop',
  },
  {
    url: '/registration',
    title: 'Registration',
    icon: 'contacts',
  },
  {
    url: '/prequalification',
    title: 'Pre-qualification',
    icon: 'solution',
    className: 'multi-line',
  },
  {
    url: '/qualification',
    title: 'Qualification audit',
    icon: 'calendar',
    className: 'multi-line',
    subMenu: [
      {
        url: '/qualification',
        title: 'Qualification list',
      },
      {
        url: '/qualification/send-resubmit-request',
        title: 'Request',
      },
    ],
  },
  {
    url: '/validation-supplier',
    title: 'Validation',
    icon: 'check',
    className: 'multi-line',
    subMenu: [
      {
        url: '/request-validation',
        title: 'Create',
      },
      {
        url: '/statusvalidation',
        title: 'Status',
      },
    ],
  },

  {
    url: '/capacity-building',
    title: 'Capacity building',
    icon: 'file-text',
    className: 'multi-line',
  },
];

export const buyerSideMenu = [
  {
    url: '/dashboard',
    title: 'Dashboard',
    icon: 'desktop',
    permission: 'showDashboard',
  },
  {
    url: '/companies?dueDiligenceStatus=exclude',
    title: 'Suppliers',
    icon: 'user',
    permission: 'manageSuppliers',
  },
  {
    url: 'action',
    title: 'Action',
    icon: 'swap',
    permissions: [
      'managePrequalification',
      'manageCapacityBuilding',
      'manageQualification',
      'manageValidation',
      'manageDifotScore',
      'manageDueDiligence',
      'manageSuccessFeedback',
      'manageBlocking',
    ],
    subMenu: [
      {
        url: '/prequalification-status',
        title: 'Pre-qualification',
        permission: 'managePrequalification',
      },
      {
        url: '/capacity-building-status',
        title: 'Capacity building',
        permission: 'manageCapacityBuilding',
      },
      {
        url: 'audit',
        title: 'Qualification',
        permissions: ['manageQualification'],
        subMenu: [
          {
            url: '/audit?prequalifiedStatus=yes',
            title: 'Send',
            permission: 'manageQualification',
          },
          {
            url: '/audit/responses',
            title: 'Desktop audit',
            permission: 'manageQualification',
          },
          {
            url: '/audit/responses-physical',
            title: 'Physical audit',
            permission: 'manageQualification',
          },
          {
            url: '/audit/resubmission-requests?prequalifiedStatus=yes',
            title: 'Supplier request',
            permission: 'manageQualification',
          },
        ],
      },
      {
        url: '/validation?productsInfoStatus=requested',
        title: 'Validation',
        permission: 'manageValidation',
      },
      {
        url: '/difot',
        title: 'DIFOT score',
        permission: 'manageDifotScore',
      },
      {
        url: '/due-diligence',
        title: 'Due Diligence',
        permission: 'manageDueDiligence',
      },
      {
        url: 'feedback',
        title: 'Success feedback',
        permissions: ['manageSuccessFeedback'],
        subMenu: [
          {
            url: '/feedback',
            title: 'Request feedback',
            permission: 'manageSuccessFeedback',
          },
          {
            url: '/feedback/responses',
            title: 'Responses',
            permission: 'manageSuccessFeedback',
          },
        ],
      },
      {
        url: '/blocking',
        title: 'Block a supplier',
        permission: 'manageBlocking',
      },
    ],
  },
  {
    url: 'tenders',
    title: 'Tenders',
    icon: 'bars',
    permissions: ['managerfq', 'manageeoi', 'managetrfq', 'managefreight'],
    subMenu: [
      {
        url: '/rfq',
        title: 'RFQ Responses',
        icon: 'bars',
        permission: 'managerfq',
      },
      {
        url: '/eoi',
        title: 'EOI Responses',
        icon: 'bars',
        permission: 'manageeoi',
      },
      {
        url: '/trfq',
        title: 'Travel RFQ Responses',
        icon: 'bars',
        permission: 'managetrfq',
      },
      {
        url: '/freight',
        title: 'Freight RFQ Responses',
        icon: 'bars',
        permission: 'managefreight',
      },
    ],
  },
  {
    url: '/report',
    title: 'Report',
    icon: 'file',
    permission: 'manageReports',
  },
  {
    url: 'logs',
    title: 'Log',
    icon: 'file',
    permissions: ['manageLog'],
    subMenu: [
      {
        url: '/logs',
        title: 'Activity log',
        icon: 'file',
        permission: 'manageLog',
      },
      {
        url: '/action-logs',
        title: 'Action log',
        icon: 'hourglass',
        permission: 'manageLog',
      },
    ],
  },
  {
    url: 'settings',
    title: 'Settings',
    icon: 'setting',
    permissions: ['manageTemplates', 'manageExpiryDates', 'manageUsers', 'showMailDeliveries'],
    subMenu: [
      {
        url: '/settings/templates',
        title: 'Templates',
        permission: 'manageTemplates',
      },
      {
        url: '/settings/manage-expiry-dates',
        title: 'Manage Expiry Dates',
        permission: 'manageExpiryDates',
      },
      {
        url: '/user-list',
        title: 'Manage Users',
        permission: 'manageUsers',
      },
      {
        url: '/mail-deliveries',
        title: 'Mail deliveries',
        permission: 'showMailDeliveries',
      },
      {
        url:
          '/manage-suppliers?activationStatus=&prequalifiedStatus=undefined&qualifiedStatus=undefined',
        title: 'Manage suppliers',
      },
    ],
  },
];
