/* eslint-disable react/display-name */

import React from 'react';
import { withRouter } from 'react-router';
import { Card, Row, Col, Button, Icon } from 'antd';
import { xlsxHandler } from 'modules/common/utils';
import { Common } from 'modules/companies/components';
import { Sidebar } from 'modules/companies/components';
import { Search } from 'modules/common/components';
import moment from 'moment';
import { dateFormat } from 'modules/common/constants';

class Difot extends Common {
  constructor(props) {
    super(props);

    this.handleImport = this.handleImport.bind(this);
  }

  handleImport(e) {
    xlsxHandler({
      e,
      success: data => {
        this.props.addDifotScores(data);
        this.inputDifot.value = '';
      },
    });
  }

  render() {
    const { totalCount, generate } = this.props;
    const { selectedCompanies } = this.state;

    const columns = this.getWrappedColumns([
      {
        title: 'DIFOT score',
        render: record => {
          if (record.lastDifotScore) {
            return <span>{record.lastDifotScore.amount.toFixed(1)}%</span>;
          }

          return <span>0%</span>;
        },
      },
      {
        title: 'Last DIFOT date',
        render: record =>
          record.lastDifotScore ? moment(record.lastDifotScore.date).format(dateFormat) : '-',
      },
      {
        title: 'Average DIFOT score',
        render: record => (
          <span>{record.averageDifotScore ? record.averageDifotScore.toFixed(1) : 0}%</span>
        ),
      },
    ]);

    return (
      <Row gutter={16}>
        <Sidebar
          suppliersCount={totalCount}
          checkedCount={selectedCompanies ? selectedCompanies.length : 0}
        />

        <Col span={19}>
          <Card title="Suppliers">
            <div className="table-operations">
              <Search />
              {this.renderSearchButtons()}

              <Button onClick={() => generate(selectedCompanies)}>
                Download template
                <Icon type="download" />
              </Button>

              <div className="upload-btn-wrapper">
                <Button>
                  Import DIFOT score <Icon type="file-excel" />
                </Button>
                <input
                  type="file"
                  onChange={this.handleImport}
                  ref={el => (this.inputDifot = el)}
                />
              </div>
            </div>

            {this.renderTable({
              rowSelection: {
                selectedCompanies,
                onChange: this.onSelectedCompaniesChange,
              },
              columns,
            })}
          </Card>
        </Col>
      </Row>
    );
  }
}

export default withRouter(Difot);
