import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Icon, Alert } from 'antd';

class ShowRelatedCompany extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal() {
    this.setState({ modalVisible: true });
  }

  hideModal() {
    this.setState({ modalVisible: false, supplierIds: [] });
  }

  render() {
    const { relatedSuppliers, comment } = this.props;
    const { modalVisible } = this.state;

    const content = [];
    relatedSuppliers.forEach(sup => {
      content.push(<li key={sup._id}>{sup.basicInfo.enName || sup.basicInfo.mnName}</li>);
    });

    return (
      <>
        <span key={0} className="small-label pointer" onClick={this.showModal}>
          <Icon type="link" /> View
        </span>

        <Modal
          key={1}
          title="Related companies"
          visible={modalVisible}
          onCancel={this.hideModal}
          footer={null}
          bodyStyle={{ maxHeight: '60vh', overflow: 'scroll' }}
        >
          <ul>{content}</ul>

          <Alert message={comment} type="info" />
        </Modal>
      </>
    );
  }
}

ShowRelatedCompany.propTypes = {
  relatedSuppliers: PropTypes.array,
  comment: PropTypes.string,
};

export default ShowRelatedCompany;
