import React from 'react';
import { withRouter } from 'react-router';
import { Checkbox, Form, Input, Button, Card, Popconfirm } from 'antd';
import {
  Uploader,
  ProductsTree,
  ExtendedField as Field,
  BaseForm,
} from 'modules/common/components';
import { getFlatProductsTree } from 'modules/common/utils';
import { withCurrentUser } from 'modules/auth/containers';

const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;
const { TextArea } = Input;

class ValidationRoleForm extends BaseForm {
  constructor(props, context) {
    super(props, context);

    const { locale = 'en' } = context;
    this.flatProductsInfo = getFlatProductsTree(locale);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.state = { files: [], adminReason: '', managerReason: '' };
  }

  handleSubmit = e => {
    e.preventDefault();

    const {
      form,
      data: { roleRelatedInfo },
    } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }

      values.validationResponseId = roleRelatedInfo._id;

      this.props.responseSend(values);
    });
  };

  onFileUpload(files) {
    this.setState({ files: files.filter(file => file.url) });
  }

  generateOptions(flatProductsInfo, productsInfo) {
    return productsInfo.map(p => ({
      value: p,
      label: flatProductsInfo[p] || '',
    }));
  }

  awardedContactCWNumberInput = () => {
    const { awardedCwNumber, awardedCwNumberFile } = this.props.data.supplierInfo;

    return (
      <div>
        {awardedCwNumber && (
          <Field
            label="Awarded contract CW number:"
            initialValue={awardedCwNumber}
            name="distributorType"
            control={
              <Input placeholder="CW Number fill" style={{ marginRight: '25px' }} disabled />
            }
          />
        )}
        {awardedCwNumberFile.length !== 0 && (
          <Field
            label="Awarded contract CW number File:"
            initialValue={awardedCwNumberFile}
            name="distributorType"
            control={<Uploader defaultFileList={awardedCwNumberFile} disabled />}
          />
        )}
      </div>
    );
  };

  awardedPoandLa = () => {
    const { awardedPoNumber, awardedPoFile } = this.props.data.supplierInfo;

    return (
      <div>
        {awardedPoNumber && (
          <Field
            label="Awarded PO/LA Number:"
            initialValue={awardedPoNumber}
            name="distributorType"
            control={<Input disabled />}
          />
        )}
        {awardedPoFile.length !== 0 && (
          <Field
            label="Awarded PO/LA Number:"
            initialValue={awardedPoFile}
            name="distributorType"
            control={<Uploader defaultFileList={awardedPoFile} disabled={true} />}
          />
        )}
      </div>
    );
  };

  distributorType = () => {
    const { distributorType, distributorFile } = this.props.data.supplierInfo;

    return (
      <div>
        {distributorType.length > 0 && (
          <Field
            viewMode={true}
            label="Distributor type:"
            initialValue={distributorType || []}
            name="awardedRfqProductCodes"
            control={<ProductsTree disabled={true} />}
          />
        )}
        {distributorFile.length > 0 && (
          <Field
            label="Distributor file:"
            initialValue={distributorFile}
            name="distributorType"
            control={<Uploader defaultFileList={distributorFile} disabled={true} />}
          />
        )}
      </div>
    );
  };

  supplierValidationInformations = () => {
    const {
      data: { supplierInfo, roleRelatedInfo },
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { awardedRfqs, businessType, availableProducts } = supplierInfo;

    const { userRoleAvailableProductCodes = [], userRoleAwardedRfqCodes = [] } =
      roleRelatedInfo || {};
    let placeholder = '';

    if (awardedRfqs.length === 0) {
      placeholder = 'You are not awarded in RFQs';
    }

    return (
      <>
        <div style={{ display: 'flex' }}>
          <FormItem label="Check Available Product Codes:" style={{ marginRight: '25px' }}>
            {getFieldDecorator('userRoleAvailableProductCodes', {
              initialValue: userRoleAvailableProductCodes,
            })(
              <CheckboxGroup
                options={this.generateOptions(this.flatProductsInfo, availableProducts)}
                className="horizontal capitalize"
              />
            )}
          </FormItem>

          <FormItem label="Check Awarded RFQ Product Codes:">
            {getFieldDecorator('userRoleAwardedRfqCodes', {
              initialValue: userRoleAwardedRfqCodes,
            })(
              <CheckboxGroup
                options={this.generateOptions(this.flatProductsInfo, userRoleAwardedRfqCodes)}
                className="horizontal capitalize"
                disabled
              />
            )}
          </FormItem>
        </div>

        <Field
          label="Awarded RFQ:"
          initialValue={awardedRfqs}
          name="awardedRfqs"
          optional={true}
          control={<ProductsTree searchPlaceholder={placeholder} disabled={true} />}
        />

        <FormItem label="Business type:">
          <Input disabled={true} defaultValue={businessType} />
        </FormItem>
      </>
    );
  };

  validationResponseReason = () => {
    const { getFieldDecorator } = this.props.form;
    const {
      data: { roleRelatedInfo = {} },
    } = this.props;

    const { managerReason, managerReasonFile, adminReasonFile = [], adminReason, adminName } =
      roleRelatedInfo || {};
    const label = adminName ? `Admin Reason  (${adminName})` : 'Admin reason';

    return (
      <>
        <Field
          label={label}
          initialValue={adminReason}
          name="adminReason"
          control={<TextArea style={{ height: 50 }} maxLength={500} disabled />}
        />

        {adminReasonFile.length > 0 && (
          <Field
            label="Admin Reason File:"
            initialValue={adminReasonFile}
            name="adminReason"
            control={
              <Uploader defaultFileList={adminReasonFile} onChange={this.onFileUpload} disabled />
            }
          />
        )}

        <FormItem label={'Manager Reasons:'}>
          {getFieldDecorator('managerReason', {
            initialValue: managerReason,
          })(<TextArea style={{ height: 50 }} maxLength={500} />)}
        </FormItem>

        {getFieldDecorator('managerReasonFile', {})(
          <Uploader
            defaultFileList={managerReasonFile}
            onChange={this.onFileUpload}
            disabled={this.isDisable}
          />
        )}
      </>
    );
  };

  supplierInformation = () => {
    return (
      <div>
        {this.supplierValidationInformations()}
        {this.awardedContactCWNumberInput()}
        {this.awardedPoandLa()}
        {this.distributorType()}
      </div>
    );
  };

  declineValidationRequest = () => {
    const { form, match } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }

      values.validationId = match.params.id;

      return this.props.decline(values);
    });
  };

  renderOperations = () => {
    const {
      data: {
        supplierInfo: { validationRequestedType },
      },
    } = this.props;

    if (validationRequestedType === 'validated') return null;

    return (
      <FormItem style={{ marginTop: '15px' }}>
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginRight: '15px' }}
          onClick={this.declineValidationRequest}
        >
          {'Decline'}
        </Button>

        <Popconfirm title={'Are you sure ?'} onConfirm={this.handleSubmit}>
          <Button type="primary">{'Approve'}</Button>
        </Popconfirm>
      </FormItem>
    );
  };

  render() {
    return (
      <Form style={{ width: '700px' }}>
        <Card title={this.props.data.companyInfo.basicInfo.enName || ''}>
          {this.supplierInformation()}
          {this.validationResponseReason()}
          {this.renderOperations()}
        </Card>
      </Form>
    );
  }
}

export default withCurrentUser(Form.create()(withRouter(ValidationRoleForm)));
