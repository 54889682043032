const validationInfoByUser = `
  query validationInfoByUser {
    validationInfoByUser
  }
`;

const getSupplierWithRoleRelatedValidationInfo = `
  query getSupplierWithRoleRelatedValidationInfo($validationId: String) {
    getSupplierWithRoleRelatedValidationInfo(validationId: $validationId)
  }
`;

const companiesValidatedProductsInfoExport = `
  query companiesValidatedProductsInfoExport($ids: [String]) {
    companiesValidatedProductsInfoExport(ids: $ids)
  }
`;

const validatedProductCodes = `
    query validatedCodesByUser {
      validatedCodesByUser 
    }
`;

export default {
  validationInfoByUser,
  getSupplierWithRoleRelatedValidationInfo,
  companiesValidatedProductsInfoExport,
  validatedProductCodes,
};
