/* eslint-disable react/display-name */

import React from 'react';
import PropTypes from 'prop-types';
import { Table, Card, Icon, Row, Col, Button, Modal, Input, message, Popconfirm } from 'antd';
import { NumberCard, NumberCardLines } from 'modules/common/components';
import { colors } from 'modules/common/constants';
import { Search, EditorCK } from 'modules/common/components';
import { Common } from 'modules/companies/components/';
import { Paginator } from 'modules/common/components';
import router from 'modules/common/router';
import { xlsxHandler } from 'modules/common/utils';
import {
  collectProducts,
  getExcelData,
  setRequestedProducts,
} from '../../../../tenders/components/utils';
import SupplierSearcher from 'modules/companies/containers/Searcher';
import { Uploader } from 'modules/common/components';
import { readFileUrl } from 'modules/common/utils';
import { disclaimerRelatedTender } from '../../utils';

const { TextArea } = Input;

class Tender extends Common {
  constructor(props) {
    super(props);

    const { tenderDetail } = this.props;
    const { suppliers } = tenderDetail.suppliers || {};

    const respondedProducts = [];
    const perProductStates = {};

    this.state = {
      ...this.state,
      selectedCompanies: [],
      responseModal: { visible: false, record: null },
      regretLetterModal: { visible: false },
      regretLetterContent: '',
      renderModal: { visible: false, record: null },
      supplierAddReason: '',
      respondedProducts,
      ...perProductStates,
      addButtonClick: false,
      suppliers: (suppliers || []).map(s => ({ ...s })),
      respondedFile: [],
      managersPermissionFile: [],
    };

    this.showResponsesModal = this.showResponsesModal.bind(this);
    this.hideResponsesModal = this.hideResponsesModal.bind(this);
    this.renderViewResponse = this.renderViewResponse.bind(this);
    this.toggleRegretLetterModal = this.toggleRegretLetterModal.bind(this);
    this.handleRegretLetterChange = this.handleRegretLetterChange.bind(this);
    this.handleSendRegretLetters = this.handleSendRegretLetters.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.getTitle = this.getTitle.bind(this);
    this.downloadRespondedFiles = this.downloadRespondedFiles.bind(this);
    this.renderUploadButton = this.renderUploadButton.bind(this);
    this.renderNote = this.renderNote.bind(this);
    this.renderNoteModal = this.renderNoteModal.bind(this);
    this.showToggleNote = this.showToggleNote.bind(this);
    this.hideToggleNote = this.hideToggleNote.bind(this);
    this.onChangeSuppliers = this.onChangeSuppliers.bind(this);
    this.renderDeleteButton = this.renderDeleteButton.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
  }

  onChangeSuppliers(suppliers) {
    this.setState({ suppliers });

    const { tenderDetail } = this.props;

    if (suppliers.length !== 0) {
      return this.props.addNewSupplier(tenderDetail._id, suppliers);
    }

    return null;
  }

  getPercent(requestedCount, count) {
    if (count) {
      return (count / requestedCount) * 100;
    }

    return 0;
  }

  setFilter(name) {
    router.setParams(this.props.history, {
      filter: name,
      page: undefined,
      perPage: undefined,
    });
  }

  getTitle() {
    const filter = router.getParam(this.props.history, 'filter');

    if (filter === 'isNotInterested') return 'Not interested';

    if (filter === 'isNotResponded') return 'Not responded';

    return 'Submitted';
  }

  showResponsesModal(record) {
    const { supplier } = record;

    this.setState({
      responseModal: {
        visible: true,
        title: supplier && supplier.basicInfo ? supplier.basicInfo.enName : '',
        record,
      },
    });
  }

  hideResponsesModal() {
    this.setState({ responseModal: { visible: false, record: null } });
  }

  toggleRegretLetterModal() {
    const { regretLetterModal } = this.state;

    this.setState({
      regretLetterModal: { visible: !regretLetterModal.visible },
    });
  }

  handleSendRegretLetters() {
    this.props.sendRegretLetter(this.state.regretLetterContent);
  }

  handleRegretLetterChange(e) {
    this.setState({ regretLetterContent: e.editor.getData() });
  }

  onChange() {
    return this.props.onChange(collectProducts(this.state));
  }

  handleFile(id, e) {
    const { respondedProducts } = this.state;
    const { tenderDetail } = this.props;
    const { rfqType } = tenderDetail;
    const rp = tenderDetail.requestedProducts;

    xlsxHandler({
      e,
      parse: false,
      success: data => {
        const docs = getExcelData({ rfqType, data });

        this.setState(setRequestedProducts({ docs, respondedProducts, rp }));

        return this.props.tenderResponseEdit({
          supplierId: id,
          respondedProducts: respondedProducts,
        });
      },
    });
  }

  uploadButton(record) {
    const { tenderDetail, tenderResponseEdit } = this.props;
    const { type, rfqType } = tenderDetail;

    if (rfqType === 'goods' || rfqType === 'freight') {
      return (
        <>
          <Button>
            {'Upload'} <Icon type="file-excel" />
          </Button>
          <input
            type="file"
            className="ant-btn"
            onChange={this.handleFile.bind(this, record.supplier._id)}
          />
        </>
      );
    }

    if (rfqType === 'service' || type === 'trfq') {
      return (
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Uploader
            onChange={([file]) =>
              tenderResponseEdit({ supplierId: record.supplier._id, respondedFiles: file })
            }
          />
          {this.renderFile(record.respondedFiles)}
        </div>
      );
    }

    return null;
  }

  renderUploadButton(record) {
    return <div className="upload-btn-wrapper">{this.uploadButton(record)}</div>;
  }

  showToggleNote(record) {
    this.setState({
      renderModal: {
        visible: true,
        record,
      },
      managersPermissionFile: record.managersPermissionFile,
      supplierAddReason: record.supplierAddReason,
    });
  }

  hideToggleNote() {
    this.setState({ renderModal: { visible: false, record: null } });
  }

  renderNote(record) {
    return (
      <a href="#note" onClick={() => this.showToggleNote(record)}>
        Note
      </a>
    );
  }

  renderFile(file) {
    if (!file) {
      return '-';
    }

    const fileList = file.map(file => (
      <span style={{ marginLeft: '10px' }} key={file.url}>
        <a href={readFileUrl(file.url)} target="__blank">
          {file.name}
        </a>
        <br />
      </span>
    ));

    return <>{fileList}</>;
  }

  onFileUpload(managersPermissionFile) {
    this.setState({ managersPermissionFile });
  }

  renderNoteModal() {
    const { renderModal, supplierAddReason, managersPermissionFile } = this.state;
    const record = renderModal.record || {};

    const supplierAddedReason = reasons => {
      this.setState({ supplierAddReason: reasons.target.value });
    };

    const onConfirm = () => {
      if (!supplierAddReason) {
        return message.warn('Please, fill the note');
      }

      this.setState({ renderModal: { visible: false } });

      return this.props.tenderResponseEdit({
        _id: record._id,
        supplierAddReason: supplierAddReason,
        supplierId: record.supplier._id,
        managersPermissionFile: managersPermissionFile,
      });
    };

    return (
      <Modal
        title="Notes"
        visible={renderModal.visible}
        maskClosable={false}
        width="50%"
        onCancel={this.hideToggleNote}
        footer={[
          <Button key="cancel" onClick={this.hideToggleNote}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={onConfirm}>
            Ok
          </Button>,
        ]}
      >
        <TextArea
          name="cancelTextReason"
          onChange={supplierAddedReason}
          style={{ height: 100 }}
          maxLength={500}
          value={this.state.supplierAddReason}
        />
        <div style={{ marginTop: '10px', display: 'flex' }}>
          <Uploader label="Upload file" onChange={this.onFileUpload} />
          {this.renderFile(managersPermissionFile)}
        </div>
      </Modal>
    );
  }

  renderDeleteButton(record) {
    const { tendersRemoveResponse } = this.props;

    return (
      <div className="upload-btn-wrapper">
        <Popconfirm
          title="Are you sure remove this supplier?"
          onConfirm={() =>
            tendersRemoveResponse({ _id: record._id, supplierId: record.supplier._id })
          }
          okText="Yes"
          cancelText="No"
        >
          <Button type="dashed" shape="circle">
            <Icon type="delete" />
          </Button>
        </Popconfirm>
      </div>
    );
  }

  renderDisclaimerIsAgree = ({ supplier }) => {
    if (supplier.disclaimerIsAgree === null) {
      return null;
    }

    if (supplier.disclaimerIsAgree) {
      return 'yes';
    }

    return 'no';
  };

  columns() {
    const { tenderDetail } = this.props;
    const { type } = tenderDetail;
    const queryParams = this.props.queryParams || {};
    const { filter } = queryParams;

    const addParticipatedSuppliersColumn = [
      {
        title: 'Upload',
        render: record => (record.buyerAdded ? this.renderUploadButton(record) : null),
      },
      { title: 'Note', render: record => (record.buyerAdded ? this.renderNote(record) : null) },
      {
        title: 'Delete',
        render: record => (record.buyerAdded ? this.renderDeleteButton(record) : null),
      },
    ];

    const column = [
      {
        title: '#',
        render: (text, record, index) => index + 1,
      },
      {
        title: 'Supplier name',
        dataIndex: 'supplier.basicInfo.enName',
      },
      {
        title: 'Vendor number',
        dataIndex: 'supplier.basicInfo.sapNumber',
      },
      {
        title: 'Prequalification status',
        dataIndex: 'supplier.prequalificationStatusDisplay',
      },
      {
        title: 'Status',
        render: record => (record.status ? record.status : 'On time'),
      },
      {
        title: 'Provided information',
        render: this.renderViewResponse,
      },
      {
        title: 'Contact person',
        dataIndex: 'supplier.contactInfo.name',
      },
      { title: 'Email', dataIndex: 'supplier.contactInfo.email' },
      { title: 'Phone', dataIndex: 'supplier.contactInfo.phone' },
    ];

    if (disclaimerRelatedTender(type) && filter === 'isInvited') {
      column.push({
        title: 'Disclaimer',
        render: record => this.renderDisclaimerIsAgree(record),
      });
    }

    return column.concat(addParticipatedSuppliersColumn);
  }

  downloadRespondedFiles() {
    const { REACT_APP_API_URL } = process.env;
    const { tenderDetail, writeTenderLog } = this.props;
    const { selectedCompanies } = this.state;

    writeTenderLog({
      variables: {
        tenderId: tenderDetail._id,
        action: 'download',
        description: 'Files have been downloaded',
      },
    });

    window.open(
      `${REACT_APP_API_URL}/download-tender-files?tenderId=${
        tenderDetail._id
      }&selectedCompanies=${selectedCompanies.toString()}`,
      '__blank'
    );
  }

  renderDownloadFilesButton() {
    const { tenderDetail } = this.props;

    if (tenderDetail.status === 'open') {
      return null;
    }

    return (
      <Button onClick={this.downloadRespondedFiles} key={2}>
        Download files
        <Icon type="file-excel" />
      </Button>
    );
  }

  renderViewResponse(text, record) {
    return (
      <a href="#view" onClick={() => this.showResponsesModal(record)}>
        View
      </a>
    );
  }

  renderStats() {
    const tenderDetail = this.props.tenderDetail || {};

    const data = this.props.data || [];
    const { submittedCount, requestedCount, notInterestedCount, notRespondedCount } = tenderDetail;

    const tenderResponseCount = data.filter(e => e.buyerAdded === true).length;

    return (
      <Row gutter={24}>
        <Col key={1} lg={6} sm={12}>
          <NumberCard
            icon="message"
            title="Invited"
            color={colors[3]}
            number={requestedCount}
            onClick={() => this.setFilter('isInvited')}
          />
        </Col>
        <Col key={2} lg={6} sm={12}>
          <NumberCardLines
            icon="like-o"
            title="Submitted"
            color={colors[2]}
            number={tenderResponseCount ? submittedCount - tenderResponseCount : submittedCount}
            resNumber={tenderResponseCount}
            percent={this.getPercent(requestedCount, submittedCount)}
            onClick={() => this.setFilter('isSubmitted')}
          />
        </Col>
        <Col key={3} lg={6} sm={12}>
          <NumberCardLines
            icon="dislike-o"
            title="Not interested"
            color={colors[4]}
            number={notInterestedCount}
            percent={this.getPercent(requestedCount, notInterestedCount)}
            onClick={() => this.setFilter('isNotInterested')}
          />
        </Col>
        <Col key={4} lg={6} sm={12}>
          <NumberCardLines
            icon="question"
            title="Not responded"
            color={colors[5]}
            number={notRespondedCount}
            percent={this.getPercent(requestedCount, notRespondedCount)}
            onClick={() => this.setFilter('isNotResponded')}
          />
        </Col>
      </Row>
    );
  }

  getResponseRows() {
    const { notRespondedSuppliers, invitedSuppliers } = this.props;

    const data = this.props.data || [];
    const queryParams = this.props.queryParams || {};
    const { filter } = queryParams;

    let responseRows = data;

    if (filter === 'isNotResponded') {
      responseRows = notRespondedSuppliers;
    }

    if (filter === 'isInvited') {
      responseRows = invitedSuppliers;
    }

    return responseRows;
  }

  renderSendRegretLetterButton(tenderDetail) {
    const {
      sentRegretLetter,
      status,
      notBidderListedSuppliers = [],
      type,
      responses,
      winnerIds = [],
    } = tenderDetail;

    const disable = type === 'rfq' && responses.length === winnerIds.length ? true : false;

    return (
      <Button
        disabled={
          sentRegretLetter ||
          ['open', 'draft'].includes(status) ||
          notBidderListedSuppliers.length === 0 ||
          disable
        }
        onClick={this.toggleRegretLetterModal}
      >
        Send regret letter
        <Icon type="mail" />
      </Button>
    );
  }

  renderRegretLetterModal() {
    const { regretLetterModalVisible, tenderDetail } = this.props;
    const { regretLetterModal, regretLetterContent, selectedCompanies } = this.state;
    const { type, sentRegretLetter, notBidderListedSuppliers = [] } = tenderDetail;
    const otherSupps = this.props.data.map(e => e.supplier._id);
    const otherSuppliers = otherSupps.filter(e => !this.state.selectedCompanies.includes(e));

    let targets = 0;
    let extraInfo = null;

    if (type === 'eoi') {
      if (!sentRegretLetter) {
        targets = notBidderListedSuppliers.length;
        extraInfo = notBidderListedSuppliers.map(supplier => {
          const { basicInfo } = supplier;

          return (
            <span style={{ marginLeft: 5, display: 'inline-block' }} key={Math.random()}>
              {basicInfo ? basicInfo.enName : ''},
            </span>
          );
        });
      }
    } else {
      targets = regretLetterModalVisible ? otherSuppliers.length : selectedCompanies.length;
    }

    return (
      <Modal
        title={`Sending regret letters to "${targets}" bidders`}
        maskClosable={false}
        visible={
          regretLetterModalVisible !== undefined
            ? regretLetterModalVisible
            : regretLetterModal.visible
        }
        onCancel={this.toggleRegretLetterModal}
        onOk={this.handleSendRegretLetters}
        width="50%"
      >
        {extraInfo}

        <EditorCK content={regretLetterContent} onChange={this.handleRegretLetterChange} />
      </Modal>
    );
  }

  renderTable(args) {
    const { tableOperations } = args;
    const {
      loading,
      totalCount,
      onChange,
      exportSuppliersTenderResponses,
      exportLoading,
    } = this.props;
    const { selectedCompanies, responseModal } = this.state;
    const tenderDetail = this.props.tenderDetail || {};
    const { winnerIds = [], status, type, suppliers } = tenderDetail;

    return (
      <Card
        bordered={true}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ marginTop: '10px' }}>
              {this.getTitle()} companies for <strong>{tenderDetail.name}</strong>
            </span>
            <Button
              loading={exportLoading}
              onClick={() => exportSuppliersTenderResponses(selectedCompanies)}
              key={Math.random()}
            >
              Export to excel
              <Icon type="file-excel" />
            </Button>
          </div>
        }
      >
        <div className="table-operations">
          <Search placeholder="Supplier name or Vendor number" />
          {tableOperations}
          {this.renderSendRegretLetterButton(tenderDetail)}
        </div>

        <Table
          rowSelection={{
            selectedCompanies,
            onChange: this.onSelectedCompaniesChange,
          }}
          rowClassName={record => {
            if (winnerIds.includes(record.supplier._id)) return 'highlight';
          }}
          columns={this.columns()}
          rowKey={record => (record.supplier ? record.supplier._id : '')}
          dataSource={status !== 'open' ? this.getResponseRows() : []}
          pagination={false}
          loading={loading}
          onChange={(pagination, filters, sorter) => onChange(pagination, filters, sorter)}
        />

        <Paginator total={totalCount} />

        <Modal
          title={`${responseModal.title}'s response`}
          visible={responseModal.visible}
          maskClosable={false}
          onCancel={this.hideResponsesModal}
          footer={null}
          width="100%"
          style={{ top: 16 }}
        >
          {responseModal.record && this.renderResponseModal(responseModal.record)}
        </Modal>

        {this.renderRegretLetterModal()}

        {type !== 'eoi' && ['closed', 'manuallyClosed'].includes(status) ? (
          <SupplierSearcher
            title="Add participated Suppliers"
            key={3}
            onSelect={this.onChangeSuppliers}
            control="button"
            options={suppliers}
          />
        ) : null}
      </Card>
    );
  }

  componentDidUpdate(prevProps) {
    const prevTender = prevProps.tenderDetail;
    const { tenderDetail, writeTenderLog } = this.props;

    if (tenderDetail && tenderDetail._id && !prevTender._id) {
      writeTenderLog({
        variables: {
          tenderId: tenderDetail._id,
          action: 'view',
          description: `Viewed "${tenderDetail.name}"`,
        },
      });
    }
  }
}

Tender.propTypes = {
  data: PropTypes.array,
  filter: PropTypes.func,
  sendRegretLetter: PropTypes.func,
  sentRegretLetter: PropTypes.boolean,
  regretLetterModalVisible: PropTypes.boolean,
  notRespondedSuppliers: PropTypes.array,
  tenderDetail: PropTypes.object,
  writeTenderLog: PropTypes.func,
  history: PropTypes.object,
  queryParams: PropTypes.object,
};

export default Tender;
