import React from 'react';
import { Icon } from 'antd';

export const statusIcons = {
  draft: { type: 'edit', color: 'rgb(161,161,164)' },
  participated: { type: 'check-circle-o', color: 'rgb(244,119,33)' },
  notInterested: { type: 'minus-circle', color: 'rgb(173,77,77,1)' },
  open: { type: 'sync', color: 'rgb(0,153,168)' },
  closed: { type: 'lock', color: 'rgb(188, 90, 58)' },
  awarded: { type: 'trophy', color: 'rgb(244,119,33)' },
  canceled: { type: 'close-circle', color: 'rgb(67,83,99)' },
  manuallyClosed: { type: 'close', color: 'rgb(67,83,99)' },
};

const dateFormat = 'YYYY/MM/DD';
const dateTimeFormat = 'YYYY/MM/DD HH:mm';

const uploadDisclaimer =
  'You may upload jpg, jpeg, png, rtf, pdf files, or simple Adobe PDF files. Files that have the ability to contain macros or other types of active code are not acceptable. Maximum file size is 30mb.';

const noLabelLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    lg: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    lg: { span: 24 },
  },
};

const booleanData = [{ text: 'Yes', value: 'true' }, { text: 'No', value: 'false' }];

const booleanDataReverse = [{ text: 'No', value: 'true' }, { text: 'Yes', value: 'false' }];

const days = [
  { text: '1 day before', value: '1' },
  { text: '3 days before', value: '3' },
  { text: '7 days before', value: '7' },
];

const notifyLoading = {
  message: 'Building an excel...',
  description: 'You will get notified when your file is ready to download!',
  icon: <Icon type="loading" />,
  duration: 120,
  key: 'loadingNotification',
};

const notifyReady = {
  message: 'Your file is ready to download',
  icon: <Icon type="file-excel" style={{ color: 'rgb(0,153,168)' }} />,
  duration: 0,
  key: 'downloadNotification',
};

const colors = [
  'rgb(0,153,168)',
  'rgb(0,116,155)',
  'rgb(110,180,175)',
  'rgb(253,185,19)',
  'rgb(188,90,58)',
  'rgb(161,161,164)',
  'rgb(191,111,125)',
  'rgb(101,176,186)',
  'rgb(204,131,65)',
  'rgb(67,83,99)',
];

// query params
const pageParams = `$page: Int $perPage: Int`;
const pageValues = `page: $page, perPage: $perPage`;

export {
  dateFormat,
  dateTimeFormat,
  uploadDisclaimer,
  noLabelLayout,
  days,
  booleanData,
  booleanDataReverse,
  notifyLoading,
  notifyReady,
  colors,
  pageParams,
  pageValues,
};
