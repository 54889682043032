import React from 'react';
import PropTypes from 'prop-types';
import { gql, compose, graphql } from 'react-apollo';
import { queries, mutations } from '../../graphql';
import { RegistrationForms } from '../../components';
import { Loading } from 'modules/common/components';
import { alert } from 'modules/common/utils';

const RegistrationContainer = props => {
  let { companyDetailQuery } = props;

  if (companyDetailQuery.error) {
    return null;
  }

  if (companyDetailQuery.loading) {
    return <Loading />;
  }

  const deActive = (_id, text, reason) => {
    const { companiesDeActiveStatusSetMutation } = props;

    if (text === '' && reason === '') {
      return alert.error('Fill the reason or comment!');
    }

    companiesDeActiveStatusSetMutation({
      variables: { _id, text, reason },
    })
      .then(() => {
        alert.success('De-activated supplier!');
        companyDetailQuery.refetch();
        props.history.push(
          '/manage-suppliers?activationStatus=&prequalifiedStatus=undefined&qualifiedStatus=undefined'
        );
      })
      .catch(error => {
        alert.error(error.message);
      });
  };

  const updatedProps = {
    ...props,
    company: {
      ...companyDetailQuery.companyDetail,
    },
    viewMode: true,
    deActive,
  };

  return <RegistrationForms {...updatedProps} />;
};

RegistrationContainer.propTypes = {
  companyDetailQuery: PropTypes.object,
  companiesDeActiveStatusSetMutation: PropTypes.func,
};

export default compose(
  graphql(gql(queries.companyDetail), {
    name: 'companyDetailQuery',
    options: ({ match }) => {
      return {
        variables: {
          _id: match.params.id,
        },
        notifyOnNetworkStatusChange: true,
      };
    },
  }),
  graphql(gql(mutations.companiesDeActiveStatusSet), {
    name: 'companiesDeActiveStatusSetMutation',
  })
)(RegistrationContainer);
