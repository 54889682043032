const en = [
  {
    label: 'A00000 - Construction',
    selectable: false,
    children: [
      {
        label: 'A01000 - Construction Contractors',
        selectable: false,
        children: [
          {
            label: "A01001 - Large EPCM's >$100M/greenfield projects",
            value: 'a01001',
            key: 'A01001',
          },
          {
            label: "A01002 - Small EPCM's (<$100M)",
            value: 'a01002',
            key: 'A01002',
          },
        ],
        value: 'a01000',
        key: 'A01000',
      },
      {
        label: 'A02000 - Construction Services',
        selectable: false,
        children: [
          {
            label: 'A02001 - Architectural and Finishes Contractors',
            value: 'a02001',
            key: 'A02001',
          },
          {
            label: 'A02002 - Building Materials',
            value: 'a02002',
            key: 'A02002',
          },
          {
            label: 'A02003 - Buildings - Prefab and temporary',
            value: 'a02003',
            key: 'A02003',
          },
          {
            label: 'A02004 - Cables',
            value: 'a02004',
            key: 'A02004',
          },
          {
            label: 'A02005 - Cement',
            value: 'a02005',
            key: 'A02005',
          },
          {
            label: 'A02006 - Structural & Civil Contractors',
            value: 'a02006',
            key: 'A02006',
          },
          {
            label: 'A02007 - Construction Materials',
            value: 'a02007',
            key: 'A02007',
          },
          {
            label: 'A02008 - Construction Specialised Equipment',
            value: 'a02008',
            key: 'A02008',
          },
          {
            label: 'A02009 - Couplings and Gear Units',
            value: 'a02009',
            key: 'A02009',
          },
          {
            label: 'A02010 - Couriers',
            value: 'a02010',
            key: 'A02010',
          },
          {
            label: 'A02011 - Electrical & Instrumentation Contractors',
            value: 'a02011',
            key: 'A02011',
          },
          {
            label: 'A02012 - Electrical Equipment & Parts',
            value: 'a02012',
            key: 'A02012',
          },
          {
            label: 'A02013 - Engines and Generators',
            value: 'a02013',
            key: 'A02013',
          },
          {
            label: 'A02014 - Fans and Blowers',
            value: 'a02014',
            key: 'A02014',
          },
          {
            label: 'A02015 - Goods Handling',
            value: 'a02015',
            key: 'A02015',
          },
          {
            label: 'A02016 - Heating & Heatng Exchange Equipment',
            value: 'a02016',
            key: 'A02016',
          },
          {
            label: 'A02017 - HVAC & Building Services Equipment',
            value: 'a02017',
            key: 'A02017',
          },
          {
            label: 'A02018 - Instrumentation and Automation',
            value: 'a02018',
            key: 'A02018',
          },
          {
            label: 'A02019 - Material Handling  Equipment',
            value: 'a02019',
            key: 'A02019',
          },
          {
            label: 'A02020 - Mechanical Contractors',
            value: 'a02020',
            key: 'A02020',
          },
          {
            label: 'A02021 - Piping',
            value: 'a02021',
            key: 'A02021',
          },
          {
            label: 'A02022 - Special Process Equipment & Packages',
            value: 'a02022',
            key: 'A02022',
          },
          {
            label: 'A02023 - Steel and Structural Products',
            value: 'a02023',
            key: 'A02023',
          },
          {
            label: 'A02024 - Tanks',
            value: 'a02024',
            key: 'A02024',
          },
          {
            label: 'A02025 - Turbines',
            value: 'a02025',
            key: 'A02025',
          },
          {
            label: 'A02026 - Valves and Actuators',
            value: 'a02026',
            key: 'A02026',
          },
          {
            label: 'A02027 - Warehousing',
            value: 'a02027',
            key: 'A02027',
          },
        ],
        value: 'a02000',
        key: 'A02000',
      },
    ],
    value: 'a00000',
    key: 'A00000',
  },
  {
    label: 'B00000 - Energy',
    selectable: false,
    children: [
      {
        label: 'B01000 - Alternate Energy',
        selectable: false,
        children: [
          {
            label: 'B01001 - Compressed Air',
            value: 'b01001',
            key: 'B01001',
          },
          {
            label: 'B01002 - Steam',
            value: 'b01002',
            key: 'B01002',
          },
          {
            label: 'B01003 - Thermal Coal',
            value: 'b01003',
            key: 'B01003',
          },
          {
            label: 'B01004 - Domestic Gas',
            value: 'b01004',
            key: 'B01004',
          },
        ],
        value: 'b01000',
        key: 'B01000',
      },
      {
        label: 'B02000 - Fuel',
        selectable: false,
        children: [
          {
            label: 'B02001 - Diesel',
            value: 'b02001',
            key: 'B02001',
          },
          {
            label: 'B02002 - Fuel oils',
            value: 'b02002',
            key: 'B02002',
          },
          {
            label: 'B02003 - Natural Gas',
            value: 'b02003',
            key: 'B02003',
          },
          {
            label: 'B02004 - Petroleum',
            value: 'b02004',
            key: 'B02004',
          },
          {
            label: 'B02005 - Propane (LPG)',
            value: 'b02005',
            key: 'B02005',
          },
          {
            label: 'B02006 - Thermal coal',
            value: 'b02006',
            key: 'B02006',
          },
          {
            label: 'B02007 - Biofuel',
            value: 'b02007',
            key: 'B02007',
          },
          {
            label: 'B02008 - Liquid natural gas',
            value: 'b02008',
            key: 'B02008',
          },
          {
            label: 'B02009 - Fuel terminal managemnt/maintenance serv',
            value: 'b02009',
            key: 'B02009',
          },
        ],
        value: 'b02000',
        key: 'B02000',
      },
      {
        label: 'B03000 - Power',
        selectable: false,
        children: [
          {
            label: 'B03001 - Electricity - Domestic',
            value: 'b03001',
            key: 'B03001',
          },
          {
            label: 'B03002 - Electricity - Industrial',
            value: 'b03002',
            key: 'B03002',
          },
          {
            label: 'B03003 - Power transmission',
            value: 'b03003',
            key: 'B03003',
          },
        ],
        value: 'b03000',
        key: 'B03000',
      },
    ],
    value: 'b00000',
    key: 'B00000',
  },
  {
    label: 'C00000 - Fixed Plant & Equipment',
    selectable: false,
    children: [
      {
        label: 'C01000 - Materials Movement',
        selectable: false,
        children: [
          {
            label: 'C01001 - Bucket wheels',
            value: 'c01001',
            key: 'C01001',
          },
          {
            label: 'C01002 - Conveyor Belting',
            value: 'c01002',
            key: 'C01002',
          },
          {
            label: 'C01003 - Conveyor Equipment & Supplies',
            value: 'c01003',
            key: 'C01003',
          },
          {
            label: 'C01004 - Reclaimers',
            value: 'c01004',
            key: 'C01004',
          },
          {
            label: 'C01005 - Ship Loaders',
            value: 'c01005',
            key: 'C01005',
          },
          {
            label: 'C01006 - Stackers & reclaimers',
            value: 'c01006',
            key: 'C01006',
          },
        ],
        value: 'c01000',
        key: 'C01000',
      },
      {
        label: 'C02000 - Materials Processing',
        selectable: false,
        children: [
          {
            label: 'C02001 - Crushers and Mills',
            value: 'c02001',
            key: 'C02001',
          },
          {
            label: 'C02002 - Crushing Consumables/Grinding Media',
            value: 'c02002',
            key: 'C02002',
          },
          {
            label: 'C02003 - Heavy Media Separation Spares',
            value: 'c02003',
            key: 'C02003',
          },
          {
            label: 'C02004 - Process Equipment',
            value: 'c02004',
            key: 'C02004',
          },
          {
            label: 'C02005 - Screens',
            value: 'c02005',
            key: 'C02005',
          },
        ],
        value: 'c02000',
        key: 'C02000',
      },
      {
        label: 'C03000 - Rail',
        selectable: false,
        children: [
          {
            label: 'C03001 - Rail - fixed assets ie ballast,sleepers',
            value: 'c03001',
            key: 'C03001',
          },
        ],
        value: 'c03000',
        key: 'C03000',
      },
      {
        label: 'C04000 - Smelting equipment',
        selectable: false,
        children: [
          {
            label: 'C04001 - Pot Shells and Superstructure',
            value: 'c04001',
            key: 'C04001',
          },
          {
            label: 'C04002 - Smelter Rectifiers',
            value: 'c04002',
            key: 'C04002',
          },
        ],
        value: 'c04000',
        key: 'C04000',
      },
    ],
    value: 'c00000',
    key: 'C00000',
  },
  {
    label: 'D00000 - Logistics',
    selectable: false,
    children: [
      {
        label: 'D01000 - Marine Equipment',
        selectable: false,
        children: [
          {
            label: 'D01001 - Barges',
            value: 'd01001',
            key: 'D01001',
          },
          {
            label: 'D01002 - Marine Vessels',
            value: 'd01002',
            key: 'D01002',
          },
          {
            label: 'D01003 - Ore Cariers',
            value: 'd01003',
            key: 'D01003',
          },
          {
            label: 'D01004 - Tugs',
            value: 'd01004',
            key: 'D01004',
          },
          {
            label: 'D01005 - Marine Parts and Spares',
            value: 'd01005',
            key: 'D01005',
          },
        ],
        value: 'd01000',
        key: 'D01000',
      },
      {
        label: 'D02000 - Transport',
        selectable: false,
        children: [
          {
            label: 'D02001 - 3rd party warehousing',
            value: 'd02001',
            key: 'D02001',
          },
          {
            label: 'D02002 - Air freight',
            value: 'd02002',
            key: 'D02002',
          },
          {
            label: 'D02003 - Barge freight',
            value: 'd02003',
            key: 'D02003',
          },
          {
            label: 'D02004 - Bulk Freight',
            value: 'd02004',
            key: 'D02004',
          },
          {
            label: 'D02005 - Container - stuffing / packing',
            value: 'd02005',
            key: 'D02005',
          },
          {
            label: 'D02006 - Driver services leasing',
            value: 'd02006',
            key: 'D02006',
          },
          {
            label: 'D02007 - International freight forwarding',
            value: 'd02007',
            key: 'D02007',
          },
          {
            label: 'D02008 - Marine container',
            value: 'd02008',
            key: 'D02008',
          },
          {
            label: 'D02009 - Marine Port Services',
            value: 'd02009',
            key: 'D02009',
          },
          {
            label: 'D02010 - Parcel freight / Couriers',
            value: 'd02010',
            key: 'D02010',
          },
          {
            label: 'D02011 - Rail freight',
            value: 'd02011',
            key: 'D02011',
          },
          {
            label: 'D02012 - Renting of terminal space',
            value: 'd02012',
            key: 'D02012',
          },
          {
            label: 'D02013 - Road freight',
            value: 'd02013',
            key: 'D02013',
          },
          {
            label: 'D02014 - Road freight on LTL',
            value: 'd02014',
            key: 'D02014',
          },
          {
            label: 'D02015 - Road freight on TL',
            value: 'd02015',
            key: 'D02015',
          },
          {
            label: 'D02016 - Sea freight',
            value: 'd02016',
            key: 'D02016',
          },
          {
            label: 'D02017 - Ship surveyer',
            value: 'd02017',
            key: 'D02017',
          },
        ],
        value: 'd02000',
        key: 'D02000',
      },
    ],
    value: 'd00000',
    key: 'D00000',
  },
  {
    label: 'E00000 - Mining equipment',
    selectable: false,
    children: [
      {
        label: 'E01000 - Drilling & consumables',
        selectable: false,
        children: [
          {
            label: 'E01001 - Drill Bits (heavy)',
            value: 'e01001',
            key: 'E01001',
          },
          {
            label: 'E01002 - Drilling Machine Parts',
            value: 'e01002',
            key: 'E01002',
          },
          {
            label: 'E01003 - Drill Steel & Consumables',
            value: 'e01003',
            key: 'E01003',
          },
          {
            label: 'E01004 - Drilling services',
            value: 'e01004',
            key: 'E01004',
          },
          {
            label: 'E01005 - Drilling machines',
            value: 'e01005',
            key: 'E01005',
          },
          {
            label: 'E01006 - Underground Drill Parts',
            value: 'e01006',
            key: 'E01006',
          },
          {
            label: 'E01007 - Underground Drills',
            value: 'e01007',
            key: 'E01007',
          },
        ],
        value: 'e01000',
        key: 'E01000',
      },
      {
        label: 'E02000 - Mining equipment & Parts',
        selectable: false,
        children: [
          {
            label: 'E02001 - Dozers',
            value: 'e02001',
            key: 'E02001',
          },
          {
            label: 'E02002 - Hydraulic Excavators',
            value: 'e02002',
            key: 'E02002',
          },
          {
            label: 'E02003 - Haul Trucks',
            value: 'e02003',
            key: 'E02003',
          },
          {
            label: 'E02004 - HME Ancillary Equipment',
            value: 'e02004',
            key: 'E02004',
          },
          {
            label: 'E02005 - HME Maintenance services',
            value: 'e02005',
            key: 'E02005',
          },
          {
            label: 'E02006 - Front End Loaders',
            value: 'e02006',
            key: 'E02006',
          },
          {
            label: 'E02007 - Shovels',
            value: 'e02007',
            key: 'E02007',
          },
          {
            label: 'E02008 - Draglines',
            value: 'e02008',
            key: 'E02008',
          },
          {
            label: 'E02009 - Cranes',
            value: 'e02009',
            key: 'E02009',
          },
          {
            label: 'E02010 - Undergroung Mining equipment incl LHD',
            value: 'e02010',
            key: 'E02010',
          },
          {
            label: 'E02011 - HME Ancillary Equipment Parts',
            value: 'e02011',
            key: 'E02011',
          },
          {
            label: 'E02012 - Haul Truck Parts',
            value: 'e02012',
            key: 'E02012',
          },
          {
            label: 'E02013 - Dragline / Rope Shovel Parts',
            value: 'e02013',
            key: 'E02013',
          },
          {
            label: 'E02014 - Industrial Engines',
            value: 'e02014',
            key: 'E02014',
          },
          {
            label: 'E02015 - Industrial Engine Parts',
            value: 'e02015',
            key: 'E02015',
          },
          {
            label: 'E02016 - Drilling machines',
            value: 'e02016',
            key: 'E02016',
          },
          {
            label: 'E02017 - Drilling machine Parts',
            value: 'e02017',
            key: 'E02017',
          },
        ],
        value: 'e02000',
        key: 'E02000',
      },
      {
        label: 'E03000 - Light Mobile Equipment',
        selectable: false,
        children: [
          {
            label: 'E03001 - Light Mobile Equipment',
            value: 'e03001',
            key: 'E03001',
          },
          {
            label: 'E03002 - Light Vehicle leases & purchases',
            value: 'e03002',
            key: 'E03002',
          },
          {
            label: 'E03003 - Light vehicle maintenance',
            value: 'e03003',
            key: 'E03003',
          },
          {
            label: 'E03004 - Mobile equipment (HME / LME) hire',
            value: 'e03004',
            key: 'E03004',
          },
          {
            label: 'E03005 - LME maintenance services',
            value: 'e03005',
            key: 'E03005',
          },
          {
            label: 'E03006 - LME parts',
            value: 'e03006',
            key: 'E03006',
          },
          {
            label: 'E03007 - Off-road passenger vehicles',
            value: 'e03007',
            key: 'E03007',
          },
          {
            label: 'E03008 - On-road passenger vehicles',
            value: 'e03008',
            key: 'E03008',
          },
          {
            label: 'E03009 - Forklifts',
            value: 'e03009',
            key: 'E03009',
          },
        ],
        value: 'e03000',
        key: 'E03000',
      },
      {
        label: 'E04000 - Mining Equipment & Parts',
        selectable: false,
        children: [
          {
            label: 'E04001 - HME Ancillary Equipment Parts',
            value: 'e04001',
            key: 'E04001',
          },
          {
            label: 'E04002 - Haul Truck Parts',
            value: 'e04002',
            key: 'E04002',
          },
          {
            label: 'E04003 - Dragline / Rope Shovel Parts',
            value: 'e04003',
            key: 'E04003',
          },
          {
            label: 'E04004 - Draglines / Rope Shovels',
            value: 'e04004',
            key: 'E04004',
          },
          {
            label: 'E04005 - Heavy Ropes/Dragline',
            value: 'e04005',
            key: 'E04005',
          },
          {
            label: 'E04006 - HME Hire',
            value: 'e04006',
            key: 'E04006',
          },
          {
            label: 'E04007 - Industrial Engines',
            value: 'e04007',
            key: 'E04007',
          },
          {
            label: 'E04008 - Industrial Engine Parts',
            value: 'e04008',
            key: 'E04008',
          },
        ],
        value: 'e04000',
        key: 'E04000',
      },
      {
        label: 'E05000 - Rail',
        selectable: false,
        children: [
          {
            label: 'E05001 - Automatic train operations (ATO)',
            value: 'e05001',
            key: 'E05001',
          },
          {
            label: 'E05002 - Locomotive parts',
            value: 'e05002',
            key: 'E05002',
          },
          {
            label: 'E05003 - Locomotives',
            value: 'e05003',
            key: 'E05003',
          },
          {
            label: 'E05004 - Ore car parts',
            value: 'e05004',
            key: 'E05004',
          },
          {
            label: 'E05005 - Ore Car resheeting',
            value: 'e05005',
            key: 'E05005',
          },
          {
            label: 'E05006 - Ore cars',
            value: 'e05006',
            key: 'E05006',
          },
          {
            label: 'E05007 - Plant Maint Service Rail Track',
            value: 'e05007',
            key: 'E05007',
          },
          {
            label: 'E05008 - Rail Equipment & Components',
            value: 'e05008',
            key: 'E05008',
          },
          {
            label: 'E05009 - Signaling and switch gear',
            value: 'e05009',
            key: 'E05009',
          },
        ],
        value: 'e05000',
        key: 'E05000',
      },
      {
        label: 'E06000 - Tyres & Belting',
        selectable: false,
        children: [
          {
            label: 'E06001 - Light Vehicle & mobile equipment tyres',
            value: 'e06001',
            key: 'E06001',
          },
          {
            label: 'E06002 - OTR tyres for HME',
            value: 'e06002',
            key: 'E06002',
          },
          {
            label: 'E06003 - Wheels & Rims',
            value: 'e06003',
            key: 'E06003',
          },
          {
            label: 'E06004 - Tyre services',
            value: 'e06004',
            key: 'E06004',
          },
          {
            label: 'E06005 - Conveyor belting',
            value: 'e06005',
            key: 'E06005',
          },
        ],
        value: 'e06000',
        key: 'E06000',
      },
      {
        label: 'E07000 - Underground Mining Equipment',
        selectable: false,
        children: [
          {
            label: 'E07001 - Underground Mining equipment incl LHD',
            value: 'e07001',
            key: 'E07001',
          },
        ],
        value: 'e07000 - underground mining equipment',
        key: 'E07000',
      },
      {
        label: 'E08000 - Mining Technology',
        selectable: false,
        children: [
          {
            label: 'E08001 - Equipment technologies',
            value: 'e08001',
            key: 'E08001',
          },
        ],
        value: 'e08000',
        key: 'E08000',
      },
    ],
    value: 'e00000',
    key: 'E00000',
  },
  {
    label: 'F00000 - MRO (maintenance, repair, operationals) & Consumables',
    selectable: false,
    children: [
      {
        label: 'F01000 - General MRO',
        selectable: false,
        children: [
          {
            label: 'F01001 - Abrasives and grinding materials',
            value: 'f01001',
            key: 'F01001',
          },
          {
            label: 'F01002 - Baghouse filtration',
            value: 'f01002',
            key: 'F01002',
          },
          {
            label: 'F01003 - Bearings/Power Transmission',
            value: 'f01003',
            key: 'F01003',
          },
          {
            label: 'F01004 - Bed Filters',
            value: 'f01004',
            key: 'F01004',
          },
          {
            label: 'F01005 - Belting',
            value: 'f01005',
            key: 'F01005',
          },
          {
            label: 'F01006 - Ceramic products',
            value: 'f01006',
            key: 'F01006',
          },
          {
            label: 'F01007 - Clothing/Uniforms',
            value: 'f01007',
            key: 'F01007',
          },
          {
            label: 'F01008 - Compressors',
            value: 'f01008',
            key: 'F01008',
          },
          {
            label: 'F01009 - Cranes and spares',
            value: 'f01009',
            key: 'F01009',
          },
          {
            label: 'F01010 - Fasteners',
            value: 'f01010',
            key: 'F01010',
          },
          {
            label: 'F01011 - Filters/Filter Media and Combo Bags',
            value: 'f01011',
            key: 'F01011',
          },
          {
            label: 'F01012 - Furnace spares',
            value: 'f01012',
            key: 'F01012',
          },
          {
            label: 'F01013 - Gaskets / seals / packing',
            value: 'f01013',
            key: 'F01013',
          },
          {
            label: 'F01014 - Grease/Masonite (Capuchon)',
            value: 'f01014',
            key: 'F01014',
          },
          {
            label: 'F01015 - Ground Engaging Tools (GET)',
            value: 'f01015',
            key: 'F01015',
          },
          {
            label: 'F01016 - Hand tools',
            value: 'f01016',
            key: 'F01016',
          },
          {
            label: 'F01017 - Hoses and fittings',
            value: 'f01017',
            key: 'F01017',
          },
          {
            label: 'F01018 - HVAC parts',
            value: 'f01018',
            key: 'F01018',
          },
          {
            label: 'F01019 - Hydraulic Equipment & Components',
            value: 'f01019',
            key: 'F01019',
          },
          {
            label: 'F01020 - Industrial Supplies',
            value: 'f01020',
            key: 'F01020',
          },
          {
            label: 'F01021 - Industrial/Marine Coatings',
            value: 'f01021',
            key: 'F01021',
          },
          {
            label: 'F01022 - Laboratory Supplies & Maintenance',
            value: 'f01022',
            key: 'F01022',
          },
          {
            label: 'F01023 - Wire Rope / Slings',
            value: 'f01023',
            key: 'F01023',
          },
          {
            label: 'F01024 - Lubricants',
            value: 'f01024',
            key: 'F01024',
          },
          {
            label: 'F01025 - Metals (copper brass steel etc.)',
            value: 'f01025',
            key: 'F01025',
          },
          {
            label: 'F01026 - Pipes/fittings/valves',
            value: 'f01026',
            key: 'F01026',
          },
          {
            label: 'F01027 - Pneumatics',
            value: 'f01027',
            key: 'F01027',
          },
          {
            label: 'F01028 - Power transmission / gearboxes',
            value: 'f01028',
            key: 'F01028',
          },
          {
            label: 'F01029 - Process filters',
            value: 'f01029',
            key: 'F01029',
          },
          {
            label: 'F01030 - Pumps  /Valves / Fittings',
            value: 'f01030',
            key: 'F01030',
          },
          {
            label: 'F01031 - Pumps/compressors/parts',
            value: 'f01031',
            key: 'F01031',
          },
          {
            label: 'F01033 - Rubber/rubber supplies',
            value: 'f01033',
            key: 'F01033',
          },
          {
            label: 'F01034 - Safety & Fire Protection',
            value: 'f01034',
            key: 'F01034',
          },
          {
            label: 'F01035 - Safety consumables',
            value: 'f01035',
            key: 'F01035',
          },
          {
            label: 'F01036 - Sand/gravel',
            value: 'f01036',
            key: 'F01036',
          },
          {
            label: 'F01037 - Stackers / Reclaimers parts',
            value: 'f01037',
            key: 'F01037',
          },
          {
            label: 'F01038 - Steel and specialty metals',
            value: 'f01038',
            key: 'F01038',
          },
          {
            label: 'F01039 - Timber/wood products',
            value: 'f01039',
            key: 'F01039',
          },
          {
            label: 'F01040 - Transmission belts and chains',
            value: 'f01040',
            key: 'F01040',
          },
          {
            label: 'F01041 - Welding equipment and supplies',
            value: 'f01041',
            key: 'F01041',
          },
          {
            label: 'F01042 - Heavy Ropes / Dragline',
            value: 'f01042',
            key: 'F01042',
          },
        ],
        value: 'f01000',
        key: 'F01000',
      },
      {
        label: 'F02000 - Electrical and Instrumentation',
        selectable: false,
        children: [
          {
            label: 'F02001 - Electrical Hardware & Equipment',
            value: 'f02001',
            key: 'F02001',
          },
          {
            label: 'F02002 - Batteries',
            value: 'f02002',
            key: 'F02002',
          },
          {
            label: 'F02003 - Electrical Consumables Supplies',
            value: 'f02003',
            key: 'F02003',
          },
          {
            label: 'F02004 - Instrumentation Equipment & Components',
            value: 'f02004',
            key: 'F02004',
          },
        ],
        value: 'f02000',
        key: 'F02000',
      },
      {
        label: 'F03000 - Lubricants',
        selectable: false,
        children: [
          {
            label: 'F03001 - Greases',
            value: 'f03001',
            key: 'F03001',
          },
          {
            label: 'F03002 - Oils',
            value: 'f03002',
            key: 'F03002',
          },
          {
            label: 'F03003 - Coolants',
            value: 'f03003',
            key: 'F03003',
          },
        ],
        value: 'f03000',
        key: 'F03000',
      },
      {
        label: 'F04000 - Office Consumables',
        selectable: false,
        children: [
          {
            label: 'F04001 - Office furniture',
            value: 'f04001',
            key: 'F04001',
          },
          {
            label: 'F04002 - Office machines and equipment',
            value: 'f04002',
            key: 'F04002',
          },
          {
            label: 'F04003 - Office Supplies',
            value: 'f04003',
            key: 'F04003',
          },
          {
            label: 'F04004 - Publications',
            value: 'f04004',
            key: 'F04004',
          },
        ],
        value: 'f04000',
        key: 'F04000',
      },
    ],
    value: 'f00000',
    key: 'F00000',
  },
  {
    label: 'G00000 - Production Consumables',
    selectable: false,
    children: [
      {
        label: 'G01000 - Packaging for Rio Tinto product sales',
        selectable: false,
        children: [
          {
            label: 'G01001 - Labels & metalized logos',
            value: 'g01001',
            key: 'G01001',
          },
          {
            label: 'G01002 - Packaging',
            value: 'g01002',
            key: 'G01002',
          },
          {
            label: 'G01003 - Steel strapping',
            value: 'g01003',
            key: 'G01003',
          },
          {
            label: 'G01004 - Paints / Marine Coatings',
            value: 'g01004',
            key: 'G01004',
          },
          {
            label: 'G01005 - Film - PE',
            value: 'g01005',
            key: 'G01005',
          },
          {
            label: 'G01006 - Film - Plain',
            value: 'g01006',
            key: 'G01006',
          },
          {
            label: 'G01007 - Film - PVC',
            value: 'g01007',
            key: 'G01007',
          },
          {
            label: 'G01008 - Fiber Glass',
            value: 'g01008',
            key: 'G01008',
          },
          {
            label: 'G01009 - Tissue Paper',
            value: 'g01009',
            key: 'G01009',
          },
          {
            label: 'G01010 - Pallets',
            value: 'g01010',
            key: 'G01010',
          },
          {
            label: 'G01011 - Bulk Bags',
            value: 'g01011',
            key: 'G01011',
          },
          {
            label: 'G01012 - Green Timber / Lumber',
            value: 'g01012',
            key: 'G01012',
          },
        ],
        value: 'g01000',
        key: 'G01000',
      },
      {
        label: 'G02000 - Aluminium and Steel Input Materials',
        selectable: false,
        children: [
          {
            label: 'G02001 - Anode Bar',
            value: 'g02001',
            key: 'G02001',
          },
          {
            label: 'G02002 - Buss bar, collector straps, flexes',
            value: 'g02002',
            key: 'G02002',
          },
          {
            label: 'G02003 - Cathodes',
            value: 'g02003',
            key: 'G02003',
          },
          {
            label: 'G02004 - Collector bars and pot steel',
            value: 'g02004',
            key: 'G02004',
          },
          {
            label: 'G02005 - Copper',
            value: 'g02005',
            key: 'G02005',
          },
          {
            label: 'G02006 - Grain refiners',
            value: 'g02006',
            key: 'G02006',
          },
          {
            label: 'G02007 - Hardeners',
            value: 'g02007',
            key: 'G02007',
          },
          {
            label: 'G02008 - Magnesium',
            value: 'g02008',
            key: 'G02008',
          },
          {
            label: 'G02009 - Manganese',
            value: 'g02009',
            key: 'G02009',
          },
          {
            label: 'G02010 - Nickel',
            value: 'g02010',
            key: 'G02010',
          },
          {
            label: 'G02011 - Pure metals',
            value: 'g02011',
            key: 'G02011',
          },
          {
            label: 'G02012 - Silicon',
            value: 'g02012',
            key: 'G02012',
          },
          {
            label: 'G02013 - Steel rods',
            value: 'g02013',
            key: 'G02013',
          },
          {
            label: 'G02014 - Steel shot and steel balls',
            value: 'g02014',
            key: 'G02014',
          },
        ],
        value: 'g02000',
        key: 'G02000',
      },
      {
        label: 'G03000 - Consumables',
        selectable: false,
        children: [
          {
            label: 'G03001 - Process consumables',
            value: 'g03001',
            key: 'G03001',
          },
          {
            label: 'G03002 - Cast house consumables',
            value: 'g03002',
            key: 'G03002',
          },
          {
            label: 'G03003 - Castings',
            value: 'g03003',
            key: 'G03003',
          },
          {
            label: 'G03004 - Cylinders / plates',
            value: 'g03004',
            key: 'G03004',
          },
          {
            label: 'G03005 - Dross tolling',
            value: 'g03005',
            key: 'G03005',
          },
          {
            label: 'G03006 - Felts',
            value: 'g03006',
            key: 'G03006',
          },
          {
            label: 'G03007 - Steel for extrusion dies',
            value: 'g03007',
            key: 'G03007',
          },
          {
            label: 'G03008 - Production Thermocouples',
            value: 'g03008',
            key: 'G03008',
          },
          {
            label: 'G03009 - Rolling oil & additives',
            value: 'g03009',
            key: 'G03009',
          },
          {
            label: 'G03010 - Buss bar, collector straps, flexes',
            value: 'g03010',
            key: 'G03010',
          },
          {
            label: 'G03011 - Collector bars and pot steel',
            value: 'g03011',
            key: 'G03011',
          },
          {
            label: 'G03012 - Copper',
            value: 'g03012',
            key: 'G03012',
          },
          {
            label: 'G03013 - Grain refiners',
            value: 'g03013',
            key: 'G03013',
          },
          {
            label: 'G03014 - Hardeners',
            value: 'g03014',
            key: 'G03014',
          },
          {
            label: 'G03015 - Nickel',
            value: 'g03015',
            key: 'G03015',
          },
          {
            label: 'G03016 - Pure metals',
            value: 'g03016',
            key: 'G03016',
          },
          {
            label: 'G03017 - Steel rods',
            value: 'g03017',
            key: 'G03017',
          },
          {
            label: 'G03018 - Steel shot and steel balls',
            value: 'g03018',
            key: 'G03018',
          },
        ],
        value: 'g03000',
        key: 'G03000',
      },
      {
        label: 'G04000 - Explosives',
        selectable: false,
        children: [
          {
            label: 'G04001 - Ammonium nitrate & bulk explosives',
            value: 'g04001',
            key: 'G04001',
          },
          {
            label: 'G04002 - Explosives Accessories',
            value: 'g04002',
            key: 'G04002',
          },
          {
            label: 'G04003 - Packaged explosives',
            value: 'g04003',
            key: 'G04003',
          },
          {
            label: 'G04004 - Drill and Blast Services',
            value: 'g04004',
            key: 'G04004',
          },
        ],
        value: 'g04000',
        key: 'G04000',
      },
      {
        label: 'G05000 - Process Chemicals',
        selectable: false,
        children: [
          {
            label: 'G05001 - Acid',
            value: 'g05001',
            key: 'G05001',
          },
          {
            label: 'G05002 - Chlorine',
            value: 'g05002',
            key: 'G05002',
          },
          {
            label: 'G05003 - Degreasing and Cleaning',
            value: 'g05003',
            key: 'G05003',
          },
          {
            label: 'G05004 - Ethanol',
            value: 'g05004',
            key: 'G05004',
          },
          {
            label: 'G05005 - Ferro silicon',
            value: 'g05005',
            key: 'G05005',
          },
          {
            label: 'G05006 - Flocculants',
            value: 'g05006',
            key: 'G05006',
          },
          {
            label: 'G05007 - Industrial Gases',
            value: 'g05007',
            key: 'G05007',
          },
          {
            label: 'G05008 - Inks',
            value: 'g05008',
            key: 'G05008',
          },
          {
            label: 'G05009 - Lime',
            value: 'g05009',
            key: 'G05009',
          },
          {
            label: 'G05010 - Metal Pre-treat/Lacquers',
            value: 'g05010',
            key: 'G05010',
          },
          {
            label: 'G05011 - Organic',
            value: 'g05011',
            key: 'G05011',
          },
          {
            label: 'G05012 - Other Process Chemicals',
            value: 'g05012',
            key: 'G05012',
          },
          {
            label: 'G05013 - Oxygen',
            value: 'g05013',
            key: 'G05013',
          },
          {
            label: 'G05014 - Resins',
            value: 'g05014',
            key: 'G05014',
          },
          {
            label: 'G05015 - Sealants',
            value: 'g05015',
            key: 'G05015',
          },
          {
            label: 'G05016 - Solvents',
            value: 'g05016',
            key: 'G05016',
          },
          {
            label: 'G05017 - Water - industrial',
            value: 'g05017',
            key: 'G05017',
          },
          {
            label: 'G05018 - Wax',
            value: 'g05018',
            key: 'G05018',
          },
          {
            label: 'G05020 - Graphite',
            value: 'g05020',
            key: 'G05020',
          },
          {
            label: 'G05021 - Graphite tubes',
            value: 'g05021',
            key: 'G05021',
          },
          {
            label: 'G05022 - Iron',
            value: 'g05022',
            key: 'G05022',
          },
          {
            label: 'G05023 - Lithium carbonate',
            value: 'g05023',
            key: 'G05023',
          },
          {
            label: 'G05024 - Magnesium oxide',
            value: 'g05024',
            key: 'G05024',
          },
          {
            label: 'G05025 - Metallurgical Coal',
            value: 'g05025',
            key: 'G05025',
          },
          {
            label: 'G05026 - Soda ash',
            value: 'g05026',
            key: 'G05026',
          },
          {
            label: 'G05027 - Bone Ash',
            value: 'g05027',
            key: 'G05027',
          },
        ],
        value: 'g05000',
        key: 'G05000',
      },
      {
        label: 'G06000 - Raw Materials',
        selectable: false,
        children: [
          {
            label: 'G06001 - Alloys',
            value: 'g06001',
            key: 'G06001',
          },
          {
            label: 'G06002 - Alumina',
            value: 'g06002',
            key: 'G06002',
          },
          {
            label: 'G06003 - Aluminium Flouride',
            value: 'g06003',
            key: 'G06003',
          },
          {
            label: 'G06004 - Anthracite',
            value: 'g06004',
            key: 'G06004',
          },
          {
            label: 'G06005 - Bath / Cryolite',
            value: 'g06005',
            key: 'G06005',
          },
          {
            label: 'G06006 - Bauxite',
            value: 'g06006',
            key: 'G06006',
          },
          {
            label: 'G06007 - Bone Ash',
            value: 'g06007',
            key: 'G06007',
          },
          {
            label: 'G06008 - Coke (Petroleum) â€“ Calcined (CPC)',
            value: 'g06008',
            key: 'G06008',
          },
          {
            label: 'G06009 - Carbon',
            value: 'g06009',
            key: 'G06009',
          },
          {
            label: 'G06010 - Caustic Soda',
            value: 'g06010',
            key: 'G06010',
          },
          {
            label: 'G06011 - Coke (metallurgical)',
            value: 'g06011',
            key: 'G06011',
          },
          {
            label: 'G06012 - Coke (Petroleum) â€“ Green (GPC)',
            value: 'g06012',
            key: 'G06012',
          },
          {
            label: 'G06013 - Electrodes',
            value: 'g06013',
            key: 'G06013',
          },
          {
            label: 'G06014 - Fluorspar',
            value: 'g06014',
            key: 'G06014',
          },
          {
            label: 'G06015 - Graphite',
            value: 'g06015',
            key: 'G06015',
          },
          {
            label: 'G06016 - Graphite tubes',
            value: 'g06016',
            key: 'G06016',
          },
          {
            label: 'G06017 - Green Coke',
            value: 'g06017',
            key: 'G06017',
          },
          {
            label: 'G06018 - Green Timber / Lumber',
            value: 'g06018',
            key: 'G06018',
          },
          {
            label: 'G06019 - Hydrated lime',
            value: 'g06019',
            key: 'G06019',
          },
          {
            label: 'G06020 - Iron',
            value: 'g06020',
            key: 'G06020',
          },
          {
            label: 'G06021 - Coal Tar Pitch',
            value: 'g06021',
            key: 'G06021',
          },
          {
            label: 'G06022 - Liquid Pitch',
            value: 'g06022',
            key: 'G06022',
          },
          {
            label: 'G06023 - Lithium carbonate',
            value: 'g06023',
            key: 'G06023',
          },
          {
            label: 'G06024 - Magnesium oxide',
            value: 'g06024',
            key: 'G06024',
          },
          {
            label: 'G06025 - Metallurgical Coal',
            value: 'g06025',
            key: 'G06025',
          },
          {
            label: 'G06026 - Ramming Paste',
            value: 'g06026',
            key: 'G06026',
          },
          {
            label: 'G06027 - Refractories & Refractory Bricks',
            value: 'g06027',
            key: 'G06027',
          },
          {
            label: 'G06028 - Refractory Services',
            value: 'g06028',
            key: 'G06028',
          },
          {
            label: 'G06029 - Smelter Technology',
            value: 'g06029',
            key: 'G06029',
          },
          {
            label: 'G06030 - Soda ash',
            value: 'g06030',
            key: 'G06030',
          },
          {
            label: 'G06031 - Cathodes',
            value: 'g06031',
            key: 'G06031',
          },
          {
            label: 'G06032 - Magnesium',
            value: 'g06032',
            key: 'G06032',
          },
          {
            label: 'G06033 - Manganese',
            value: 'g06033',
            key: 'G06033',
          },
          {
            label: 'G06034 - Silicon',
            value: 'g06034',
            key: 'G06034',
          },
        ],
        value: 'g06000',
        key: 'G06000',
      },
    ],
    value: 'g00000',
    key: 'G00000',
  },
  {
    label: 'H00000 - Services',
    children: [
      {
        label: 'H01000 - Ancillary Services',
        selectable: false,
        children: [
          {
            label: 'H01001 - Component monitoring contractors',
            value: 'h01001',
            key: 'H01001',
          },
          {
            label: 'H01002 - Contract Labor-Outside Services',
            value: 'h01002',
            key: 'H01002',
          },
          {
            label: 'H01003 - Employee transport services',
            value: 'h01003',
            key: 'H01003',
          },
          {
            label: 'H01004 - Equipment Hire',
            value: 'h01004',
            key: 'H01004',
          },
          {
            label: 'H01005 - Fabrication',
            value: 'h01005',
            key: 'H01005',
          },
          {
            label: 'H01006 - Foundries',
            value: 'h01006',
            key: 'H01006',
          },
          {
            label: 'H01007 - Instrumentation',
            value: 'h01007',
            key: 'H01007',
          },
          {
            label: 'H01008 - Laboratory',
            value: 'h01008',
            key: 'H01008',
          },
          {
            label: 'H01009 - Machining+B147',
            value: 'h01009',
            key: 'H01009',
          },
          {
            label: 'H01010 - Marine Contractors',
            value: 'h01010',
            key: 'H01010',
          },
          {
            label: 'H01011 - Medical services',
            value: 'h01011',
            key: 'H01011',
          },
          {
            label: 'H01012 - Metal fabrication services',
            value: 'h01012',
            key: 'H01012',
          },
          {
            label: 'H01013 - Office equipment maintenance services',
            value: 'h01013',
            key: 'H01013',
          },
          {
            label: 'H01014 - Port Charges',
            value: 'h01014',
            key: 'H01014',
          },
          {
            label: 'H01015 - Printing services',
            value: 'h01015',
            key: 'H01015',
          },
          {
            label: 'H01016 - Road maintenance contractors',
            value: 'h01016',
            key: 'H01016',
          },
          {
            label: 'H01017 - Sampling / Testing Contractors',
            value: 'h01017',
            key: 'H01017',
          },
          {
            label: 'H01018 - Screen Print',
            value: 'h01018',
            key: 'H01018',
          },
          {
            label: 'H01019 - Shipping Agents',
            value: 'h01019',
            key: 'H01019',
          },
          {
            label: 'H01020 - Site Services Other',
            value: 'h01020',
            key: 'H01020',
          },
          {
            label: 'H01021 - Steepljacks',
            value: 'h01021',
            key: 'H01021',
          },
          {
            label: 'H01022 - Surveying',
            value: 'h01022',
            key: 'H01022',
          },
          {
            label: 'H01023 - Tolling',
            value: 'h01023',
            key: 'H01023',
          },
          {
            label: 'H01024 - Water treatment materials',
            value: 'h01024',
            key: 'H01024',
          },
        ],
        value: 'h01000',
        key: 'H01000',
      },
      {
        label: 'H02000 - Bulk materials',
        selectable: false,
        children: [
          {
            label: 'H02001 - Pipeline Tolling Service',
            value: 'h02001',
            key: 'H02001',
          },
        ],
        value: 'h02000',
        key: 'H02000',
      },
      {
        label: 'H03000 - Civil & Mining Services',
        selectable: false,
        children: [
          {
            label: 'H03001 - Civil Contractors',
            value: 'h03001',
            key: 'H03001',
          },
          {
            label: 'H03002 - Explosives Services',
            value: 'h03002',
            key: 'H03002',
          },
          {
            label: 'H03003 - Mining Contractors',
            value: 'h03003',
            key: 'H03003',
          },
        ],
        value: 'h03000',
        key: 'H03000',
      },
      {
        label: 'H04000 - Facilities & Site Management',
        selectable: false,
        children: [
          {
            label: 'H04001 - Building lease',
            value: 'h04001',
            key: 'H04001',
          },
          {
            label: 'H04002 - Building maintenance, plumb/elect service',
            value: 'h04002',
            key: 'H04002',
          },
          {
            label: 'H04003 - Catering',
            value: 'h04003',
            key: 'H04003',
          },
          {
            label: 'H04004 - Fuel terminal management and maintenance services',
            value: 'h04004',
            key: 'H04004',
          },
          {
            label: 'H04005 - Grounds Maintenance services',
            value: 'h04005',
            key: 'H04005',
          },
          {
            label: 'H04006 - Janitorial services',
            value: 'h04006',
            key: 'H04006',
          },
          {
            label: 'H04007 - Laundry services',
            value: 'h04007',
            key: 'H04007',
          },
          {
            label: 'H04008 - Property leases / rentals',
            value: 'h04008',
            key: 'H04008',
          },
          {
            label: 'H04009 - Property Purchases',
            value: 'h04009',
            key: 'H04009',
          },
          {
            label: 'H04010 - Safety and Fire Protection services',
            value: 'h04010',
            key: 'H04010',
          },
          {
            label: 'H04011 - Security services',
            value: 'h04011',
            key: 'H04011',
          },
          {
            label: 'H04012 - Site Civil works',
            value: 'h04012',
            key: 'H04012',
          },
          {
            label: 'H04013 - Waste removal, disposal & recycling',
            value: 'h04013',
            key: 'H04013',
          },
          {
            label: 'H04014 - Water supply - domestic',
            value: 'h04014',
            key: 'H04014',
          },
        ],
        value: 'h04000',
        key: 'H04000',
      },
      {
        label: 'H05000 - IT',
        selectable: false,
        children: [
          {
            label: 'H05001 - Hardware equipment maintenance',
            value: 'h05001',
            key: 'H05001',
          },
          {
            label: 'H05002 - Hardware Leasing',
            value: 'h05002',
            key: 'H05002',
          },
          {
            label: 'H05003 - Hardware purchase',
            value: 'h05003',
            key: 'H05003',
          },
          {
            label: 'H05004 - IT Services',
            value: 'h05004',
            key: 'H05004',
          },
          {
            label: 'H05005 - Land Telecommunications',
            value: 'h05005',
            key: 'H05005',
          },
          {
            label: 'H05006 - Mobile Telecommunications',
            value: 'h05006',
            key: 'H05006',
          },
          {
            label: 'H05007 - PC, Software and Related',
            value: 'h05007',
            key: 'H05007',
          },
          {
            label: 'H05008 - Software',
            value: 'h05008',
            key: 'H05008',
          },
          {
            label: 'H05009 - Telecommunications',
            value: 'h05009',
            key: 'H05009',
          },
        ],
        value: 'h05000',
        key: 'H05000',
      },
      {
        label: 'H06000 - Maintenance Services',
        selectable: false,
        children: [
          {
            label: 'H06001 - Automotive Maintenance',
            value: 'h06001',
            key: 'H06001',
          },
          {
            label: 'H06002 - Cranage Contractors',
            value: 'h06002',
            key: 'H06002',
          },
          {
            label: 'H06003 - Crushing & Mill Contractors',
            value: 'h06003',
            key: 'H06003',
          },
          {
            label: 'H06004 - Elec trans/cabling contractors',
            value: 'h06004',
            key: 'H06004',
          },
          {
            label: 'H06005 - Furnace repair/maintenance',
            value: 'h06005',
            key: 'H06005',
          },
          {
            label: 'H06006 - Handling & Moving Equipment Maintenance',
            value: 'h06006',
            key: 'H06006',
          },
          {
            label: 'H06007 - HVAC Maintenance',
            value: 'h06007',
            key: 'H06007',
          },
          {
            label: 'H06008 - Maintenance Services',
            value: 'h06008',
            key: 'H06008',
          },
          {
            label: 'H06009 - Plant Maint Service Electrical',
            value: 'h06009',
            key: 'H06009',
          },
          {
            label: 'H06010 - Plant Maint Service Hydraulic',
            value: 'h06010',
            key: 'H06010',
          },
          {
            label: 'H06011 - Plant Maint Service Mechanical',
            value: 'h06011',
            key: 'H06011',
          },
          {
            label: 'H06012 - Plant Maint Service Water Treatment',
            value: 'h06012',
            key: 'H06012',
          },
          {
            label: 'H06013 - Plumbing Maintenance',
            value: 'h06013',
            key: 'H06013',
          },
          {
            label: 'H06014 - Temp Labor/Industrial',
            value: 'h06014',
            key: 'H06014',
          },
          {
            label: 'H06015 - Transmission / cabling contractors',
            value: 'h06015',
            key: 'H06015',
          },
          {
            label: 'H06016 - Refractory Services',
            value: 'h06016',
            key: 'H06016',
          },
        ],
        value: 'h06000',
        key: 'H06000',
      },
      {
        label: 'H07000 - Support Services',
        selectable: false,
        children: [
          {
            label: 'H07001 - Underground Mining Contractors',
            value: 'h07001',
            key: 'H07001',
          },
        ],
        value: 'h07000',
        key: 'H07000',
      },
      {
        label: 'H08000 - Professional Services',
        selectable: false,
        children: [
          {
            label: 'H08001 - Accounting/financial/audit/tax Service',
            value: 'h08001',
            key: 'H08001',
          },
          {
            label: 'H08002 - Advertising/Marketing Service',
            value: 'h08002',
            key: 'H08002',
          },
          {
            label: 'H08003 - Banking Services',
            value: 'h08003',
            key: 'H08003',
          },
          {
            label: 'H08004 - Business & General Consulting',
            value: 'h08004',
            key: 'H08004',
          },
          {
            label: 'H08005 - Consulting Services',
            value: 'h08005',
            key: 'H08005',
          },
          {
            label: 'H08006 - Customs Clearance & brokerage',
            value: 'h08006',
            key: 'H08006',
          },
          {
            label: 'H08007 - Engineering, technical or consulting srvc',
            value: 'h08007',
            key: 'H08007',
          },
          {
            label: 'H08008 - Environmental, Health/Safety consulting',
            value: 'h08008',
            key: 'H08008',
          },
          {
            label: 'H08009 - Health Insurance',
            value: 'h08009',
            key: 'H08009',
          },
          {
            label: 'H08010 - HR Services / Benefits',
            value: 'h08010',
            key: 'H08010',
          },
          {
            label: 'H08011 - Insurance',
            value: 'h08011',
            key: 'H08011',
          },
          {
            label: 'H08012 - IT Consulting',
            value: 'h08012',
            key: 'H08012',
          },
          {
            label: 'H08013 - Legal Services',
            value: 'h08013',
            key: 'H08013',
          },
          {
            label: 'H08014 - Market Research',
            value: 'h08014',
            key: 'H08014',
          },
          {
            label: 'H08015 - Physicians Fees',
            value: 'h08015',
            key: 'H08015',
          },
          {
            label: 'H08016 - Outsourcing Services',
            value: 'h08016',
            key: 'H08016',
          },
          {
            label: 'H08017 - Research and Development',
            value: 'h08017',
            key: 'H08017',
          },
          {
            label: 'H08018 - Risk Management Services',
            value: 'h08018',
            key: 'H08018',
          },
          {
            label: 'H08019 - Services for External Relations',
            value: 'h08019',
            key: 'H08019',
          },
          {
            label: 'H08020 - Temp Labor/Administration',
            value: 'h08020',
            key: 'H08020',
          },
          {
            label: 'H08021 - Training and Education - Fees',
            value: 'h08021',
            key: 'H08021',
          },
          {
            label: 'H08022 - Training Services',
            value: 'h08022',
            key: 'H08022',
          },
        ],
        value: 'h08000',
        key: 'H08000',
      },
      {
        label: 'H09000 - Support Services',
        selectable: false,
        children: [
          {
            label: 'H09001 - Site Contractors - electrical/mechanical',
            value: 'h09001',
            key: 'H09001',
          },
          {
            label: 'H09002 - Supplementary Labour',
            value: 'h09002',
            key: 'H09002',
          },
        ],
        value: 'h09000',
        key: 'H0900a',
      },
      {
        label: 'H10000 - Travel',
        selectable: false,
        children: [
          {
            label: 'H10001 - Air Travel - RPT',
            value: 'h10001',
            key: 'H10001',
          },
          {
            label: 'H10002 - Air Travel - Scheduled Charter',
            value: 'h10002',
            key: 'H10002',
          },
          {
            label: 'H10003 - Car Hire / Rental',
            value: 'h10003',
            key: 'H10003',
          },
          {
            label: 'H10004 - Hotel / Accommodation',
            value: 'h10004',
            key: 'H10004',
          },
          {
            label: 'H10005 - Meals',
            value: 'h10005',
            key: 'H10005',
          },
          {
            label: 'H10006 - Taxi / Bus / Train',
            value: 'h10006',
            key: 'H10006',
          },
          {
            label: 'H10007 - Travel',
            value: 'h10007',
            key: 'H10007',
          },
          {
            label: 'H10008 - Travel Agency Services',
            value: 'h10008',
            key: 'H10008',
          },
        ],
        value: 'h10000',
        key: 'H10000',
      },
    ],
    value: 'h00000',
    key: 'H00000',
  },
];

const mn = [
  {
    label: 'A00000 - Барилга угсралт',
    selectable: false,
    children: [
      {
        label: 'A01000 - Барилга угсралтын гэрээт компани',
        children: [
          {
            label:
              'A01001 - ЗТХБУМ-ийн томоохон гэрээт ажлууд (100 саяас ам.долллараас дээших төслүүд)',
            value: 'a01001',
            key: 'A01001',
          },
          {
            label: 'A01002 - ЗТХБУМ-ийн жижиг гэрээт ажлууд (100 сая ам.долллараас дооших)',
            value: 'a01002',
            key: 'A01002',
          },
        ],
        value: 'a01000',
        key: 'A01000',
      },
      {
        label: 'A02000 - Барилга угсралтын үйлчилгээ',
        selectable: false,
        children: [
          {
            label: 'A02001 - Барилга угсралт, засал чимэглэлийн ажил',
            value: 'a02001',
            key: 'A02001',
          },
          {
            label: 'A02002 - Барилгын материал',
            value: 'a02002',
            key: 'A02002',
          },
          {
            label: 'A02003 - Барилга - Угсармал болон түр барилгууд',
            value: 'a02003',
            key: 'A02003',
          },
          {
            label: 'A02004 - Кабель, утас, туслах материалууд',
            value: 'a02004',
            key: 'A02004',
          },
          {
            label: 'A02005 - Цемент, дүүргэгч, бетон зуурмаг',
            value: 'a02005',
            key: 'A02005',
          },
          {
            label: 'A02006 - Хийц бүтээц, барилга угсралтын гэрээт гүйцэтгэгчид',
            value: 'a02006',
            key: 'A02006',
          },
          {
            label: 'A02007 - Барилгын материал',
            value: 'a02007',
            key: 'A02007',
          },
          {
            label: 'A02008 - Барилгын тусгай тоног төхөөрөмж',
            value: 'a02008',
            key: 'A02008',
          },
          {
            label: 'A02009 - Хэлхээ ба араан дамжуулгын иж бүрдэл',
            value: 'a02009',
            key: 'A02009',
          },
          {
            label: 'A02010 - Тээвэрлэлтийн үйлчилгээ',
            value: 'a02010',
            key: 'A02010',
          },
          {
            label: 'A02011 - Цахилгаан ба нарийн хэмжүүрийн гэрээт гүйцэтгэгч',
            value: 'a02011',
            key: 'A02011',
          },
          {
            label: 'A02012 - Цахилгаан тоног төхөөрөмж, сэлбэг хэрэгсэл',
            value: 'a02012',
            key: 'A02012',
          },
          {
            label: 'A02013 - Мотор, генератор',
            value: 'a02013',
            key: 'A02013',
          },
          {
            label: 'A02014 - Агааржуулалтын сэнс',
            value: 'a02014',
            key: 'A02014',
          },
          {
            label: 'A02015 - Ачаа бараа зөөх үйлчилгээ',
            value: 'a02015',
            key: 'A02015',
          },
          {
            label: 'A02016 - Дулааны болон дулаан солилцуурын тоног төхөөрөмж',
            value: 'a02016',
            key: 'A02016',
          },
          {
            label: 'A02017 - ХСАС болон барилгын дотор дэд бүтцийн тоног төхөөрөмж',
            value: 'a02017',
            key: 'A02017',
          },
          {
            label: 'A02018 - Нарийн хэмжүүр, автоматжуулалт',
            value: 'a02018',
            key: 'A02018',
          },
          {
            label: 'A02019 - Ачаа бараах зөөх тоног төхөөрөмж',
            value: 'a02019',
            key: 'A02019',
          },
          {
            label: 'A02020 - Барилгын дотор дэд бүтцийн угсралт суурилуулалтын гэрээт гүйцэтгэгч',
            value: 'a02020',
            key: 'A02020',
          },
          {
            label: 'A02021 - Шугам хоолойн угсралт суурилуулалт',
            value: 'a02021',
            key: 'A02021',
          },
          {
            label: 'A02022 - Технологийн тоног төхөөрөмж, иж бүрдэл',
            value: 'a02022',
            key: 'A02022',
          },
          {
            label: 'A02023 - Ган хийц, бэлдэц',
            value: 'a02023',
            key: 'A02023',
          },
          {
            label: 'A02024 - Танк, сав, доторлогоо',
            value: 'a02024',
            key: 'A02024',
          },
          {
            label: 'A02025 - Турбин',
            value: 'a02025',
            key: 'A02025',
          },
          {
            label: 'A02026 - Хаалт, тохируулгатай хаалт',
            value: 'a02026',
            key: 'A02026',
          },
          {
            label: 'A02027 - Агуулахын үйлчилгээ',
            value: 'a02027',
            key: 'A02027',
          },
        ],
        value: 'a02000',
        key: 'A02000',
      },
    ],
    value: 'a00000',
    key: 'A00000',
  },
  {
    label: 'B00000 - Эрчим хүч',
    selectable: false,
    children: [
      {
        label: 'B01000 - Уламжлалт бус эрчим хүч',
        children: [
          {
            label: 'B01001 - Даралтат хий',
            value: 'b01001',
            key: 'B01001',
          },
          {
            label: 'B01002 - Даралтат уур',
            value: 'b01002',
            key: 'B01002',
          },
          {
            label: 'B01003 - Дулааны нүүрс',
            value: 'b01003',
            key: 'B01003',
          },
          {
            label: 'B01004 - Дотоодын хий',
            value: 'b01004',
            key: 'B01004',
          },
        ],
        value: 'b01000',
        key: 'B01000',
      },
      {
        label: 'B02000 - Түлш шатахуун',
        selectable: false,
        children: [
          {
            label: 'B02001 - Дизель түлш',
            value: 'b02001',
            key: 'B02001',
          },
          {
            label: 'B02002 - Мазут',
            value: 'b02002',
            key: 'B02002',
          },
          {
            label: 'B02003 - Байгалийн хий',
            value: 'b02003',
            key: 'B02003',
          },
          {
            label: 'B02004 - Бензин',
            value: 'b02004',
            key: 'B02004',
          },
          {
            label: 'B02005 - Пропан (LPG)',
            value: 'b02005',
            key: 'B02005',
          },
          {
            label: 'B02006 - Дулааны нүүрс',
            value: 'b02006',
            key: 'B02006',
          },
          {
            label: 'B02007 - Био түлш',
            value: 'b02007',
            key: 'B02007',
          },
          {
            label: 'B02008 - Шингэн хий',
            value: 'b02008',
            key: 'B02008',
          },
          {
            label: 'B02009 - Түлшний терминал менежмент/засвар үйлчилгээ',
            value: 'b02009',
            key: 'B02009',
          },
        ],
        value: 'b02000',
        key: 'B02000',
      },
      {
        label: 'B03000 - Цахилгаан эрчим хүч',
        selectable: false,
        children: [
          {
            label: 'B03001 - Цахилгаан эрчим хүч - Дотоодын',
            value: 'b03001',
            key: 'B03001',
          },
          {
            label: 'B03002 - Цахилгаан эрчим хүч - Үйлдвэрлэлийн',
            value: 'b03002',
            key: 'B03002',
          },
          {
            label: 'B03003 - Цахилгаан дамжуулалт',
            value: 'b03003',
            key: 'B03003',
          },
        ],
        value: 'b03000',
        key: 'B03000',
      },
    ],
    value: 'b00000',
    key: 'B00000',
  },
  {
    label: 'C00000 - Суурин үйлдвэр, тоног төхөөрөмж',
    selectable: false,
    children: [
      {
        label: 'C01000 - Материал тээвэрлэлт',
        selectable: false,
        children: [
          {
            label: 'C01001 - Олон хутгуурт экскаватор',
            value: 'c01001',
            key: 'C01001',
          },
          {
            label: 'C01002 - Конвейер беллинг',
            value: 'c01002',
            key: 'C01002',
          },
          {
            label: 'C01003 - Дамжуургын тоног төхөөрөмж, хангамжийн зүйлс',
            value: 'c01003',
            key: 'C01003',
          },
          {
            label: 'C01004 - Овоолгоос буцаан татах төхөөрөмж',
            value: 'c01004',
            key: 'C01004',
          },
          {
            label: 'C01005 - Ачих төхөөрөмж',
            value: 'c01005',
            key: 'C01005',
          },
          {
            label: 'C01006 - Овоолго хийх, овоолгоос буцаан татах төхөөрөмж',
            value: 'c01006',
            key: 'C01006',
          },
        ],
        value: 'c01000',
        key: 'C01000',
      },
      {
        label: 'C02000 - Материал боловсруулалт',
        selectable: false,
        children: [
          {
            label: 'C02001 - Бутлуур, тээрэм',
            value: 'c02001',
            key: 'C02001',
          },
          {
            label: 'C02002 - Бутлуурын хэрэглээний зүйлс, тээрэмдэх биет',
            value: 'c02002',
            key: 'C02002',
          },
          {
            label: 'C02003 - Тээрэмпэх биетийг ялгах шигшүүрийн сэлбэг хэрэгсэл',
            value: 'c02003',
            key: 'C02003',
          },
          {
            label: 'C02004 - Технологийн процессын тоног төхөөрөмж',
            value: 'c02004',
            key: 'C02004',
          },
          {
            label: 'C02005 - Шүүр',
            value: 'c02005',
            key: 'C02005',
          },
        ],
        value: 'c02000',
        key: 'C02000',
      },
      {
        label: 'C03000 - Төмөр зам',
        selectable: false,
        children: [
          {
            label: 'C03001 - Төмөр зам - зам төмөр, дэр мод, хайрган суурь зэрэг суурин дэд бүтэц',
            value: 'c03001',
            key: 'C03001',
          },
        ],
        value: 'c03000',
        key: 'C03000',
      },
      {
        label: 'C04000 - Хайлуулалтын тоног төхөөрөмж',
        selectable: false,
        children: [
          {
            label: 'C04001 - Хайлах тогоо, дээд анги',
            value: 'c04001',
            key: 'C04001',
          },
          {
            label: 'C04002 - Хайлах тогооны шулуутгагч',
            value: 'c04002',
            key: 'C04002',
          },
        ],
        value: 'c04000',
        key: 'C04000',
      },
    ],
    value: 'c00000',
    key: 'C00000',
  },
  {
    label: 'D00000 - Ложистик',
    children: [
      {
        label: 'D01000 - Далайн тээврийн тоног төхөөрөмж',
        selectable: false,
        children: [
          {
            label: 'D01001 - Чиргүүл онгоц',
            value: 'd01001',
            key: 'D01001',
          },
          {
            label: 'D01002 - Далайн тээврийн онгоц',
            value: 'd01002',
            key: 'D01002',
          },
          {
            label: 'D01003 - Хүдэр тээвэрлэгч',
            value: 'd01003',
            key: 'D01003',
          },
          {
            label: 'D01004 - Чирэгч онгоц',
            value: 'd01004',
            key: 'D01004',
          },
          {
            label: 'D01005 - далайн тээврийн эд анги, сэлбэг хэрэгсэл',
            value: 'd01005',
            key: 'D01005',
          },
        ],
        value: 'd01000',
        key: 'D01000',
      },
      {
        label: 'D02000 - Тээвэр',
        selectable: false,
        children: [
          {
            label: 'D02001 - Гуравдагч этгээдийн агуулахын үйлчилгээ',
            value: 'd02001',
            key: 'D02001',
          },
          {
            label: 'D02002 - Агаарын тээвэр',
            value: 'd02002',
            key: 'D02002',
          },
          {
            label: 'D02003 - Далайн тээвэр',
            value: 'd02003',
            key: 'D02003',
          },
          {
            label: 'D02004 - Задгай ачааны тээвэр',
            value: 'd02004',
            key: 'D02004',
          },
          {
            label: 'D02005 - Чингэлэг тээвэр - Ачих/Баглах',
            value: 'd02005',
            key: 'D02005',
          },
          {
            label: 'D02006 - Гэрээт жолоочийн үйлчилгээ',
            value: 'd02006',
            key: 'D02006',
          },
          {
            label: 'D02007 - Олон улсын тээвэр зуучийн үйлчилгээ',
            value: 'd02007',
            key: 'D02007',
          },
          {
            label: 'D02008 - Далайн чингэлэг тээвэр',
            value: 'd02008',
            key: 'D02008',
          },
          {
            label: 'D02009 - Далайн боомтын үйлчилгээ',
            value: 'd02009',
            key: 'D02009',
          },
          {
            label: 'D02010 - Илгээмжийн үйлчилгээ',
            value: 'd02010',
            key: 'D02010',
          },
          {
            label: 'D02011 - Төмөр замын тээвэр',
            value: 'd02011',
            key: 'D02011',
          },
          {
            label: 'D02012 - Терминалийн талбайн түрээсийн үйлчилгээ',
            value: 'd02012',
            key: 'D02012',
          },
          {
            label: 'D02013 - Авто тээвэр',
            value: 'd02013',
            key: 'D02013',
          },
          {
            label: 'D02014 - Авто тээвэр - жижиг ачаа',
            value: 'd02014',
            key: 'D02014',
          },
          {
            label: 'D02015 - Авто тээвэр - том ачаа',
            value: 'd02015',
            key: 'D02015',
          },
          {
            label: 'D02016 - Далайн тээвэр',
            value: 'd02016',
            key: 'D02016',
          },
          {
            label: 'D02017 - Геодезийн хэмжилтийн онгоц',
            value: 'd02017',
            key: 'D02017',
          },
        ],
        value: 'd02000',
        key: 'D02000',
      },
    ],
    value: 'd00000',
    key: 'D00000',
  },
  {
    label: 'E00000 - Хөдөлгөөнт тоног төхөөрөмж',
    selectable: false,
    children: [
      {
        label: 'E01000 - Өрөмдлөг, өрөмдлөгийн хэрэглээний зүйлс',
        children: [
          {
            label: 'E01001 - Өрмийн толгой (том)',
            value: 'e01001',
            key: 'E01001',
          },
          {
            label: 'E01002 - Өрөмдлөгийн машины эд анги',
            value: 'e01002',
            key: 'E01002',
          },
          {
            label: 'E01003 - Өрмийн ган хийц, хэрэглээний зүйлс',
            value: 'e01003',
            key: 'E01003',
          },
          {
            label: 'E01004 - Өрөмдлөгийн үйлчилгээ',
            value: 'e01004',
            key: 'E01004',
          },
        ],
        value: 'e01000',
        key: 'E01000',
      },
      {
        label: 'E02000 - Уурхайн хүнд даацын тоног төхөөрөмж',
        selectable: false,
        children: [
          {
            label: 'E02001 - Бульдозер',
            value: 'e02001',
            key: 'E02001',
          },
          {
            label: 'E02002 - Гидравлик экскаватор',
            value: 'e02002',
            key: 'E02002',
          },
          {
            label: 'E02003 - Хүнд даацын машин',
            value: 'e02003',
            key: 'E02003',
          },
          {
            label: 'E02004 - Уурхайн хүнд даацын тоног төхөөрөмжийн сэлбэг хэрэгсэл',
            value: 'e02004',
            key: 'E02004',
          },
          {
            label: 'E02005 - Уурхайн хүнд даацын тоног төхөөрөмжийн гэрээт засвар үйлчилгээ',
            value: 'e02005',
            key: 'E02005',
          },
          {
            label: 'E02006 - Дугуйт ачигч',
            value: 'e02006',
            key: 'E02006',
          },
          {
            label: 'E02007 - Экскаватор',
            value: 'e02007',
            key: 'E02007',
          },
          {
            label: 'E02008 - Алхагч экскаватор',
            value: 'e02008',
            key: 'E02008',
          },
          {
            label: 'E02009 - Кран',
            value: 'e02009',
            key: 'E02009',
          },
          {
            label: 'E02010 - Далд уурхай тоног төхөөрөмж',
            value: 'e02010',
            key: 'E02010',
          },
          {
            label: 'E02011 - Тоног төхөөрөмжийн туслах хэрэгслүүд',
            value: 'e02011',
            key: 'E02011',
          },
          {
            label: 'E02012 - Ачааны машины эд анги',
            value: 'e02012',
            key: 'E02012',
          },
          {
            label: 'E02013 - Алхагч экскаватор / олс хүрз эд анги',
            value: 'e02013',
            key: 'E02013',
          },
          {
            label: 'E02014 - Аж үйлдвэрийн хөдөлгүүр',
            value: 'e02014',
            key: 'E02014',
          },
          {
            label: 'E02015 - Аж үйлдвэрийн хөдөлгүүрийн эд анги',
            value: 'e02015',
            key: 'E02015',
          },
          {
            label: 'E02016 - Өрөмдлөгийн машин',
            value: 'e02016',
            key: 'E02016',
          },
          {
            label: 'E02017 - өрөмдлөгийн машины эд анги',
            value: 'e02017',
            key: 'E02017',
          },
        ],
        value: 'e02000',
        key: 'E02000',
      },
      {
        label: 'E03000 - Хөнгөн тэрэг',
        selectable: false,
        children: [
          {
            label: 'E03001 - Хөнгөн тэрэг',
            value: 'e03001',
            key: 'E03001',
          },
          {
            label: 'E03002 - Хөнгөн тэрэгний түрээс, худалдан авалт',
            value: 'e03002',
            key: 'E03002',
          },
          {
            label: 'E03003 - Хөнгөн тэрэгний засвар үйлчилгээ',
            value: 'e03003',
            key: 'E03003',
          },
          {
            label: 'E03004 - Хөнгөн тэрэгний гэрээт үйлчилгээ',
            value: 'e03004',
            key: 'E03004',
          },
          {
            label: 'E03005 - Хөнгөн тэрэгний засвар үйлчилгээ',
            value: 'e03005',
            key: 'E03005',
          },
          {
            label: 'E03006 - Хөнгөн тэрэгний сэлбэг хэрэгсэл',
            value: 'e03006',
            key: 'E03006',
          },
          {
            label: 'E03007 - Бартаат замын зорчигч тээврийн машин',
            value: 'e03007',
            key: 'E03007',
          },
          {
            label: 'E03008 - Засмал замын зорчигч тээврийн машин',
            value: 'e03008',
            key: 'E03008',
          },
          {
            label: 'E03009 - Сэрээт ачигч',
            value: 'e03009',
            key: 'E03009',
          },
        ],
        value: 'e03000',
        key: 'E03000',
      },
      {
        label: 'E04000 - Уурхайн тоног төхөөрөмж, сэлбэг хэрэгсэл',
        selectable: false,
        children: [
          {
            label: 'E04001 - Уурхайн хүнд даацын туслах тоног төхөөрөмжийн сэлбэг хэрэгсэл',
            value: 'e04001',
            key: 'E04001',
          },
          {
            label: 'E04002 - Хүнд даацын машины сэлбэг хэрэгсэл',
            value: 'e04002',
            key: 'E04002',
          },
          {
            label: 'E04003 - Алхагч экскаватор / Татлагат экскаваторын сэлбэг хэрэгсэл',
            value: 'e04003',
            key: 'E04003',
          },
          {
            label: 'E04004 - Алхагч экскаватор / Татлагат экскаватор',
            value: 'e04004',
            key: 'E04004',
          },
          {
            label: 'E04005 - Хүнд даацын ган татлага, алхагч экскаватор',
            value: 'e04005',
            key: 'E04005',
          },
          {
            label: 'E04006 - Уурхайн хүнд даацын тоног төхөөрөмжийн түрээс',
            value: 'e04006',
            key: 'E04006',
          },
          {
            label: 'E04007 - Үйлдвэрийн зориулалттай хөдөлгүүр',
            value: 'e04007',
            key: 'E04007',
          },
          {
            label: 'E04008 - Үйлдвэрийн зориулалттай хөдөлгүүрийн сэлбэг хэрэгсэл',
            value: 'e04008',
            key: 'E04008',
          },
        ],
        value: 'e04000',
        key: 'E04000',
      },
      {
        label: 'E05000 - Төмөр зам',
        selectable: false,
        children: [
          {
            label: 'E05001 - Төмөр замын автомат тээврийн удирдлагын систем',
            value: 'e05001',
            key: 'E05001',
          },
          {
            label: 'E05002 - Зүтгүүрийн сэлбэг хэрэгсэл',
            value: 'e05002',
            key: 'E05002',
          },
          {
            label: 'E05003 - Зүтгүүр',
            value: 'e05003',
            key: 'E05003',
          },
          {
            label: 'E05004 - Хүдэр тээвэрлэгчийн сэлбэг хэрэгсэл',
            value: 'e05004',
            key: 'E05004',
          },
          {
            label: 'E05005 - Хүдэр тээвэрлэгчийн доторлогоо',
            value: 'e05005',
            key: 'E05005',
          },
          {
            label: 'E05006 - Хүдэр тээвэрлэгч',
            value: 'e05006',
            key: 'E05006',
          },
          {
            label: 'E05007 - Үйлдвэрийн засвар үйлчилгээний төмөр зам',
            value: 'e05007',
            key: 'E05007',
          },
          {
            label: 'E05008 - Төмөр замын тоног төхөөрөмж, эд анги',
            value: 'e05008',
            key: 'E05008',
          },
          {
            label: 'E05009 - Дохионы болон сэлгээний тоноглол',
            value: 'e05009',
            key: 'E05009',
          },
        ],
        value: 'e05000',
        key: 'E05000',
      },
      {
        label: 'E06000 - Дугуй',
        selectable: false,
        children: [
          {
            label: 'E06001 - Хөнгөн тэрэг болон хөдөлгөөнт тоног төхөөрөмжийн дугуй',
            value: 'e06001',
            key: 'E06001',
          },
          {
            label: 'E06002 - Уурхайн хүнд даацын тоног төхөөрөмжийн даацын дугуй',
            value: 'e06002',
            key: 'E06002',
          },
          {
            label: 'E06003 - Дугуй ба обуд',
            value: 'e06003',
            key: 'E06003',
          },
          {
            label: 'E06004 - Дугуйны үйлчилгээ',
            value: 'e06004',
            key: 'E06004',
          },
          {
            label: 'E06005 - Конвейер хошуу',
            value: 'e06005',
            key: 'E06005',
          },
        ],
        value: 'e06000',
        key: 'E06000',
      },
      {
        label: 'E07000 - Гүний уурхайн тоног төхөөрөмж',
        selectable: false,
        children: [
          {
            label: 'E07001 - Гүний уурхайн тоног төхөөрөмж (ачих, тээвэрлэх буулгах)',
            value: 'e07001',
            key: 'E07001',
          },
        ],
        value: 'e07000',
        key: 'E07000',
      },
      {
        label: 'E08000 - Уул уурхайн технологи',
        selectable: false,
        children: [
          {
            label: 'E08001 - Тоног төхөөрөмжийн технологи',
            value: 'e08001',
            key: 'E08001',
          },
        ],
        value: 'e08000',
        key: 'E08000',
      },
    ],
    value: 'e00000',
    key: 'E00000',
  },
  {
    label: 'F00000 - Үйл ажиллагаа, засвар үйлчилгээнд ашиглагдах материал, хэрэгсэл',
    selectable: false,
    children: [
      {
        label: 'F01000 - Үйл ажиллагаа, засвар үйлчилгээнд ашиглагдах нийтлэг материал, хэрэгсэл',
        selectable: false,
        children: [
          {
            label: 'F01001 - Өнгөлөх, зүлгэх, тасдах материал',
            value: 'f01001',
            key: 'F01001',
          },
          {
            label: 'F01002 - Тоосны камерын шүүлтүүр',
            value: 'f01002',
            key: 'F01002',
          },
          {
            label: 'F01003 - Холхивч/Хүч дамжуулагч',
            value: 'f01003',
            key: 'F01003',
          },
          {
            label: 'F01004 - Буцламгай давхаргат шүүлтүүр',
            value: 'f01004',
            key: 'F01004',
          },
          {
            label: 'F01005 - Дамжуургын тууз',
            value: 'f01005',
            key: 'F01005',
          },
          {
            label: 'F01006 - Керамик бүтээгдэхүүнүүд',
            value: 'f01006',
            key: 'F01006',
          },
          {
            label: 'F01007 - Хувцас/Дүрэмт хувцас',
            value: 'f01007',
            key: 'F01007',
          },
          {
            label: 'F01008 - Компрессор',
            value: 'f01008',
            key: 'F01008',
          },
          {
            label: 'F01009 - Кран, краны сэлбэг хэрэгсэл',
            value: 'f01009',
            key: 'F01009',
          },
          {
            label: 'F01010 - Бэхэлгээ',
            value: 'f01010',
            key: 'F01010',
          },
          {
            label: 'F01011 - Шүүлтүүр/Шүүх материал, уутан шүүлтүүр',
            value: 'f01011',
            key: 'F01011',
          },
          {
            label: 'F01012 - Зуухны сэлбэг хэрэгсэл',
            value: 'f01012',
            key: 'F01012',
          },
          {
            label: 'F01013 - Жийргэвч / Битүүмжлэх / Чигжих хэрэгсэл',
            value: 'f01013',
            key: 'F01013',
          },
          {
            label: 'F01014 - Тослох материал/Masonite (Capuchon)',
            value: 'f01014',
            key: 'F01014',
          },
          {
            label: 'F01015 - Газар ухах хэрэгсэл (GET)',
            value: 'f01015',
            key: 'F01015',
          },
          {
            label: 'F01016 - Гар багаж',
            value: 'f01016',
            key: 'F01016',
          },
          {
            label: 'F01017 - Хоолой, тоноглолууд',
            value: 'f01017',
            key: 'F01017',
          },
          {
            label: 'F01018 - Халаалт, салхивч, агаар сэлгэлтийн төхөөрөмжийн сэлбэг хэрэгслүүд',
            value: 'f01018',
            key: 'F01018',
          },
          {
            label: 'F01019 - Гидравликийн тоног төхөөрөмж, бүрдэл хэсгүүд',
            value: 'f01019',
            key: 'F01019',
          },
          {
            label: 'F01020 - Үйлдвэрлэлийн хангамжийн материалууд',
            value: 'f01020',
            key: 'F01020',
          },
          {
            label: 'F01021 - Үйлдвэр/Далайн тээврийн зориулалттай бүрээс',
            value: 'f01021',
            key: 'F01021',
          },
          {
            label: 'F01022 - Лабораторын хангамжийн хэрэгсэл, засвар үйлчилгээ',
            value: 'f01022',
            key: 'F01022',
          },
          {
            label: 'F01023 - Ган татлага/оосорлох хэрэгсэл',
            value: 'f01023',
            key: 'F01023',
          },
          {
            label: 'F01024 - Тосолгооны материал',
            value: 'f01024',
            key: 'F01024',
          },
          {
            label: 'F01025 - Метал бэлдэц (зэс, гууль, ган гэх мэт.)',
            value: 'f01025',
            key: 'F01025',
          },
          {
            label: 'F01026 - Хоолой/тоноглол/хаалт',
            value: 'f01026',
            key: 'F01026',
          },
          {
            label: 'F01027 - Хийн багаж төхөөрөмж',
            value: 'f01027',
            key: 'F01027',
          },
          {
            label: 'F01028 - Хүч дамжуулагч/хурдны хайрцаг',
            value: 'f01028',
            key: 'F01028',
          },
          {
            label: 'F01029 - Технологийн процессын шүүлтүүр',
            value: 'f01029',
            key: 'F01029',
          },
          {
            label: 'F01030 - Хоолой/хаалт/тоноглол',
            value: 'f01030',
            key: 'F01030',
          },
          {
            label: 'F01031 - Шахуурга/компрессор/сэлбэг хэрэгсэл',
            value: 'f01031',
            key: 'F01031',
          },
          {
            label: 'F01033 - Резин/резинэн бэлдэц',
            value: 'f01033',
            key: 'F01033',
          },
          {
            label: 'F01034 - Аюулгүй ажиллагаа, галын аюулгүй байдал',
            value: 'f01034',
            key: 'F01034',
          },
          {
            label: 'F01035 - Аюулгүй ажиллагааны хэрэглээний зүйлс',
            value: 'f01035',
            key: 'F01035',
          },
          {
            label: 'F01036 - Элс/хайрга',
            value: 'f01036',
            key: 'F01036',
          },
          {
            label: 'F01037 - Овоолго хийх, овоолгоос буцаан татах төхөөрөмжийн сэлбэг хэрэгсэл',
            value: 'f01037',
            key: 'F01037',
          },
          {
            label: 'F01038 - Ган болон тусгай төмөрлөгүүд',
            value: 'f01038',
            key: 'F01038',
          },
          {
            label: 'F01039 - Банз/модон бүтээгдэхүүнүүд',
            value: 'f01039',
            key: 'F01039',
          },
          {
            label: 'F01040 - Суран/гинжин дамжуулга',
            value: 'f01040',
            key: 'F01040',
          },
          {
            label: 'F01041 - Гагнуурын тоног төхөөрөмж, хангамжийн зүйлс',
            value: 'f01041',
            key: 'F01041',
          },
          {
            label: 'F01042 - Хүнд олс / Алхагч экскаватор',
            value: 'f01042',
            key: 'F01042',
          },
        ],
        value: 'f01000',
        key: 'F01000',
      },
      {
        label: 'F02000 - Цахилгааны болон нарийн хэмжүүрийн хэрэгсэл',
        selectable: false,
        children: [
          {
            label: 'F02001 - Цахилгааны техник хэрэгсэл, тоног төхөөрөмж',
            value: 'f02001',
            key: 'F02001',
          },
          {
            label: 'F02002 - Зай тэжээл',
            value: 'f02002',
            key: 'F02002',
          },
          {
            label: 'F02003 - Цахилгааны сэлбэг хэрэгсэл, хэрэглээний зүйлс',
            value: 'f02003',
            key: 'F02003',
          },
          {
            label: 'F02004 - Нарийн хэмжүүрийн тоног төхөөрөмж, эд анги',
            value: 'f02004',
            key: 'F02004',
          },
        ],
        value: 'f02000',
        key: 'F02000',
      },
      {
        label: 'F03000 - Тос, тослох материал',
        selectable: false,
        children: [
          {
            label: 'F03001 - Тослох материал',
            value: 'f03001',
            key: 'F03001',
          },
          {
            label: 'F03002 - Тос',
            value: 'f03002',
            key: 'F03002',
          },
          {
            label: 'F03003 - Хөргөгч',
            value: 'f03003',
            key: 'F03003',
          },
        ],
        value: 'f03000 - lubricants',
        key: 'F03000',
      },
      {
        label: 'F04000 - Оффисын хэрэглээний зүйлс',
        selectable: false,
        children: [
          {
            label: 'F04001 - Оффисын тавилга',
            value: 'f04001',
            key: 'F04001',
          },
          {
            label: 'F04002 - Оффисын тоног төхөөрөмж',
            value: 'f04002',
            key: 'F04002',
          },
          {
            label: 'F04003 - Оффисын хангамжийн зүйлс',
            value: 'f04003',
            key: 'F04003',
          },
          {
            label: 'F04004 - Хэвлэл захиалга',
            value: 'f04004',
            key: 'F04004',
          },
        ],
        value: 'f04000',
        key: 'F04000',
      },
    ],
    value: 'f00000',
    key: 'F00000',
  },
  {
    label: 'G00000 - Үйлдвэрлэлийн хэрэглээний зүйлс',
    selectable: false,
    children: [
      {
        label: 'G01000 - Рио Тинтогийн борлуулах бүтээгдэхүүний савлагаа',
        selectable: false,
        children: [
          {
            label: 'G01001 - Шошго, лого',
            value: 'g01001',
            key: 'G01001',
          },
          {
            label: 'G01002 - Сав баглаа',
            value: 'g01002',
            key: 'G01002',
          },
          {
            label: 'G01003 - Ган бүслүүр',
            value: 'g01003',
            key: 'G01003',
          },
          {
            label: 'G01004 - Будаг / Далайн тээврийн зориулалттай бүрээс',
            value: 'g01004',
            key: 'G01004',
          },
          {
            label: 'G01005 - Хальс - Полиэтилен',
            value: 'g01005',
            key: 'G01005',
          },
          {
            label: 'G01006 - Хальс - Цулгай',
            value: 'g01006',
            key: 'G01006',
          },
          {
            label: 'G01007 - Хальс - Поливенилхлорид',
            value: 'g01007',
            key: 'G01007',
          },
          {
            label: 'G01008 - Шилэн мяндас',
            value: 'g01008',
            key: 'G01008',
          },
          {
            label: 'G01009 - Боолтын цаас',
            value: 'g01009',
            key: 'G01009',
          },
          {
            label: 'G01010 - Тавиур',
            value: 'g01010',
            key: 'G01010',
          },
          {
            label: 'G01011 - Задгай ачааны уут',
            value: 'g01011',
            key: 'G01011',
          },
          {
            label: 'G01012 - ногоон мод / модон материал',
            value: 'g01012',
            key: 'G01012',
          },
        ],
        value: 'g01000',
        key: 'G01000',
      },
      {
        label: 'G02000 - Хөнгөнцагааны болон гангийн бэлдэц материал',
        selectable: false,
        children: [
          {
            label: 'G02001 - Анодын хашлага',
            value: 'g02001',
            key: 'G02001',
          },
          {
            label: 'G02002 - Гүйдлийн холбогч, цуглуулах татуурга, уян холбоос г.м.',
            value: 'g02002',
            key: 'G02002',
          },
          {
            label: 'G02003 - Катод',
            value: 'g02003',
            key: 'G02003',
          },
          {
            label: 'G02004 - Цуглуулах хашлага, ган доторлогоо',
            value: 'g02004',
            key: 'G02004',
          },
          {
            label: 'G02005 - Зэс',
            value: 'g02005',
            key: 'G02005',
          },
          {
            label: 'G02006 - Талстжуулах нэмэлт',
            value: 'g02006',
            key: 'G02006',
          },
          {
            label: 'G02007 - Хатууруулагч',
            value: 'g02007',
            key: 'G02007',
          },
          {
            label: 'G02008 - Магнезиум',
            value: 'g02008',
            key: 'G02008',
          },
          {
            label: 'G02009 - Марганец',
            value: 'g02009',
            key: 'G02009',
          },
          {
            label: 'G02010 - Никель',
            value: 'g02010',
            key: 'G02010',
          },
          {
            label: 'G02011 - Цэвэр металлууд ',
            value: 'g02011',
            key: 'G02011',
          },
          {
            label: 'G02012 - Цахиур',
            value: 'g02012',
            key: 'G02012',
          },
          {
            label: 'G02013 - Ган туйван',
            value: 'g02013',
            key: 'G02013',
          },
          {
            label: 'G02014 - Ган үрэл, бөмбөлөг',
            value: 'g02014',
            key: 'G02014',
          },
        ],
        value: 'g02000',
        key: 'G02000',
      },
      {
        label: 'G03000 - Хэрэглээний зүйлс',
        selectable: false,
        children: [
          {
            label: 'G03001 - Технологийн процссын хэрэглээний зүйлс',
            value: 'g03001',
            key: 'G03001',
          },
          {
            label: 'G03002 - Цутгуурын цехийн хэрэглээний зүйлс',
            value: 'g03002',
            key: 'G03002',
          },
          {
            label: 'G03003 - Цутгуур',
            value: 'g03003',
            key: 'G03003',
          },
          {
            label: 'G03004 - Цилиндр / Хавтан',
            value: 'g03004',
            key: 'G03004',
          },
          {
            label: 'G03005 - Шаарга боловсруулах',
            value: 'g03005',
            key: 'G03005',
          },
          {
            label: 'G03006 - Эсгий',
            value: 'g03006',
            key: 'G03006',
          },
          {
            label: 'G03007 - Ган бэлдэц материал',
            value: 'g03007',
            key: 'G03007',
          },
          {
            label: 'G03008 - Үйлдвэрлэлийн термопар, дулааны анхдагч хэмжүүр',
            value: 'g03008',
            key: 'G03008',
          },
          {
            label: 'G03009 - Тослох-хөргөх шингэн, нэмэлт бодис',
            value: 'g03009',
            key: 'G03009',
          },
          {
            label: 'G03010 - buss bar, цуглуулагч оосор, цахилгаан дамжуулах уян хатан утас',
            value: 'g03010',
            key: 'G03010',
          },
          {
            label: 'G03011 - цуглуулагч сараалж болон шавар гангаар хийсэн',
            value: 'g03011',
            key: 'G03011',
          },
          {
            label: 'G03012 - Зэс',
            value: 'g03012',
            key: 'G03012',
          },
          {
            label: 'G03013 - Тариа боловсруулагчид',
            value: 'g03013',
            key: 'G03013',
          },
          {
            label: 'G03014 - Хатууруулагч',
            value: 'g03014',
            key: 'G03014',
          },
          {
            label: 'G03015 - Никель',
            value: 'g03015',
            key: 'G03015',
          },
          {
            label: 'G03016 - Цэвэр метал',
            value: 'g03016',
            key: 'G03016',
          },
          {
            label: 'G03017 - Ган саваа',
            value: 'g03017',
            key: 'G03017',
          },
          {
            label: 'G03018 - Ган бөөрөнцөг, ган бөмбөлөгтэй',
            value: 'g03018',
            key: 'G03018',
          },
        ],
        value: 'g03000',
        key: 'G03000',
      },
      {
        label: 'G04000 - Тэсрэх бодис',
        selectable: false,
        children: [
          {
            label: 'G04001 - Аммонийн нитрат, задгай тэсрэх бодис',
            value: 'g04001',
            key: 'G04001',
          },
          {
            label: 'G04002 - Тэсэлгээний нэмэлт хэрэгсэл',
            value: 'g04002',
            key: 'G04002',
          },
          {
            label: 'G04003 - Эмульсийн тэсрэх бодис',
            value: 'g04003',
            key: 'G04003',
          },
          {
            label: 'G04004 - Өрөмдлөг, тэсэлгээний үйлчилгээ',
            value: 'g04004',
            key: 'G04004',
          },
        ],
        value: 'g04000',
        key: 'G04000',
      },
      {
        label: 'G05000 - Химийн бодис',
        selectable: false,
        children: [
          {
            label: 'G05001 - Хүчил',
            value: 'g05001',
            key: 'G05001',
          },
          {
            label: 'G05002 - Хлор',
            value: 'g05002',
            key: 'G05002',
          },
          {
            label: 'G05003 - Тосгүйжүүлэх, цэвэрлэх уусмал',
            value: 'g05003',
            key: 'G05003',
          },
          {
            label: 'G05004 - Этанол',
            value: 'g05004',
            key: 'G05004',
          },
          {
            label: 'G05005 - Ферро цахиур',
            value: 'g05005',
            key: 'G05005',
          },
          {
            label: 'G05006 - Флокулянт',
            value: 'g05006',
            key: 'G05006',
          },
          {
            label: 'G05007 - Үйлдвэрлэлийн зориулалттай хий',
            value: 'g05007',
            key: 'G05007',
          },
          {
            label: 'G05008 - Бэх',
            value: 'g05008',
            key: 'G05008',
          },
          {
            label: 'G05009 - Шохой',
            value: 'g05009',
            key: 'G05009',
          },
          {
            label: 'G05010 - Метал гадаргуу боловсруулах, маажиндах бодис',
            value: 'g05010',
            key: 'G05010',
          },
          {
            label: 'G05011 - Органик бодис',
            value: 'g05011',
            key: 'G05011',
          },
          {
            label: 'G05012 - Химийн бусад бодис',
            value: 'g05012',
            key: 'G05012',
          },
          {
            label: 'G05013 - Хүчилтөрөгч',
            value: 'g05013',
            key: 'G05013',
          },
          {
            label: 'G05014 - Резин',
            value: 'g05014',
            key: 'G05014',
          },
          {
            label: 'G05015 - Чигжээс',
            value: 'g05015',
            key: 'G05015',
          },
          {
            label: 'G05016 - Уусгагч',
            value: 'g05016',
            key: 'G05016',
          },
          {
            label: 'G05017 - Үйлдвэрлэлийн ус',
            value: 'g05017',
            key: 'G05017',
          },
          {
            label: 'G05018 - Лав',
            value: 'g05018',
            key: 'G05018',
          },
          {
            label: 'G05020 - Графит',
            value: 'g05020',
            key: 'G05020',
          },
          {
            label: 'G05021 - Графит хоолой',
            value: 'g05021',
            key: 'G05021',
          },
          {
            label: 'G05022 - Төмөр',
            value: 'g05022',
            key: 'G05022',
          },
          {
            label: 'G05023 - Литийн карбонат',
            value: 'g05023',
            key: 'G05023',
          },
          {
            label: 'G05024 - Магнийн исэл',
            value: 'g05024',
            key: 'G05024',
          },
          {
            label: 'G05025 - Металлургийн нүүрс',
            value: 'g05025',
            key: 'G05025',
          },
          {
            label: 'G05026 - содны үнс',
            value: 'g05026',
            key: 'G05026',
          },
          {
            label: 'G05027 - Ясны үнс',
            value: 'g05027',
            key: 'G05027',
          },
        ],
        value: 'g05000',
        key: 'G05000',
      },
      {
        label: 'G06000 - Түүхий эд',
        selectable: false,
        children: [
          {
            label: 'G06001 - Хайлш',
            value: 'g06001',
            key: 'G06001',
          },
          {
            label: 'G06002 - Хөнгөнцагааны исэл',
            value: 'g06002',
            key: 'G06002',
          },
          {
            label: 'G06003 - Хөнгөнцагааны флорид',
            value: 'g06003',
            key: 'G06003',
          },
          {
            label: 'G06004 - Чулуун нүүрс',
            value: 'g06004',
            key: 'G06004',
          },
          {
            label: 'G06005 - Криолитийн уусмал',
            value: 'g06005',
            key: 'G06005',
          },
          {
            label: 'G06006 - Боксит',
            value: 'g06006',
            key: 'G06006',
          },
          {
            label: 'G06007 - Яны үнс',
            value: 'g06007',
            key: 'G06007',
          },
          {
            label: 'G06008 - Газрын тосны хатаасан кокс',
            value: 'g06008',
            key: 'G06008',
          },
          {
            label: 'G06009 - Нүүрстөрөгч',
            value: 'g06009',
            key: 'G06009',
          },
          {
            label: 'G06010 - Идэмхий натри',
            value: 'g06010',
            key: 'G06010',
          },
          {
            label: 'G06011 - Кокс (металлургийн)',
            value: 'g06011',
            key: 'G06011',
          },
          {
            label: 'G06012 - Кокс (газрын тосны)',
            value: 'g06012',
            key: 'G06012',
          },
          {
            label: 'G06013 - Электрод',
            value: 'g06013',
            key: 'G06013',
          },
          {
            label: 'G06014 - Хайлуур жонш',
            value: 'g06014',
            key: 'G06014',
          },
          {
            label: 'G06015 - Бал чулуу',
            value: 'g06015',
            key: 'G06015',
          },
          {
            label: 'G06016 - Бал чулуун хоолой',
            value: 'g06016',
            key: 'G06016',
          },
          {
            label: 'G06017 - Газрын тосны кокс',
            value: 'g06017',
            key: 'G06017',
          },
          {
            label: 'G06018 - Нойтон банз, дүнз',
            value: 'g06018',
            key: 'G06018',
          },
          {
            label: 'G06019 - Гидратын шохой',
            value: 'g06019',
            key: 'G06019',
          },
          {
            label: 'G06020 - Төмөр',
            value: 'g06020',
            key: 'G06020',
          },
          {
            label: 'G06021 - Давирхай',
            value: 'g06021',
            key: 'G06021',
          },
          {
            label: 'G06022 - Шингэн давирхай',
            value: 'g06022',
            key: 'G06022',
          },
          {
            label: 'G06023 - Литийн карбонат',
            value: 'g06023',
            key: 'G06023',
          },
          {
            label: 'G06024 - Магнийн исэл',
            value: 'g06024',
            key: 'G06024',
          },
          {
            label: 'G06025 - Металл ургийн нүүрс',
            value: 'g06025',
            key: 'G06025',
          },
          {
            label: 'G06026 - Өөрөө хатуурах хучилт',
            value: 'g06026',
            key: 'G06026',
          },
          {
            label: 'G06027 - Галд тэсвэртэй тоосго',
            value: 'g06027',
            key: 'G06027',
          },
          {
            label: 'G06028 - Галд тэсвэртэй доторлогооны засвар үйлчилгээ',
            value: 'g06028',
            key: 'G06028',
          },
          {
            label: 'G06029 - Хайлуулах үйлдвэрийн технологи',
            value: 'g06029',
            key: 'G06029',
          },
          {
            label: 'G06030 - Содны үнс',
            value: 'g06030',
            key: 'G06030',
          },
          {
            label: 'G06031 - Катод',
            value: 'g06031',
            key: 'G06031',
          },
          {
            label: 'G06032 - Магни',
            value: 'g06032',
            key: 'G06032',
          },
          {
            label: 'G06033 - Марганц',
            value: 'g06033',
            key: 'G06033',
          },
          {
            label: 'G06034 - Цахиур',
            value: 'g06034',
            key: 'G06034',
          },
        ],
        value: 'g06000',
        key: 'G06000',
      },
    ],
    value: 'g00000',
    key: 'G00000',
  },
  {
    label: 'H00000 - Үйлчилгээ',
    selectable: false,
    children: [
      {
        label: 'H01000 - Туслах үйлчилгээ',
        selectable: false,
        children: [
          {
            label: 'H01001 - Хяналт шинжилгээний гэрээт гүйцэтгэгч',
            value: 'h01001',
            key: 'H01001',
          },
          {
            label: 'H01002 - Гэрээт ажилтан - Гаднын үйлчилгээ',
            value: 'h01002',
            key: 'H01002',
          },
          {
            label: 'H01003 - Ажилчдын тээврийн үйлчилгээ',
            value: 'h01003',
            key: 'H01003',
          },
          {
            label: 'H01004 - Түрээсээр ашиглах тоног төхөөрөмж',
            value: 'h01004',
            key: 'H01004',
          },
          {
            label: 'H01005 - Эд анги үйлдвэрлэл',
            value: 'h01005',
            key: 'H01005',
          },
          {
            label: 'H01006 - Цутгуурын цех',
            value: 'h01006',
            key: 'H01006',
          },
          {
            label: 'H01007 - Нарийн хэмжүүр',
            value: 'h01007',
            key: 'H01007',
          },
          {
            label: 'H01008 - Лаборатори',
            value: 'h01008',
            key: 'H01008',
          },
          {
            label: 'H01009 - Токарийн цех',
            value: 'h01009',
            key: 'H01009',
          },
          {
            label: 'H01010 - Далайн тээврийн гэрээт гүйцэтгэгч',
            value: 'h01010',
            key: 'H01010',
          },
          {
            label: 'H01011 - Эмнэлгийн үйлчилгээ',
            value: 'h01011',
            key: 'H01011',
          },
          {
            label: 'H01012 - Метал бэлдэц үйлдвэрлэл',
            value: 'h01012',
            key: 'H01012',
          },
          {
            label: 'H01013 - Оффисын тоног төхөөрөмжийн засвар үйлчилгээ',
            value: 'h01013',
            key: 'H01013',
          },
          {
            label: 'H01014 - Боомтын төлбөр',
            value: 'h01014',
            key: 'H01014',
          },
          {
            label: 'H01015 - Хэвлэлийн үйлчилгээ',
            value: 'h01015',
            key: 'H01015',
          },
          {
            label: 'H01016 - Зам засвар, арчлалтын гэрээт гүйцэтгэгч',
            value: 'h01016',
            key: 'H01016',
          },
          {
            label: 'H01017 - Дээж авах, шинжилгээ хийх үйлчилгээ',
            value: 'h01017',
            key: 'H01017',
          },
          {
            label: 'H01018 - Даавуун хэвлэл',
            value: 'h01018',
            key: 'H01018',
          },
          {
            label: 'H01019 - Тээвэрлэлтийн үйлчилгээ',
            value: 'h01019',
            key: 'H01019',
          },
          {
            label: 'H01020 - Уурхайн талбайн бусад үйлчилгээ',
            value: 'h01020',
            key: 'H01020',
          },
          {
            label: 'H01021 - Өндрийн засвар үйлчилгээ',
            value: 'h01021',
            key: 'H01021',
          },
          {
            label: 'H01022 - Геодезийн хэмжилт',
            value: 'h01022',
            key: 'H01022',
          },
          {
            label: 'H01023 - Тоолуур, хэмжилтийн үйлчилгээ',
            value: 'h01023',
            key: 'H01023',
          },
          {
            label: 'H01024 - Ус цэвэршүүлэх материал',
            value: 'h01024',
            key: 'H01024',
          },
        ],
        value: 'h01000',
        key: 'H01000',
      },
      {
        label: 'H02000 - Задгай материал',
        selectable: false,
        children: [
          {
            label: 'H02001 - Шугам хоолойн тоолуур, хэмжилтийн үйлчилгээ',
            value: 'h02001',
            key: 'H02001',
          },
        ],
        value: 'h02000',
        key: 'H02000',
      },
      {
        label: 'H03000 - Барилга, уурхайн үйлчилгээ',
        selectable: false,
        children: [
          {
            label: 'H03001 - Барилга угсралтын гэрээт гүйцэтгэгч',
            value: 'h03001',
            key: 'H03001',
          },
          {
            label: 'H03002 - Тэсэлгээний үйлчилгээ',
            value: 'h03002',
            key: 'H03002',
          },
          {
            label: 'H03003 - Олборлолтын гэрээт гүйцэтгэгч',
            value: 'h03003',
            key: 'H03003',
          },
        ],
        value: 'h03000',
        key: 'H03000',
      },
      {
        label: 'H04000 - Аж ахуй, уурхайн талбайн менежмент',
        selectable: false,
        children: [
          {
            label: 'H04001 - Барилгын түрээс',
            value: 'h04001',
            key: 'H04001',
          },
          {
            label: 'H04002 - Барилгын засвар үйлчилгээ, сантехникийн болон цахилгааны ажлууд',
            value: 'h04002',
            key: 'H04002',
          },
          {
            label: 'H04003 - Хоолны үйлчилгээ',
            value: 'h04003',
            key: 'H04003',
          },
          {
            label: 'H04004 - Шатахуун түгээгүүрийн ашиглалт, засвар үйлчилгээ',
            value: 'h04004',
            key: 'H04004',
          },
          {
            label: 'H04005 - Газрын гадаргуугийн арчлалт',
            value: 'h04005',
            key: 'H04005',
          },
          {
            label: 'H04006 - Цэвэрлэгээний үйлчилгээ',
            value: 'h04006',
            key: 'H04006',
          },
          {
            label: 'H04007 - Угаалгын үйлчилгээ',
            value: 'h04007',
            key: 'H04007',
          },
          {
            label: 'H04008 - Хөрөнгийн түрээс',
            value: 'h04008',
            key: 'H04008',
          },
          {
            label: 'H04009 - Хөрөнгийн худалдан авалт',
            value: 'h04009',
            key: 'H04009',
          },
          {
            label: 'H04010 - Аюулгүй ажиллагаа, галын аюулгүй байдал',
            value: 'h04010',
            key: 'H04010',
          },
          {
            label: 'H04011 - Хамгаалалтын үйлчилгээ',
            value: 'h04011',
            key: 'H04011',
          },
          {
            label: 'H04012 - Уурхайн талбар дах барилга угсралтын ажил',
            value: 'h04012',
            key: 'H04012',
          },
          {
            label:
              'H04013 - Хог хаягдал цуглуулах, зайлуулах, дахин боловсруулах үйлчилгээ (аюултай хог хаягдал орно)',
            value: 'h04013',
            key: 'H04013',
          },
          {
            label: 'H04014 - Ус хангамж - Хэрэглээний',
            value: 'h04014',
            key: 'H04014',
          },
        ],
        value: 'h04000',
        key: 'H04000',
      },
      {
        label: 'H05000 - Мэдээллийн технологи',
        selectable: false,
        children: [
          {
            label: 'H05001 - Техник хангамжийн засвар үйлчилгээ',
            value: 'h05001',
            key: 'H05001',
          },
          {
            label: 'H05002 - Техник хангамжийн түрээс',
            value: 'h05002',
            key: 'H05002',
          },
          {
            label: 'H05003 - Техник хангамжийн худалдан авалт',
            value: 'h05003',
            key: 'H05003',
          },
          {
            label: 'H05004 - Мэдээллийн технологийн үйлчилгээ',
            value: 'h05004',
            key: 'H05004',
          },
          {
            label: 'H05005 - Суурин утас',
            value: 'h05005',
            key: 'H05005',
          },
          {
            label: 'H05006 - Гар утас',
            value: 'h05006',
            key: 'H05006',
          },
          {
            label: 'H05007 - Компьютер, программ хангамж, холбогдох бусад зүйлс',
            value: 'h05007',
            key: 'H05007',
          },
          {
            label: 'H05008 - Программ хангамж',
            value: 'h05008',
            key: 'H05008',
          },
          {
            label: 'H05009 - Холбоо',
            value: 'h05009',
            key: 'H05009',
          },
        ],
        value: 'h05000',
        key: 'H05000',
      },
      {
        label: 'H06000 - Засвар үйлчилгээ',
        selectable: false,
        children: [
          {
            label: 'H06001 - Автын засвар үйлчилгээ',
            value: 'h06001',
            key: 'H06001',
          },
          {
            label: 'H06002 - Краны гэрээт үйлчилгээ үзүүлэгч',
            value: 'h06002',
            key: 'H06002',
          },
          {
            label: 'H06003 - Бутлуур, тээрмийн гэрээт үйлчилгээ үзүүлэгч',
            value: 'h06003',
            key: 'H06003',
          },
          {
            label: 'H06004 - Цахилгаан дамжуулалт, цахилгаан холболтын гэрээт үйлчилгээ үзүүлэгч',
            value: 'h06004',
            key: 'H06004',
          },
          {
            label: 'H06005 - Зауухны засвар үйлчилгээ',
            value: 'h06005',
            key: 'H06005',
          },
          {
            label: 'H06006 - Материал зөөх тоног төхөөрөмжийн засвар үйлчилгээ',
            value: 'h06006',
            key: 'H06006',
          },
          {
            label: 'H06007 - ХСАС-ийн засвар үйлчилгээ',
            value: 'h06007',
            key: 'H06007',
          },
          {
            label: 'H06008 - Засвар үйлчилгээ',
            value: 'h06008',
            key: 'H06008',
          },
          {
            label: 'H06009 - Үйлдвэрийн цахилгаан тоног төхөөрөмжийн засвар үйлчилгээ',
            value: 'h06009',
            key: 'H06009',
          },
          {
            label: 'H06010 - Үйлдвэрийн гидравлик тоног төхөөрөмжийн засвар үйлчилгээ',
            value: 'h06010',
            key: 'H06010',
          },
          {
            label: 'H06011 - Үйлдвэрийн механикийн тоног төхөөрөмжийн засвар үйлчилгээ',
            value: 'h06011',
            key: 'H06011',
          },
          {
            label: 'H06012 - Үйлдвэрийн ус цэвэршүүлэх байгууламжийн засвар үйлчилгээ',
            value: 'h06012',
            key: 'H06012',
          },
          {
            label: 'H06013 - Шугам хоолойн засвар үйлчилгээ',
            value: 'h06013',
            key: 'H06013',
          },
          {
            label: 'H06014 - Түр ажиллах хүчин / Үйлдвэрлэлийн',
            value: 'h06014',
            key: 'H06014',
          },
          {
            label: 'H06015 - Цахилгаан дамжуулалт, цахилгаан холболт',
            value: 'h06015',
            key: 'H06015',
          },
          {
            label: 'H06016 - Галд тэсвэртэй үйлчилгээ',
            value: 'h06016',
            key: 'H06016',
          },
        ],
        value: 'h06000',
        key: 'H06000',
      },
      {
        label: 'H07000 - Олборлолт',
        selectable: false,
        children: [
          {
            label: 'H07001 - Гүний уурхайн олборлолтын гэрээт гүйцэтгэгч',
            value: 'h07001',
            key: 'H07001',
          },
        ],
        value: 'h07000',
        key: 'H07000',
      },
      {
        label: 'H08000 - Мэргэжлийн үйлчилгээ',
        selectable: false,
        children: [
          {
            label: 'H08001 - НББ, санхүү, аудит, татварын зөвлөх үйлчилгээ',
            value: 'h08001',
            key: 'H08001',
          },
          {
            label: 'H08002 - Сурталчилгаа, маркетингийн үйлчилгээ',
            value: 'h08002',
            key: 'H08002',
          },
          {
            label: 'H08003 - Банкны үйлчилгээ',
            value: 'h08003',
            key: 'H08003',
          },
          {
            label: 'H08004 - Бизнесийн болон ерөнхий зөвлөх үйлчилгээ',
            value: 'h08004',
            key: 'H08004',
          },
          {
            label: 'H08005 - Зөвлөх үйлчилгээ',
            value: 'h08005',
            key: 'H08005',
          },
          {
            label: 'H08006 - Гаалийн бүрдүүлэлт, брокерын үйлчилгээ',
            value: 'h08006',
            key: 'H08006',
          },
          {
            label: 'H08007 - Инженер, техниз, мэргэжлийн болон ерөнхий зөвлөх үйлчилгээ',
            value: 'h08007',
            key: 'H08007',
          },
          {
            label: 'H08008 - Байгаль орчин, эрүүл мэнд, аюулгүй ажиллагааны зөвлөх үйлчилгээ',
            value: 'h08008',
            key: 'H08008',
          },
          {
            label: 'H08009 - Эрүүл мэндийн даатгал',
            value: 'h08009',
            key: 'H08009',
          },
          {
            label: 'H08010 - Хүний нөөц, урамшууллын зөвлөх үйлчилгээ',
            value: 'h08010',
            key: 'H08010',
          },
          {
            label: 'H08011 - Даатгал',
            value: 'h08011',
            key: 'H08011',
          },
          {
            label: 'H08012 - Мэдээллийн технологийн зөвлөх үйлчилгээ',
            value: 'h08012',
            key: 'H08012',
          },
          {
            label: 'H08013 - Хуулийн үйлчилгээ',
            value: 'h08013',
            key: 'H08013',
          },
          {
            label: 'H08014 - Зах зээлийн судалгаа',
            value: 'h08014',
            key: 'H08014',
          },
          {
            label: 'H08015 - Эмч, эмчилгээний төлбөр',
            value: 'h08015',
            key: 'H08015',
          },
          {
            label: 'H08016 - Мэргэжлийн үйлчилгээ',
            value: 'h08016',
            key: 'H08016',
          },
          {
            label: 'H08017 - Судалгаа, хөгжил',
            value: 'h08017',
            key: 'H08017',
          },
          {
            label: 'H08018 - Эрсдэлийн менежментийн үйлчилгээ',
            value: 'h08018',
            key: 'H08018',
          },
          {
            label: 'H08019 - Гадаад харилцааны үйлчилгээ',
            value: 'h08019',
            key: 'H08019',
          },
          {
            label: 'H08020 - Түр ажиллах хүчин / Захиргааны',
            value: 'h08020',
            key: 'H08020',
          },
          {
            label: 'H08021 - Сургалт, боловсрол - Төлбөр',
            value: 'h08021',
            key: 'H08021',
          },
          {
            label: 'H08022 - Сургалтын үйлчилгээ',
            value: 'h08022',
            key: 'H08022',
          },
        ],
        value: 'h08000',
        key: 'H08000',
      },
      {
        label: 'H09000 - Дэмжлэг үзүүлэх үйлчилгээ',
        selectable: false,
        children: [
          {
            label: 'H09001 - Уурхайн талбайн гэрээт гүйцэтгэгчид - Цахилгааны, механикийн',
            value: 'h09001',
            key: 'H09001',
          },
          {
            label: 'H09002 - Нэмэлт ажиллах хүчин',
            value: 'h09002',
            key: 'H09002',
          },
        ],
        value: 'h09000',
        key: 'H09000',
      },
      {
        label: 'H10000 - Аялал',
        selectable: false,
        children: [
          {
            label: 'H10001 - Агаарын тээвэр - Ердийн нислэг',
            value: 'h10001',
            key: 'H10001',
          },
          {
            label: 'H10002 - Агаарын тээвэр - Захиалгат нислэг',
            value: 'h10002',
            key: 'H10002',
          },
          {
            label: 'H10003 - Машины түрээс',
            value: 'h10003',
            key: 'H10003',
          },
          {
            label: 'H10004 - Зочид буудал / Байр сууц',
            value: 'h10004',
            key: 'H10004',
          },
          {
            label: 'H10005 - Хоол',
            value: 'h10005',
            key: 'H10005',
          },
          {
            label: 'H10006 - Такси, автобус, галт тэрэг',
            value: 'h10006',
            key: 'H10006',
          },
          {
            label: 'H10007 - Аялал',
            value: 'h10007',
            key: 'H10007',
          },
          {
            label: 'H10008 - Аялалын товчооны үйлчилгээ',
            value: 'h10008',
            key: 'H10008',
          },
        ],
        value: 'h10000',
        key: 'H10000',
      },
    ],
    value: 'h00000',
    key: 'H00000',
  },
];

export default { en, mn };
