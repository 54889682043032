const register = `
  mutation register($email: String!) {
    register(email: $email)
  }
`;

const commonParams = `
  $username: String!,
  $email: String!,
  $role: String!,
  $firstName: String,
  $lastName: String,
  $jobTitle: String,
  $phone: Float,
  $permissions: [String!],
  $accessOfficer: String!
`;

const commonValues = `
  username: $username,
  email: $email,
  role: $role,
  firstName: $firstName,
  lastName: $lastName,
  jobTitle: $jobTitle,
  phone: $phone,
  password: $password,
  passwordConfirmation: $passwordConfirmation,
  permissions: $permissions,
  accessOfficer: $accessOfficer
  attachments: $attachments
`;

const usersAdd = `
  mutation usersAdd(
    ${commonParams},
    $password: String!,
    $passwordConfirmation: String!
    $attachments: [JSON]
  ) {
    usersAdd(${commonValues}) {
        _id
    }
  }
`;

const usersEdit = `
  mutation usersEdit(
    $_id: String!,
    ${commonParams},
    $password: String,
    $passwordConfirmation: String
    $attachments: [JSON]
  ) {
    usersEdit(
      _id: $_id,
      ${commonValues}
    ) {
        _id
        username
        email
        role
        isSupplier
        companyId
        firstName
        lastName
        jobTitle
        phone
        accessOfficer
        attachments
    }
  }
`;

const usersToggleState = `
  mutation usersToggleState($_id: String!) {
    usersToggleState(_id: $_id)
  }
`;

const resetPassword = `
  mutation resetPassword($token: String!, $newPassword: String!) {
    resetPassword(token: $token, newPassword: $newPassword)
  }
`;

const configsSaveTemplate = `
  mutation configsSaveTemplate(
    $name: String!
    $kind: String!
    $from: String!
    $subject: JSON!
    $content: JSON!
    ) {

    configsSaveTemplate(
      name: $name
      kind: $kind
      from: $from
      subject: $subject
      content: $content
    ) {

      _id
      eoiTemplates
      rfqTemplates
      freightTemplates
    }
  }
`;

const configsSavePrequalificationDow = `
  mutation configsSavePrequalificationDow($doc: ConfigPrequalificationDowInput!) {
    configsSavePrequalificationDow(doc: $doc) {
      _id
      prequalificationDow
      specificPrequalificationDow
    }
  }
`;

const configsSaveDueDiligenceDow = `
  mutation configsSaveDueDiligenceDow($doc: ConfigDueDiligenceDowInput!) {
    configsSaveDueDiligenceDow(doc: $doc) {
      _id
      dueDiligenceDow
      specificDueDiligenceDow
    }
  }
`;

const configsSaveManageSuppliersDow = `
  mutation configsSaveManageSuppliersDow($doc: ConfigManageSuppliersDowInput!) {
    configsSaveManageSuppliersDow(doc: $doc) {
      _id
      dueDiligenceDow
      specificDueDiligenceDow
    }
  }
`;

const configsSaveAuditDow = `
  mutation configsSaveAuditDow($doc: ConfigAuditDowInput!) {
    configsSaveAuditDow(doc: $doc) {
      _id
      auditDow
      specificAuditDow
    }
  }
`;

const configsSaveImprovementPlanDow = `
  mutation configsSaveImprovementPlanDow($doc: ConfigImprovementPlanDowInput!){
    configsSaveImprovementPlanDow(doc: $doc){
      _id
      improvementPlanDow
      specificImprovementPlanDow
    }
  }
`;

const configsSaveValidationDow = `
  mutation configsSaveValidationDow($doc: ConfigDueDiligenceDowInput!) {
    configsSaveValidationDow(doc: $doc) {
      _id
      validationDow
      specificValidationDow
    }
  }
`;

export default {
  register,
  usersAdd,
  usersEdit,
  resetPassword,
  usersToggleState,
  configsSaveTemplate,
  configsSavePrequalificationDow,
  configsSaveAuditDow,
  configsSaveImprovementPlanDow,
  configsSaveDueDiligenceDow,
  configsSaveManageSuppliersDow,
  configsSaveValidationDow,
};
