import React from 'react';
import { message } from 'antd';
import PropTypes from 'prop-types';
import { ManageSuppliers } from '../components';
import { gql, compose, graphql } from 'react-apollo';
import { exportFile } from 'modules/common/components';
import generator from '../../../modules/companies/containers/list/generator';
import {
  queries as companyQueries,
  mutations as companyMutations,
} from 'modules/companies/graphql';

class ManageSuppliersContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      exportLoading: false,
    };
  }

  render() {
    const { queryParams, companyReActivateMutation, companiesQuery } = this.props;

    const exportCompanies = companies => {
      this.setState({ exportLoading: true });
      exportFile({
        query: companyQueries.exportCurrentCompanyDeActivation,
        variables: {
          search: queryParams.search,
          region: queryParams.region,
          status: queryParams.status,
          productCodes: queryParams.productCodes,
          _ids: companies,
        },
        onFinish: () => this.setState({ exportLoading: false }),
      });
    };

    const companyReActivate = (_id, email, enName, expiryDate) => {
      companyReActivateMutation({ variables: { _id, email, enName, expiryDate } })
        .then(() => {
          message.success('Successfully ReActivated');
          companiesQuery.refetch();
        })
        .catch(error => {
          message.error(error.message);
        });
    };

    const extendedProps = {
      ...this.props,
      exportCompanies,
      companyReActivate,
      exportLoading: this.state.exportLoading,
    };

    return <ManageSuppliers {...extendedProps} />;
  }
}

ManageSuppliersContainer.propTypes = {
  companiesQuery: PropTypes.object,
  queryParams: PropTypes.object,
};

const WithData = compose(
  graphql(gql(companyMutations.companyReActivate), {
    name: 'companyReActivateMutation',
  })
)(ManageSuppliersContainer);

export default generator(WithData, 'companies', () => ({
  source: 'manageSupplier',
}));
