import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Form, Input, Card, Button, Icon } from 'antd';
import Field from 'modules/common/components/ExtendedField';
import { BaseForm } from 'modules/common/components';
import { labels } from '../constants';

const groups = [
  'managingDirector',
  'executiveOfficer',
  'salesDirector',
  'financialDirector',
  'otherMember1',
  'otherMember2',
  'otherMember3',
];

class ManagementTeam extends BaseForm {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.copyAbove = this.copyAbove.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    const doc = {};

    const { viewMode, showExtendedField, form } = this.props;
    const { getFieldValue } = form;

    const setValue = group => {
      doc[group] = {};

      doc[group].name = getFieldValue(`${group}Name`);
      doc[group].jobTitle = getFieldValue(`${group}JobTitle`);
      doc[group].phone = getFieldValue(`${group}Phone`);
      doc[group].email = getFieldValue(`${group}Email`);
    };

    if (viewMode && showExtendedField) {
      ['managingDirector', 'executiveOfficer'].forEach(group => {
        setValue(group);
      });

      return this.saveDirect(doc);
    }

    groups.forEach(group => {
      setValue(group);
    });

    return this.saveDirect(doc);
  }

  copyAbove(prefix) {
    const { getFieldValue, setFieldsValue } = this.props.form;

    const group = groups[groups.indexOf(prefix) - 1];
    let values = {};

    values[`${prefix}Name`] = getFieldValue(`${group}Name`);
    values[`${prefix}JobTitle`] = getFieldValue(`${group}JobTitle`);
    values[`${prefix}Phone`] = getFieldValue(`${group}Phone`);
    values[`${prefix}Email`] = getFieldValue(`${group}Email`);

    setFieldsValue(values);
  }

  renderItem(prefix, { optional = true, showExtendedField = false, disabled = false } = {}) {
    const data = this.props.data[prefix] || {};
    const ddData = this.props.ddData[prefix] || {};
    const viewMode = this.props.viewMode;
    const { __ } = this.context;
    const fieldProps = { showExtendedField, viewMode };

    return (
      <Card
        title={__(labels[prefix])}
        extra={
          !viewMode &&
          prefix !== 'managingDirector' && (
            <Button onClick={() => this.copyAbove(prefix)}>
              <Icon type="copy" />
              {__('Copy above')}
            </Button>
          )
        }
      >
        <Field
          {...fieldProps}
          label="Full name"
          name={`${prefix}Name`}
          prefix={prefix}
          initialValue={data.name}
          ddInitialValue={ddData.name}
          optional={optional}
          control={<Input placeholder={__('First name + Last name')} disabled={disabled} />}
        />

        <Field
          {...fieldProps}
          label="Job title"
          name={`${prefix}JobTitle`}
          prefix={prefix}
          initialValue={data.jobTitle || __(labels[prefix])}
          ddInitialValue={ddData.jobTitle}
          optional={optional}
          control={<Input disabled={disabled} />}
        />

        <Field
          {...fieldProps}
          label="Phone"
          name={`${prefix}Phone`}
          prefix={prefix}
          initialValue={data.phone}
          ddInitialValue={ddData.phone}
          optional={optional}
          control={<Input type="number" disabled={disabled} />}
        />

        <Field
          {...fieldProps}
          label="E-mail"
          name={`${prefix}Email`}
          prefix={prefix}
          validation="email"
          initialValue={data.email}
          ddInitialValue={ddData.email}
          optional={optional}
          canBeCryllic={false}
          control={<Input disabled={disabled} />}
        />
      </Card>
    );
  }

  render() {
    const { showExtendedField, disabled } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        {this.renderItem('managingDirector', { optional: false, showExtendedField, disabled })}
        {this.renderItem('executiveOfficer', { showExtendedField, disabled })}
        {this.renderItem('salesDirector')}
        {this.renderItem('financialDirector')}

        {this.renderItem('otherMember1')}
        {this.renderItem('otherMember2')}
        {this.renderItem('otherMember3')}

        {this.renderGoBack()}
        {this.renderSubmit()}
      </Form>
    );
  }
}

ManagementTeam.contextTypes = {
  __: PropTypes.func,
};

const ManagementTeamForm = Form.create()(ManagementTeam);

export default withRouter(ManagementTeamForm);
