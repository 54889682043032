import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Select } from 'antd';
import { Uploader } from 'modules/common/components';
import { riskData } from './constants';

const FormItem = Form.Item;

class RiskModal extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);

    this.state = { files: null };
  }

  handleSubmit(e) {
    e.preventDefault();

    const { form, onSave } = this.props;
    const { files } = this.state;

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSave({ files, ...values });
      }
    });
  }

  onFileUpload(files) {
    this.setState({ files });
  }

  renderOptions() {
    return riskData.map((option, i) => (
      <Select.Option key={i} value={option.value}>
        {option.text}
      </Select.Option>
    ));
  }

  render() {
    const { form, hide, company } = this.props;
    const { getFieldDecorator } = form;

    if (!company) {
      return null;
    }

    return (
      <Modal
        title={company.basicInfo.enName}
        onOk={this.handleSubmit}
        visible={true}
        maskClosable={false}
        onCancel={hide}
        bodyStyle={{ maxHeight: '60vh', overflow: 'scroll' }}
      >
        <Form onSubmit={this.handleSubmit}>
          <FormItem label="Due diligence report">
            {getFieldDecorator('files', {})(<Uploader onChange={this.onFileUpload} />)}
          </FormItem>

          <FormItem label="Risk Rating">
            {getFieldDecorator('risk', {
              rules: [{ required: true, message: 'This field is required!' }],
            })(<Select>{this.renderOptions()}</Select>)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

RiskModal.propTypes = {
  form: PropTypes.object,
  company: PropTypes.object,
  hide: PropTypes.func,
  onSave: PropTypes.func,
};

export default Form.create()(RiskModal);
