import React from 'react';
import Sidebar from '../../companies/components/list/Sidebar';
import { Search } from 'modules/common/components';
import Common from 'modules/companies/components/list/Common';
import { Card, Row, Col, Button, Icon, Select, Divider, Form, DatePicker, Popover } from 'antd';
import queryString from 'query-string';
import router from 'modules/common/router';
import { riskValue } from 'modules/dueDiligence/components/constants';
import moment from 'moment';
import { dateFormat } from 'modules/common/constants';
import { alert } from '../../common/utils';
import { withRouter } from 'react-router';

const Option = Select.Option;
class ManageSuppliers extends Common {
  constructor(props) {
    super(props);

    const { history } = props;
    const query = queryString.parse(history.location.search);

    this.state = {
      ...this.state,
      checkedCount: 0,
      selectedSuppliers: [],
      activationStatus: query.activationStatus || '',
      deActivateReasonModal: true,
      selectedCompanyId: null,
      text: '',
      reason: '',
      isField: false,
      modalShow: false,
      companyId: null,
      reActiveDate: moment(),
    };

    this._isMounted = false;
    this.handleCheck = this.handleCheck.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleCheck(companyIds, selectedSuppliers) {
    this.setState({ selectedSuppliers });

    this.onSelectedCompaniesChange(companyIds);
  }

  handleSelect(value, name) {
    this.setState({ [name]: value }, () => {
      this.filter({ [name]: value });
    });
  }

  filter(filter) {
    const { history } = this.props;
    const { activationStatus } = this.state;

    const filterValues = {
      ...filter,
      activationStatus,
    };

    router.setParams(history, filterValues);
  }

  renderActivationStatus() {
    const { activationStatus } = this.state;

    return (
      <tr key="activationStatus">
        <td>
          <label>Activation status</label>
        </td>
        <td>
          <Select
            onChange={e => this.handleSelect(e, 'activationStatus')}
            size="small"
            value={activationStatus}
          >
            <Option value="deactivated">De-Activated</Option>
            <Option value="reactivated">Re-Activated</Option>
            <Option value="completed">Completed</Option>
            <Option value="">All</Option>
          </Select>
        </td>
      </tr>
    );
  }

  renderViewLink({ _id }) {
    return <a href={`/view-registration/${_id}?manageSuppliers`}>View</a>;
  }
  handleCloseDateChange = value => {
    this.setState({ reActiveDate: value });
  };

  renderDatePicker = (_id, email, enName) => {
    const { reActiveDate } = this.state;
    return (
      <>
        <DatePicker
          defaultPickerValue={reActiveDate}
          showTime={false}
          format={'YYYY/MM/DD'}
          placeholder="Choose date"
          onChange={this.handleCloseDateChange}
        />
        <Button
          size="small"
          type="primary"
          style={{ backgroundColor: '#F85F3E', color: 'white', marginLeft: '5px' }}
          onClick={() => this.props.companyReActivate(_id, email, enName, reActiveDate)}
        >
          Ok
        </Button>
      </>
    );
  };

  renderActiveStatusButton({ deActiveStatus, basicInfo, _id, isSentRequiredInformation }) {
    const { status, supplierSentReActivate } = deActiveStatus || {};

    let text = basicInfo && basicInfo.enName && basicInfo.enName.toUpperCase();

    if (
      ['deactivated', 'notPrequalified', 'reactivated'].includes(status) &&
      !supplierSentReActivate
    ) {
      if (status === 'notPrequalified') {
        return (
          <Button
            size="small"
            type="primary"
            style={{ backgroundColor: '#F85F3E', color: 'white' }}
            onClick={() => alert.error(text + ` company status has changed and it's been a week`)}
          >
            Deactivated
          </Button>
        );
      }
      return (
        <Button
          size="small"
          type="primary"
          onClick={() => alert.error(text + ` company status has changed and it's been a week`)}
        >
          Reactivated
        </Button>
      );
    }

    if (supplierSentReActivate && status === 'notPrequalified') {
      return (
        <Popover
          style={{ backgroundColor: '#F85F3E', color: 'white' }}
          content={this.renderDatePicker(_id, basicInfo.email, basicInfo.enName)}
          trigger="click"
        >
          <Button
            size="small"
            type="primary"
            style={{ backgroundColor: '#F85F3E', color: 'white' }}
          >
            Deactivated
          </Button>
        </Popover>
      );
    }

    if (!isSentRequiredInformation && status === 'completed') {
      text = text + ` company submitted Registration & Pre-Qualification`;
      return (
        <Button
          size="small"
          style={{ backgroundColor: '#64B752', color: 'white' }}
          onClick={() => alert.success(text)}
        >
          Reactivated
        </Button>
      );
    }

    return (
      <a href="#reactivated" style={{ color: '#808080' }}>
        Reactivated
      </a>
    );
  }

  renderOperation(record) {
    return (
      <div>
        {this.renderViewLink(record)}
        <Divider key={0} type="vertical" />
        {this.renderActiveStatusButton(record)}
      </div>
    );
  }

  render() {
    const { exportCompanies, exportLoading, totalCount } = this.props;
    const { selectedCompanies } = this.state;

    let columns = this.getWrappedColumns([
      {
        title: 'Qualification status',
        width: 40,
        dataIndex: 'qualificationStatusDisplay',
      },
      {
        title: 'Validation status',
        width: 40,
        dataIndex: 'productsInfoValidationStatusDisplay',
      },
      {
        title: 'Block status',
        width: 40,
        render: record => record.isBlocked,
      },
      {
        title: 'Due dilligence',
        width: 40,
        render: record => {
          const { risk } = record.lastDueDiligence || {};

          if (!risk) return 'n/a';

          return <span className={`small-label capitalize ${risk}`}>{riskValue[risk]}</span>;
        },
      },
      {
        title: 'DIFOT score (average)',
        width: 40,
        render: record =>
          record.averageDifotScore ? `${record.averageDifotScore.toFixed(1)}%` : '-',
        sorter: true,
        key: 'averageDifotScore',
      },
      {
        title: 'Owner',
        width: 50,
        render: record => (record.owner ? record.owner.email : '-'),
      },
      {
        title: 'De-Activated Date',
        width: 80,
        render: record => {
          if (!record.deActiveStatus) {
            return '-';
          }

          return moment(record.deActiveStatus.deActivatedDate).format(dateFormat);
        },
      },
      {
        title: 'Supplier re-Activated Date',
        width: 80,
        render: record => {
          if (!record.deActiveStatus) {
            return '-';
          }

          return record.deActiveStatus.reActivatedDate
            ? moment(record.deActiveStatus.reActivatedDate).format(dateFormat)
            : '-';
        },
      },

      {
        title: 'Ot re-Activated Date',
        width: 80,
        render: record => {
          if (!record.deActiveStatus) {
            return '-';
          }

          return record.deActiveStatus.adminSentReActivateDate
            ? moment(record.deActiveStatus.adminSentReActivateDate).format(dateFormat)
            : '-';
        },
      },
      { title: 'Actions', width: 80, render: (text, record) => this.renderOperation(record) },
      {},
    ]);

    columns = columns.slice(0, 15);
    return (
      <Row gutter={16}>
        <Sidebar
          suppliersCount={totalCount}
          checkedCount={selectedCompanies ? selectedCompanies.length : 0}
          renderActivationStatus={this.renderActivationStatus.bind(this)}
        />

        <Col span={19}>
          <Card title="Suppliers">
            <div className="table-operations">
              <Search />
              {this.renderSearchButtons()}

              <Button loading={exportLoading} onClick={() => exportCompanies(selectedCompanies)}>
                Export to excel
                <Icon type="file-excel" />
              </Button>
            </div>

            {this.renderTable({
              rowSelection: {
                selectedCompanies,
                onChange: this.handleCheck,
              },
              columns,
            })}
          </Card>
        </Col>
      </Row>
    );
  }
}

const ManageSuppliersForm = Form.create()(ManageSuppliers);

export default withRouter(ManageSuppliersForm);
