/*eslint-disable max-len */

export const structureData = [
  { text: 'Company or Incorporated', value: 'Company or Incorporated' },
  { text: 'Partnership', value: 'Partnership' },
  { text: 'OEM', value: 'OEM' },
  { text: 'Trust', value: 'Trust' },
  { text: 'Government Owned Enterprise', value: 'Government Owned Enterprise' },
  {
    text: 'Non-Governmental Organization (NGO)',
    value: 'Non-Governmental Organization (NGO)',
  },
  { text: 'Sole trader or Individual', value: 'Sole Trader' },
  { text: 'Other', value: 'Other' },
];

export const foreignPercentageData = [
  { text: '0% (Mongolian only)', value: '0% (Mongolian only)' },
  { text: '1-24%', value: '1-24%' },
  { text: '25-49%', value: '25-49%' },
  { text: '50-74%', value: '50-74%' },
  { text: '75-99%', value: '75-99%' },
  { text: '100% (Foreign only)', value: '100% (Foreign only)' },
];

export const booleanData = [{ text: 'Yes', value: 'true' }, { text: 'No', value: 'false' }];

export const descriptions = {
  certificateOfRegistration:
    'You may upload jpg, jpeg, png, rtf, pdf files, or simple Adobe PDF files. Files that have the ability to contain macros or other types of active code are not acceptable. Maximum file size is 30mb.',

  email: `
    Please fill in generic shared email. Receive an interest in the announcement
    of the OT Procurement Depertment and other notices only by e-mail. Please
    note that OT will not be responsible for non-EOI receipts due to the
    personal e-mail entry.
  `,
};

export const labels = {
  foreignOwnershipPercentage:
    '10. Please indicate what percentage of company is owned by foreign entity',

  corporateStructure: `Business type`,

  managingDirector: `15. Managing director`,
  executiveOfficer: `16. Executive officer`,
  salesDirector: `17. Sales director`,
  financialDirector: `18. Financial director`,
  otherMember1: `19. Other management team member`,
  otherMember2: `Other management team member 2`,
  otherMember3: `Other management team member 3`,
};

export const certLabels = {
  description: 'Request',
  isOTSupplier: `Are you an existing supplier to OT?`,
  cwpo: `Please provide CW and PO number`,
};

export const groupLabels = {
  head:
    'Please provide details of your Ultimate Parent Company or any associated companies if applicable.',

  isParentExistingSup: `Is your parent company existing supplier in OT`,

  hasParent: `22. Do you have an Ultimate Parent Company?`,

  parentName: `Ultimate parent company`,

  parentAddress: `Ultimate Parent Company address`,

  parentRegistrationNumber: `Registration number of Ultimate Parent Company`,

  role: `23. Are you a Manufacturer, Distributor or Service Provider?`,

  isExclusiveDistributor: `Are you an exclusive distributor?`,

  attachments: `Please list names of authorized distribution rights /EOM/`,

  primaryManufacturerName: `Primary manufacturer name`,

  countryOfPrimaryManufacturer: `Country of primary manufacture`,
};

export const productDescription =
  'The product code is structured in a 3 level: Family Sect Code > Macro Sect Code > Sect Code. Clicking on the check boxes will expand the product codes. You can only select at “Sect Code” level.; Alternatively, you can use the search tool by free text to find potential matches of your products and services.; Adding and Removing product codes: To add a Product / Service click on the check box. To remove simply un tick the box, or click the “x”.';

export const productReminder = `Please be informed that some of your selected code(s) will be validated and locked from OT. if you would like to change the validated/locked code(s) please send your request via below email.`;

export const aimagData = [
  { text: 'Ulaanbaatar', value: 'Ulaanbaatar' },
  { text: 'Arkhangai', value: 'Arkhangai' },
  { text: 'Bayankhongor', value: 'Bayankhongor' },
  { text: 'Bayan-Olgii', value: 'Bayan-Olgii' },
  { text: 'Bulgan', value: 'Bulgan' },
  { text: 'Darkhan-Uul', value: 'Darkhan-Uul' },
  { text: 'Dornod', value: 'Dornod' },
  { text: 'Dornogovi', value: 'Dornogovi' },
  { text: 'Dundgovi', value: 'Dundgovi' },
  { text: 'Govi-Altai', value: 'Govi-Altai' },
  { text: 'Govisümber', value: 'Govisümber' },
  { text: 'Khentii', value: 'Khentii' },
  { text: 'Khovd', value: 'Khovd' },
  { text: 'Khovsgol', value: 'Khovsgol' },
  { text: 'Orkhon', value: 'Orkhon' },
  { text: 'Umnugovi', value: 'Umnugovi' },
  { text: 'Ovorkhangai', value: 'Ovorkhangai' },
  { text: 'Selenge', value: 'Selenge' },
  { text: 'Sükhbaatar', value: 'Sükhbaatar' },
  { text: 'Tov', value: 'Tov' },
  { text: 'Uvs', value: 'Uvs' },
  { text: 'Zavkhan', value: 'Zavkhan' },
];

export const soumData = [
  { text: 'Bayan-Ovoo', value: 'Bayan-Ovoo' },
  { text: 'Bayandalai', value: 'Bayandalai' },
  { text: 'Bulgan', value: 'Bulgan' },
  { text: 'Dalanzadgad', value: 'Dalanzadgad' },
  { text: 'Gurvan tes', value: 'Gurvan tes' },
  { text: 'Khanbogd', value: 'Khanbogd' },
  { text: 'Khan khongor', value: 'Khan khongor' },
  { text: 'Khurmen', value: 'Khurmen' },
  { text: 'Mandal-Ovoo', value: 'Mandal-Ovoo' },
  { text: 'Manlai', value: 'Manlai' },
  { text: 'Nomgon', value: 'Nomgon' },
  { text: 'Noyon', value: 'Noyon' },
  { text: 'Sevrei', value: 'Sevrei' },
  { text: 'Tsogt-Ovoo', value: 'Tsogt-Ovoo' },
  { text: 'Tsogttsetsii', value: 'Tsogttsetsii' },
];

export const countryData = [
  { text: 'Afghanistan', value: 'AF' },
  { text: 'Åland Islands', value: 'AX' },
  { text: 'Albania', value: 'AL' },
  { text: 'Algeria', value: 'DZ' },
  { text: 'American Samoa', value: 'AS' },
  { text: 'AndorrA', value: 'AD' },
  { text: 'Angola', value: 'AO' },
  { text: 'Anguilla', value: 'AI' },
  { text: 'Antarctica', value: 'AQ' },
  { text: 'Antigua and Barbuda', value: 'AG' },
  { text: 'Argentina', value: 'AR' },
  { text: 'Armenia', value: 'AM' },
  { text: 'Aruba', value: 'AW' },
  { text: 'Australia', value: 'AU' },
  { text: 'Austria', value: 'AT' },
  { text: 'Azerbaijan', value: 'AZ' },
  { text: 'Bahamas', value: 'BS' },
  { text: 'Bahrain', value: 'BH' },
  { text: 'Bangladesh', value: 'BD' },
  { text: 'Barbados', value: 'BB' },
  { text: 'Belarus', value: 'BY' },
  { text: 'Belgium', value: 'BE' },
  { text: 'Belize', value: 'BZ' },
  { text: 'Benin', value: 'BJ' },
  { text: 'Bermuda', value: 'BM' },
  { text: 'Bhutan', value: 'BT' },
  { text: 'Bolivia', value: 'BO' },
  { text: 'Bosnia and Herzegovina', value: 'BA' },
  { text: 'Botswana', value: 'BW' },
  { text: 'Bouvet Island', value: 'BV' },
  { text: 'Brazil', value: 'BR' },
  { text: 'British Indian Ocean Territory', value: 'IO' },
  { text: 'Brunei Darussalam', value: 'BN' },
  { text: 'Bulgaria', value: 'BG' },
  { text: 'Burkina Faso', value: 'BF' },
  { text: 'Burundi', value: 'BI' },
  { text: 'Cambodia', value: 'KH' },
  { text: 'Cameroon', value: 'CM' },
  { text: 'Canada', value: 'CA' },
  { text: 'Cape Verde', value: 'CV' },
  { text: 'Cayman Islands', value: 'KY' },
  { text: 'Central African Republic', value: 'CF' },
  { text: 'Chad', value: 'TD' },
  { text: 'Chile', value: 'CL' },
  { text: 'China', value: 'CN' },
  { text: 'Christmas Island', value: 'CX' },
  { text: 'Cocos (Keeling) Islands', value: 'CC' },
  { text: 'Colombia', value: 'CO' },
  { text: 'Comoros', value: 'KM' },
  { text: 'Congo', value: 'CG' },
  { text: 'Congo, The Democratic Republic of the', value: 'CD' },
  { text: 'Cook Islands', value: 'CK' },
  { text: 'Costa Rica', value: 'CR' },
  { text: "Cote D'Ivoire", value: 'CI' },
  { text: 'Croatia', value: 'HR' },
  { text: 'Cuba', value: 'CU' },
  { text: 'Cyprus', value: 'CY' },
  { text: 'Czech Republic', value: 'CZ' },
  { text: 'Denmark', value: 'DK' },
  { text: 'Djibouti', value: 'DJ' },
  { text: 'Dominica', value: 'DM' },
  { text: 'Dominican Republic', value: 'DO' },
  { text: 'Ecuador', value: 'EC' },
  { text: 'Egypt', value: 'EG' },
  { text: 'El Salvador', value: 'SV' },
  { text: 'Equatorial Guinea', value: 'GQ' },
  { text: 'Eritrea', value: 'ER' },
  { text: 'Estonia', value: 'EE' },
  { text: 'Ethiopia', value: 'ET' },
  { text: 'Falkland Islands (Malvinas)', value: 'FK' },
  { text: 'Faroe Islands', value: 'FO' },
  { text: 'Fiji', value: 'FJ' },
  { text: 'Finland', value: 'FI' },
  { text: 'France', value: 'FR' },
  { text: 'French Guiana', value: 'GF' },
  { text: 'French Polynesia', value: 'PF' },
  { text: 'French Southern Territories', value: 'TF' },
  { text: 'Gabon', value: 'GA' },
  { text: 'Gambia', value: 'GM' },
  { text: 'Georgia', value: 'GE' },
  { text: 'Germany', value: 'DE' },
  { text: 'Ghana', value: 'GH' },
  { text: 'Gibraltar', value: 'GI' },
  { text: 'Greece', value: 'GR' },
  { text: 'Greenland', value: 'GL' },
  { text: 'Grenada', value: 'GD' },
  { text: 'Guadeloupe', value: 'GP' },
  { text: 'Guam', value: 'GU' },
  { text: 'Guatemala', value: 'GT' },
  { text: 'Guernsey', value: 'GG' },
  { text: 'Guinea', value: 'GN' },
  { text: 'Guinea-Bissau', value: 'GW' },
  { text: 'Guyana', value: 'GY' },
  { text: 'Haiti', value: 'HT' },
  { text: 'Heard Island and Mcdonald Islands', value: 'HM' },
  { text: 'Holy See (Vatican City State)', value: 'VA' },
  { text: 'Honduras', value: 'HN' },
  { text: 'Hong Kong', value: 'HK' },
  { text: 'Hungary', value: 'HU' },
  { text: 'Iceland', value: 'IS' },
  { text: 'India', value: 'IN' },
  { text: 'Indonesia', value: 'ID' },
  { text: 'Iran, Islamic Republic Of', value: 'IR' },
  { text: 'Iraq', value: 'IQ' },
  { text: 'Ireland', value: 'IE' },
  { text: 'Isle of Man', value: 'IM' },
  { text: 'Israel', value: 'IL' },
  { text: 'Italy', value: 'IT' },
  { text: 'Jamaica', value: 'JM' },
  { text: 'Japan', value: 'JP' },
  { text: 'Jersey', value: 'JE' },
  { text: 'Jordan', value: 'JO' },
  { text: 'Kazakhstan', value: 'KZ' },
  { text: 'Kenya', value: 'KE' },
  { text: 'Kiribati', value: 'KI' },
  { text: "Korea, Democratic People'S Republic of", value: 'KP' },
  { text: 'Korea, Republic of', value: 'KR' },
  { text: 'Kuwait', value: 'KW' },
  { text: 'Kyrgyzstan', value: 'KG' },
  { text: "Lao People'S Democratic Republic", value: 'LA' },
  { text: 'Latvia', value: 'LV' },
  { text: 'Lebanon', value: 'LB' },
  { text: 'Lesotho', value: 'LS' },
  { text: 'Liberia', value: 'LR' },
  { text: 'Libyan Arab Jamahiriya', value: 'LY' },
  { text: 'Liechtenstein', value: 'LI' },
  { text: 'Lithuania', value: 'LT' },
  { text: 'Luxembourg', value: 'LU' },
  { text: 'Macao', value: 'MO' },
  { text: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
  { text: 'Madagascar', value: 'MG' },
  { text: 'Malawi', value: 'MW' },
  { text: 'Malaysia', value: 'MY' },
  { text: 'Maldives', value: 'MV' },
  { text: 'Mali', value: 'ML' },
  { text: 'Malta', value: 'MT' },
  { text: 'Marshall Islands', value: 'MH' },
  { text: 'Martinique', value: 'MQ' },
  { text: 'Mauritania', value: 'MR' },
  { text: 'Mauritius', value: 'MU' },
  { text: 'Mayotte', value: 'YT' },
  { text: 'Mexico', value: 'MX' },
  { text: 'Micronesia, Federated States of', value: 'FM' },
  { text: 'Moldova, Republic of', value: 'MD' },
  { text: 'Monaco', value: 'MC' },
  { text: 'Mongolia', value: 'MN' },
  { text: 'Montserrat', value: 'MS' },
  { text: 'Morocco', value: 'MA' },
  { text: 'Mozambique', value: 'MZ' },
  { text: 'Myanmar', value: 'MM' },
  { text: 'Namibia', value: 'NA' },
  { text: 'Nauru', value: 'NR' },
  { text: 'Nepal', value: 'NP' },
  { text: 'Netherlands', value: 'NL' },
  { text: 'Netherlands Antilles', value: 'AN' },
  { text: 'New Caledonia', value: 'NC' },
  { text: 'New Zealand', value: 'NZ' },
  { text: 'Nicaragua', value: 'NI' },
  { text: 'Niger', value: 'NE' },
  { text: 'Nigeria', value: 'NG' },
  { text: 'Niue', value: 'NU' },
  { text: 'Norfolk Island', value: 'NF' },
  { text: 'Northern Mariana Islands', value: 'MP' },
  { text: 'Norway', value: 'NO' },
  { text: 'Oman', value: 'OM' },
  { text: 'Pakistan', value: 'PK' },
  { text: 'Palau', value: 'PW' },
  { text: 'Palestinian Territory, Occupied', value: 'PS' },
  { text: 'Panama', value: 'PA' },
  { text: 'Papua New Guinea', value: 'PG' },
  { text: 'Paraguay', value: 'PY' },
  { text: 'Peru', value: 'PE' },
  { text: 'Philippines', value: 'PH' },
  { text: 'Pitcairn', value: 'PN' },
  { text: 'Poland', value: 'PL' },
  { text: 'Portugal', value: 'PT' },
  { text: 'Puerto Rico', value: 'PR' },
  { text: 'Qatar', value: 'QA' },
  { text: 'Reunion', value: 'RE' },
  { text: 'Romania', value: 'RO' },
  { text: 'Russian Federation', value: 'RU' },
  { text: 'RWANDA', value: 'RW' },
  { text: 'Saint Helena', value: 'SH' },
  { text: 'Saint Kitts and Nevis', value: 'KN' },
  { text: 'Saint Lucia', value: 'LC' },
  { text: 'Saint Pierre and Miquelon', value: 'PM' },
  { text: 'Saint Vincent and the Grenadines', value: 'VC' },
  { text: 'Samoa', value: 'WS' },
  { text: 'San Marino', value: 'SM' },
  { text: 'Sao Tome and Principe', value: 'ST' },
  { text: 'Saudi Arabia', value: 'SA' },
  { text: 'Senegal', value: 'SN' },
  { text: 'Serbia and Montenegro', value: 'CS' },
  { text: 'Seychelles', value: 'SC' },
  { text: 'Sierra Leone', value: 'SL' },
  { text: 'Singapore', value: 'SG' },
  { text: 'Slovakia', value: 'SK' },
  { text: 'Slovenia', value: 'SI' },
  { text: 'Solomon Islands', value: 'SB' },
  { text: 'Somalia', value: 'SO' },
  { text: 'South Africa', value: 'ZA' },
  { text: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { text: 'Spain', value: 'ES' },
  { text: 'Sri Lanka', value: 'LK' },
  { text: 'Sudan', value: 'SD' },
  { text: 'Suriname', value: 'SR' },
  { text: 'Svalbard and Jan Mayen', value: 'SJ' },
  { text: 'Swaziland', value: 'SZ' },
  { text: 'Sweden', value: 'SE' },
  { text: 'Switzerland', value: 'CH' },
  { text: 'Syrian Arab Republic', value: 'SY' },
  { text: 'Taiwan, Province of China', value: 'TW' },
  { text: 'Tajikistan', value: 'TJ' },
  { text: 'Tanzania, United Republic of', value: 'TZ' },
  { text: 'Thailand', value: 'TH' },
  { text: 'Timor-Leste', value: 'TL' },
  { text: 'Togo', value: 'TG' },
  { text: 'Tokelau', value: 'TK' },
  { text: 'Tonga', value: 'TO' },
  { text: 'Trinidad and Tobago', value: 'TT' },
  { text: 'Tunisia', value: 'TN' },
  { text: 'Turkey', value: 'TR' },
  { text: 'Turkmenistan', value: 'TM' },
  { text: 'Turks and Caicos Islands', value: 'TC' },
  { text: 'Tuvalu', value: 'TV' },
  { text: 'Uganda', value: 'UG' },
  { text: 'Ukraine', value: 'UA' },
  { text: 'United Arab Emirates', value: 'AE' },
  { text: 'United Kingdom', value: 'GB' },
  { text: 'United States', value: 'US' },
  { text: 'United States Minor Outlying Islands', value: 'UM' },
  { text: 'Uruguay', value: 'UY' },
  { text: 'Uzbekistan', value: 'UZ' },
  { text: 'Vanuatu', value: 'VU' },
  { text: 'Venezuela', value: 'VE' },
  { text: 'Viet Nam', value: 'VN' },
  { text: 'Virgin Islands, British', value: 'VG' },
  { text: 'Virgin Islands, U.S.', value: 'VI' },
  { text: 'Wallis and Futuna', value: 'WF' },
  { text: 'Western Sahara', value: 'EH' },
  { text: 'Yemen', value: 'YE' },
  { text: 'Zambia', value: 'ZM' },
  { text: 'Zimbabwe', value: 'ZW' },
];

export const shareholderType = [
  { text: 'Individual', value: 'individual' },
  { text: 'Company', value: 'company' },
];
