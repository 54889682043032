import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Modal, Form, Input, Checkbox, Icon, Radio, Row, Tag } from 'antd';
import { Paginator } from 'modules/common/components';
import router from 'modules/common/router';
import { getColor } from '../utils';

const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;
const RadioGroup = Radio.Group;

const propTypes = {
  history: PropTypes.object,
  data: PropTypes.array,
  totalCount: PropTypes.number,
  pagination: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

export default class Common extends React.Component {
  constructor(props) {
    super(props);

    this.state = { selectedCompanies: [], showModal: false };

    this.onSelectedCompaniesChange = this.onSelectedCompaniesChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.toggleSearchModal = this.toggleSearchModal.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.renderCompanyName = this.renderCompanyName.bind(this);
  }

  handleTableChange(pagination, filter, sorter) {
    const columnKey = sorter.columnKey;

    if (columnKey) {
      router.setParams(this.props.history, {
        sortField: columnKey,
        sortDirection: sorter.order === 'descend' ? -1 : 1,
      });
    }
  }

  onSelectedCompaniesChange(selectedCompanies) {
    this.setState({ selectedCompanies });
  }

  toggleSearchModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  clearSearch() {
    const { history } = this.props;

    history.push();
  }

  renderSearchButtons() {
    return (
      <Radio.Group className="search-btn">
        <Radio.Button onClick={() => this.toggleSearchModal()}>
          <Icon type="search" />
        </Radio.Button>
        <Radio.Button onClick={this.clearSearch}>
          <Icon type="close-circle" />
        </Radio.Button>
      </Radio.Group>
    );
  }

  renderCompanyName(company) {
    const color = getColor(company);
    const companyName = ((company.basicInfo || {}).enName || '').toUpperCase();

    if (!color) return companyName;

    return <Tag color={color}>{companyName}</Tag>;
  }

  getWrappedColumns(middleColumns = [], backColumns = [], checkDuplicate = false, isHide = false) {
    return [
      {
        key: 'supplierName',
        title: 'Supplier name',
        width: 160,
        fixed: 'left',
        render: record => {
          return checkDuplicate
            ? this.renderCompanyName(record)
            : ((record.basicInfo || {}).enName || '').toUpperCase();
        },
        fieldName: 'companyName',
      },
      {
        key: 'venderNumber',
        title: 'Vendor number',
        dataIndex: 'basicInfo.sapNumber',
        width: 100,
      },
      {
        key: 'tierType',
        title: 'Tier type',
        dataIndex: 'tierTypeDisplay',
        width: 40,
      },
      {
        title: 'DD verification status',
        width: 40,
        render: record => <span className="small-label">{record.dueDiligenceStatusDisplay}</span>,
      },
      {
        key: 'prequalificationStatus',
        title: 'Pre-qualification',
        width: 40,
        render: record => (
          <Link to={`/prequalification-status/${record._id}?view`}>
            {record.prequalificationStatusDisplay}
          </Link>
        ),
      },
      ...middleColumns,

      isHide
        ? {}
        : {
            key: 'contactPerson',
            title: 'Contact person',
            dataIndex: 'contactInfo.name',
            width: 60,
          },
      {
        key: 'email',
        title: 'Email address',
        dataIndex: 'contactInfo.email',
        fieldName: 'email',
        width: 60,
      },
      {
        key: 'phoneNumber',
        title: 'Phone number',
        dataIndex: 'contactInfo.phone',
        fieldName: 'phone',
        width: 60,
      },
      ...backColumns,
    ];
  }

  renderTable(extraProps) {
    const { loading, totalCount, data, history } = this.props;
    const columns = extraProps.columns;
    const fieldOptions = [];
    const fieldOptionKeys = [];

    columns.forEach(column => {
      const key = column.fieldName;

      if (key) {
        fieldOptions.push({ label: column.title, value: key });
        fieldOptionKeys.push(key);
      }
    });

    const extraOptions = [
      { label: 'Registration number', value: 'registrationNumber' },
      { label: 'Address', value: 'address' },
      { label: 'Full name', value: 'fullName' },
      { label: 'First name', value: 'firstName' },
      { label: 'Last name', value: 'lastName' },
    ];

    extraOptions.forEach(column => {
      fieldOptions.push(column);
      fieldOptionKeys.push(column.value);
    });

    const props = {
      ...extraProps,
      dataSource: data,
      rowKey: record => record._id,
      pagination: false,
      loading,
      scroll: { x: 1224 },
      onChange: this.handleTableChange,
    };

    return (
      <div>
        <Table {...props} />
        <Paginator total={totalCount} />
        <SearchForm
          show={this.state.showModal}
          hide={this.toggleSearchModal}
          history={history}
          fieldOptions={fieldOptions}
          fieldOptionKeys={fieldOptionKeys}
        />
      </div>
    );
  }
}

class SearchModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkAll: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFullSearch = this.handleFullSearch.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCheckAllChange = this.onCheckAllChange.bind(this);
  }

  onChange(checkedList) {
    this.setState({
      checkAll: checkedList.length === this.props.fieldOptionKeys.length,
    });
  }

  onCheckAllChange(e) {
    this.props.form.setFieldsValue({
      fieldNames: e.target.checked ? this.props.fieldOptionKeys : [],
    });

    this.setState({
      checkAll: e.target.checked,
    });
  }

  handleSort(order) {
    router.setParams(this.props.history, { sortDirection: order });
  }

  handleFullSearch(values) {
    const query = {};

    for (const key in values) {
      const val = values[key];
      if (val) {
        query[key] = val.toString().replace('&', ',');
      }
    }

    router.setParams(this.props.history, query);
  }

  handleSubmit(e) {
    e.preventDefault();

    const { form, hide } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.handleFullSearch(values);
        hide();
      }
    });
  }

  render() {
    const { show, hide, form, fieldOptions } = this.props;
    const { getFieldDecorator } = form;

    if (!show) return null;

    const args = {
      rules: [{ required: true, message: 'This field is required!' }],
    };

    return (
      <Modal
        title="Search"
        okText="Search"
        onOk={this.handleSubmit}
        visible={true}
        maskClosable={false}
        onCancel={hide}
        bodyStyle={{ maxHeight: '60vh', overflow: 'scroll' }}
      >
        <Form onSubmit={this.handleSubmit}>
          <FormItem label="Sort">
            {getFieldDecorator('sortDirection', { ...args, initialValue: -1 })(
              <RadioGroup>
                <Radio.Button value={1}>
                  <Icon type="caret-up" />
                  &nbsp;Ascending
                </Radio.Button>
                <Radio.Button value={-1}>
                  <Icon type="caret-down" />
                  &nbsp; Descending
                </Radio.Button>
              </RadioGroup>
            )}
          </FormItem>

          <FormItem label="Search">
            {getFieldDecorator('searchValue', {})(<Input placeholder="value" />)}
          </FormItem>

          <Row>
            <Checkbox onChange={this.onCheckAllChange} checked={this.state.checkAll}>
              Select all
            </Checkbox>
          </Row>

          <FormItem label="Fields">
            {getFieldDecorator('fieldNames', args)(
              <CheckboxGroup
                options={fieldOptions}
                className="horizontal"
                onChange={this.onChange}
              />
            )}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

SearchModal.propTypes = {
  form: PropTypes.object,
  show: PropTypes.bool,
  hide: PropTypes.func,
  onSearch: PropTypes.func,
  history: PropTypes.object,
  fieldOptions: PropTypes.array,
  fieldOptionKeys: PropTypes.array,
};

const SearchForm = Form.create()(SearchModal);

Common.propTypes = propTypes;
