import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Modal, Select, TreeSelect } from 'antd';
import moment from 'moment';
import { Uploader } from '../../../common/components';
import { alert } from 'modules/common/utils';

const FormItem = Form.Item;
const Option = Select.Option;

const propTypes = {
  form: PropTypes.object.isRequired,
  user: PropTypes.object,
  mainAction: PropTypes.func,
  handleCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  permissionModules: PropTypes.array.isRequired,
};

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      respondedFiles: ((this.props.user && this.props.user.attachments) || []).map(a => ({ ...a })),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onServiceFileUpload = this.onServiceFileUpload.bind(this);
  }

  handleCancel() {
    this.props.onClose();
  }

  onInputChange(name, value) {
    this[name] = value;
  }

  handleSubmit(e) {
    e.preventDefault();

    const { user, form } = this.props;

    form.validateFieldsAndScroll((err, data) => {
      if (err) {
        return;
      }

      if (user) {
        data._id = user._id;
      }

      const [first, last] = data.username.split(' ');
      if (!first || !last) return alert.error('Please fill Firstname and Lastname!');

      data.attachments = this.state.respondedFiles;
      data.username = first + ' ' + last;

      this.props.mainAction(data);
    });
  }

  onServiceFileUpload(files) {
    this.setState({ respondedFiles: files });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { permissionModules, userPermissions, currentUserQuery, title } = this.props;
    const { currentUser } = currentUserQuery;
    const { respondedFiles } = this.state;

    const user = this.props.user || {};

    return (
      <div>
        <Modal
          title={title}
          visible={true}
          maskClosable={false}
          width={700}
          okText="Save"
          onCancel={this.handleCancel}
          onOk={this.handleSubmit}
          bodyStyle={{ maxHeight: '65vh', overflow: 'scroll' }}
        >
          <p>
            Ensure you complete all the required fields before clicking ‘Save User Details’. After
            saving you have the option of making the person
          </p>

          <Form className="user-register-form">
            <FormItem label="Role">
              {getFieldDecorator('role', {
                initialValue: user.role || 'contributor',
                rules: [{ required: true, message: 'Please select role!' }],
              })(
                <Select placeholder="Select a option">
                  <Option value="admin">Admin</Option>
                  <Option value="contributor">Contributor</Option>
                  <Option value="manager">Manager</Option>
                  <Option value="superintend">Superintend</Option>
                </Select>
              )}
            </FormItem>
            <FormItem label="Permissions">
              {getFieldDecorator('permissions', {
                initialValue: userPermissions || 'permissions',
              })(
                <TreeSelect
                  treeData={permissionModules}
                  onChange={value => this.onInputChange('permissions', value)}
                  treeCheckable={true}
                  searchPlaceholder="Please select"
                  style={{ width: '100%', marginBottom: '16px' }}
                />
              )}
            </FormItem>
            <FormItem label="Job Title">
              {getFieldDecorator('jobTitle', {
                initialValue: user.jobTitle || '',
              })(<Input />)}
            </FormItem>
            <FormItem label="First Name and Second Name">
              {getFieldDecorator('username', {
                initialValue: user.username || '',
                rules: [
                  {
                    required: true,
                    message: 'Please input your First Name and Second Name!',
                  },
                ],
              })(<Input />)}
            </FormItem>
            <FormItem label="Password">
              {getFieldDecorator('password', {
                rules: [
                  {
                    validator: this.checkConfirm,
                  },
                ],
              })(<Input type="password" autoComplete="new-password" />)}
            </FormItem>
            <FormItem label="Confirm Password">
              {getFieldDecorator('passwordConfirmation', {
                rules: [
                  {
                    validator: this.checkPassword,
                  },
                ],
              })(<Input type="password" onBlur={this.handleConfirmBlur} />)}
            </FormItem>
            <FormItem label="User Id (email)">
              {getFieldDecorator('email', {
                initialValue: user.email || '',
                rules: [
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ],
              })(<Input />)}
            </FormItem>

            <FormItem label="Access Officer">
              {getFieldDecorator('accessOfficer', {
                initialValue: user.accessOfficer || currentUser.email,
              })(<Input disabled />)}
            </FormItem>
            <FormItem label="Provided Date">
              {getFieldDecorator('providedDate', {
                initialValue: moment(user.providedDate ? user.providedDate : new Date()).format(
                  'YYYY/MM/DD'
                ),
              })(<Input disabled />)}
            </FormItem>
            <FormItem label="attachments">
              <Uploader
                multiple
                onChange={this.onServiceFileUpload}
                defaultFileList={respondedFiles}
              />
            </FormItem>
          </Form>
        </Modal>
      </div>
    );
  }
}

UserForm.propTypes = propTypes;

export default Form.create()(UserForm);
