import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Form, Card, Alert, message } from 'antd';
import { ExtendedField as Field, BaseForm, ProductsTree } from 'modules/common/components';
import { productDescription, productReminder } from '../constants';

let validatedProductsInfo = [];
let validatedProductCodes = [];

const codeRemoveHandler = (context, e) => {
  const { __ } = context;

  if (e.target.classList.contains('ant-select-selection__choice__remove')) {
    const parent = e.target.parentElement;
    const itemToRemove = parent.querySelector('.ant-select-selection__choice__content').innerText;

    for (const validItem of validatedProductsInfo) {
      if (itemToRemove.toLowerCase().includes(validItem)) {
        message.error(__('Can not remove already validated code'));
        e.preventDefault();
        e.stopPropagation();
      }
    }
  }

  if (e.target.classList.contains('ant-select-selection__choice__remove')) {
    const parent = e.target.parentElement;
    const itemToRemove = parent.querySelector('.ant-select-selection__choice__content').innerText;
    for (const validItem of validatedProductCodes) {
      if (itemToRemove.toLowerCase().includes(validItem)) {
        message.error(__('Can not remove already validated code'));
        e.preventDefault();
        e.stopPropagation();
      }
    }
  }
  if (
    e.target.classList.contains('ant-select-tree-title') ||
    e.target.classList.contains('ant-select-tree-node-selected')
  ) {
    const parent = e.target.parentElement;
    const itemSelectedToRemove = parent.querySelector('.ant-select-tree-title').innerText;

    for (const validItem of validatedProductCodes) {
      if (itemSelectedToRemove.toLowerCase().includes(validItem)) {
        message.error(__('Can not remove already validated code'));
        e.preventDefault();
        e.stopPropagation();
      }
    }
  }
};

class RegistrationForm extends BaseForm {
  componentDidMount() {
    document
      .querySelector('body')
      .addEventListener('click', codeRemoveHandler.bind(this, this.context), true);
  }

  componentWillUnmount() {
    document.querySelector('body').removeEventListener('click', codeRemoveHandler, true);
  }

  handleSubmit(e) {
    e.preventDefault();

    const { send, viewMode, showExtendedField } = this.props;

    if (showExtendedField && viewMode) {
      send();
    } else {
      const { __ } = this.context;
      const productCodes = this.getFieldValue('productsInfo');

      if (productCodes.length < 21) {
        this.saveDirect(this.getFieldValue('productsInfo'), true);
      } else {
        message.error(__('You can not choose 20+ product and service codes'));
      }
    }
  }

  render() {
    validatedProductsInfo = this.props.validatedProductsInfo;
    validatedProductCodes = this.props.validatedProductCodes;

    const { __ } = this.context;
    const viewMode = this.props.viewMode;
    const deActive = this.props.deActivateFunc;

    return (
      <Form>
        <Card>
          <Field
            viewMode={viewMode}
            label="Product codes"
            initialValue={this.props.data}
            name="productsInfo"
            control={
              <ProductsTree searchPlaceholder={__('Please select products')} disabled={viewMode} />
            }
          />

          <Alert
            message={__('Help')}
            description={__(productDescription)}
            type="info"
            style={{ marginBottom: '24px' }}
          />

          <div
            className="ant-alert ant-alert-warning ant-alert-with-description ant-alert-no-icon"
            style={{ marginBottom: '24px' }}
          >
            <div className="ant-alert-description">
              <p>{__(productReminder)}</p>

              <div>{__('Email')}: OTOyusupport@ot.mn</div>
              <div>{__('Phone')}: +976 1133 1880 ext:8888</div>
            </div>
          </div>
        </Card>

        {this.renderGoBack()}
        {this.renderSubmit('Save & submit', this.handleSubmit)}
        {deActive}
      </Form>
    );
  }
}

RegistrationForm.contextTypes = {
  __: PropTypes.func,
  locale: PropTypes.string,
};

const ProductsForm = Form.create()(RegistrationForm);

export default withRouter(ProductsForm);
