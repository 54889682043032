/* eslint-disable react/display-name */

import React from 'react';
import { withRouter } from 'react-router';
import { Card, Row, Col, Button, Icon, Popconfirm } from 'antd';
import { Search } from 'modules/common/components';
import { readFileUrl } from 'modules/common/utils';
import { Common, Sidebar } from 'modules/companies/components';
import moment from 'moment';
import { riskValue } from './constants';
import { dateFormat } from 'modules/common/constants';
import ModalForm from './Modal';
import RiskForm from './RiskModal';
import CompanyLink from './RelatedModal';
import ShowRelatedCompanies from './ShowRelatedCompanies';

class DueDiligence extends Common {
  constructor(props) {
    super(props);

    this.state = {
      currentCompany: null,
      modalData: null,
      selectedSuppliers: [],
    };

    this.showRiskModal = this.showRiskModal.bind(this);
    this.hideRiskModal = this.hideRiskModal.bind(this);
    this.handleRiskSave = this.handleRiskSave.bind(this);
    this.toggleDueDiligenceModal = this.toggleDueDiligenceModal.bind(this);
    this.handleCloseDateSave = this.handleCloseDateSave.bind(this);
    this.handleCheck = this.handleCheck.bind(this);

    this.reports = {};
  }

  handleCheck(companyIds, selectedSuppliers) {
    this.setState({ selectedSuppliers });

    this.onSelectedCompaniesChange(companyIds);
  }

  handleRiskSave(values) {
    const { currentCompany } = this.state;

    this.props.addRisk({ supplierId: currentCompany._id, ...values });
    this.hideRiskModal();
  }

  showRiskModal(company) {
    this.setState({ currentCompany: company });
  }

  hideRiskModal() {
    this.setState({ currentCompany: null });
  }

  toggleDueDiligenceModal(supplierId) {
    this.setState({ modalData: this.state.modalData ? null : { supplierId } });
  }

  handleCloseDateSave(values) {
    this.props.updateCloseDate(values);
  }

  renderFile(record) {
    const lastDueDiligence = record.lastDueDiligence || {};
    const files = lastDueDiligence.files;

    if (!files) {
      return '-';
    }

    const fileList = files.map(file => (
      <span key={file.url}>
        <a href={readFileUrl(file.url)} target="__blank">
          {file.name}
        </a>
        <br />
      </span>
    ));

    return <>{fileList}</>;
  }

  renderDueDiligenceDate(record, key, key2) {
    const lastDueDiligence = record.lastDueDiligence;

    if (!lastDueDiligence) return '-';

    const date = lastDueDiligence[key] || lastDueDiligence[key2];

    return date && moment(date).format(dateFormat);
  }

  renderRisk(record) {
    const lastDueDiligence = record.lastDueDiligence || {};
    const value = lastDueDiligence.risk;
    const removeRisk = this.props.removeRisk;

    return (
      <>
        <span className="small-label">
          <Icon type="edit" onClick={() => this.showRiskModal(record)} />
          &nbsp;
          {value && (
            <Popconfirm title="Are you sure ?" onConfirm={() => removeRisk(record._id)}>
              <Icon type="close-circle" />
            </Popconfirm>
          )}
        </span>
        {value && <span className={`small-label ${value}`}>&nbsp; {riskValue[value]}</span>}
      </>
    );
  }

  showRelatedCompany(record) {
    const { lastDueDiligence } = record || {};
    const relatedSuppliers = lastDueDiligence && lastDueDiligence.relatedSuppliers;

    if (!relatedSuppliers) return <></>;
    const comment = lastDueDiligence.relatedSupplierComment;

    return (
      <>
        <ShowRelatedCompanies relatedSuppliers={relatedSuppliers} comment={comment} />
        <CompanyLink
          suppliers={relatedSuppliers}
          save={this.props.saveRelatedCompany}
          comment={comment}
        />
      </>
    );
  }

  render() {
    const { totalCount, exportExcel, cancel, saveRelatedCompany } = this.props;
    const { selectedCompanies, currentCompany, selectedSuppliers } = this.state;

    const columns = this.getWrappedColumns(
      [
        {
          title: 'Related companies',
          width: 40,
          render: record => this.showRelatedCompany(record),
        },
      ],
      [
        {
          title: 'Invitation date',
          width: 40,
          render: record => this.renderDueDiligenceDate(record, 'date', 'createdDate'),
        },
        {
          title: 'Close date',
          width: 40,
          render: record => this.renderDueDiligenceDate(record, 'closeDate'),
        },
        {
          title: 'Supplier submission',
          width: 40,
          render: record => this.renderDueDiligenceDate(record, 'supplierSubmissionDate'),
        },
        {
          title: 'Report file',
          width: 40,
          render: record => this.renderFile(record),
        },
        {
          title: 'Publish date',
          width: 40,
          render: record => this.renderDueDiligenceDate(record, 'fileUploadDate'),
        },
        {
          title: 'Risk',
          width: 40,
          key: 'risk',
          render: record => this.renderRisk(record, 'risk'),
        },
        {
          title: 'Actions',
          width: 40,
          render: record => {
            const editBtn = <a href={`/due-diligence/${record._id}`}>Edit</a>;

            return (
              <>
                {record.isDueDiligenceValidated !== null ? (
                  <>
                    <Popconfirm title="Are you sure ?" onConfirm={cancel.bind(this, record._id)}>
                      <span className="small-label pointer">Cancel</span>
                    </Popconfirm>

                    {!record.isDueDiligenceValidated && <>&nbsp;|&nbsp; {editBtn}</>}
                  </>
                ) : (
                  editBtn
                )}
                {!record.isDueDiligenceEditable && (
                  <>
                    &nbsp;|&nbsp;
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => this.toggleDueDiligenceModal(record._id)}
                    >
                      Enable
                    </Button>
                  </>
                )}
              </>
            );
          },
        },
      ],
      true
    );

    return (
      <Row gutter={16}>
        <Sidebar
          suppliersCount={totalCount}
          checkedCount={selectedCompanies ? selectedCompanies.length : 0}
        />

        <Col span={19}>
          <Card title="Suppliers">
            <div className="table-operations">
              <Search />
              {this.renderSearchButtons()}

              <CompanyLink suppliers={selectedSuppliers} save={saveRelatedCompany} />

              <Button onClick={() => exportExcel(selectedCompanies)}>
                Export excel
                <Icon type="file-excel" />
              </Button>
            </div>

            {this.renderTable({
              rowSelection: {
                selectedCompanies,
                onChange: this.handleCheck,
              },
              columns,
            })}
          </Card>

          <RiskForm
            company={currentCompany}
            hide={this.hideRiskModal}
            onSave={this.handleRiskSave}
          />

          <ModalForm
            data={this.state.modalData}
            hide={this.toggleDueDiligenceModal}
            onSave={this.handleCloseDateSave}
            isEnableForm={true}
          />
        </Col>
      </Row>
    );
  }
}

export default withRouter(DueDiligence);
