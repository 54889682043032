import React from 'react';
import PropTypes from 'prop-types';
import { dateFormat } from 'modules/common/constants';
import { Modal, Form, Select, Row, DatePicker } from 'antd';
import moment from 'moment';

const FormItem = Form.Item;
const Option = Select.Option;

class DueDiligenceModal extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    const { form, onSave, data } = this.props;
    const { supplierId } = data;

    form.validateFieldsAndScroll((err, values) => {
      if (!err) onSave(supplierId ? { ...values, supplierId } : values);
    });
  }

  render() {
    const { form, hide, data, isEnableForm = false } = this.props;

    if (!data) return null;

    const { getFieldDecorator } = form;
    const status = data.dueDiligenceStatusDisplay;
    const validated = data.isDueDiligenceValidated;
    const args = {
      rules: [{ required: true, message: 'This field is required!' }],
    };

    const title = isEnableForm ? 'Enable request approve' : 'Due diligence verification data';

    return (
      <Modal
        title={title}
        onOk={this.handleSubmit}
        visible={true}
        maskClosable={false}
        onCancel={hide}
        bodyStyle={{ maxHeight: '60vh', overflow: 'scroll' }}
      >
        <Form onSubmit={this.handleSubmit}>
          {!isEnableForm && (
            <Row>
              Status: <span className="data-label">{status}</span>
            </Row>
          )}

          {!isEnableForm && (
            <FormItem label="Date">
              {getFieldDecorator('date', { ...args, initialValue: moment() })(
                <DatePicker format={dateFormat} placeholder="Date" />
              )}
            </FormItem>
          )}

          {(!validated || isEnableForm) && (
            <FormItem label="Close Date">
              {getFieldDecorator('closeDate', { ...args, initialValue: moment().add(14, 'days') })(
                <DatePicker format={dateFormat} placeholder="Date" />
              )}
            </FormItem>
          )}

          {(!validated || isEnableForm) && (
            <FormItem label="Reminder day">
              {getFieldDecorator('reminderDay', args)(
                <Select>
                  <Option value={1}>1 day before</Option>
                  <Option value={2}>2 day before</Option>
                  <Option value={3}>3 day before</Option>
                  <Option value={4}>4 day before</Option>
                  <Option value={5}>5 day before</Option>
                  <Option value={6}>6 day before</Option>
                  <Option value={7}>7 day before</Option>
                </Select>
              )}
            </FormItem>
          )}
        </Form>
      </Modal>
    );
  }
}

DueDiligenceModal.propTypes = {
  form: PropTypes.object,
  data: PropTypes.object,
  hide: PropTypes.func,
  onSave: PropTypes.func,
  isEnableForm: PropTypes.bool,
};

export default Form.create()(DueDiligenceModal);
