const commonParams = `
  $number: String!
  $name: String!
  $content: String!
  $attachments: [JSON]
  $publishDate: Date!
  $closeDate: Date!
  $file: JSON
  $sourcingOfficer: String
  $responsibleBuyerIds: [String]
  $reminderDay: Float
  $supplierIds: [String]
  $isToAll: Boolean
  $tierTypes: [String]
  $productCodes: String
  $requestedProducts: [TenderRequestedProductInput]
  $requestedDocuments: [String]
`;

const commonFields = `
  number: $number
  name: $name
  content: $content
  attachments: $attachments
  publishDate: $publishDate
  closeDate: $closeDate
  file: $file
  sourcingOfficer: $sourcingOfficer
  responsibleBuyerIds: $responsibleBuyerIds
  reminderDay: $reminderDay
  supplierIds: $supplierIds
  isToAll: $isToAll
  tierTypes: $tierTypes
  productCodes: $productCodes
  requestedProducts: $requestedProducts
  requestedDocuments: $requestedDocuments
`;

const tendersAdd = `
  mutation tendersAdd(
    $type: String!,
    $rfqType: String,
    $freightType: String,
    ${commonParams}
  ) {
    tendersAdd(
      type: $type,
      rfqType: $rfqType,
      freightType: $freightType,
      ${commonFields}
    ) {
      _id
    }
  }
`;

const tendersEdit = `
  mutation tendersEdit(
    $_id: String!,
    $type: String!,
    $rfqType: String,
    ${commonParams}
  ) {
    tendersEdit(
      _id: $_id,
      type: $type,
      rfqType: $rfqType,
      ${commonFields}
    ) {
      _id
    }
  }
`;

const tenderResponseParams = `
  $tenderId: String!
  $isNotInterested: Boolean
  $respondedProducts: [TenderRespondedProductInput]
  $respondedFiles: [JSON]
  $respondedDocuments: [TenderRespondedDocumentInput]
  $disclaimerIsAgree: Boolean
`;

const tenderResponseFields = `
  tenderId: $tenderId,
  isNotInterested: $isNotInterested
  respondedProducts: $respondedProducts
  respondedFiles: $respondedFiles
  respondedDocuments: $respondedDocuments
  disclaimerIsAgree: $disclaimerIsAgree
`;

const tendersResponseEditParams = `
  $tenderId: String!
  $supplierAddReason: String
  $supplierId: String!
  $respondedProducts: [TenderRespondedProductInput]
  $respondedFiles: [JSON]
  $managersPermissionFile: [JSON]
 `;

const tendersResponseEditFields = `
  tenderId: $tenderId
  supplierAddReason: $supplierAddReason
  supplierId: $supplierId
  respondedProducts: $respondedProducts
  respondedFiles: $respondedFiles
  managersPermissionFile: $managersPermissionFile
 `;

const tendersResponsesAdd = `
  mutation tenderResponsesAdd(
    ${tenderResponseParams}
  ) {
    tenderResponsesAdd(
      ${tenderResponseFields}
    ) {
      _id
    }
  }
`;

const tendersResponsesEdit = `
  mutation tenderResponsesEdit(
    ${tenderResponseParams}
  ) {
    tenderResponsesEdit(
      ${tenderResponseFields}
    ) {
      _id
    }
  }
`;

const tenderResponsesSend = `
  mutation tenderResponsesSend($tenderId: String, $supplierId: String) {
    tenderResponsesSend(tenderId: $tenderId, supplierId: $supplierId) {
      _id
    }
  }
`;

const tendersAward = `
  mutation tendersAward(
    $_id: String!
    $supplierIds: [String!]!
    $note: String
    $attachments: [TenderAwardAttachment]
  ) {
    tendersAward(
      _id: $_id
      supplierIds: $supplierIds
      note: $note
      attachments: $attachments
    ) {
      type
    }
  }
`;

const sendRegretLetter = `
  mutation tendersSendRegretLetter(
    $_id: String!
    $subject: String!
    $content: String!
  ) {
    tendersSendRegretLetter(
      _id: $_id
      subject: $subject
      content: $content
    )
  }
`;

const tendersCancel = `
  mutation tendersCancel($_id: String!, $cancelReason: String, $cancelReasonDesc: String ) {
    tendersCancel(_id: $_id, cancelReason: $cancelReason, cancelReasonDesc: $cancelReasonDesc) {
      _id
    }
  }
`;

const tendersCloseManually = `
  mutation tendersCloseManually($_id: String){
    tendersCloseManually(_id: $_id){
      _id
    }
  }
`;

const tendersResponseEdit = `
  mutation tendersResponseEdit(${tendersResponseEditParams}){
    tendersResponseEdit(${tendersResponseEditFields}){
      _id
    }
  }
`;

const tendersRemoveResponse = `
  mutation tendersRemoveResponse($tenderId: String!, $_id: String!, $supplierId: String!) {
    tendersRemoveResponse(tenderId: $tenderId, _id: $_id, supplierId: $supplierId)
  }
`;

const tendersToNewSupplierAdd = `
  mutation tendersToNewSupplierAdd(
    $tenderId: String
    $supplierIds: [String]
  ){
    tendersToNewSupplierAdd(
      tenderId: $tenderId, supplierIds: $supplierIds
    )
  }
`;

const userEmailSubEoi = `
  mutation userEmailSubEoi($isUserEmail: String){
      userEmailSubEoi(isUserEmail: $isUserEmail)
  }
`;

export default {
  tendersAdd,
  tendersCancel,
  tendersEdit,
  tendersResponsesAdd,
  tendersResponsesEdit,
  tendersAward,
  sendRegretLetter,
  tenderResponsesSend,
  tendersToNewSupplierAdd,
  tendersCloseManually,
  tendersResponseEdit,
  tendersRemoveResponse,
  userEmailSubEoi,
};
