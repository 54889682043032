import React from 'react';
import { withRouter } from 'react-router';
import { Form, Input, Button, Card, Select } from 'antd';
import {
  Uploader,
  ProductsTree,
  ExtendedField as Field,
  BaseForm,
} from 'modules/common/components';
import { withCurrentUser } from 'modules/auth/containers';

const FormItem = Form.Item;
const { Option } = Select;

class ValidationRoleForm extends BaseForm {
  constructor(props) {
    super(props);

    const { validationInformations } = props || {};
    const { awardedCwNumber, awardedPoNumber } = validationInformations || {};

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.state = {
      files: [],
      awardedContractSelect: awardedCwNumber || awardedPoNumber ? 'yes' : 'no',
    };
  }

  handleCancel() {
    this.props.onClose();
  }

  handleSubmit = e => {
    e.preventDefault();

    const { form, validationInformations } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }

      values._id = validationInformations._id;

      this.props.validationCreate(values);
    });
  };

  onFileUpload(files) {
    this.setState({ files: files.filter(file => file.url) });
  }

  generateOptions(flatProductsInfo, productsInfo) {
    return productsInfo.map(p => ({
      value: p,
      label: flatProductsInfo[p] || '',
    }));
  }

  awardedContact = () => {
    const { awardedContractSelect } = this.state;

    const onChangeAwardedContact = e => {
      this.setState({ awardedContractSelect: e });
    };

    return (
      <FormItem label="Awarded Contracts">
        <div style={{ alignItems: 'center' }}>
          <Select
            onChange={onChangeAwardedContact}
            initialValue={awardedContractSelect}
            value={awardedContractSelect}
            disabled={this.isDisable}
          >
            <Select.Option key={Math.random()} value="yes">
              {'yes'}
            </Select.Option>
            <Select.Option key={Math.random()} value="no">
              {'no'}
            </Select.Option>
          </Select>
        </div>
      </FormItem>
    );
  };

  awardedContactCWNumberInput = () => {
    const { getFieldDecorator } = this.props.form;
    const { awardedContractSelect } = this.state;
    const { awardedCwNumber, awardedCwNumberFile } = this.props.validationInformations;

    return awardedContractSelect === 'yes' ? (
      <FormItem label="Awarded contract CW number">
        <div style={{ display: 'flex', alignItems: 'baseline', width: '100%' }}>
          {getFieldDecorator('awardedCwNumber', {
            initialValue: awardedCwNumber,
          })(
            <Input
              placeholder="CW Number fill"
              style={{ marginRight: '25px' }}
              disabled={this.isDisable}
            />
          )}

          {getFieldDecorator('awardedCwNumberFile', {})(
            <Uploader
              defaultFileList={awardedCwNumberFile}
              onChange={this.onFileUpload}
              disabled={this.isDisable}
            />
          )}
        </div>
      </FormItem>
    ) : null;
  };

  awardedPoandLa = () => {
    const { getFieldDecorator } = this.props.form;
    const { awardedContractSelect } = this.state;
    const { awardedPoNumber, awardedPoFile } = this.props.validationInformations;

    return awardedContractSelect === 'yes' ? (
      <FormItem label="Awarded PO/LA Number">
        <div style={{ display: 'flex', alignItems: 'baseline', width: '100%' }}>
          {getFieldDecorator('awardedPoNumber', {
            initialValue: awardedPoNumber,
          })(<Input placeholder="PO/LA Number" style={{ marginRight: '25px' }} />)}

          {getFieldDecorator('awardedPoFile', {})(
            <Uploader
              defaultFileList={awardedPoFile}
              onChange={this.onFileUpload}
              disabled={this.isDisable}
            />
          )}
        </div>
      </FormItem>
    ) : null;
  };

  distributorType = () => {
    const { getFieldDecorator } = this.props.form;
    const { distributorType, distributorFile } = this.props.validationInformations;

    return (
      <Form.Item label="Distributor type">
        {getFieldDecorator('distributorType', {
          initialValue: distributorType,
          rules: [{ required: true, message: 'This field is required!' }],
        })(
          <Select
            mode="multiple"
            style={{ marginRight: '25px' }}
            placeholder="Please select distributor type"
          >
            <Option value="OEM">Original Equipment Manufacturer (OEM)</Option>
            <Option value="serviceProvider">Service Provider</Option>
            <Option value="distributor">Distributor</Option>
            <Option value="noneOfAbove">None of above</Option>
          </Select>
        )}
        {getFieldDecorator('distributorFile', {})(
          <Uploader
            defaultFileList={distributorFile}
            onChange={this.onFileUpload}
            disabled={this.isDisable}
          />
        )}
      </Form.Item>
    );
  };

  supplierValidationInformations = () => {
    const { validationInformations } = this.props;

    const {
      awardedRfqs,
      availableProducts,
      awardedRfqProductCodes,
      businessType,
    } = validationInformations;

    let placeholder = '';

    if (awardedRfqs.length === 0) {
      placeholder = 'You are not awarded in RFQs';
    }

    return (
      <>
        <Field
          viewMode={true}
          label="Available Product Codes for Validation"
          initialValue={availableProducts}
          name="availableProducts"
          control={<ProductsTree disabled={true} />}
        />
        <Field
          viewMode={true}
          label="Awarded RFQ Product Codes:"
          initialValue={awardedRfqProductCodes || []}
          name="awardedRfqProductCodes"
          optional={true}
          control={<ProductsTree disabled={true} />}
        />
        <Field
          label="Awarded RFQ:"
          initialValue={awardedRfqs}
          name="awardedRfqs"
          optional={true}
          control={<ProductsTree searchPlaceholder={placeholder} disabled={true} />}
        />
        <FormItem label="Business type:">
          <Input disabled={true} defaultValue={businessType} />
        </FormItem>
      </>
    );
  };

  renderOperations = () => {
    const {
      currentUser: { validationRequestedType },
    } = this.props;

    return (
      <FormItem>
        {validationRequestedType &&
        !['approved', 'declined', 'manager', 'validated'].includes(
          validationRequestedType.validationRequestedType
        ) ? (
          <Button type="primary" onClick={this.handleSubmit} style={{ width: '80px' }}>
            {validationRequestedType &&
            !['checked', 'adminsent'].includes(validationRequestedType.validationRequestedType)
              ? 'Edit'
              : 'Submit'}
          </Button>
        ) : (
          ''
        )}
      </FormItem>
    );
  };

  render() {
    return (
      <Form style={{ width: '700px' }}>
        <Card title="Validation Create Form">
          {this.supplierValidationInformations()}
          {this.awardedContact()}
          {this.awardedContactCWNumberInput()}
          {this.awardedPoandLa()}
          {this.distributorType()}
          {this.renderOperations()}
        </Card>
      </Form>
    );
  }
}

export default withCurrentUser(Form.create()(withRouter(ValidationRoleForm)));
