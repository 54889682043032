const addressFields = `
  address,
  address2,
  address3,
  townOrCity,
  province,
  zipCode,
  country,
`;

const basicInfoFields = `
  isRegisteredOnSup,
  sapNumber,
  enName,
  mnName,
  ${addressFields}
  registeredInCountry,
  registeredInAimag,
  registeredInSum,
  isChinese
  corporateStructure,
  registrationNumber,
  certificateOfRegistration,
  email,
  website,
  foreignOwnershipPercentage,
  totalNumberOfEmployees,
  totalNumberOfUmnugoviEmployees,
  totalNumberOfMongolianEmployees,
`;

const contactInfoFields = `
  name,
  jobTitle,
  ${addressFields}
  phone,
  phone2,
  email,
`;

const managementTeamItemFields = `
  name,
  jobTitle,
  phone,
  email,
`;

const managementTeamFields = `
  managingDirector {
    ${managementTeamItemFields}
  },

  executiveOfficer {
    ${managementTeamItemFields}
  },

  salesDirector {
    ${managementTeamItemFields}
  },

  financialDirector {
    ${managementTeamItemFields}
  },

  otherMember1 {
    ${managementTeamItemFields}
  },

  otherMember2 {
    ${managementTeamItemFields}
  },

  otherMember3 {
    ${managementTeamItemFields}
  },
`;

const shareholderItemFields = `
  type,
  firstName,
  lastName,
  jobTitle,
  companyName,
  percentage,
`;

const certificateInfoFields = `
  description,
  file,
`;

const groupInfoFields = `
  hasParent,
  isParentExistingSup,
  parentName,
  parentAddress,
  parentRegistrationNumber,
  role,
  isExclusiveDistributor,
  attachments,
  primaryManufacturerName,
  countryOfPrimaryManufacturer,
  authorizedDistributions,
  factories {
    name,
    townOrCity,
    country,
    productCodes,
  }
`;

const registrationFields = `
  _id
  basicInfo {
    ${basicInfoFields}
  }
  contactInfo {
    ${contactInfoFields}
  }
  managementTeamInfo {
    ${managementTeamFields}
  }
  groupInfo {
    ${groupInfoFields}
  }
  shareholderInfo {
    attachments,
    shareholders {
      ${shareholderItemFields}
    }
  }
  certificateInfo {
    ${certificateInfoFields}
  }
  productsInfo
`;

export const companyByUser = `
  query companyByUser {
    companyByUser {
      isSentRegistrationInfo
      isSkippedPrequalification
      ${registrationFields}
      isDueDiligenceEditable
      validatedProductsInfo
      deActiveStatus{
        status
        deActive
        supplierSentReActivate
      }
    }
  }
`;

const prequalificationFields = `
  _id
  basicInfo {
    ${basicInfoFields}
  }
  financialInfo {
    canProvideAccountsInfo
    reasonToCannotNotProvide
    currency
    annualTurnover {
      year
      amount
    }
    preTaxProfit {
      year
      amount
    }
    totalAssets {
      year
      amount
    }
    totalCurrentAssets {
      year
      amount
    }
    totalShareholderEquity {
      year
      amount
    }
    recordsInfo {
      date
      file
    }
    isUpToDateSSP
    isUpToDateCTP
  }
  businessInfo {
    doesMeetMinimumStandarts
    doesMeetMinimumStandartsFile
    doesHaveJobDescription
    doesHaveJobDescriptionFile
    doesConcludeValidContracts
    doesConcludeValidContractsFile
    employeeTurnoverRate
    doesHaveLiabilityInsurance
    doesHaveLiabilityInsuranceFile
    doesHaveCodeEthics
    doesHaveCodeEthicsFile
    doesHaveResponsiblityPolicy
    doesHaveResponsiblityPolicyFile
    hasConvictedLabourLaws
    hasConvictedForHumanRights
    hasConvictedForBusinessIntegrity
    proveHasNotConvicted
    hasLeadersConvicted
    doesEmployeePoliticallyExposed
    pepName
    organizationChartFile
    hasConvictedLabourLawsDescription
    hasConvictedForHumanRightsDescription
    isSubContractor

    investigations {
      name
      date
      status
      statusDate
    }
  }
  environmentalInfo {
    doesHavePlan
    doesHavePlanFile
    hasEnvironmentalRegulatorInvestigated
    dateOfInvestigation
    reasonForInvestigation
    actionStatus
    investigationDocumentation
    hasConvictedForEnvironmentalLaws
    proveHasNotConvicted
  }
  healthInfo {
    doesHaveHealthSafety
    doesHaveHealthSafetyFile
    areHSEResourcesClearlyIdentified
    areHSEResourcesClearlyIdentifiedFile
    doesHaveDocumentedProcessToEnsure
    doesHaveDocumentedProcessToEnsureFile
    areEmployeesUnderYourControl
    areEmployeesUnderYourControlFile
    doesHaveDocumentForRiskAssesment
    doesHaveDocumentForRiskAssesmentFile
    doesHaveDocumentForIncidentInvestigation
    doesHaveDocumentForIncidentInvestigationFile
    doesHaveDocumentedFitness
    doesHaveDocumentedFitnessFile
    isWillingToComply
    hasIndustrialAccident
    tmha
    ltifr
    injuryExplanation
    seniorManagement
    isWillingToCommit
    isPerparedToCompile
    hasWorkedOnWorldBank
    hasWorkedOnWorldBankDescription
    hasWorkedOnLargeProjects
    hasWorkedOnLargeProjectsDescription
    doesHaveLicense
    doesHaveLicenseDescription
  }
`;

export const companyPrequalificationDetail = `
  query companyByUser {
    companyByUser {
      ${prequalificationFields}
      isPrequalificationInfoEditable
      isPrequalified
      isSkippedPrequalification
      prequalificationSkippedReason
      prequalifiedStatus
    }
  }
`;

const genericParams = `
  $search: String
  $region: String
  $productCodes: String
  $includeBlocked: Boolean
  $productsInfoStatus: String
  $prequalifiedStatus: String
  $qualifiedStatus: String
  $difotScore: String
  $source: String
  $_ids: [String]
  $page: Int
  $perPage: Int,
  $searchValue: String,
  $fieldNames: String,
  $dueDiligenceStatus: String,
  $dueDiligenceRisk: String
  $activationStatus: String,
  $sapRegistration: String,
  $suppliersScale: String
`;

const commonParams = `
  ${genericParams}
  $sortField: String
  $sortDirection: Int
`;

const genericValues = `
  search: $search
  region: $region
  productCodes: $productCodes
  includeBlocked: $includeBlocked
  productsInfoStatus: $productsInfoStatus
  prequalifiedStatus: $prequalifiedStatus
  qualifiedStatus: $qualifiedStatus,
  difotScore: $difotScore
  source: $source
  _ids: $_ids
  page: $page
  perPage: $perPage
  searchValue: $searchValue
  fieldNames: $fieldNames
  dueDiligenceStatus: $dueDiligenceStatus
  dueDiligenceRisk: $dueDiligenceRisk
  activationStatus: $activationStatus
  sapRegistration: $sapRegistration
  suppliersScale: $suppliersScale
`;

const commonValues = `
  ${genericValues}
  sortField: $sortField
  sortDirection: $sortDirection
`;

const commonFields = `
  _id
  basicInfo {
    enName,
    email,
    sapNumber,
    registrationNumber
  }
  contactInfo {
    name,
    email,
    phone
  }
  isPrequalified
  tierType
  tierTypeDisplay
  prequalificationStatusDisplay
  shareholderInfo {
    shareholders {
      type
      firstName
      lastName
    }
  }
  managementTeamInfo {
    managingDirector {
      name
    }
    executiveOfficer {
      name
    }
  }
  isSentRequiredInformation
`;

const companies = `
  query companies(${commonParams}) {
    companies(${commonValues}) {
      ${commonFields}
      owner {
        email
      }
      tierType
      averageDifotScore
      lastDueDiligence

      qualificationStatusDisplay
      isBlocked
      productsInfoValidationStatusDisplay

      financialInfo {
        annualTurnover {
          year
          amount
        }
      }     
      deActiveStatus{
        status
        deActive
        supplierSentReActivate
        adminSentReActivateDate
        deActivatedAdmin
        deActivatedDate
        deActiveReason
        deActiveReasonDesc
        userEmail
        reActivatedDate
      } 
    }
  }
`;

const exportCompanies = `
  query companiesExport(${commonParams}) {
    companiesExport(${commonValues})
  }
`;

const exportCompany = `
  query companyRegistrationExport($_id: String!) {
    companyRegistrationExport(_id: $_id)
  }
`;

const exportCurrentCompanyRegistration = `
  query companyRegistrationSupplierExport {
    companyRegistrationSupplierExport
  }
`;

const exportCurrentCompanyPrequalification = `
  query companyPrequalificationSupplierExport {
    companyPrequalificationSupplierExport
  }
`;

const status = `
  query companies(${genericParams}) {
    companies(${genericValues} sortField: "prequalifiedDate", sortDirection: -1) {
      ${commonFields}
      prequalifiedDate
      prequalificationSubmittedCount
      prequalificationInfoSentDate
    }
  }
`;

const difot = `
  query companies(${commonParams}) {
    companies(${commonValues}) {
      ${commonFields}
      lastDifotScore
      averageDifotScore
    }
  }
`;

const dueDiligence = `
  query companies(${commonParams}) {
    companies(${commonValues}) {
      ${commonFields}

      dueDiligenceStatusDisplay
      lastDueDiligence
      isDueDiligenceValidated
      isDueDiligenceEditable
    }
  }
`;

const feedback = `
  query companies(${commonParams}) {
    companies(${commonValues}) {
      ${commonFields}
      lastFeedback {
        closeDate
        supplierResponse {
          _id
          status
          totalEmploymentOt
          totalEmploymentUmnugovi
          employmentChangesAfter
          numberOfEmployeeWorkToScopeNational
          numberOfEmployeeWorkToScopeUmnugovi
          procurementTotalSpend
          procurementNationalSpend
          procurementUmnugoviSpend
          corporateSocial
          otherStories
        }
      }
    }
  }
`;

const validation = `
  query companies(${commonParams}) {
    companies(${commonValues}) {
      ${commonFields}
      productsInfo
      validatedProductsInfo
      isProductsInfoValidated
      lastProductsInfoValidation

      validationRequestedSuppliers {
        _id
        buyerSetValidationDate
        validationRequestedType
        isValidationCreated

        sentValidationRequestDate
        validatedDate
        approvedDate
        
        adminDeclinedDate
        managerDeclinedDate
      }
    }
  }
`;

const audit = `
  query companies(${commonParams}) {
    companies(${commonValues}) {
      ${commonFields}
      qualificationStatusDisplay
    }
  }
`;

const capacityBuilding = `
  query companies(${commonParams}) {
    companies(${commonValues}) {
      ${commonFields}
      isSentPrequalificationInfo
      isPrequalificationInfoEditable
      certificateInfo {
        description
        file
      }
    }
  }
`;

const auditResubmissionRequests = `
  query companies(${commonParams}) {
    companies(${commonValues}) {
      ${commonFields}
      qualificationStatusDisplay
      qualificationState
    }
  }
`;

const simpleCompanies = `
  query companies(${commonParams}) {
    companies(${commonValues}) {
      _id
      basicInfo {
        mnName,
        enName
      }
      shareholderInfo {
        shareholders {
          firstName,
          lastName
        }
      }
    }
  }
`;

const companyDetail = `
  query companyDetail($_id: String!) {
    companyDetail(_id: $_id) {
      ${registrationFields}
      isPrequalified
      deActiveStatus{
        deActive
      }
    }
  }
`;

const companiesTotalCount = `
  query companiesTotalCount(${commonParams}) {
    companiesTotalCount(${commonValues})
  }
`;

const exportCurrentCompanyDeActivation = `
  query companyDeActivationSupplierExport(${commonParams}) {
    companyDeActivationSupplierExport(${commonValues})
  }
`;
const validatedProductCodes = `
  query validatedProductCodes{
    validatedProductCodes
  }
    
`;

export default {
  companyByUser,
  companyPrequalificationDetail,
  companies,
  companiesTotalCount,
  simpleCompanies,
  exportCompanies,
  dueDiligence,
  difot,
  validation,
  feedback,
  status,
  prequalificationFields,
  companyDetail,
  audit,
  capacityBuilding,
  auditResubmissionRequests,
  exportCompany,
  exportCurrentCompanyRegistration,
  exportCurrentCompanyPrequalification,
  exportCurrentCompanyDeActivation,
  // use due diligence query
  registrationFields,
  addressFields,
  managementTeamItemFields,
  validatedProductCodes,
};
