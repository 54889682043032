import React from 'react';
import { gql, graphql, compose } from 'react-apollo';
import { mutations, queries } from '../graphql';
import { ValidationRoleForm, ValidationManagerForm } from '../components';
import { Loading } from 'modules/common/components';
import { withCurrentUser } from 'modules/auth/containers';
import { withRouter } from 'react-router';
import { alert } from 'modules/common/utils';

class ValidationRoleFormContainer extends React.Component {
  createValidationResponse = doc => {
    const {
      currentUser,
      adminValidateCodesMutation,
      createRoleValidationResponseMutation,
      createManagerValidationResponseMutation,
      getSupplierWithRoleRelatedValidationInfoQuery,
    } = this.props;

    let mutation = createManagerValidationResponseMutation;
    let successText = 'Successfully Approved';

    if (currentUser.role !== 'manager') {
      mutation = createRoleValidationResponseMutation;
      successText = 'Successfully sent to Manager';
    }

    if (currentUser.role !== 'manager' && doc.action === 'validate') {
      mutation = adminValidateCodesMutation;
      successText = 'Successfully validated';
    }

    mutation({ variables: doc })
      .then(() => {
        alert.success(successText);
        getSupplierWithRoleRelatedValidationInfoQuery.refetch();

        setTimeout(() => {
          window.location.replace('/validation');
        }, 2000);
      })
      .catch(error => {
        alert.error(error.message);
      });
  };

  fixedValidationResponse = doc => {
    const {
      currentUser,
      fixValidationResponseMutation,
      getSupplierWithRoleRelatedValidationInfoQuery,
    } = this.props;
    let mutation = fixValidationResponseMutation;
    let successText = 'Send to Supplier';

    if (currentUser.role === 'admin') {
      mutation({ variables: doc })
        .then(() => {
          alert.success(successText);
          getSupplierWithRoleRelatedValidationInfoQuery.refetch();

          setTimeout(() => {
            window.location.replace('/validation');
          }, 2000);
        })
        .catch(error => {
          alert.error(error.message);
        });
    }
  };

  declineValidationRequest = doc => {
    const { declineValidationRequestMutation } = this.props;

    declineValidationRequestMutation({ variables: doc })
      .then(() => {
        alert.success('Successfully Declined');
        setTimeout(() => {
          window.location.replace('/validation');
        }, 2000);
      })
      .catch(error => {
        alert.error(error.message);
      });
  };

  render() {
    const { getSupplierWithRoleRelatedValidationInfoQuery, currentUser } = this.props;

    if (getSupplierWithRoleRelatedValidationInfoQuery.error) {
      return null;
    }

    if (getSupplierWithRoleRelatedValidationInfoQuery.loading) {
      return <Loading />;
    }

    const extendedProps = {
      ...this.props,
      data: getSupplierWithRoleRelatedValidationInfoQuery.getSupplierWithRoleRelatedValidationInfo,
      responseSend: this.createValidationResponse,
      decline: this.declineValidationRequest,
      responseFixed: this.fixedValidationResponse,
    };

    if (currentUser.role !== 'manager') {
      return <ValidationRoleForm {...extendedProps} />;
    }

    return <ValidationManagerForm {...extendedProps} />;
  }
}

export default compose(
  graphql(gql(queries.getSupplierWithRoleRelatedValidationInfo), {
    name: 'getSupplierWithRoleRelatedValidationInfoQuery',
    options: ({ match }) => {
      return {
        variables: {
          validationId: match.params.id,
        },
        fetchPolicy: 'network-only',
      };
    },
  }),
  graphql(gql(mutations.createManagerValidationResponse), {
    name: 'createManagerValidationResponseMutation',
  }),
  graphql(gql(mutations.createRoleValidationResponse), {
    name: 'createRoleValidationResponseMutation',
  }),
  graphql(gql(mutations.adminValidateCodes), {
    name: 'adminValidateCodesMutation',
  }),
  graphql(gql(mutations.declineValidationRequest), {
    name: 'declineValidationRequestMutation',
  }),
  graphql(gql(mutations.fixValidationResponse), {
    name: 'fixValidationResponseMutation',
  })
)(withRouter(withCurrentUser(ValidationRoleFormContainer)));
