import React from 'react';
import PropTypes from 'prop-types';
import { compose, gql, graphql } from 'react-apollo';
import { SupplierTenders } from '../components';
import listCommonQueriesGenerator from './listCommonQueriesGenerator';
import { message } from 'antd';
import { queries as companyQueries } from '../../companies/graphql';

class TendersContainer extends React.Component {
  componentDidMount() {
    const { tendersTableQuery } = this.props;
    const location = this.props.location || {};

    if (location.search === '?refetch') {
      tendersTableQuery.refetch();
    }
  }

  render() {
    const {
      tendersTableQuery,
      tendersCountQuery,
      userEmailSubEoiMutation,
      companyByUserQuery,
      currentUserQuery,
    } = this.props;

    const { currentUser } = this.context;

    if (
      tendersTableQuery.loading ||
      tendersCountQuery.loading ||
      companyByUserQuery.loading ||
      currentUserQuery.loading
    ) {
      return <SupplierTenders {...{ ...this.props, loading: true }} />;
    }

    const tenders = tendersTableQuery.tendersSupplier || [];

    const totalCount = tendersCountQuery.tendersSupplierTotalCount || 1;

    const contactInfo = companyByUserQuery.companyByUser.contactInfo || {};

    const userEmailSubEoi = key => {
      userEmailSubEoiMutation({ variables: { isUserEmail: key } })
        .then(e => {
          message.success('Success');
        })
        .catch(error => {
          message.error(error.message);
        });
    };

    const updatedProps = {
      ...this.props,
      data: tenders,
      currentUser: currentUser,
      exportTenders: this.exportTenders,
      totalCount,
      userEmailSubEoi,
      contactInfo,
    };

    return <SupplierTenders {...updatedProps} />;
  }
}

TendersContainer.propTypes = {
  type: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
  tendersTableQuery: PropTypes.object,
  tendersResponsesAdd: PropTypes.func,
  queryParams: PropTypes.object,
  supplierId: PropTypes.string,
  tendersCountQuery: PropTypes.object,
};

TendersContainer.contextTypes = {
  __: PropTypes.func,
  currentUser: PropTypes.object,
};

export default compose(
  ...listCommonQueriesGenerator('tendersSupplier', 'totalSupplierTenders'),
  graphql(gql(companyQueries.companyByUser), {
    name: 'companyByUserQuery',
  })
)(TendersContainer);
